import React, { useContext } from 'react';
import '../Dashboard/profile.css';
import { AuthContext } from '../../context/AuthContext';

export default function FullySession() {

    const {state, availabilityEditData} = useContext(AuthContext);


    const handleEditAvailability = async () => {
        await availabilityEditData({bookingId: state?.editCardAvaibility?.availabilityId});
    }

    return (
    <div className="booked-session-card">
        <div className="booked-session-card-wrap">
            <div className="booked-session-card-top">
                <div className="booked-session-card-label">Your schedule for</div>
                <div className="dash-empty-sess-head">{state?.editCardAvaibility?.bookingDate}</div>
            </div>
            <div className="booked-session-card-middle">
            <div className="schedule-details-summary-timings-wrap">
                            <div className="schedule-details-summary-timings-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <g clipPath="url(#clip0_4438_77328)">
                                            <path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_4438_77328">
                                            <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                            </clipPath>
                                            </defs>
                                    </svg>
                                </div>
                                <div className="schedule-details-summary-timings-title">Each session duration</div>
                                <div className="schedule-details-summary-timings-dot"></div>
                                <div className="schedule-details-summary-timings-time">{state?.editCardAvaibility?.sessionDuration}</div>
                </div>
                <div className="schedule-details-summary-timings-wrap">
                                <div className="schedule-details-summary-timings-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"/>
                                    </svg>
                                </div>
                                <div className="schedule-details-summary-timings-title">Total sessions created</div>
                                <div className="schedule-details-summary-timings-dot"></div>
                                <div className="schedule-details-summary-timings-time">{state?.editCardAvaibility?.totalSessionsCreated}</div>
                 </div>
                 <div className="schedule-details-summary-timings-wrap">
                            <div className="schedule-details-summary-timings-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                        <path d="M4.5 6L6 7.5L11 2.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10.5 6.5V10C10.5 10.2652 10.3946 10.5196 10.2071 10.7071C10.0196 10.8946 9.76522 11 9.5 11H2.5C2.23478 11 1.98043 10.8946 1.79289 10.7071C1.60536 10.5196 1.5 10.2652 1.5 10V3C1.5 2.73478 1.60536 2.48043 1.79289 2.29289C1.98043 2.10536 2.23478 2 2.5 2H8" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="schedule-details-summary-timings-title">Number of sessions booked</div>
                                <div className="schedule-details-summary-timings-dot"></div>
                                <div className="schedule-details-summary-timings-time">{state?.editCardAvaibility?.numberOfSessionsBooked}</div>
                 </div>
            </div>
            {state?.editCardAvaibility?.pastOrFuture === "future" && (<div className="booked-session-card-btn">
                <button className='primary-btn white' onClick={handleEditAvailability}>
                   edit schedule
                </button>
            </div>)}
            <div className="booked-session-card-note">
            <div className="slot-booking-note">
                    <div className="slot-booking-note-wrap">
                            <div className="slot-booking-note-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path d="M4.11267 7.78644L7.78906 4.11029L4.63748 1.48438L1.48624 4.63541L4.11267 7.78644Z" fill="#BE1931"></path>
                                    <path d="M0.433686 4.63648C1.01357 5.217 1.95407 5.217 2.53396 4.63648L4.6349 2.53602C5.21545 1.95618 5.21545 1.01506 4.6349 0.434883C4.05535 -0.144961 3.11451 -0.144961 2.53396 0.434883L0.433686 2.53602C-0.146533 3.11586 -0.146533 4.05664 0.433686 4.63648Z" fill="#DD2E44"></path>
                                    <path d="M7.35212 5.94922C7.35212 5.94922 12.2521 11.7262 11.9898 11.9894C11.7273 12.2523 5.94959 7.35166 5.94959 7.35166L7.35212 5.94922Z" fill="#99AAB5"></path>
                                    <path d="M3.58463 9.36273C4.16417 9.94324 5.10501 9.94324 5.6859 9.36273L9.36196 5.68624C9.94185 5.10606 9.94185 4.16596 9.36196 3.58578C8.78174 3.00526 7.8409 3.00526 7.26069 3.58578L3.58463 7.26193C3.00441 7.84244 3.00441 8.78221 3.58463 9.36273Z" fill="#DD2E44"></path>
                                </svg>
                            </div>
                            <div className="slot-booking-note-text"><strong>Note: </strong>You only make changes to sessions that don’t have an active booking. A session slot cannot be changed, until either the mentor or the mentee cancels the session.</div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="booked-session-card-tag">
            <div className="booked-session-card-tag-wrap fully">
            fully Booked
            </div>
        </div> */}
    </div>
)
}
