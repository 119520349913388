import React,{useContext} from 'react'
import Girl from '../../assets/images/messageIb.png';
import useFetchRecipient from '../../hooks/useFetchRecipient.js';
import {ChatContext} from "../../context/ChatContext.js"

function UserChatProfile({chat, user, onlineUsers}) {
    const {recipientUser} = useFetchRecipient(chat, user);
    const { unreadNotificationsFun, notification, markThisUserNotificationAsRead } = useContext(ChatContext);

    const unreadNotifications = unreadNotificationsFun(notification);

    const thisUserNotification = unreadNotifications?.filter(n => n.senderId == recipientUser?._id );

    return (
        <div onClick={() => {
            if(thisUserNotification?.length !== 0 ){
                markThisUserNotificationAsRead(thisUserNotification, notification);
            }
        }}>
        <div className="dashboard-messages-inbox-peep-img">
            <img src={recipientUser?.avatar} alt="avatar" />
            <div className={onlineUsers?.some((u) => u.userId === recipientUser?._id ) ? 'online-offline-dot online' : 'online-offline-dot'}></div>
        </div>
        <div className="dashboard-messages-inbox-peep-details">
            <div className="dashboard-messages-inbox-peep-name">{recipientUser?.fullName}</div>
            <div className="dashboard-messages-inbox-peep-desig">{recipientUser?.designation}</div>
            <div>{thisUserNotification?.length > 0 && thisUserNotification?.length}</div>
        </div>
        </div>
    )
}

export default UserChatProfile