import React from 'react';
import '../blogs/blogs.css';
import star from '../../assets/images/star.svg'
import arrow from '../../assets/images/arrow.svg'; 
import { Link } from 'react-router-dom';

function PostCommunity () {
  return (
    <section className="blog-create">
      <div className="blog-container-for-line">
        <div className='createBlogWrap'>
        <div className="createBlog">
            <div className="cb-head">
                <h5>Have some thoughts you wanna share with the community?</h5>
            </div>
            <div className="write-blog">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                    <path d="M7 12H13" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 1.39918C10.2652 1.14359 10.6249 1 11 1C11.1857 1 11.3696 1.03525 11.5412 1.10374C11.7128 1.17224 11.8687 1.27263 12 1.39918C12.1313 1.52574 12.2355 1.67598 12.3066 1.84134C12.3776 2.00669 12.4142 2.18392 12.4142 2.36289C12.4142 2.54187 12.3776 2.7191 12.3066 2.88445C12.2355 3.0498 12.1313 3.20005 12 3.3266L3.66667 11.3575L1 12L1.66667 9.43011L10 1.39918Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p>Why not write a community post of your own?</p>
            </div>
            <Link to="/community/create" className="primary-btn white">create Post</Link>
            {/* <a href="#!" class="primary-btn white">create Post</a> */}
            <div className="background1">
                <div className="back1">
                    <img src={arrow} />
                </div>
                <div className="back2">
                    <img src={star} />
                </div>
                <div className="back3">
                    <img src={arrow} />
                </div>
            </div>
            <div className="background2">
                <div className="back1">
                    <img src={arrow} />
                </div>
                <div className="back2">
                    <img src={star} />
                </div>
                <div className="back3">
                    <img src={arrow} />
                </div>
            </div>
        </div>
        </div>
      </div>
    </section>
  );
}

export default PostCommunity;