import React, {useContext, useRef,useState} from 'react';
import '../home/why-choose-insage.css';
import './become-a-mentor.css';
import { useInView } from "framer-motion";
import Login from '../reusable/login-mentor/Login';
import SignUp from '../reusable/signup-mentor/SignUp';
import ForgotPassword from '../reusable/forgot-password/forgotPassword';
import ChangePassword from '../reusable/change-password/changePassword';
import { Link } from 'react-router-dom';
import actionTypes from '../../context/actionTypes';
import { AuthContext } from '../../context/AuthContext';

export default function BamGetAhead({props,propsrole}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true }); 
    const {dispatch} = useContext(AuthContext);
    const data = props;
    const [isOpen, setIsOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isChangeOpen, setChangeOpen] = useState(false);

  const handleLoginClick = () => {
    setIsOpen(true);
    setSignUpOpen(false);
    document.body.classList.add('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseLoginPopup = () =>{
    setIsOpen(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  }
  const handleOpenSignUp = () => {
    setSignUpOpen(true);
    setIsOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseSignUp = () => {
    setSignUpOpen(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleOpenForgot = () => {
    setForgotOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseForgot = () => {
    setForgotOpen(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  }; 
  const handleOpenChange = () => {
    setChangeOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
    setForgotOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseChange = () => {
    setChangeOpen(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  return (
    <>
    <section className="bam-get-ahead">
            <div className="custom-container-fluid">
                <div className="container">
                <div className="insage-knowledge-at-work-wrapper flex flex-jsb align">
            <div className="insage-knowledge-at-work-left flex align">
                 <div ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-label flex flex-jsc">
                   <div className="label col-white">{data?.gaTag}</div>
                 </div>
                 <div className="insage-knowledge-at-work-content">
                    <div className="insage-knowledge-at-work-content-wrapper">
                        <div className="insage-knowledge-at-work-content-heading">
                            <h3 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>{data?.gaHead}</h3>
                            </div>
                        <div className="insage-knowledge-at-work-content-body">
                            <div className="insage-knowledge-at-work-content-body-label">
                                <span ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="label">{data?.gaLabel}</span>
                            </div>
                            <div className="insage-knowledge-at-work-content-body-text">
                                <p ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className='col-p'>{data?.gaBody}</p>
                            </div>
                        </div>
                        <div className="insage-get-ahead-btn">
                            
        {propsrole === undefined ? <button onClick={handleLoginClick} ref={ref} style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="primary-btn primary-btn-2 border">become a mentor</button> : <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 black">Explore our mentors</Link>}
                        </div>
                    </div>
                 </div>

            </div>
            <div className="insage-knowledge-at-work-right">
                <div ref={ref} style={{
           clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-image">
                    <img className='img' src={data?.gaImg} alt="" />
                </div>
            </div>
        </div> 
                </div>
              <div className="bam-get-ahead-bg">
                <svg width="296" height="416" viewBox="0 0 296 416" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M96.8833 -4.23491e-06L415.701 -1.81709e-05L415.701 96.7947L165.3 96.7573L165.487 96.9444L416 347.615L346.237 415.064L96.8834 165.554L96.7337 165.404L96.7338 416L-1.63648e-09 415.963L-1.39464e-05 96.9444L96.8833 -4.23491e-06Z" fill="#FFECA1"/>
                </svg>
                </div>  
      
    </div>
    </section>

<div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpen ? 'active' : ''}`}>
<Login onClose={handleCloseLoginPopup} onSignUpClick={handleOpenSignUp} onForgotClick={handleOpenForgot} />
</div>
<div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpen ? 'active' : ''}`}>
<SignUp onLoginClick={handleLoginClick } onClose={handleCloseSignUp}/>
</div>
<div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpen ? 'active' : ''}`}>
<ForgotPassword  onClose={handleCloseForgot} handleOpenChange={handleOpenChange}/>
</div>
<div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpen ? 'active' : ''}`}>
<ChangePassword  onClose={handleCloseChange} handleLoginClick={handleLoginClick}/>
</div>
</>
  )
}
