import React, {useContext, useEffect, useState,useRef} from 'react';
import './community.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CommunityPostItem from './CommunityPostItem';
import ReportPopup from './ReportPopup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../context/AuthContext';
import axios from "axios"
import NoSesh from '../../assets/images/no-sesh.svg';
import Cookies from 'js-cookie';
import actionTypes from '../../context/actionTypes';


export default function Community_Post({props, fetchCommunityPosts, displayLoadMore}) {

  const {getCommunityListFilter, getCommunityList, getCommunityListT, getCommunityListFilterAuth, dispatch, state} = useContext(AuthContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('newest');
  const [pageNo, setPageNo] = useState(1);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [openReportSessionPopup, setOpenReportSessionPopup] = useState(false);
  const [currentPopupOpen, setCurrentPopupOpen] = useState(false);
  const [category, setCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState({});
  const [reaction, setReaction] = useState(null);
  const [tabState, setTabState] = useState(0);
  const [filter, setFilter] = useState({})

console.log('props ', props);

  const handleLikeDislike = async (communityId, reaction) => {

    const token = Cookies.get('token');
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+'/like-dislike-community', {
        communityId:communityId,
        reaction: reaction,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setReaction(reaction);
      // You can do something with the response if needed
      // console.log(response.data);

      // console.log('communityId ', communityId);

      const updatedCommData = state.commData.map(item => {
        if (item.communityId === communityId) {
          // Replace the data for the matching communityId
          return { ...item, ...response.data.result };
        }
        return item;
      });

    //  console.log('updatedCommData ', updatedCommData);

      dispatch({ type: actionTypes.COMMUNITY_LIST, payload: updatedCommData});

      
      
    } catch (error) {
      console.error('Error liking community:', error);
    }
  };

  const dropdownOptions = [
    'newest',
    'oldest'
  ];

  let userRole = Cookies.get('userRole');

  const dropdownRef = useRef();
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  const urlPost = userRole? 'communities': 'community';

  const handleAllClick =async () => {
    setTabState(0);
    fetchCommunityPosts({}, 1, selectedOption);
  }


  const handleShortFilere = (data={}, pageNo, selectedOption) => {
    fetchCommunityPosts(data,pageNo, selectedOption )
  }

  const handleCategoryClick = (index, data) => {
    setSelectedCategory({categoryId: data._id})
    setFilter({categoryId: data._id})
    setPageNo(1);
    handleShortFilere({categoryId: data._id}, 1, selectedOption)
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowDropdown(false);
    setPageNo(1);
    // handleShortFilere(1, option, selectedCategory);
    handleShortFilere(filter, 1, option);
  };

const handleLoadMore = (page) => {
  setPageNo(page+1);
  fetchCommunityPosts(filter , page+1, selectedOption )
}


  //Open Report Session Popup
  const openReportSessionPop = (ongoingSession) => {
    setSelectedItemData(ongoingSession);
    setOpenReportSessionPopup(true);
    setCurrentPopupOpen(true);
    document.body.classList.add('popup-open');
    };

    useEffect(() => {
      axios
      .get(
        `${process.env.REACT_APP_API_URL}/mentor/list-blog-categories`)
      .then((res) => {
  
        if(res){
          //   console.log('categoy',res.data);
            setCategory(res.data)
        }

      })
      .catch((err) => {
          console.log('error in category');
      })
  },[]);

    //Close Report Session Popup
    const closeReportSessionPop = () => {
        setOpenReportSessionPopup(false);
        setCurrentPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    const notifyRe = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Post Reported Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );
    const notifyC = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Link Copied Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );




    const categories = category?.result || [];
  return (
    <section className="Community_post_section">
         <div className="blog-container-for-line">
             <div className="Community_post_wrapper">
                 <div className="Community_tab-wrapper">
                 <Tabs>
                  <div className="Commmunity-tab-flex">
                        <div className="Community-tabs">
                            <TabList>
                              <Tab class="tabs">
                                <button onClick={() => handleAllClick()}>   
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path d="M6 1L1 3.5L6 6L11 3.5L6 1Z" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1 8.5L6 11L11 8.5" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1 6L6 8.5L11 6" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                 All
                                </button>
                              </Tab>
                              {category?.result?.map((data, index) => {
                                  return <>
                                  <Tab class="tabs" onClick={() => handleCategoryClick(index+1, data)}>
                                    <button>{data?.name}</button>
                                   </Tab>
                                  </>
                              })}
                            </TabList>
                        </div>
                       
                            <div className="sort-by-dropdown" onClick={toggleDropdown} ref={dropdownRef}>
                                <button className="dropdown-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none" className="sort-icon">
                                        <path d="M4 5.71875V11.3438" stroke="#555555" stroke-width="0.8" stroke-linecap="square" stroke-linejoin="round"/>
                                        <path d="M7 8.71875L4 11.7188L1 8.71875" stroke="#555555" stroke-width="0.8" stroke-linecap="square" stroke-linejoin="round"/>
                                        <path d="M15 8.5H10" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15 5.5H10" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18 11.5H10" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>  
                                    Sort by: {' '}
                                {selectedOption}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8"
                                    height="5"
                                    viewBox="0 0 8 5"
                                    fill="none"
                                    className={`dropdown-icon ${showDropdown ? 'rotate' : ''}`}
                                >
                                    <path
                                    d="M1 1.5L4 4.5L7 1.5"
                                    stroke="#555555"
                                    stroke-linecap="square"
                                    stroke-linejoin="round"
                                    />
                                </svg>
                                </button>
                                {showDropdown && (
                                <div className="dropdown-options">
                                {dropdownOptions.map((option) => (
                                <div key={option} onClick={() => handleOptionSelect(option)}>
                                    {option}
                                </div>
                                ))}
                            </div>
                                )}
                            </div>
                       
                  </div>
                  <div className="Community_post_box_wrapper">
                  <TabPanel>
                     {state?.commData?.result?.map((prop,index) => {
                      return(
                    <CommunityPostItem key={index}
                        openReportSessionPopup={() => openReportSessionPop(prop)} props={prop} notiC={notifyC} handleLikeDislike={handleLikeDislike}
                      />
                      );
                    })}    
                  </TabPanel>

                    {categories?.map((data, index) => {
                      
                      const filteredPosts = state?.commData?.result?.filter(post => post.categoryName === data?.name);

                      console.log('state?.commData?.result ', state?.commData?.result);
                      console.log('filteredPosts' , filteredPosts);
                      console.log('cat ', data);
                      return (
                        <TabPanel key={index}>
                          {filteredPosts?.length > 0 ? (
                            filteredPosts.map((prop, index) => (
                              <CommunityPostItem
                                key={index}
                                openReportSessionPopup={() => openReportSessionPop(prop)}
                                props={prop}
                                notiC={notifyC}
                                handleLikeDislike={handleLikeDislike}
                              />
                            ))
                          ) : (
                            <div className='noresultsfound'>
                                <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">No results found</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p> Oops! It seems like there are no articles that match your selected filters. Try adjusting your filters or explore our other community for more interesting insights.</p>
                                    </div>
                                    
                                </div>
                              </div>
                            </div>
                        )}
                        </TabPanel>
                      );
                      
                    })}
                      
                  </div>
                  </Tabs>
                 </div>
                 
             </div>
             {displayLoadMore && <div className='loadmore-btn'><div className='primary-btn black' onClick={() => handleLoadMore(pageNo)}>Load More</div></div>}
        </div>
        <div className="mentee-profile-pop-ups">
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openReportSessionPopup ? 'active' : ''}`}>
                    {currentPopupOpen && (
                        <ReportPopup
                            data={selectedItemData}
                            noti={notifyRe}
                            onclose={closeReportSessionPop}
                        />
                    )}
                </div>
            </div>
        
     </section>
  )
}
