import React, { useRef } from "react";
import "./Cards.css";
import { useInView } from "framer-motion";

export default function Cards({posts}) {
  const ref = useRef(null);
  
  return (
    
    <div ref={ref} className="industry-experts-cards-wrapper flex">
        {posts?.threeCards?.map((post,index) => {
              return (
                <div className="industry-experts-card" key={index}>
                    <div className="industry-experts-card-wrapper">
                    <div className="industry-experts-card-image">
                        <img src={post.ieImage} alt="" className="img-cont" />
                    </div>
                    <div className="industry-experts-card-content">
                        <div className="industry-experts-card-heading">
                        <span className="label">{post.ieHeading}</span>
                        </div>
                        <div className="industry-experts-card-body">
                        <p className="col-p">{post.ieContent}</p>
                        </div>
                    </div>
                    </div>
                </div>
              );
          })}
      </div>
  );
}
