import React from 'react';
import './Onboarding-header.css';
import Logo from '../../assets/images/insage-logo.svg';

const OnboardingHeader = () => {
  return (
    <div className='obHeader flex'>
      <div className="obHeader-left">
        <div className='obHeader-wrapper'>
            
                <div className='oh-logo'>
                    <img className='oh-logo-img' src={Logo} alt="" />
                </div>
          
        </div></div>
      <div className="obHeader-right"></div>
        
    </div>
  )
}

export default OnboardingHeader
