import React from 'react'
import "./Onboarding-content.css";
import '../reusable/login/login.css';
import ObForm from './Ob-form';
import Ob1 from '../../assets/images/onboarding1.png';
import Ob2 from '../../assets/images/onBoarding2.png';
import ObP1 from '../../assets/images/onBoardingP1.svg';
import ObP2 from '../../assets/images/onBoardingP2.svg';

const OnboardingContent = () => {

    return (
        <div className='ob-content flex flex-jsb'>
            <div className='obc-left'>
                    <div className='obcleft-in'>
                        <div className='obc-form'>
                            <ObForm/> 
                        </div>
                    </div>
            </div>
            <div className='obc-right'>
                <div className="obc-right-grid1">
                    <div className="obc-right-grid-wrapper flex flex-c flex-jse align-e">
                        <div className="obc-right-grid-text"><span>Unlock your true potential with professionals.</span></div>
                    </div>
                    <img src={ObP1} alt="" />
                </div>
                <div className="obc-right-grid2">
                    <img src={Ob1} alt="" />
                </div>
                <div className="obc-right-grid3">
                    <img src={Ob2} alt="" />
                </div>
                <div className="obc-right-grid4">
                    <div className="obc-right-grid-wrapper flex flex-c align-e flex-jse">
                       <div className="obc-right-grid-text"><span>Insage believes in the power of mentorship.</span></div>
                    </div>
                    <img src={ObP2} alt="" />
                </div>
            </div>
        </div>
    )
}

export default OnboardingContent
