import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function WithdrawPop({onClose, walletBalance}) {

    const { getWithdrawEarning} = useContext(AuthContext);

    const [amount, setAmount] = useState('');
    const [withdrawAll, setWithdrawAll] = useState(false);
    const [loading, setloading] = useState(false);

    const handleCheckboxChange = (e) => {
        setWithdrawAll(e.target.checked);
        if (e.target.checked) {
            setAmount(walletBalance);
        } else {
            setAmount('');
        }
    };

    const handleAmountChange = (e) => {
        const inputAmount = Number(e.target.value);
        setAmount(inputAmount);
    };

    const handleWithdrawButton = async () => {
        setloading(true);
        const data = {
            amount: amount
        }
        const inputAmount = Number(amount);

        if (!isNaN(inputAmount) && inputAmount >= 100 && inputAmount <= walletBalance) {
            const res = await getWithdrawEarning(data);
            if(res){
                setloading(false);
                onClose();

            }
        }

    }



    return (
    <div className='confirm-schedule-pop'>
    <div className="confirm-schedule-pop-wrap">
            <div className="login-head"><span>Withdraw amount</span></div>
            <div className="avail-balance">Available balance: <span>${walletBalance}</span></div>
            <div className="slot-booking-note">
            <div className="slot-booking-note-wrap">
                <div className="slot-booking-note-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M4.11267 7.78644L7.78906 4.11029L4.63748 1.48438L1.48624 4.63541L4.11267 7.78644Z" fill="#BE1931"/>
                        <path d="M0.433686 4.63648C1.01357 5.217 1.95407 5.217 2.53396 4.63648L4.6349 2.53602C5.21545 1.95618 5.21545 1.01506 4.6349 0.434883C4.05535 -0.144961 3.11451 -0.144961 2.53396 0.434883L0.433686 2.53602C-0.146533 3.11586 -0.146533 4.05664 0.433686 4.63648Z" fill="#DD2E44"/>
                        <path d="M7.35212 5.94922C7.35212 5.94922 12.2521 11.7262 11.9898 11.9894C11.7273 12.2523 5.94959 7.35166 5.94959 7.35166L7.35212 5.94922Z" fill="#99AAB5"/>
                        <path d="M3.58463 9.36273C4.16417 9.94324 5.10501 9.94324 5.6859 9.36273L9.36196 5.68624C9.94185 5.10606 9.94185 4.16596 9.36196 3.58578C8.78174 3.00526 7.8409 3.00526 7.26069 3.58578L3.58463 7.26193C3.00441 7.84244 3.00441 8.78221 3.58463 9.36273Z" fill="#DD2E44"/>
                    </svg>
                </div>
                <div className="slot-booking-note-text">
                    <strong>Note: </strong> All transactions requests are handled by the admin. The admin will get in contact you via email within 24 hours of putting in a withdrawal request.
                </div>
            </div>
            </div>
            <div className="withdrawPop-input">
                <div className="withdrawPop-input-head">Enter the amount you want to withdraw</div>
                <div className="withdrawPop-input-input">
                    <input type="number" placeholder='Enter amount' value={amount} onChange={handleAmountChange} />
                </div>
                <div className="withdrawPop-input-checkbox">
                    <label htmlFor="">
                    <input type="checkbox" name="" id=""  checked={withdrawAll} onChange={handleCheckboxChange}/>
                    <span>Withdraw all</span>
                    </label>
                </div>
            </div>
            <div className="withdrawPop-btns">
                <button className='primary-btn cancel'>Cancel</button>
                <button className='primary-btn withdraw' onClick={handleWithdrawButton}> {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                <>Withdraw</>
                                )}</button>
            </div>
    </div>
    <div onClick={onClose} className="confirm-schedule-close">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
        <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </div>
</div>
  )
}
