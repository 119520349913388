import React, {useState, useRef, useEffect, useContext} from 'react';
import './resources.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SampleImage from '../../assets/images/about-connection.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContext } from '../../context/AuthContext';
import actionTypes from '../../context/actionTypes';

export default function DashCommunityCard ({post,openCancelSessionPopup, get}) {

    const { id } = useParams();

    const additionalClass = !post?.image ? 'no-image' : '';

    console.log('saved resources',post);

    const {dispatch, state } = useContext(AuthContext)

    const handleDeleteClick =  (communityId) => () =>{

        const userToken = Cookies.get('token');
        let data = state?.resourcesCommData?.result?.filter(item => item.blogId !== communityId);

        let mergedData = {
            ...state.resourcesCommData,
            result: data
          };
        dispatch({ type: actionTypes.RESOURCES_COMM, payload: mergedData});

            const resourceData = {
                resourceId: communityId,
                resourceType: "Community",
                action: "remove"
            }
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/add/remove/resource`,
                resourceData,
                {
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                }
              )
              .then((res) => {

                if(res){
                  console.log(res);
                }
              })
              .catch((err) => {

              })
              .finally(() => {

              });
    }


    console.log('post ', post);

  return ( 
    
    <div className='resources-card'>
        <div className='resources-card-in'>
                {post?.image && 
                    <Link to={`/community/${post?.slug}`}>
                        <div className='rci-image'>
                            <img src={post?.image} alt="post image" />
                        </div> 
                    </Link>
                }
            <div className={`rci-content ${additionalClass}`}>
                <div className='rcic-head'>
                    <div className='rcich-top'>
                        <div className='author-details'>
                            <div className='author-pic'>
                                <img src={post?.avatar} alt="avatar" />
                            </div>
                            <div className='author-name'>
                               {post?.authorName}
                            </div>
                        </div>
                        <div className='post-date'>
                           {post?.createdAt}
                        </div>
                    </div>
                    <div className='rcich-bottom'>
                        <div className='rcic-blog-heading'>
                            <Link to={`/community/${post?.slug}`}>{post?.title}</Link>
                        </div>
                        <div className='rcic-blog-para'>
                            <Link to={`/community/${post?.slug}`}><p dangerouslySetInnerHTML={{ __html: post?.content }}></p></Link>
                        </div>
                    </div>
                </div>
                <div className='rcic-foot'>
                    <div className='rcicf-left'>
                        <div className='category-tag'>
                            <div className='ctag'>{post?.categoryName}</div>
                        </div>
                    </div>
                    <div className='rcicf-right'>
                        <button className='deleteicon-button'  onClick={handleDeleteClick(post?.blogId)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <path d="M1.5 3.5H2.5H10.5" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.5 3.5V10.5C9.5 10.7652 9.39464 11.0196 9.20711 11.2071C9.01957 11.3946 8.76522 11.5 8.5 11.5H3.5C3.23478 11.5 2.98043 11.3946 2.79289 11.2071C2.60536 11.0196 2.5 10.7652 2.5 10.5V3.5M4 3.5V2.5C4 2.23478 4.10536 1.98043 4.29289 1.79289C4.48043 1.60536 4.73478 1.5 5 1.5H7C7.26522 1.5 7.51957 1.60536 7.70711 1.79289C7.89464 1.98043 8 2.23478 8 2.5V3.5" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5 6V9" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7 6V9" stroke="#222222" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  )
}
