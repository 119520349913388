import React from 'react';
import { useState } from "react";
import Cookies from 'js-cookie';
import ObStep1 from './Ob-step-1';
import ObStep2 from './Ob-step-2';
import ObStep3 from './Ob-step-3';
import ObStep4 from './Ob-step-4';
import ObStep5 from './Ob-step-5';
import ObStep6 from './Ob-step-6';
import ObStep7 from './Ob-step-7';
import ObStep8 from './Ob-step-8';
import ObStep9 from './Ob-step-9';

const ObForm = () => {
    //state for steps
    const flag = Cookies.get('flag');
    let value = flag ? parseInt(flag) : 1;
    const [step, setStep] = useState(value);

    console.log('step ', typeof step)
    const [onboardingFormData, setOnboardingFormData] = useState({
      name: "",
      timeZone: "",
      tz: "",
      timeZoneValue: "",
      guidance: [],
      guidanceState: [],
      selectedCategory: "",
      price:"",
      language: [],
      languagesState: [],
      age: "",
      email: "",
      linkedinProfile:"",
      educationCheckbox: false,
      experienceCheckbox: false,
      education: [
        {
          degree: "",
          collegeName: "",
          from: "",
          to: "",
        },
      ],
      experience: [
        {
          positionName: "",
          companyName: "",
          from: "",
          to: "",
        },
      ],
      avatar:"",
      bio:"",
      industry:"",
      levelOfExp:"",
      country:"",
      isMentoredBefore:"Yes",
      mentoringHours:"0-3",
      isChargingForMentoring:"Yes",
      acceptThreeReviewTnC:"Yes",
      acceptCallAndChatTnC:"Yes",
      approachToMentoring:"Not at all motivated",
      perWeekMentoringHour:"",
      whyBecomeMentor:"",
      preferredMentoredTopic:"",
      flag: 10  // redirect to profile dashboard
  });

  const handleOnboardingInputChange = (e) => {
    const { name, value } = e.target;
    setOnboardingFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
};

const handleOnboardingInputChange2 = (name, value) => {
  setOnboardingFormData((prevData) => ({
      ...prevData,
      [name]: value,
  }));
};
    
console.log('onboardingFormData ', onboardingFormData);


    // function for going to next step by increasing step state by 1
    const nextStep = () => {
      setStep(step + 1);
    };

    // function for going to previous step by decreasing step state by 1
    const prevStep = () => {
      setStep(step - 1);
    };


// javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <div className="obcFormIn">
            <ObStep1 nextStep={nextStep} handleOnboardingInputChange={handleOnboardingInputChange} values={onboardingFormData} />
        </div>
      );
    
    case 2:
      return (
        <div className="obcFormIn">
            <ObStep2 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange={handleOnboardingInputChange} values={onboardingFormData} />
        </div>
      );
      // Only formData is passed as prop to show the final value at form submit

    case 3:
        return (
            <div className="obcFormIn">
                <ObStep3 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange={handleOnboardingInputChange} values={onboardingFormData} />
            </div>
        );

    case 4:
        return (
            <div className="obcFormIn">
                <ObStep4 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange2={handleOnboardingInputChange2} values={onboardingFormData} />
            </div>
        );

    case 5:
      return (
        <div className="obcFormIn">
            <ObStep5 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange2={handleOnboardingInputChange2} values={onboardingFormData}  />
        </div>
      );
      case 6:
      return (
        <div className="obcFormIn">
            <ObStep6 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange={handleOnboardingInputChange} values={onboardingFormData}  />
        </div>
      );
      case 7:
        return (
          <div className="obcFormIn">
              <ObStep7 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange={handleOnboardingInputChange} values={onboardingFormData}  />
          </div>
        );
        case 8:
        return (
          <div className="obcFormIn">
              <ObStep8 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange={handleOnboardingInputChange} values={onboardingFormData}  />
          </div>
        );  
        case 9:
          return (
            <div className="obcFormIn">
                <ObStep9 nextStep={nextStep} prevStep={prevStep} handleOnboardingInputChange={handleOnboardingInputChange} values={onboardingFormData}  />
            </div>
          );  
    // default case to show nothing
    default:
      return (
        <div className="obcFormIn">
          hello
        </div>
      );
  }
  
}

export default ObForm
