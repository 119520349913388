import React, { useContext, useEffect, useState } from 'react';
import '../Dashboard/profile.css';
import NoSesh from '../../assets/images/no-sesh.svg';
import ThumbsImage from '../../assets/images/thumbs-emoji.svg'
import GreenStars from '../../assets/images/green-stars.svg'
import MentorDashReviewsCard from '../Reviews/MentorDashReviwsCard';
import { AuthContext } from '../../context/AuthContext';
import actionTypes from '../../context/actionTypes';

export default function DashboardReviews() {


    const {getReviewsMentor, state, dispatch} = useContext(AuthContext);

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState(state?.reviewsMentor?.sortOrder=== "asc" ? "latest" : "oldest");


    const handleReviewFilterAndLoadMore = (pageNo, selectedOption) => {
        getReviewsMentor(pageNo, selectedOption);
    }



    const handleLoadMore = async (pageNo) => {
    let res =await getReviewsMentor(Number(pageNo)+1, selectedOption);
    if(typeof res === 'object'){
        let mergedData = {
            ...state.reviewsMentor,
            ...res,
            result: [...state.reviewsMentor.result, ...res.result]
          };

          console.log('mergedData ', mergedData)
        dispatch({ type: actionTypes.REVIEWS_MENTORS, payload: mergedData});
    }
}

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const dropdownOptions = [
    'latest',
    'oldest',
    'popularity Descending',
    'popularity Ascending'
    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        handleReviewFilterAndLoadMore(1, option);
      };


      const totalAvarage = state?.reviewsMentor?.reviewsTillDate;

      const totalAvarageStars = [];

      for (let i = 0; i < 5; i++) {
        if (i < parseInt(totalAvarage)) {
            totalAvarageStars.push(<img key={i} src={GreenStars} alt="filled star"/>);
        } else {
            
        }
    }

    // console.log('reviewsMentor ', state?.reviewsMentor);

  return (
    <div className="dashboard-empty-state">
        <div className="dash-reviews-wrap">
            <div className="dash-reviews-head">
                <div className='drh-left'>
                    <div className="dash-reviews-sess-head">reviews</div>
                    <div className="dash-reviews-sess-body"><p>See what People say about you.</p></div>
                </div>
                <div className='drh-right'>
                <div className="sort-by-dropdown" onClick={toggleDropdown}>
                    <button className="dropdown-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none" className="sort-icon">
                            <path d="M4 5.71875V11.3438" stroke="#555555" stroke-width="0.8" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M7 8.71875L4 11.7188L1 8.71875" stroke="#555555" stroke-width="0.8" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M15 8.5H10" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15 5.5H10" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18 11.5H10" stroke="#555555" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>  
                        Sort By: {' '}
                    {selectedOption}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="5"
                        viewBox="0 0 8 5"
                        fill="none"
                        className={`dropdown-icon ${showDropdown ? 'rotate' : ''}`}
                    >
                        <path
                        d="M1 1.5L4 4.5L7 1.5"
                        stroke="#555555"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                        />
                    </svg>
                    </button>
                    {showDropdown && (
                    <div className="dropdown-options">
                        {dropdownOptions.map((option) => (
                        <div key={option} onClick={() => handleOptionSelect(option)}>
                            {option}
                        </div>
                        ))}
                    </div>
                    )}
                </div>
                </div>
            </div>
            {state?.reviewsMentor?.result?.length > 0 ? <>
                <div className='filled-reviews-wrap'>
                <div className='avg-reviews-box'>
                    <div className='arb-in'>
                        <div className='arbi-left'>
                            <div className='arbi-stars'>
                                <h4 className='arbi-star-number'>{parseInt(state.reviewsMentor?.reviewsTillDate)}</h4>
                                <div className='arbi-star-stars'>
                                   {totalAvarageStars}
                                </div>
                            </div>
                            <div className='arbi-content'>
                                <div className='arbi-content-head'>
                                    <h6>You’re doing great! keep it up</h6>
                                </div>
                                <div className='arbi-content-para'>
                                    <p>Your average rating by your mentees.</p>
                                </div>
                            </div>
                        </div>
                        <div className='arbi-right'>
                            <div className='arbi-icon'>
                                <img src={ThumbsImage}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='reviews-session-card-wrap'>
                    <div className='reviews-session-card'>
                        {state?.reviewsMentor?.result?.map((data, index) => <MentorDashReviewsCard post={data} index={index}/>)}
                        {state?.reviewsMentor?.hasMorePages && <div onClick={() => handleLoadMore(state?.reviewsMentor?.currentPage)} className="favourite-mentors-list-button">
                            <a href="javascript:void(0)" className="isg-guide-load-more">load more</a>
                        </div>} 
                    </div>
                </div>
            </div>
            </> 
            : 
            <> <div className="dash-emp-no-sess">
                    <div className="dash-emp-no-sess-wrap">
                        <div className="dash-emp-no-sess-img">
                            <img src={NoSesh} alt="" />
                        </div>
                        <div className="dash-emp-no-sess-head">You haven’t received any reviews</div>
                        <div className="dash-emp-no-sess-body">
                            <p> Once you start receiving reviews from your mentees, they'll show up here. Keep up the good work!</p>
                        </div>
                        {/* <div className="dash-emp-no-sess-btn">
                            <a href="#!" className="primary-btn black">Coming Soon</a>
                        </div> */}
                    </div>
                </div>
            </>
            }
        </div>
    </div>
  )
}
