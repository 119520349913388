import React,{useContext, useEffect, useState} from 'react';
import '../login/login.css';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../ErrorMessage';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import actionTypes from '../../../context/actionTypes';

export default function ForgotPassword({onClose, handleOpenChange, noti}) {

    const { ForgotPasswordFun, state, dispatch } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({
        email: '',
        message:'',
      });
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

  const validateForm = async (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = {
      email: '',
      message: '',
    };

    // Check email
    if (!email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email address';
      valid = false;
    } 

    setErrors(newErrors);
    setLoading(true);
      try {
        let res = await ForgotPasswordFun(email);
        setLoading(false);   
        if(res){
          noti();
          onClose();
          dispatch({ type: actionTypes.REMOVE_ERROR});
        }
      } catch (error) {
        console.log(error);
      }


  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if (token) {
        Cookies.set('forgot_token', token);
        handleOpenChange();
    } else {
        // console.log('Token not found in the URL.');
    }
}, []);
  return (
    <div className="insage-login">
    <div className="insage-login-wrapper">
        <div className="login-head">
            <span>Forgot Password</span>
            </div>
        <div className="forgot-password-p">
            <p>No worries! Please enter your registered email address below and we’ll send you a link to reset your password</p>
            </div>
        <div className="login-form-wrapper">
            <form>
                <div className="login-form-input flex flex-c">
                    <label htmlFor="email">Email ID</label>
                    <input type="email" placeholder="Type here" value={email} onChange={handleEmailChange} />
                    {state.error && <ErrorMessage errorData={state.error} />}
                </div>
                <button className='primary-btn black login-button' type="button" onClick={validateForm}>
                  {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                     'submit'
                   )}
                                                        </button>
            </form>
        </div>
        </div>
        <div className="login-close-button" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round"/>
        </svg>
        </div>
    </div>
    )
}
