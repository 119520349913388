import React, {useState, useRef, useEffect} from 'react';
import './dashSession.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import starFilled from '../../../assets/images/star-filled.svg'
import starEmpty from '../../../assets/images/star-empty.svg'

export default function DashSessionGridCompleted ({props,openCompletedExpandSessionPopup,openWriteReviewPopupOnDash,onClick}) {

    // console.log('propssss', props);

  return ( 
    <div className='session-grid-card-outer'>
        <div className='session-grid-card-in'>
            <div className='sgc-main'>
                <div className='sgcm-top'>
                    <div className='sgcmt-upper'>
                        <div className='sgcmt-mentor-image round'>
                            <img src={props?.mentorAvatar}/>
                        </div>
                        <div className='sgcmt-mentor-name'>{props?.mentorName}</div>
                        <div className='sgcmt-mentor-poscom'>
                            <div className='sgcmt-mentor-position'>{props?.mentorDesignation}</div>
                            <div className='dot-seperator'></div>
                            <div className='sgcmt-mentor-company'>{props?.mentorCompany}</div>
                        </div>
                    </div>
                    <div className='sgcmt-lower'>
                        <div className='sg-date'>
                            <div className='sgd-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"/>
                                </svg>
                            </div>
                            <div className='sgd-text'>
                                {props?.sessionDate}
                            </div>
                        </div>
                        <div className='sg-time'>
                            <div className='sgd-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <g clipPath="url(#clip0_5122_71366)">
                                    <path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_5122_71366">
                                    <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className='sgd-text'>
                                {props?.startTime} - {props?.endTime}
                            </div>
                        </div>
                        <div className='detailsbtn'>
                            <div className='more-link' onClick={() => openCompletedExpandSessionPopup(props)}>
                                more details
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="6" viewBox="0 0 4 6" fill="none">
                                        <path d="M1 5L3 3L1 0.999999" stroke="#666666" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='sgcm-foot'>
                    {props.review > 0 ?
                        <>
                        <div className="rated-div flex align">
                            <div className="rated-text">
                                Rated
                            </div>
                            <div className="rated-icon-number flex align">
                                <div className="rated-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <path d="M5 0.5L6.3905 3.46215L9.5 3.94006L7.25 6.24448L7.781 9.5L5 7.96215L2.219 9.5L2.75 6.24448L0.5 3.94006L3.6095 3.46215L5 0.5Z" fill="#C39D00"/>
                                    </svg>
                                </div>
                                <div className="rated-number">
                                    {props.review}
                                </div>
                            </div>
                        </div>
                        </>
                    :
                      <>
                      <button className='review-text' onClick={() => openWriteReviewPopupOnDash(props)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                          <path d="M5.47296 1.55469H1.99399C1.73037 1.55469 1.47754 1.65941 1.29113 1.84582C1.10472 2.03223 1 2.28506 1 2.54868V9.50662C1 9.77025 1.10472 10.0231 1.29113 10.2095C1.47754 10.3959 1.73037 10.5006 1.99399 10.5006H8.95194C9.21556 10.5006 9.46838 10.3959 9.65479 10.2095C9.8412 10.0231 9.94593 9.77025 9.94593 9.50662V6.02765" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M9.20283 0.808794C9.40055 0.611076 9.66871 0.5 9.94833 0.5C10.2279 0.5 10.4961 0.611076 10.6938 0.808794C10.8915 1.00651 11.0026 1.27467 11.0026 1.55429C11.0026 1.8339 10.8915 2.10206 10.6938 2.29978L5.97236 7.02124L3.98438 7.51824L4.48137 5.53026L9.20283 0.808794Z" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>Review
                      </button>
                      </>
                    }
                    
                    <div className='sgcmf-cancel'>
                        <Link to={`/mentors/${props?.mentorUsername}`} className='book-button'>Book Again</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
