import React, { useContext, useEffect, useState } from 'react';
import Header from '../../layouts/header/Header';
import MentorBookingGrid from '../../components/mentor-booking/MentorBooking';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContext } from '../../context/AuthContext';
import moment from 'moment';
import Loader from '../../components/reusable/loading/Loader';

export default function MentorBooking() {
  // const [availableDates, setAvailableDates] = useState([]);
  const [availabilities, setAvailabilities] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());

  const {availableDates, setAvailableDates} = useContext(AuthContext);

  const { id } = useParams();

  // console.log('loading ', loading);


  useEffect(() => {
    const token = Cookies.get('token');
    if(!token) return;
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL; 
    const requestData = {
      mentorId:id
    };
    axios
      .post(`${apiUrl}/mentee/available-dates`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if(res.data.success){
          const today = new Date().toISOString().split('T')[0];
          // setAvailableDates(res.data.result)
          setAvailableDates(prev => {
            const futureDates = res.data.result.filter(date => date > today);
            // const newAvailableDates = [...prev, ...futureDates];
            return futureDates;
          });
          const datesNotBeforeToday = [];
          res.data.result.forEach(date => {
            // Compare the date with today
            if (date > today) {
              
                datesNotBeforeToday.push(date);
            }
        });

        if(datesNotBeforeToday.length){
          datesNotBeforeToday.sort();

          setSelectedDate(moment(datesNotBeforeToday[0]).format('YYYY-MM-DD'))

          axios
          .post(`${apiUrl}/mentee/get-availability`, {
            dateToBook: datesNotBeforeToday[0],
            // dateToBook: "2023-10-23",
            mentorId: id
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if(res.data.success){
              setAvailabilities(res.data.result)
              setLoading(false);
            }else{
              setAvailabilities({});
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err)
            setLoading(false);
          });
        }     // if 
        }else{
          setAvailableDates([]);
          setLoading(false);
        }

      })
      .catch((err) =>{
        console.log(err)
        setLoading(false);
      } );
  }, [id]);

  return (
    <main className='mentorBooking'>
        <Header/>
        {loading && <Loader/>}
        <MentorBookingGrid availableDates={availableDates} availabilities={availabilities} setAvailabilities={setAvailabilities} setLoading={setLoading} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
    </main>
  )
}
