import React from 'react';

const ErrorMessage = ({ errorData }) => {
    let messages, path, msg;

    console.log(' errorData ', errorData);
    console.log(typeof errorData);

    if (Array.isArray(errorData)) {
        messages = errorData.map((error, index) => (
            <li key={index}>{error.msg}</li>
        ));
        console.log('messages ', errorData);
        path = errorData[0]?.path;
        msg = errorData[0]?.msg;
    }else if (typeof errorData === 'object'){

        messages = Array.isArray(errorData?.error) ? errorData?.error?.map((error, index) => (
            <li key={index}>{error.msg}</li>
        )) : <li>{errorData?.message}</li>;

        path = Array.isArray(errorData?.error) && errorData[0]?.path;
        msg = Array.isArray(errorData?.error) ? errorData[0]?.msg : messages;
    }else if (errorData.error && errorData.error.message) {
        messages = <li>{errorData.error.message}</li>;
        path = null;
        msg = null;
    } else if (errorData?.message) {
        messages = <li>{errorData.message}</li>;
        path = null;
        msg = null;
    } else {
        messages = null;
        path = null;
        msg = null;
    }

    return (
        <div>
            <ul>
                {messages}
            </ul>
            {/* {path && <span>Error Path: {path}</span>} */}
            {/* {msg && <span>Error Msg: {msg}</span>} */}
        </div>
    );
};

export default ErrorMessage;

