import React, { useContext,useEffect,useRef } from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import Profile from '../../components/mentorProfilePage/profile';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; 
import { useNavigate } from 'react-router-dom';

export default function MentorProfilePage() {

  const { id } = useParams();
  const navigate = useNavigate();

  const { state, getUserProfileInfo } = useContext(AuthContext);

    const [topMentorData, setTopMentorData] = React.useState([]);
    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/mentor/${id}`)
        .then((res) => {
          if (res.data.success) {
            setTopMentorData(res.data);
          } else {
            navigate('/404');
          }
        })
        .catch((err) => console.log(err));
      
      getUserProfileInfo();
    }, [id]);

    console.log(topMentorData)

  return (
    <main className='mentee-profile-main'>
      <Header/>
        <Profile props={topMentorData}/>
      <Footer/>
    </main>
  )
}
