import React, { useState } from 'react';
import moment from 'moment';
import '../Calendar/calendar.css';
import './calendar-mente.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

const Calendar = ({ disabledDates, availableDates, setAvailabilities, setLoading, setSelectedSlot , selectedDate, setSelectedDate}) => {
    // const today = new Date().toISOString().split('T')[0];
    // const datesNotBeforeToday = [];
    // availableDates.forEach(date => {
    //     // Compare the date with today
    //     if (date >= today) {
    //         datesNotBeforeToday.push(date);
    //     }
    // });
    // datesNotBeforeToday.sort();

    // console.log('datesNotBeforeToday?.[0] ', datesNotBeforeToday?.[0]);

const [currentDate, setCurrentDate] = useState(moment());
// const [selectedDate, setSelectedDate] = useState(moment(availableDates[0]));

console.log('selectedDate ', selectedDate);

const { id } = useParams();

const renderMonth = (date) => {
const startOfMonth = date.clone().startOf('month');
const endOfMonth = date.clone().endOf('month');
const firstDay = startOfMonth.format('d'); // 0 (Sunday) to 6 (Saturday)

const days = [];
let day = startOfMonth.clone().subtract(firstDay, 'days');

const handleDayClick = (day) => {
    if (!disabledDates.includes(day.format('YYYY-MM-DD'))) {
        setSelectedDate(day);
        const Day= day.format('DD /MM / YYYY');
        const summDate = day.format('DD MMMM, YYYY');
        const getDate = day.format('YYYY-MM-DD');
        const token = Cookies.get('token');
        if(!token) return;
        setLoading(true);
        const apiUrl = process.env.REACT_APP_API_URL; 
        setSelectedSlot({});
        axios
            .post(`${apiUrl}/mentee/get-availability`, {
            dateToBook: getDate,
            mentorId: id
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            })
            .then((res) => {
            if(res.data.success){
                setAvailabilities(res.data.result)
                setLoading(false);
            }else{
                setAvailabilities({});
                setLoading(false);
            }
            
            })
        .catch((err) => {
            console.log(err)
            setLoading(false);
        });
    }
    };

const handleClearClick = () => {
    setSelectedDate(moment());
    // onDateChange('dd / mm / yyyy'); 
};    

while (day.isSameOrBefore(endOfMonth, 'day')) {
    days.push(day.clone());
    day.add(1, 'day');
}


const renderDays = (days) => {
    return days.map((day) => {
        // Check if the date belongs to the current month
        const isCurrentMonth = day.isSame(currentDate, 'month');
        // Check if the date is the selected date
        const isSelected = day.isSame(selectedDate, 'day');
        // Check if the date is in the disabledDates array
        const isDisabled = disabledDates.includes(day.format('YYYY-MM-DD'));

        // Check if the date is in the availableDates array
        const isAvailable = availableDates.includes(day.format('YYYY-MM-DD'));

        // Get the current date
        const today = moment().startOf('day');

        // Check if the date is in the past or today
        const isPastDate = day.isBefore(today, 'day');
        // isSameOrBefore

        // Add the "disabled" class if it's in the past or is in the disabledDates array
        const dayClasses = `day${isSelected ? ' selected' : ''}${!isAvailable || isPastDate  ? ' disabled' : ''}`;

        // `day${isSelected ? ' selected' : ''}${isDisabled || isPastDate ? ' disabled' : ''}`;

        const handleClick = () => {
            // Check if the element has the "disabled" class
            if (!dayClasses.includes('disabled')) {
                // If not disabled, perform the action
                handleDayClick(day);
            }
        };

        return (
            <div
                key={day.format('YYYY-MM-DD')}
                className={dayClasses}
                onClick={handleClick}
            >
                {day.format('D')}
            </div>
        );
    });
};




return (
    <div className="month" key={startOfMonth.format('YYYY-MM')}>
        <div className="top-calender-wrap">
            <div className="calender-buttons">
                <button onClick={handlePrevMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path d="M5 1L1 5L5 9" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <h2>{startOfMonth.format('MMMM YYYY')}</h2>
                <button onClick={handleNextMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
        <div className="days-header">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day} className="day-label">
            {day}
            </div>
        ))}
        </div>
        <div className="days">{renderDays(days)}</div>
    </div>
    );
};

const handlePrevMonth = () => {
setCurrentDate(currentDate.clone().subtract(1, 'month'));
};

const handleNextMonth = () => {
setCurrentDate(currentDate.clone().add(1, 'month'));
};

return (
<div className="calendar">
    {renderMonth(currentDate)}
    {/* Selected Day: {selectedDate ? selectedDate.format('DD /MM / YYYY') : 'None'} */}
</div>
);
};

export default Calendar;
