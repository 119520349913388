import React from 'react';
import './onboarding.css';
import OnboardingHeader from '../../components/mentor-onboarding/Onboarding-header';
import OnboardingContent from '../../components/mentor-onboarding/Onboarding-content';

const MentorOnboarding = () => {
  return (
    <>
      <OnboardingHeader/>
      <OnboardingContent/>
    </>
  )
}

export default MentorOnboarding 
