import React from 'react'
import './loader.css';

const Loader = () => {
  return (
    <div className='loadingState'>
        <span className="loader"></span>
    </div>
  )
}

export default Loader
