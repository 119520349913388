import React,{useState,useContext,useEffect} from 'react';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../ErrorMessage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../reusable/loading/Loader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NoSesh from '../../assets/images/no-sesh.svg';
import 'react-tabs/style/react-tabs.css';
import '../guides/guides.css';
import '../Dashboard/profile.css';
import WithdrawPop from './withdrawPop';
import actionTypes from '../../context/actionTypes';
import TransactionHistory from './TransactionHistory';

export default function Earnings() {

    const { getMentorTransactionHistory, state, dispatch } = useContext(AuthContext);
    const [isPop, setIsPop] = useState(false);
    const [walletClass, setWalletClass] = useState('');
    const [earningsClass, setEarningsClass] = useState('');
    const [dollar, setDollar] =useState();

    const handleClick = () =>{
        setIsPop(true);
        document.body.classList.add('popup-open');
    }
    const handleClose = () =>{
        setIsPop(false);
        document.body.classList.remove('popup-open');
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getMentorTransactionHistory();
                const walletBalance = state?.earningData?.walletBalance || 0;
                if (walletBalance > 100) {
                    setWalletClass('mentor-earnings-wallet withdraw');
                    setEarningsClass('mentor-lifetime-earnings');
                    setDollar(false);
                  } else if (walletBalance > 0) {
                    setWalletClass('mentor-earnings-wallet');
                    setEarningsClass('mentor-lifetime-earnings');
                  } else {
                    setWalletClass('mentor-earnings-wallet disabled');
                    setEarningsClass('mentor-lifetime-earnings disabled');
                    setDollar(true);
                  }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const handleLoadMore = async (pageNo) => {
        let res = await getMentorTransactionHistory(Number(pageNo)+1);
    
        if(typeof res === 'object'){
            let mergedData = {
                ...state.mentorTransHitory,
                ...res,
                result: [...state.mentorTransHitory.result, ...res.result]
              };
            dispatch({ type: actionTypes.MENTOR_TRANS_HISTORY, payload: mergedData});
        }
    }

    const post = state?.mentorTransHitory;

  return (
   <div className="mentor-earnings">
    {state.loading && <Loader/>}
        {/* {state.error && <ErrorMessage errorData={state.error} />} */}
        <div className="mentor-earnings-wrap">
                <div className="mentor-earnings-top">
                    <div className="dash-empty-sess-head">Wallet</div>
                    <div className="dash-empty-sess-body"><p>This wallet provides you an insights for all your earnings and a log of your transaction history.</p></div>
                </div>
                <div className="mentor-earnings-tabs">
                    <Tabs>
                        <div className="insage-guides-tab-buttons insage-guides">
                            <TabList>
                                <Tab>Earnings</Tab>
                                <Tab>Transaction history</Tab>
                            </TabList>
                        </div>
                        <div className="mentor-earnings-tab-wrap">
                            <TabPanel>
                                <div className="mentor-earnings-tab">
                                   <div className={walletClass}>
                                        <div className="mentor-earnings-wallet-wrap">
                                            <div className="mentor-earnings-wallet-top">
                                                <div className="mentor-earnings-wallet-head-wrap">
                                                   <div className="mentor-earnings-wallet-head">Wallet Balance</div>
                                                   <div className="mentor-earnings-wallet-svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
                                                        <path d="M1.16667 3C0 3 0 4 0 4V14.5C0 14.5 0 15.5 1.16667 15.5H19.8333C21 15.5 21 14.5 21 14.5V4C21 4 21 3 19.8333 3H1.16667Z" fill="#5C913B"/>
                                                        <path d="M1.16667 0.5C0 0.5 0 1.5 0 1.5V11.5C0 11.5 0 12.5 1.16667 12.5H19.8333C21 12.5 21 11.5 21 11.5V1.5C21 1.5 21 0.5 19.8333 0.5H1.16667Z" fill="#A7D28B"/>
                                                        <path d="M14.5846 9.74609C16.6787 9.74609 18.3763 8.29102 18.3763 6.49609C18.3763 4.70117 16.6787 3.24609 14.5846 3.24609C12.4906 3.24609 10.793 4.70117 10.793 6.49609C10.793 8.29102 12.4906 9.74609 14.5846 9.74609Z" fill="#77B255"/>
                                                        <path d="M19.25 11.75H1.75C1.26758 11.75 0.875 11.4135 0.875 11V2C0.875 1.5865 1.26758 1.25 1.75 1.25H19.25C19.7324 1.25 20.125 1.5865 20.125 2V11C20.125 11.4135 19.7324 11.75 19.25 11.75ZM1.75 1.75C1.58958 1.75 1.45833 1.862 1.45833 2V11C1.45833 11.1375 1.58958 11.25 1.75 11.25H19.25C19.4104 11.25 19.5417 11.1375 19.5417 11V2C19.5417 1.862 19.4104 1.75 19.25 1.75H1.75Z" fill="#5C913B"/>
                                                        <path d="M8.16797 0.5H12.8346V12.531H8.16797V0.5Z" fill="#FFE8B6"/>
                                                        <path d="M8.16797 12.5H12.8346V15.5H8.16797V12.5Z" fill="#FFAC33"/>
                                                        <path d="M6.89031 7.51531C6.89031 6.02581 3.68606 6.12281 3.68606 5.22331C3.68606 4.78781 4.17198 4.57531 4.73548 4.57531C5.6834 4.57531 5.85198 5.09731 6.28131 5.09731C6.58523 5.09731 6.73165 4.93331 6.73165 4.74931C6.73165 4.32131 5.97565 3.99831 5.25056 3.88631V3.47381C5.25056 3.21631 5.00731 3.00781 4.7069 3.00781C4.40648 3.00781 4.16323 3.21681 4.16323 3.47381V3.90031C3.3734 4.05531 2.69265 4.52531 2.69265 5.29081C2.69265 6.72181 5.8969 6.66381 5.8969 7.66981C5.8969 8.01731 5.45706 8.36531 4.7349 8.36531C3.65165 8.36531 3.29056 7.73681 2.85131 7.73681C2.63665 7.73681 2.44531 7.89131 2.44531 8.12431C2.44531 8.49481 3.16865 8.93981 4.1644 9.06481L4.16381 9.06681V9.53381C4.16381 9.79081 4.40765 9.99981 4.70806 9.99981C5.0079 9.99931 5.25115 9.79031 5.25115 9.53381V9.06681C5.25115 9.06181 5.24823 9.05731 5.24765 9.05281C6.14306 8.90931 6.89031 8.40981 6.89031 7.51531Z" fill="#5C913B"/>
                                                        </svg>
                                                    </div> 
                                                </div>
                                                <div className="mentor-earnings-wallet-balance">
                                                    {state?.earningData===null? '$0' : '$'+state?.earningData?.walletBalance}
                                                </div>
                                            </div>
                                            <div className="mentor-earnings-wallet-middle">
                                                {dollar && 
                                                <>
                                                 <div className="mentor-earnings-wallet-text">
                                                    <p>Insufficient funds to withdraw. You can only withdraw once you have $100 in your account.</p>
                                                </div>
                                                </>
                                                }
                                               
                                                <div className="mentor-earnings-wallet-btn">
                                                    <button onClick={handleClick} className='primary-btn'>
                                                        withdraw now
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mentor-earnings-wallet-bottom">
                                                <div className="mentor-earnings-wallet-text">
                                                    <p>Please note, money from a session reflects in your wallet after 6 days of completion.</p>
                                                </div>
                                                <div className="mentor-earnings-wallet-pending-amt">
                                                Funds being processed: <span>{state?.earningData===null? '$0' : '$'+state?.earningData?.totalInProcessSum}</span>
                                                </div>
                                            </div>
                                        </div>
                                   </div>
                                   <div className={earningsClass}>
                                      <div className="mentor-lifetime-earnings-wrap">
                                            <div className="mentor-earnings-wallet-top">
                                                <div className="mentor-earnings-wallet-head-wrap">
                                                   <div className="mentor-earnings-wallet-head">Lifetime Earnings</div>
                                                   <div className="mentor-earnings-wallet-svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                                        <path d="M15.2126 6.76888L11.2866 5.27176L9.82055 0.321787C9.76408 0.130896 9.59246 0 9.39879 0C9.20511 0 9.03349 0.130896 8.97702 0.321787L7.51143 5.27176L3.58496 6.76888C3.41158 6.83524 3.29688 7.00477 3.29688 7.19521C3.29688 7.38519 3.41158 7.55563 3.58496 7.62153L7.50923 9.1182L8.9757 14.2191C9.03129 14.4118 9.20335 14.544 9.39879 14.544C9.59379 14.544 9.76629 14.4118 9.82188 14.2186L11.2883 9.11775L15.2131 7.62107C15.3856 7.55563 15.5007 7.38564 15.5007 7.19521C15.5007 7.00522 15.3856 6.83524 15.2126 6.76888Z" fill="#FFAC33"/>
                                                        <path d="M6.38838 11.7715L5.3675 11.3824L4.97044 9.88257C4.91838 9.68441 4.74412 9.54715 4.54515 9.54715C4.34618 9.54715 4.17191 9.68441 4.11941 9.88212L3.72235 11.382L2.70191 11.771C2.52853 11.8369 2.41382 12.0074 2.41382 12.1973C2.41382 12.3873 2.52853 12.5578 2.70191 12.6237L3.71706 13.0114L4.11721 14.6576C4.16662 14.8594 4.34265 15.0012 4.54515 15.0012C4.74765 15.0012 4.92368 14.8594 4.97309 14.6571L5.37324 13.0109L6.38838 12.6232C6.56176 12.5578 6.67647 12.3873 6.67647 12.1973C6.67647 12.0074 6.56176 11.8369 6.38838 11.7715ZM4.47456 1.92562L3.43162 1.52793L3.04515 0.453039C2.98118 0.27442 2.81618 0.15625 2.63132 0.15625C2.44691 0.15625 2.28191 0.27442 2.2175 0.453039L1.83147 1.52793L0.788088 1.92562C0.614706 1.99198 0.5 2.16196 0.5 2.35194C0.5 2.54193 0.614706 2.71236 0.788088 2.77827L1.83147 3.17596L2.2175 4.25085C2.28191 4.42947 2.44691 4.54764 2.63132 4.54764C2.81574 4.54764 2.98074 4.42947 3.04515 4.25085L3.43118 3.17596L4.47456 2.77827C4.64794 2.71191 4.76265 2.54193 4.76265 2.35194C4.76265 2.16196 4.64794 1.99198 4.47456 1.92562Z" fill="#FFCC4D"/>
                                                        </svg>
                                                    </div> 
                                                </div>
                                                <div className="mentor-lifetime-earnings-showing-from">
                                                    <div className="mentor-lifetime-earnings-showing-from-label">Showing earnings {state?.earningData===null? 'till' : 'from'}</div>
                                                    <div className="mentor-lifetime-earnings-showing-from-date">{state?.earningData===null? 'Present' : state?.earningData?.firstTransactionDate+' - Present'}</div>
                                                </div>
                                                <div className="mentor-earnings-wallet-balance">
                                                {state?.earningData===null? '$0' : '$'+state?.earningData?.lifetimeEarning}
                                                </div>
                                                <div className="mentor-lifetime-earnings-pointers">
                                                    <div className="mentor-lifetime-earnings-pointer">
                                                        <div className="mentor-lifetime-earnings-pointer-wrap">
                                                            <div className="mentor-lifetime-earnings-pointer-head">Total sessions taken</div>
                                                            <div className="mentor-lifetime-earnings-pointer-number">{state?.earningData===null? '0' : state?.earningData?.totalSessionsTaken}</div>
                                                        </div>
                                                    </div>
                                                    <div className="mentor-lifetime-earnings-pointer">
                                                        <div className="mentor-lifetime-earnings-pointer-wrap">
                                                            <div className="mentor-lifetime-earnings-pointer-head">Total earnings last month</div>
                                                            <div className="mentor-lifetime-earnings-pointer-number">{state?.earningData===null? '$0' : '$'+state?.earningData?.lastMonthEarning}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                      </div>
                                   </div>
                                </div>
                                <div className={`withdraw-amt-pop ${isPop ? 'active': ''}`}>
                                    <WithdrawPop onClose={handleClose} walletBalance={state?.earningData?.walletBalance} />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="mentor-transactions-tab">
                                    {state?.mentorTransHitory?.result.length > 0 ? 
                                    <div className='mentor-trans-wrap'>
                                        <div className='mtw-headings'>
                                            <div className='mtw-row'>
                                                <div className='mtw-colum'>Order ID</div>
                                                <div className='mtw-colum'>Mentee name</div>
                                                <div className='mtw-colum'>Transaction date</div>
                                                <div className='mtw-colum'>Transaction type</div>
                                                <div className='mtw-colum'>Amount</div>
                                            </div>
                                        </div>
                                        <div className='mtw-data'>
                                            {state?.mentorTransHitory?.result && state?.mentorTransHitory?.result?.map((post,index) => {
                                                return (
                                                    <TransactionHistory props={post} key={index}/>
                                                );
                                            })}
                                        </div>
                                    </div> : 
                                    <div class="dash-emp-no-sess-wrap">
                                        <div class="dash-emp-no-sess-img">
                                            <img src={NoSesh} alt="" />
                                        </div>
                                        <div class="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                                        <div class="dash-emp-no-sess-body">
                                            <p> As a mentor, you haven't started your journey yet! Start taking sessions and sharing your expertise with mentees today</p>
                                        </div>
                                    </div>
                                    }
                                    { state?.mentorTransHitory?.hasMorePages && <div className="favourite-mentors-list-button">
                                    <div className="isg-guide-load-more" onClick={() => handleLoadMore(state.mentorTransHitory.currentPage)}>
                                        Load More
                                    </div>
                                    </div>
                                     }
                                </div>
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
        </div>
   </div>
  )
}
