import React, {useRef} from 'react';
import './become-a-mentor.css';
import '../../components/about/about.css';
import '../../components/features/features.css';
import BamImgSvg from '../../assets/images/become-a-mentor-bg-svg.png';
import { useInView } from "framer-motion";

export default function BamSecond({props, topMentorData}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true }); 
    const data = props;
  return (
    <section className="bam-second-section">
        <div className="container">
            <div className="bam-second-section-wrapper">
                <div className="bam-second-section-top">
                    <div className="bam-second-section-top-wrapper">
                        <div className="about-banner-heading">
                            <h2 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} dangerouslySetInnerHTML={{ __html: data?.ecoHead }}></h2>
                            </div>
                        <div className="bam-second-section-body">
                            <p ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "0.9s"
        }} className="col-p">{data?.ecoBody}</p>
                            </div>    
                    </div>
                </div>
                <div className="bam-second-section-bottom">
                    <div className="bam-second-section-bottom-wrapper">
                        <div ref={ref} style={{
         clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "0.9s"
        }} className="bam-second-section-img">
                            <img src={data?.ecoImg} alt="" className="img" />
                        </div>
                        <div className="bam-second-section-svg">
                           <img src={BamImgSvg} alt="" className="img" />
                        </div>
                        <div className="wwo-bottom-left-bottom-mentors">
                        <div className="wwo-bottom-left-bottom-mentors-wrapper">
                            <div className="wwo-top-mentors-heading">our Top Mentors</div>
                            <div className="wwo-top-mentors-list">

                            {topMentorData?.result?.allMentor?.slice(0, 3)?.map((post,index) => {
                                return (
                                <div key={index} className="wwo-top-mentor-mentor flex align">
                                    <div className="wwo-top-mentor-image">
                                      <img src={post?.avatar} alt="avatar" className='img' />
                                    </div>
                                    <div className="wwo-top-mentor-desc">
                                        <div className="wwo-top-mentor-name">{post?.fullName}</div>
                                        <div className="wwo-top-mentor-designation">{post?.designation} at {post?.company}</div>
                                    </div>
                                </div>
                                )

                            })}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
