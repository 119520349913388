import React from 'react';
import './trm.css';
import Trm from '../../../assets/images/trm-card-dash.png';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function TrmCard({post}) {
    // console.log(post)
    const { id } = useParams();
  return ( 
    <Link to={`/mentors/${post.username}`}>
    <div className="top-rated-mentor-card">
        <div className="top-rated-mentor-card-image">
            <img src={post?.avatar} alt="avatar" />
            <div className="trm-card-tag">
                <div className="trm-card-tag-wrapper">{post?.industry}</div>
            </div>
        </div>
        <div className="top-rated-mentor-card-content">
            <div className="top-rated-mentor-card-content-wrap">
                <div className="top-rated-mentor-card-name-price flex align flex-jsb">
                    <div className="top-rated-mentor-card-name">{post?.fullName}</div>
                    <div className="top-rated-mentor-card-price">{post?.hourlyRate}</div>
                </div>
                <div className="top-rated-mentor-card-designation flex align">
                    <div className="top-rated-mentor-card-designation-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path d="M10 4.5H2C1.44772 4.5 1 4.88376 1 5.35714V9.64286C1 10.1162 1.44772 10.5 2 10.5H10C10.5523 10.5 11 10.1162 11 9.64286V5.35714C11 4.88376 10.5523 4.5 10 4.5Z" fill="#C0C0C0" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 3.5V1.72222C8 1.66329 7.89464 1.60676 7.70711 1.56509C7.51957 1.52341 7.26522 1.5 7 1.5H5C4.73478 1.5 4.48043 1.52341 4.29289 1.56509C4.10536 1.60676 4 1.66329 4 1.72222V3.5" stroke="#BABABA" strokeLinecap="square" strokeLinejoin="round"/>
                        <path d="M8 11.5V4.5M4 4.5V11.5" stroke="#FEFBF4" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className="top-rated-mentor-card-designation-text flex align">
                        {post?.designation}
                    </div>
                </div>
                <div className="top-rated-mentor-card-designation trm-card-exp flex align">
                    <div className="top-rated-mentor-card-designation-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path d="M10 4.5H2C1.44772 4.5 1 4.88376 1 5.35714V9.64286C1 10.1162 1.44772 10.5 2 10.5H10C10.5523 10.5 11 10.1162 11 9.64286V5.35714C11 4.88376 10.5523 4.5 10 4.5Z" fill="#C0C0C0" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 3.5V1.72222C8 1.66329 7.89464 1.60676 7.70711 1.56509C7.51957 1.52341 7.26522 1.5 7 1.5H5C4.73478 1.5 4.48043 1.52341 4.29289 1.56509C4.10536 1.60676 4 1.66329 4 1.72222V3.5" stroke="#BABABA" strokeLinecap="square" strokeLinejoin="round"/>
                        <path d="M8 11.5V4.5M4 4.5V11.5" stroke="#FEFBF4" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className="top-rated-mentor-card-designation-text flex align">
                        {post?.company}
                    </div>
                </div>
                <div className="top-rated-mentor-card-designation trm-card-exp flex align">
                    <div className="top-rated-mentor-card-designation-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <g clipPath="url(#clip0_3408_20950)">
                            <path d="M9 4.5H9.5C10.0304 4.5 10.5391 4.71071 10.9142 5.08579C11.2893 5.46086 11.5 5.96957 11.5 6.5C11.5 7.03043 11.2893 7.53914 10.9142 7.91421C10.5391 8.28929 10.0304 8.5 9.5 8.5H9" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1 4H9V8.5C9 9.03043 8.78929 9.53914 8.41421 9.91421C8.03914 10.2893 7.53043 10.5 7 10.5H3C2.46957 10.5 1.96086 10.2893 1.58579 9.91421C1.21071 9.53914 1 9.03043 1 8.5V4Z" fill="#C0C0C0"/>
                            <path d="M3 1V2.5" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5 1V2.5" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7 1V2.5" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3408_20950">
                                <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                        </defs>
                        </svg>
                    </div>
                    <div className="top-rated-mentor-card-designation-text flex align">
                        {post?.levelOfExp} Years of Experience 
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Link>
  )
}
