import React from 'react';
import axios from 'axios';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import FAQsPage from '../../components/faqs/FAQsPage';

export default function FAQs() {
    const [faqData, setFaqData] = React.useState([]);
    const [faqData2, setFaqData2] = React.useState([]);
    const [faqData3, setFaqData3] = React.useState([]);
    const [faqData4, setFaqData4] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("./faq.json")
    .then((res) => setFaqData(res.data))
    .catch(err=>console.log(err))
  },[]);
  React.useEffect(() => {
    axios
    .get("./faq2.json")
    .then((res) => setFaqData2(res.data))
    .catch(err=>console.log(err))
  },[]);
  React.useEffect(() => {
    axios
    .get("./faq3.json")
    .then((res) => setFaqData3(res.data))
    .catch(err=>console.log(err))
  },[]);
  React.useEffect(() => {
    axios
    .get("./faq4.json")
    .then((res) => setFaqData4(res.data))
    .catch(err=>console.log(err))
  },[]);
  return (
    <main className='insage-FAQ-temp'>
        <Header/>
         <FAQsPage props={faqData.faq} props2={faqData2.faq} props3={faqData3.faq} props4={faqData4.faq}/> 
        <Footer/>
    </main>
  )
}
