import React, { useContext, useState } from 'react';
import '../menteeProfile/mentee-profile.css';
import '../onboarding/Onboarding-content.css';
import '../reusable/login/login.css';
import MultiSelectTags from '../reusable/multi-select/multiSelect';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function OverviewPop({onclose,noti}) {

    const { state, updateMentorProfileOverView } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const[linkedinError, setLinkedinError] = useState("");
    const[videoError, setVideoError] = useState("");
    const [formData, setFormData] = useState({
        bio: state?.profile?.bio,
        bioVideo:state?.profile?.bioVideo,
        linkedinProfile: state?.profile?.linkedinProfile,
        industry:state?.profile?.industry,
        guidanceState:(state?.profile?.guidance || []).map((values, index) => ({ value: values, label: values, key: index })),
        guidance: Object.values(state?.profile?.guidance || []),
        languagesState:(state?.profile?.language || []).map((values, index) => ({ value: values, label: values, key: index })),
        language: Object.values(state?.profile?.language || []),
    })
    const [bidVideoData, setBioVideoData] = useState(state?.profile?.bioVideo);


    const handleOnboardingInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleVideoChange = e => {
        const file = e.target.files[0];
        const maxSize = 20 * 1024 * 1024; // 20 MB in bytes
        if (file.size > maxSize) {
            setVideoError("The video size must not exceed 20 MB");
            return;
        }
        else {
            setVideoError("");
        }
        console.log('file ', file);
        const reader = new FileReader();

        reader.onload = () => {
            setFormData({
                ...formData,
                bioVideo: file,
                });
                setBioVideoData(reader.result)
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveVideo = e => {
        setFormData({
            ...formData,
            bioVideo: null,
        });
        setBioVideoData('');
    };

    const handleGuidance = (event) => {
        const selectedOptions = event.target.value; // Assuming event.target.value is an array of selected values

        const newGuidanceState = selectedOptions.map((option) => ({
            value: option.value,
            label: option.value,
        }));


        const guidance = selectedOptions.map((option) => option.value);

        console.log(selectedOptions.length);
        setFormData((prevFormData) => ({
            ...prevFormData,
            guidanceState: newGuidanceState,
            guidance: selectedOptions.length ? guidance : [],
        }));
    };

    const handleLanguage = (event) => {
        const selectedOptions = event.target.value;

        const newLanguagesState = selectedOptions.map((option) => ({
            value: option.value,
            label: option.value,
        }));
        const language = selectedOptions.map((option) => option.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            languagesState: newLanguagesState,
            language: selectedOptions.length > 0 ? language : [],
        }));
    };

    const handleOverviewForm = async (e) => {
        e.preventDefault();
        try {
            if(!formData.linkedinProfile.trim()){
                setLinkedinError("Please enter your LinkedIn profile link");
                return;
            }
            if(!formData.linkedinProfile.startsWith('https://www.linkedin.com/')){
                setLinkedinError('LinkedIn profile link must start with "https://www.linkedin.com/"');
                return;
            }
            setLoading(true);
            let res = await updateMentorProfileOverView(formData);
            if(res){
                onclose();
                noti();
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const languageOptions = [
        { value: 'English', label: 'English' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Urdu', label: 'Urdu' },
        { value: 'French', label: 'French' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'Arabic', label: 'Arabic' },
        { value: 'Portugese', label: 'Portugese' }
    ];

    return (
        <div className="insage-login">
        <div className="insage-login-wrapper">
            <div className="login-head"><span>overview</span></div>
            <div className="overview-profile-pop-form">
                <form action="post">
                    <div className='login-form-input flex flex-c'>
                        <label className='flex bio-label align' htmlFor="aboutMe">About me</label>
                            <textarea id='aboutMe' name='bio' value={formData?.bio} onChange={handleOnboardingInputChange} />
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="linkedinProfile">Linkedin URL</label>
                        {linkedinError && <p className="error-name-onb">{linkedinError}</p>}
                        <input type="text" id="linkedinProfile" name='linkedinProfile' placeholder='Type here' value={formData?.linkedinProfile} onChange={handleOnboardingInputChange} />
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="guidance">Sharing guidance in</label>
                        <div className="select-wrapper">
                            <MultiSelectTags
                                id="guidance"
                                options={[
                                    { value: 'Domain Related', label: 'Domain Related' },
                                    { value: 'Organisational Culture', label: 'Organisational Culture' },
                                    { value: 'Management Skills', label: 'Management Skills' },
                                    { value: 'Career Path', label: 'Career Path' },
                                    { value: 'Feedback & Conflict Resolution', label: 'Feedback & Conflict Resolution' },
                                    { value: 'Effective communication', label: 'Effective communication' },
                                    { value: 'Networking', label: 'Networking' },
                                    { value: 'Leadership Skills', label: 'Leadership Skills' }
                                    // Add more options as needed
                                
                                ]}
                                value={formData?.guidanceState}
                                onChange={handleGuidance}
                                required
                            />
                        </div>
                    </div>
                    <div className='login-form-input flex flex-c'>
                            <label htmlFor="industry">Industry</label>
                            <div className="select-wrapper">
                                <select
                                id="industry"
                                name="industry"
                                value={formData?.industry}
                                onChange={handleOnboardingInputChange}
                                required
                                >
                                <option value="" disabled>Select an industry</option>
                                <option value="Art & Design">Art & Design</option>
                                <option value="Coding & Dev">Coding & Dev</option>
                                <option value="Marketing">Marketing</option>
                                <option value="IT & Software">IT & Software</option>
                                <option value="Personal Development">Personal Development</option>
                                <option value="Business & Sales">Business & Sales</option>
                                <option value="Photography">Photography</option>
                                <option value="Management">Management</option>
                                <option value="Other">Other</option>
                                </select>
                            </div>
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="languages">Languages</label>
                            <div className="select-wrapper">
                                <MultiSelectTags
                                    id="languages"
                                    name="languagesState"
                                    options={languageOptions}
                                    value={formData?.languagesState}
                                    onChange={handleLanguage}
                                    required
                                />
                                </div>    
                    </div>
                    <div className="overview-video-cont">
                        <div className="overview-video-wrap">
                            <video autoplay="false" playsinline controls="true" src={bidVideoData}></video>
                        </div>
                        
                        <div className="overview-video-text">
                            The video must not exceed a size of 20 mb
                        </div>
                        <div className="overview-video-btn">
                            <label htmlFor='bioVideo'>
                                + choose file
                                <input type="file" accept="video/*" name="bioVideo" id="bioVideo" onChange={handleVideoChange}/>
                            </label>
                            {videoError && <p className="error-name-onb">{videoError}</p>}
                        </div>
                        <div className='videoremove'>
                            {bidVideoData && (
                            <div onClick={handleRemoveVideo} className='popup-btn'>Remove Video</div>
                            )}
                        </div>
                    </div>
                    <div className="overview-profile-pop-btn">
                        <button className='primary-btn' onClick={handleOverviewForm}>
                        {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                            'save changes'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div className="login-close-button" onClick={onclose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
        </div>
    )
}
