import React, { useContext,useRef } from 'react';
import './ExploreMentors.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useInView } from "framer-motion";
import axios from 'axios';
// import PopularCards from '../../explore-our-mentors/PopularCards';
import { AuthContext } from '../../../context/AuthContext'; 
import TopCards from './TopCards';

export default function ExploreMentors({topMentorData}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });  
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const { state } = useContext(AuthContext);


// console.log('topMentorData ', topMentorData?.result);
    // const Address = BgData[SectionConstants.secDataType];
    
  return (
    <section className="insage-explore-mentors">
        <div className="container">
        <div className="insage-top-categories-top-wrapper">
            <div ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-top-categories-label flex flex-jsc">
              <span className="label col-white">popular mentors</span>
              </div>
              <div className="insage-top-categories-heading">
                <h2 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>Discover Top Mentors</h2>
                </div>
              <div className="insage-top-categories-body">
                <p ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>The best advice is great advice tailor-made for you. Our mentors are passionate about passing down their wisdom and inspiring the newer generation to realise their full potential.</p>
                </div>
          </div>
          <div className="mentors-cards">
            <Swiper autoHeight={true} spaceBetween={24}  breakpoints={{ 0: { slidesPerView: 1,}, 768: { slidesPerView: 2,}, 992: { slidesPerView: 3,},}} navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current }} modules={[Navigation]} className="mySwiper mentors-swiper">
             
              {topMentorData?.result?.allMentor?.map((posts,index) => {
                // {console.log('posts ', posts)}
                    return (
                        <SwiperSlide key={index}>
                            <TopCards post={posts}/>
                        </SwiperSlide>
                    );
                })} 
                <div className="prev-btn black" ref={navigationPrevRef}>
                  <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.256229 20.9111L12.7305 33.3854L16.5177 29.5981L6.71894 19.8023L6.73358 19.8023L26.3432 19.7961L26.2526 14.4274L6.7338 14.4336L6.72209 14.4336L16.5271 4.62862L12.7407 0.845215L0.258618 13.3273L0.256229 20.9111Z" />
                  </svg>
              </div>
              <div className="next-btn black" ref={navigationNextRef}>
                  <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M26.7438 20.9111L14.2695 33.3854L10.4823 29.5981L20.2811 19.8023L20.2664 19.8023L0.656813 19.7961L0.747358 14.4274L20.2662 14.4336L20.2779 14.4336L10.4729 4.62862L14.2593 0.845215L26.7414 13.3273L26.7438 20.9111Z"/>
                  </svg>
              </div>
            </Swiper>
          </div>
        </div>
    </section>
  )
}
