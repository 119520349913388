import React,{useState} from 'react';
import './mentee-profile.css';

export default function ProfilePop({onclose, onOverOpen, onIntroOpen, onEduOpen, onExpOpen}) {
    const [activeAccordion, setActiveAccordion] = useState('');
    
    return (
    <div className="insage-login">
    <div className="insage-login-wrapper">
        <div className="login-head"><span>Complete profile</span></div>
        <div className="complete-profile-pop-accordions">
            <div className={activeAccordion === 'accord1'? 'complete-profile-pop-accordion active' : 'complete-profile-pop-accordion'} >
                <div onClick={() =>  setActiveAccordion('accord1')} className={activeAccordion === 'accord1' ? 'complete-profile-pop-head-arrow flex flex-jsb align active' : 'complete-profile-pop-head-arrow flex flex-jsb align'}>
                    <div className="complete-profile-pop-head">Intro</div>
                    <div className="complete-profile-pop-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                        <path d="M1 1.5L6 6.5L11 1.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="complete-profile-pop-body">
                    <div>
                        <p>We noticed that you still have some empty fields in your profile. Did you know that filling out all your profile information increases your chances of getting accepted by mentors?</p>
                        <button onClick={onIntroOpen} className='primary-btn'>edit</button>
                    </div>
                </div>
            </div>
            <div className={activeAccordion === 'accord2'? 'complete-profile-pop-accordion active' : 'complete-profile-pop-accordion'} >
                <div onClick={() =>  setActiveAccordion('accord2')} className={activeAccordion === 'accord2' ? 'complete-profile-pop-head-arrow flex flex-jsb align active' : 'complete-profile-pop-head-arrow flex flex-jsb align'}>
                    <div className="complete-profile-pop-head">Overview</div>
                    <div className="complete-profile-pop-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                        <path d="M1 1.5L6 6.5L11 1.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="complete-profile-pop-body">
                    <div>
                        <p>We noticed that you still have some empty fields in your profile. Did you know that filling out all your profile information increases your chances of getting accepted by mentors?</p>
                        <button onClick={onOverOpen} className='primary-btn'>edit</button>
                    </div>
                </div>
            </div>
            <div className={activeAccordion === 'accord3'? 'complete-profile-pop-accordion active' : 'complete-profile-pop-accordion'} >
                <div onClick={() =>  setActiveAccordion('accord3')} className={activeAccordion === 'accord3' ? 'complete-profile-pop-head-arrow flex flex-jsb align active' : 'complete-profile-pop-head-arrow flex flex-jsb align'}>
                    <div className="complete-profile-pop-head">Education</div>
                    <div className="complete-profile-pop-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                        <path d="M1 1.5L6 6.5L11 1.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="complete-profile-pop-body">
                    <div>
                        <p>We noticed that you still have some empty fields in your profile. Did you know that filling out all your profile information increases your chances of getting accepted by mentors?</p>
                        <button onClick={onEduOpen} className='primary-btn'>edit</button>
                    </div>
                </div>
            </div>
            <div className={activeAccordion === 'accord4'? 'complete-profile-pop-accordion active' : 'complete-profile-pop-accordion'} >
                <div onClick={() =>  setActiveAccordion('accord4')} className={activeAccordion === 'accord4' ? 'complete-profile-pop-head-arrow flex flex-jsb align active' : 'complete-profile-pop-head-arrow flex flex-jsb align'}>
                    <div className="complete-profile-pop-head">Experience</div>
                    <div className="complete-profile-pop-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                        <path d="M1 1.5L6 6.5L11 1.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="complete-profile-pop-body">
                    <div>
                        <p>We noticed that you still have some empty fields in your profile. Did you know that filling out all your profile information increases your chances of getting accepted by mentors?</p>
                        <button onClick={onExpOpen} className='primary-btn'>edit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="login-close-button" onClick={onclose}>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
        <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    </div>
    </div>
  )
}
