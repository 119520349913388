import React, {useState, useRef, useEffect} from 'react';
import './dashSession.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import starFilled from '../../../assets/images/star-filled.svg'
import starEmpty from '../../../assets/images/star-empty.svg'

export default function MentorDashSessionGrid ({props,openExpandSessionPopup,openCancelSessionPopup}) {

  return ( 
    <div className='session-grid-card-outer'>
        <div className='session-grid-card-in'>
            <div className='sgc-main'>
                <div className='sgcm-top'>
                    <div className='sgcmt-upper'>
                        <div className='sgcmt-mentor-image'>
                            <img src={props?.menteeAvatar}/>
                        </div>
                        <div className='sgcmt-mentor-name'>{props?.menteeName}</div>
                        <div className='sgcmt-mentor-poscom'>
                            <div className='sgd-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g clip-path="url(#clip0_6019_68415)">
                                    <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#C0C0C0" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z" stroke="#C0C0C0" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6 7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7Z" stroke="#C0C0C0" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_6019_68415">
                                    <rect width="12" height="12" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className='sgcmt-mentor-position'>{props?.menteeCompany}</div>
                            <div className='dot-seperator'></div>
                            <div className='sgcmt-mentor-company'>{props?.menteeDesignation}</div>
                        </div>
                    </div>
                    <div className='sgcmt-lower'>
                        <div className='sg-date'>
                            <div className='sgd-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"/>
                                </svg>
                            </div>
                            <div className='sgd-text'>
                                {props?.sessionDate}
                            </div>
                        </div>
                        <div className='sg-time'>
                            <div className='sgd-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <g clipPath="url(#clip0_5122_71366)">
                                    <path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_5122_71366">
                                    <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className='sgd-text'>
                                {props?.startTime} - {props?.endTime}
                            </div>
                        </div>
                        <div className='detailsbtn'>
                            <div className='more-link' onClick={() => openExpandSessionPopup(props)}>
                                more details
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="6" viewBox="0 0 4 6" fill="none">
                                        <path d="M1 5L3 3L1 0.999999" stroke="#666666" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='sgcm-foot'>
                    <div className='sgcmf-text'>Can’t make it?</div>
                    <div className='sgcmf-cancel'>
                        <button className="cancel-text" onClick={() => openCancelSessionPopup(props)}>Cancel Session</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
