import React from 'react';
import axios from 'axios';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';

export default function TermsCondition() {
    const [staticData, setstaticData] = React.useState([]);
    React.useEffect(() => {
        axios
        .get("./terms-condition.json")
        .then((res) => setstaticData(res.data))
        .catch(err=>console.log(err))
    },[]);
    return(
        <main>
            <Header/>
                <section className='static-page'>
                    <div className='container'>
                        <div className='heading center'>
                            <h3>Terms and Condition</h3>
                        </div>
                        <div className='static-content-wrapper'>
                            <div className='static-content'>
                                <div className='scw-head'>
                                    <h6>Heading</h6>
                                </div>
                                <div className='scw-para'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing tincidunt id risus diam sagittis morbi feugiat. Convallis euismod sollicitudin ut pretium ipsum. Porttitor vel sit vel feugiat. Elit quis elit feugiat nisi, tempor sed. </p>
                                    <p>Eget viverra blandit senectus fermentum id nibh scelerisque venenatis. Venenatis enim ultricies amet nisi diam egestas penatibus egestas. Luctus pellentesque mus consequat non dignissim eleifend nulla volutpat. Nunc neque donec sed aliquam, proin.</p>
                                </div>
                            </div>
                            <div className='static-content'>
                                <div className='scw-head'>
                                    <h6>Sub-heading</h6>
                                </div>
                                <div className='scw-para'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing tincidunt id risus diam sagittis morbi feugiat. Convallis euismod sollicitudin ut pretium ipsum. Porttitor vel sit vel feugiat. Elit quis elit feugiat nisi, tempor sed. Eget viverra blandit senectus fermentum id nibh scelerisque venenatis. Venenatis enim ultricies amet nisi dia.</p>
                                </div>
                            </div>
                            <div className='static-content'>
                                <div className='scw-head'>
                                    <h6>Sub-heading</h6>
                                </div>
                                <div className='scw-para'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing tincidunt id risus diam sagittis morbi feugiat. Convallis euismod sollicitudin ut pretium ipsum. Porttitor vel sit vel feugiat. Elit quis elit feugiat</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing tincidunt id risus diam sagittis morbi feugiat. Convallis euismod sollicitudin ut pretium ipsum. Porttitor vel sit vel feugiat. Elit quis elit feugiat nisi, tempor sed. Eget viverra blandit senectus fermentum id nibh scelerisque venenatis. Venenatis enim ultricies amet nisi dia.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </main>
    )
}