import React, { useContext, useState,useRef } from 'react';
import '../Dashboard/dashboardPopup.css';
import { unifiedPostRequest } from '../../context/api'; 
import Cookies from 'js-cookie';
import demoImage from '../../assets/images/blog-image.jpeg';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ChatContext } from '../../context/ChatContext';
import { AuthContext } from '../../context/AuthContext';

export default function UpdateBlogPostPopup ({onclose,data,posttitle,editorhtml,readTime, noti, notiE, blogId, editData, industry, setIndustry, bannerImage, setBannerImage}) {

    const {handleTabIndex} = useContext(ChatContext);

    const {state} = useContext(AuthContext);

    // const location = useLocation();
    // const { editState } = location.state || {};

    // let editData;

    // if(editState==='true'){
    //     editData = state.editBlogData
    // }else{
    //     editData = {}
    // }

    // console.log('editData ', editData);

    // const [industry, setIndustry] = useState(editData.categoryId);
    // const [bannerImage, setBannerImage] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const userDetail = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [categortError , setCategoryErr] = useState('')

    const handleFileInputChange = (e) => {
        const input = e.target;
        const file = e.target.files[0];
        const label = input.nextElementSibling;

        if (label) {
            const labelVal = label.innerHTML;

            let fileName = '';
            if (input.files && input.files.length > 1) {
                fileName = (input.getAttribute('data-multiple-caption') || '').replace('{count}', input.files.length);
            } else {
                fileName = e.target.value.split('\\').pop();
            }

            if (fileName) {
                label.querySelector('span').innerHTML = fileName;
            } else {
                label.innerHTML = labelVal;
            }
            setBannerImage(input.files[0]);
            if (file) {
                const reader = new FileReader();
          
                reader.onloadend = () => {
                  setSelectedImage(reader.result);
                };
          
                reader.readAsDataURL(file);
            }
        }
        
    };

    const inputRef = useRef();

    const inputElements = document.querySelectorAll('.inputfile');

    Array.from(inputElements).forEach((input) => {
        input.addEventListener('change', handleFileInputChange);
    });

    const handlePublishNow = async () => {
        try {
          setLoading(true);
          const postData = {
            title: posttitle,
            content: editorhtml,
            categoryId: industry ? industry : editData.categoryId,
            image: bannerImage,
            readingTime: readTime,
            blogId:blogId
          };

          let  publishResponse;

        //   console.log('postData ', postData);

          if(postData.categoryId){
            setCategoryErr('')
            publishResponse = await publishNowAPI(postData);
          }else{
            setCategoryErr('Please select the category')
            setLoading(false);
            return;
          }
    
          // Send data to the API
          

          if (publishResponse){
            console.log('Publish now response:', publishResponse);
            setLoading(false);
            onclose();
            noti();
            document.body.classList.remove('popup-open');
            setTimeout(()=>{
                handleTabIndex(7);
                navigate('/mentor/dashboard');
            },2000)
           
          }
          else{
            setLoading(false);
            onclose();
            notiE();
            document.body.classList.remove('popup-open');
          }
          // Handle the API response as needed
         
        } catch (error) {
          console.error('Error publishing now:', error);
        }
      };
    
      const publishNowAPI = async (postData) => {
        try {
          let postUrl = process.env.REACT_APP_API_URL + '/mentor/update-blog';
          const response = await unifiedPostRequest(postUrl, postData, true, true);

          return response;
        } catch (error) {
          throw error;
        }
      };

      

    return (
        <div className="insage-login dashboard-popups postingpopup">
            <div className="insage-login-wrapper">

                <div className="overview-profile-pop-form">
                    <form action="post">
                        <div className='postpopup-wrap'>
                            <div className='pp-colm'>
                                <div className='ppc-head'>
                                    <h6>Banner Image (Optional)</h6>
                                </div>
                                <div className='ppc-content'>
                                    <div className='imgbox'>
                                        <img src={selectedImage ? selectedImage : demoImage} />
                                    </div>
                                    <p className='imageinfo'>The file must not exceed 2mb in size.</p>
                                    <div className='imageInput'>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            className="inputfile"
                                            ref={inputRef}
                                            onChange={handleFileInputChange}
                                        />
                                        <label htmlFor="fileInput">
                                            {/* Add any additional HTML structure needed for your label */}
                                            <span>+ Choose a file</span>
                                        </label>
                                    </div>
                                    <div className='note-text'>
                                        <p><b>Note:</b> Changes here will affect how your blog appears in public places like Insage’s blogs page and in peoples’ inboxes — not the contents of the story itself.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='pp-colm'>
                                <div className='ppc-head'>
                                    <h6>Publishing as: <span>{userDetail?.fullName}</span></h6>
                                </div>
                                <div className='ppc-content'>
                                    <div className='login-form-input flex flex-c'>
                                        <label htmlFor="industry">How can we categorize your blog post?</label>
                                        <p>Choosing the right category can help your blog reach the right audience and make it easier for readers to find content that's relevant to their interests.</p>
                                        <span className='errormessage'>{categortError}</span>
                                        <div className="select-wrapper">
                                            <select
                                                id={industry}
                                                name="industry"
                                                required
                                                value={industry}
                                                onChange={(e) => setIndustry(e.target.value)}
                                            >
                                                <option value="" disabled>Select category</option>
                                                {data?.map((category) => (
                                                    <option key={category?._id} value={category?._id}>
                                                        {category?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="overview-profile-pop-btn">
                                        <div className='primary-btn green' onClick={handlePublishNow}>
                                        {loading ? (
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                    ) : (
                                                <>Publish</>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                    </form>
                </div>
            </div>
            <div className="login-close-button" onClick={onclose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}
