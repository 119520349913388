import React, { useRef } from 'react';
import './Quote.css';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import { useInView } from "framer-motion";

export default function Quote({props}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });



  const data = props;

  return (
    <section ref={ref} className="insage-quote">
        <div className="insage-quote-wrapper container text-align">
            <div className="insage-quote-text"><h2 style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} >"{data?.quoteHeading}"</h2></div>
            <div className="insage-quote-author"><span style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="label">{data?.quoteName}</span></div>
        </div>
    </section>
  )
}
