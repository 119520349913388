import React, { useContext, useState, useEffect } from 'react';
// import './mentee-profile.css';
import '../onboarding/Onboarding-content.css';
import '../reusable/login/login.css';
import MultiSelectTags from '../reusable/multi-select/multiSelect';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import starFilled from '../../assets/images/star-filled.svg'
import starEmpty from '../../assets/images/star-empty.svg'
import { Rating } from 'react-simple-star-rating';
import axios from 'axios';
import Cookies from 'js-cookie';
import actionTypes from '../../context/actionTypes';

export default function WriteReviewOnDashPopup ({ data, noti, onclose,onCancel,onOpenNew }) {

    const [reviewDescription, setReviewDescription] = useState('');
    const [loading, setLoading] = useState(false);
    // Separate states for each category's rating
    const [approachabilityRating, setApproachabilityRating] = useState(0);
    const [contentDeliveryRating, setContentDeliveryRating] = useState(0);
    const [valueForMoneyRating, setValueForMoneyRating] = useState(0);
    const [criteria04Rating, setCriteria04Rating] = useState(0);
    const [bookingDetails,setBookingDetails ] = useState([]);
    const [message,setMessage ] = useState([]);
    const [error, setError] = useState(null);

    const { state, dispatch } = useContext(AuthContext);

    const handleOpenNewPopup = () => {
        onCancel(data);
      };

      const handleDescriptionChange = (event) => {
        setReviewDescription(event.target.value);
      };

    let bookingId = data?.bookingId;
    // let message = data?.bookingId;
    let approachability = approachabilityRating;
    let contentDelivery = contentDeliveryRating;
    let valueForMoney = valueForMoneyRating;
    let critera04 = criteria04Rating;

    const handleRating = (rate, category) => {
        // Update the corresponding rating state based on the category
        switch (category) {
          case 'approachability':
            setApproachabilityRating(rate);
            break;
          case 'contentDelivery':
            setContentDeliveryRating(rate);
            break;
          case 'valueForMoney':
            setValueForMoneyRating(rate);
            break;
          case 'criteria04':
            setCriteria04Rating(rate);
            break;
          default:
            break;
        }
      };

      const onPointerMove = (value, index) => console.log(value, index);

      const handleReset = (category) => {
        // Reset the corresponding rating state based on the category
        switch (category) {
          case 'approachability':
            setApproachabilityRating(0);
            break;
          case 'contentDelivery':
            setContentDeliveryRating(0);
            break;
          case 'valueForMoney':
            setValueForMoneyRating(0);
            break;
          case 'criteria04':
            setCriteria04Rating(0);
            break;
          default:
            break;
        }
      };


      const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userToken = Cookies.get('token');
    
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/create-review`,
            {
              bookingId,
              approachability,
              contentDelivery,
              valueForMoney,
              critera04,
              message: reviewDescription,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {
            setBookingDetails(res.data);
            setError(null);
            if(res){
              setLoading(false);
              // const average = Math.round((approachability + contentDelivery + valueForMoney + critera04) / 4);

              const average = Math.round((approachability + contentDelivery + valueForMoney) / 3);

              // console.log('res ', res.data);
              // console.log('average ', average);
              // console.log('state?.dashboard ', state?.dashboard?.completedBookingsFetch);

              const updatedSessionData = state?.dashboard?.completedBookingsFetch.map(data => {
                // Check if the current data object has the desired booking ID
                if (data.bookingId === bookingId) {
                  // Update the 'review' field with the new value
                  return { ...data, review: average };
                }
              
                // If the bookingId doesn't match, return the original data object
                return data;
              });
              
              // Merge existing completedBookingsFetch with updated session data
              const mergedData = { ...state?.dashboard, completedBookingsFetch: updatedSessionData };

              console.log('mergedData ', mergedData);
              
              // Dispatch the updated data
              dispatch({ type: actionTypes.DASHBOARD_MENTEE, payload: mergedData });
              noti();
              onclose();
              
            }
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            setLoading(false);
          });
      };


    return (
        <div className="insage-login">
            <div className="insage-login-wrapper writereviewWrapper">
                <div className="login-head"><span>write a review</span></div>
                <div className='write-review-pop'>
                    <div className='wrp-image'>
                        <img src={data?.mentorAvatar}/>
                    </div>
                    <div className='wrp-data'>
                        <div className='mentor-name'>
                            {data?.mentorName}
                        </div>
                        <div className='mentor-details'>
                            <div className='wrpm-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <path d="M10 4.5H2C1.44772 4.5 1 4.88376 1 5.35714V9.64286C1 10.1162 1.44772 10.5 2 10.5H10C10.5523 10.5 11 10.1162 11 9.64286V5.35714C11 4.88376 10.5523 4.5 10 4.5Z" fill="#C0C0C0" stroke="#C0C0C0" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 3.5V1.72222C8 1.66329 7.89464 1.60676 7.70711 1.56509C7.51957 1.52341 7.26522 1.5 7 1.5H5C4.73478 1.5 4.48043 1.52341 4.29289 1.56509C4.10536 1.60676 4 1.66329 4 1.72222V3.5" stroke="#BABABA" stroke-linecap="square" stroke-linejoin="round"/>
                                    <path d="M8 11.5V4.5M4 4.5V11.5" stroke="#FFF6E1" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className='wrpm-data'>
                                <div className='md-position'>{data?.mentorDesignation}</div>
                                <div class="dot-seperator"></div>
                                <div className='md-company'>{data?.mentorCompany}</div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="overview-profile-pop-form">
                <form onSubmit={handleSubmit}>
                    <div className='put-ratings-wrap'>
                        <p className='prw-head'>Put ratings for {data?.mentorName}</p>
                        <div className='prr-ratings'>
                            <div className='pr-rate'>
                                <div className='prr-head'>Approachability</div>
                                <div className='prr-content'>
                                    <div style={{direction: 'ltr',fontFamily: 'sans-serif',touchAction: 'none'}}>
                                        <Rating
                                            fillColorArray={['#f14f45','#f16c45','#f18845','#f1b345','#f1d045']}
                                            initialValue={approachabilityRating}
                                            onClick={(rate) => handleRating(rate, 'approachability')}
                                            onPointerMove={onPointerMove}
                                            transition
                                        />
                                    </div>
                                    <div className='prr-reset'>
                                        <button onClick={() => handleReset('approachability')} style={{marginLeft: 10}}>Clear</button>
                                    </div>
                                </div>
                            </div>
                            <div className='pr-rate'>
                                <div className='prr-head'>Content Delivery</div>
                                <div className='prr-content'>
                                    <div style={{direction: 'ltr',fontFamily: 'sans-serif',touchAction: 'none'}}>
                                        <Rating
                                            fillColorArray={['#f14f45','#f16c45','#f18845','#f1b345','#f1d045']}
                                            initialValue={contentDeliveryRating}
                                            onClick={(rate) => handleRating(rate, 'contentDelivery')}
                                            onPointerMove={onPointerMove}
                                            transition
                                        />
                                    </div>
                                    <div className='prr-reset'>
                                        <button onClick={() => handleReset('contentDelivery')} style={{marginLeft: 10}}>Clear</button>
                                    </div>
                                </div>
                            </div>
                            <div className='pr-rate'>
                                <div className='prr-head'>Value for Money</div>
                                <div className='prr-content'>
                                    <div style={{direction: 'ltr',fontFamily: 'sans-serif',touchAction: 'none'}}>
                                        <Rating
                                            fillColorArray={['#f14f45','#f16c45','#f18845','#f1b345','#f1d045']}
                                            initialValue={valueForMoneyRating}
                                            onClick={(rate) => handleRating(rate, 'valueForMoney')}
                                            onPointerMove={onPointerMove}
                                            transition
                                        />
                                    </div>
                                    <div className='prr-reset'>
                                        <button onClick={() => handleReset('valueForMoney')} style={{marginLeft: 10}}>Clear</button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='pr-rate'>
                                <div className='prr-head'>Criteria 04</div>
                                <div className='prr-content'>
                                    <div style={{direction: 'ltr',fontFamily: 'sans-serif',touchAction: 'none'}}>
                                        <Rating
                                            fillColorArray={['#f14f45','#f16c45','#f18845','#f1b345','#f1d045']}
                                            initialValue={criteria04Rating}
                                            onClick={(rate) => handleRating(rate, 'criteria04')}
                                            onPointerMove={onPointerMove}
                                            transition
                                        />
                                    </div>
                                    <div className='prr-reset'>
                                        <button onClick={() => handleReset('criteria04')} style={{marginLeft: 10}}>Clear</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="slci-foot">
                        <div className='login-form-input flex flex-c'>
                        <label className="flex bio-label align" htmlFor="aboutMe">
                            Describe the session in details (optional)
                        </label>
                        <textarea
                            id="aboutMe"
                            name="bio"
                            placeholder={'Tell us how your session was:'}
                            value={reviewDescription}
                            onChange={handleDescriptionChange}
                            />
                        </div>
                        <div className='review-submit'>
                        <button type="submit" className="popup-btn book-button">
                            {loading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                                  ) : (
                                  'Submit'
                                  )}
                            </button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            <div className="login-close-button" onClick={onclose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}
