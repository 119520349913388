import React, { useContext,useState } from 'react';
import '../Dashboard/profile.css';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function MentorSchedule({props,buttonClick}) {

    const {state, availabilityEditData} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleEditAvailability = async () => {
        await availabilityEditData({bookingId: props?.availabilityId});
        buttonClick();
    }

    return (
    <div className="booked-session-card">
        <div className="booked-session-card-wrap">
            <div className="booked-session-card-top">
                <div className='slot-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M20 17.7783C20 19.0055 19.005 20.0005 17.7778 20.0005H2.22222C0.995 20.0005 0 19.0055 0 17.7783V5.00054C0 3.77332 0.995 2.77832 2.22222 2.77832H17.7778C19.005 2.77832 20 3.77332 20 5.00054V17.7783Z" fill="#E0E7EC"/>
                        <path d="M13.1435 10.6218H9.92852C9.25075 10.6218 8.99908 10.1823 8.99908 9.75509C8.99908 9.31565 9.23741 8.88843 9.92852 8.88843H14.4752C15.1163 8.88843 15.3796 9.46621 15.3796 9.79287C15.3796 10.044 15.2413 10.3079 15.0907 10.6218L11.9757 16.9523C11.6241 17.6557 11.4857 17.9062 10.9207 17.9062C10.2296 17.9062 9.87797 17.379 9.87797 17.0145C9.87797 16.864 9.91575 16.7512 10.0035 16.5751L13.1435 10.6218ZM6.05019 10.5551H5.77241C5.16964 10.5551 4.91797 10.149 4.91797 9.72176C4.91797 9.28176 5.23186 8.88843 5.77241 8.88843H6.89186C7.43186 8.88843 7.73352 9.27787 7.73352 9.78009V16.9273C7.73352 17.5551 7.42352 17.9068 6.88352 17.9068C6.34352 17.9068 6.05019 17.5551 6.05019 16.9273V10.5551Z" fill="#66757F"/>
                        <path d="M18.8889 0H17.0683C17.1639 0.163889 17.2222 0.352222 17.2222 0.555556C17.2222 1.16889 16.7244 1.66667 16.1111 1.66667C15.4978 1.66667 15 1.16889 15 0.555556C15 0.352222 15.0583 0.163889 15.1539 0H4.84611C4.94167 0.163889 5 0.352222 5 0.555556C5 1.16889 4.50222 1.66667 3.88889 1.66667C3.27556 1.66667 2.77778 1.16889 2.77778 0.555556C2.77778 0.352222 2.83611 0.163889 2.93167 0H1.11111C0.497778 0 0 0.497778 0 1.11111V7.22222H20V1.11111C20 0.497778 19.5022 0 18.8889 0Z" fill="#DD2F45"/>
                        <path d="M7.32413 2.55736C7.32413 2.27958 7.50191 2.12402 7.7408 2.12402C7.97913 2.12402 8.15691 2.27958 8.15691 2.55736V5.34458H9.14524C9.42858 5.34458 9.5508 5.55569 9.54524 5.74458C9.53413 5.92791 9.38969 6.11069 9.14524 6.11069H7.75746C7.48524 6.11069 7.32413 5.93347 7.32413 5.65569V2.55736ZM3.48524 2.55736C3.48524 2.27958 3.66302 2.12402 3.90191 2.12402C4.1408 2.12402 4.31858 2.27958 4.31858 2.55736V4.49513C4.31858 5.00625 4.6458 5.41125 5.17358 5.41125C5.67858 5.41125 6.02302 4.98402 6.02302 4.49513V2.55736C6.02302 2.27958 6.20024 2.12402 6.43913 2.12402C6.67802 2.12402 6.8558 2.27958 6.8558 2.55736V4.53958C6.8558 5.47236 6.08913 6.17791 5.17358 6.17791C4.24635 6.17791 3.48524 5.48347 3.48524 4.53958V2.55736ZM3.0508 5.00013C3.0508 5.93291 2.45691 6.17736 1.95135 6.17736C1.56858 6.17736 0.929688 6.02736 0.929688 5.54402C0.929688 5.39458 1.05747 5.20569 1.2408 5.20569C1.46302 5.20569 1.65747 5.41125 1.90691 5.41125C2.21802 5.41125 2.21802 5.12236 2.21802 4.94458V2.55736C2.21802 2.27958 2.3958 2.12402 2.63413 2.12402C2.87358 2.12402 3.0508 2.27958 3.0508 2.55736V5.00013Z" fill="#F5F8FA"/>
                        <path d="M17.7773 5.55547C17.7773 5.86214 18.0257 6.11103 18.3329 6.11103C18.6401 6.11103 18.8885 5.86214 18.8885 5.55547C18.8885 5.24881 18.6401 4.99992 18.3329 4.99992C18.0257 4.99992 17.7773 5.24881 17.7773 5.55547ZM17.7773 3.88881C17.7773 4.19547 18.0257 4.44436 18.3329 4.44436C18.6401 4.44436 18.8885 4.19547 18.8885 3.88881C18.8885 3.58214 18.6401 3.33325 18.3329 3.33325C18.0257 3.33325 17.7773 3.58214 17.7773 3.88881ZM16.1107 5.55547C16.1107 5.86214 16.359 6.11103 16.6662 6.11103C16.9735 6.11103 17.2218 5.86214 17.2218 5.55547C17.2218 5.24881 16.9735 4.99992 16.6662 4.99992C16.359 4.99992 16.1107 5.24881 16.1107 5.55547ZM16.1107 3.88881C16.1107 4.19547 16.359 4.44436 16.6662 4.44436C16.9735 4.44436 17.2218 4.19547 17.2218 3.88881C17.2218 3.58214 16.9735 3.33325 16.6662 3.33325C16.359 3.33325 16.1107 3.58214 16.1107 3.88881ZM14.444 5.55547C14.444 5.86214 14.6923 6.11103 14.9996 6.11103C15.3068 6.11103 15.5551 5.86214 15.5551 5.55547C15.5551 5.24881 15.3068 4.99992 14.9996 4.99992C14.6923 4.99992 14.444 5.24881 14.444 5.55547ZM14.444 3.88881C14.444 4.19547 14.6923 4.44436 14.9996 4.44436C15.3068 4.44436 15.5551 4.19547 15.5551 3.88881C15.5551 3.58214 15.3068 3.33325 14.9996 3.33325C14.6923 3.33325 14.444 3.58214 14.444 3.88881ZM12.7773 3.88881C12.7773 4.19547 13.0257 4.44436 13.3329 4.44436C13.6401 4.44436 13.8885 4.19547 13.8885 3.88881C13.8885 3.58214 13.6401 3.33325 13.3329 3.33325C13.0257 3.33325 12.7773 3.58214 12.7773 3.88881ZM12.7773 5.55547C12.7773 5.86214 13.0257 6.11103 13.3329 6.11103C13.6401 6.11103 13.8885 5.86214 13.8885 5.55547C13.8885 5.24881 13.6401 4.99992 13.3329 4.99992C13.0257 4.99992 12.7773 5.24881 12.7773 5.55547Z" fill="#F4ABBA"/>
                        </svg>
                </div>
                <div className="booked-session-card-label">Your schedule for</div>
                <div className="dash-empty-sess-head">{props?.bookingDate}</div>
            </div>
            <div className="booked-session-card-middle">
                
                <div className="schedule-details-summary-timings-wrap">
                    <div className="schedule-details-summary-timings-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                            <path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"/>
                        </svg>
                    </div>
                    <div className="schedule-details-summary-timings-title">Total sessions</div>
                    <div className="schedule-details-summary-timings-dot"></div>
                    <div className="schedule-details-summary-timings-time">{props?.totalSessionsCreated}
                    </div>
                </div>
                <div className="schedule-details-summary-timings-wrap">
                    <div className="schedule-details-summary-timings-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                            <path d="M4.5 6L6 7.5L11 2.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.5 6.5V10C10.5 10.2652 10.3946 10.5196 10.2071 10.7071C10.0196 10.8946 9.76522 11 9.5 11H2.5C2.23478 11 1.98043 10.8946 1.79289 10.7071C1.60536 10.5196 1.5 10.2652 1.5 10V3C1.5 2.73478 1.60536 2.48043 1.79289 2.29289C1.98043 2.10536 2.23478 2 2.5 2H8" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className="schedule-details-summary-timings-title">Sessions booked</div>
                    <div className="schedule-details-summary-timings-dot"></div>
                    <div className="schedule-details-summary-timings-time">{props?.numberOfSessionsBooked}</div>
                </div>
                <div className="schedule-details-summary-timings-wrap">
                    <div className="schedule-details-summary-timings-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <g clipPath="url(#clip0_4438_77328)">
                                <path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_4438_77328">
                                <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                                </defs>
                        </svg>
                    </div>
                    <div className="schedule-details-summary-timings-title">Duration</div>
                    <div className="schedule-details-summary-timings-dot"></div>
                    <div className="schedule-details-summary-timings-time">{props?.sessionDuration}</div>
                </div>
            </div>
            <div className="booked-session-card-btn">
                <button className='primary-btn white' onClick={handleEditAvailability}>
                   edit schedule
                </button>
            </div>
        </div>
        {/* <div className="booked-session-card-tag">
            <div className="booked-session-card-tag-wrap fully">
            fully Booked
            </div>
        </div> */}
    </div>
)
}
