import React,{useContext, useState} from 'react';
import '../login/login.css'
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../ErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ChangePassword({onClose, handleLoginClick, noti}) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [loading, setLoading] = useState(false);

    const { ResetPasswordFun, state } = useContext(AuthContext);
    const navigate = useNavigate();

    const closePop = () => {
        // setChangeOpen(false);
        document.body.classList.remove('popup-open');
    }
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password === confirmPassword) {
            try {
                setLoading(true);
                let res = await ResetPasswordFun(password);
                if(res){
                    noti();
                    onClose();
                    closePop();
                    setLoading(false);
                    navigate('/')
                  }
            } catch (error) {
                console.log(error);
            }
        } else {
        setPasswordMatch(false);
        }
    };

    return (
        <div className="insage-login">
        <div className="insage-login-wrapper">
            <div className="login-head"><span>Change password</span></div>
            {state.loading && <p>Loading...</p>}
            {state.error && <ErrorMessage errorData={state.error} />}
            <div className="forgot-password-p"><p>Please enter the new password.</p></div>
            <div className="login-form-wrapper">
                <form onSubmit={handleSubmit}>
                    <div className="login-form-input flex flex-c">
                        <label htmlFor="password">New password</label>
                        <input type="password" placeholder="Type here" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="login-form-input flex flex-c">
                        <label htmlFor="password">Confirm new password</label>
                        <input type="password" placeholder="Type here" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                    {!passwordMatch && <p style={{ color: 'red' }}>Passwords do not match!</p>}
                    <button className='primary-btn black login-button' type="submit">
                    {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                     'submit'
                   )}
                    </button>
                </form>
            </div>
        </div>
        <div className="login-close-button" onClick={onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
    </div>
    )
}
