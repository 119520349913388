import React,{useContext, useState} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../Dashboard/profile.css';
import '../guides/guides.css';
import NoSesh from '../../assets/images/no-sesh.svg';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../ErrorMessage';
import Loader from '../reusable/loading/Loader';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import resetimage from '../../assets/images/reset.svg';
import axios from 'axios';

export default function Settings() {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Inactive accounts');
    const [errorInput, setErrorInput] = useState('');
    const [changePasswordData, setChangePasswordData] = useState({
        current_password: '',
        new_password: '',
        password: ''
    })

    const { changeMentorPasswordFun, state, deleteMentorAccount,dispatch,logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [errorNewPassword, setErrorNewPassword] = useState("");
    const [errorMatch, setErrorMatch] = useState("");

    const handleInputChange = (e) => {
        // Reset error messages
        setErrorNewPassword('');
        setErrorMatch('');
        setInputValue(e.target.value);
      };

      const handleSelectChange = (e) => {
        setSelectedCategory(e.target.value);
      };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        // Reset error messages
        setErrorNewPassword('');
        setErrorMatch('');
        setChangePasswordData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const notifyRe = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Account delete request submit!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

    
    const handleChangePassword = async (e) => {
        e.preventDefault();

        // Reset error messages
        setErrorNewPassword('');
        setErrorMatch('');

        // Check if new password is provided
        if (!changePasswordData.new_password) {
            setErrorNewPassword('Password is required');
            return;
        }

        // Check if new password meets length requirement
        if (changePasswordData.new_password.length < 8) {
            setErrorNewPassword('Password must be at least 8 characters long');
            return;
        }

        // Check if new password meets complexity requirements
        if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(changePasswordData.new_password)) {
            setErrorNewPassword('Password must contain at least one uppercase letter, one lowercase letter, and one number');
            return;
        }

        // Check if new password matches re-entered password
        if (changePasswordData.password !== changePasswordData.new_password) {
            setErrorMatch('Passwords do not match');
            return;
        }
        
        try {
            if (changePasswordData.password === changePasswordData.new_password) {
                const res = await changeMentorPasswordFun(changePasswordData);
                if (res) {
                    notifypass('Passwords changed successfully. Please login with the new password.');
                    logout();
                }
                else{
                    notifypasserror('Current Password does not match');   
                    //console.log(error.message); 
                }
            } else {
                // Passwords do not match
                throw new Error('Passwords do not match');
            }
        } catch (error) {
            notifypasserror(error);  // Pass the error message to notifyE()
    
            // Handle error
            console.error('Error changing password', error);
        }
    };
    

    const handleDeleteAccount = async (e) => {
        e.preventDefault();

        try {
                if (inputValue.toUpperCase() === 'DELETE') {
                    setErrorInput('');
                    deleteMentorAccount({reason:selectedCategory})
                    notifyRe()
                } else {
                    setErrorInput('Incorrect input');
                    return;
                }


            // Close the delete account popup
            onClose();
        } catch (error) {
            // Handle error
            console.error('Error deleting account', error);
        }
    };

    let profile = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;

    console.log('profile ', profile?.userId);

    const handleForgotPasswordClick = async () => {
        try {
            const token = Cookies.get('token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/forgot-password-auth`,{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                Cookies.set('forgot_userId', profile?.userId);
                notifypass(response?.data?.message);
            } else {
                notifypasserror('Account delete request submit!');
            }
        } catch (error) {
            // Handle error
            console.error('Error hitting API:', error);
    
            // Display error notification
            notifypasserror('Error hitting API');
        }
    };
    
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleTogglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    const handleTogglePassword3 = () => {
        setShowPassword3(!showPassword3);
    };
    const [showPop,setShowPop]= useState(false);
    const popOpen = () => {
        setShowPop(true);
        document.body.classList.add("pop-open");
    }
    const onClose = () => {
        setShowPop(false);
        document.body.classList.remove("pop-open");
    }

    const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Password Changed Successfully</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

      const notifyE = () => {
        toast.error(
            <div className="custom-toast">
                <div className="custom-toast-top flex align">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" rx="8" fill="#F45E5C"/>
                        <path d="M11.25 4.75L4.75 11.25" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.75 4.75L11.25 11.25" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>Error</span>
                </div>
                <div className="custom-toast-bot">
                    <span>Password did not match!</span>
                </div>
            </div>,
            {
                className: 'custom-toast-container-error',
            }
        );
    };

    const notifypass = (message) => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>{message}</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

      const notifypasserror = (message) => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#F45E5C"/>
                    <path d="M11.25 4.75L4.75 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.75 4.75L11.25 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
          <span>Error</span>
            </div>
            <div className="custom-toast-bot">
              <span>{message}</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container-error',
        }
      );

    const userDetails = Cookies.get('userDetail') && JSON.parse(Cookies.get('userDetail'))

  return (
    <div className="profile-settings dashboard-empty-state">
         <div className="dash-empty-sessions-content">
                    <div className="dash-empty-sess-head">Settings</div>
                    <div className="dash-empty-sess-body"><p>You can change your existing password here.</p></div>
                </div>
                <div className="profile-settings-tabs-cont">
                
                <div className="profile-settings-form-wrapper">
                {state.loading && <Loader/>}
                {/* {state.error && <ErrorMessage errorData={state.error} />} */}
                    <div className="profile-settings-password-head">Change your password below:</div>
                    
                    <div className="profile-settings-form-cont">
                        <form action="post">
                            <div className="profile-settings-form-input-label flex flex-c">
                                <label htmlFor="current-password">current password</label>
                                <div className="profile-settings-form-input-wrap">
                                    <input type={showPassword ? 'text' : 'password'} placeholder='.......' name="current_password" value={changePasswordData.current_password} onChange={handlePasswordChange}/>
                                    <svg className={`password-eye ${showPassword ? 'show' : ''}`} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <svg className={`password-eye-slash ${showPassword ? 'show' : ''}`} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.75 4.75L15.25 12" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </div>        
                            </div>
                            <div className="profile-settings-form-input-label flex flex-c">
                                <label htmlFor="current-password">New password</label>
                                {errorNewPassword && <p className="error-name-onb">{errorNewPassword}</p>} 
                                <div className="profile-settings-form-input-wrap">
                                    <input type={showPassword2 ? 'text' : 'password'} placeholder='.......' name="new_password" value={changePasswordData.new_password} onChange={handlePasswordChange}/>
                                    <svg className={`password-eye ${showPassword2 ? 'show' : ''}`} onClick={handleTogglePassword2} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <svg className={`password-eye-slash ${showPassword2 ? 'show' : ''}`} onClick={handleTogglePassword2} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.75 4.75L15.25 12" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </div> 
                            </div>
                            <div className="profile-settings-form-input-label flex flex-c">
                                <label htmlFor="current-password">Re-enter new password</label>
                                {errorMatch && <p className="error-name-onb">{errorMatch}</p>} 
                                <div className="profile-settings-form-input-wrap">
                                    <input type={showPassword3 ? 'text' : 'password'} placeholder='.......' name="password" value={changePasswordData.password} onChange={handlePasswordChange}/>
                                    <svg className={`password-eye ${showPassword3 ? 'show' : ''}`} onClick={handleTogglePassword3} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <svg className={`password-eye-slash ${showPassword3 ? 'show' : ''}`} onClick={handleTogglePassword3} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.75 4.75L15.25 12" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div> 
                            </div>
                            <div className="profile-settings-form-btn dash-emp-no-sess-btn">
                                <button onClick={handleChangePassword} className='primary-btn black'>Change password</button>
                            </div>
                        </form>
                    </div>
                    <div className='reset-text'>
                            <p>If you have logged in using Social Media accounts, please use reset option below.</p>
                        </div>
                    <div onClick={handleForgotPasswordClick} className="profile-settings-delete-btn green flex">
                            <div className="profile-settings-delete-icon">
                                <img src={resetimage} alt='reset-icon'/>
                            </div>
                            <div className="profile-settings-delete-text">Reset your password</div>
                        </div>
                    <div onClick={popOpen} className="profile-settings-delete-btn lesspad flex">
                        <div className="profile-settings-delete-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                            <path d="M1 4.19727H2.43346H13.9012" stroke="#FF6565" strokeWidth="1.43346" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.4678 4.19896V14.2332C12.4678 14.6134 12.3168 14.978 12.048 15.2468C11.7792 15.5156 11.4146 15.6667 11.0344 15.6667H3.86706C3.48688 15.6667 3.12227 15.5156 2.85345 15.2468C2.58462 14.978 2.43359 14.6134 2.43359 14.2332V4.19896M4.58379 4.19896V2.76549C4.58379 2.38532 4.73481 2.02071 5.00364 1.75188C5.27247 1.48306 5.63707 1.33203 6.01725 1.33203H8.88418C9.26436 1.33203 9.62896 1.48306 9.89779 1.75188C10.1666 2.02071 10.3176 2.38532 10.3176 2.76549V4.19896" stroke="#FF6565" strokeWidth="1.43346" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.01562 7.7832V12.0836" stroke="#FF6565" strokeWidth="1.43346" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.88281 7.7832V12.0836" stroke="#FF6565" strokeWidth="1.43346" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </div>
                        <div className="profile-settings-delete-text">Delete my account</div>
                    </div>
                </div>
                <div className={`login-sign-up-pop-up flex flex-c delete-account-pop ${ showPop ? 'active' : ''}`}>
                <div className="insage-login">
                        <div className="insage-login-wrapper">
                            <div className="login-head"><span>Delete account</span></div>
                            <div className="forgot-password-p"><p>Are you sure you want to delete the account linked to {userDetails?.email}. You will not be able to undo.</p></div>
                            <div className="login-form-wrapper">
                                <form>
                                   <div className="profile-settings-form-input-label flex flex-c">
                                <label htmlFor="current-password">Select your reason for leaving</label>
                                <div className="profile-settings-form-input-wrap">
                                        <select
                                            id="categories"
                                            name="categories"
                                            required
                                            value={selectedCategory}
                                            onChange={handleSelectChange}
                                            >
                                            {/* <option value="">Select reason</option> */}
                                            <option value="Privacy concerns">Privacy concerns</option>
                                            <option value="Security issues">Security issues</option>
                                            <option value="Inactive accounts">Inactive accounts</option>
                                            <option value="Ethical concerns">Ethical concerns</option>
                                            <option value="Legal reasons">Legal reasons</option>
                                            {/* Add more options as needed */}
                                        </select>
                                      
                                        </div>        
                                    </div>
                                    <div className="profile-settings-form-input-label flex flex-c">
                                <label htmlFor="current-password">To confirm, type <span>“DELETE”</span></label>
                                <p>{errorInput}</p>
                                <div className="profile-settings-form-input-wrap flex">
                                <input
                                    type="text"
                                    placeholder='Type here'
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    />
                                           <button onClick={handleDeleteAccount} className='primary-btn delete-account-btn'>confirm</button>
                                        </div>        
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="login-close-button" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </div>
                </div>
                </div>
            
            
         </div>

       
        </div> 
  )
}
