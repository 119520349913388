import React, { useContext, useState } from 'react';
import './mentee-profile.css';
import '../onboarding/Onboarding-content.css';
import '../reusable/login/login.css';
import TimezoneSelect from 'react-timezone-select';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Background from '../../assets/images/background.jpg';
import Avatar from '../../assets/images/avatar.png';

export default function IntroPop({onclose, noti}) {

    const { state, updateMentorProfile } = useContext(AuthContext);

    const [avatar, setAvatar] = useState(state?.profile?.avatar);
    const [bgImage, setBgImage] = useState(state?.profile?.bgImage);
    const [avatarData, setAvatarData] = useState('');
    const [bgImageData, setBgImageData] = useState('');
    const [fullName, setFullName] = useState(state?.profile?.fullName);
    const [designation, setDesignation] = useState(state?.profile?.designation);
    const [company , setCompany] = useState(state?.profile?.company);
    const [country , setCountry] = useState(state?.profile?.country);
    const [sessionCost , setSessionCost] = useState(state?.profile?.hourlyRate);
    const [selectedTimezone, setSelectedTimezone] =useState(state?.profile?.tz );
    const [nameError, setNameError] = useState("");
    const [companyError, setCompanyError] = useState("");
    const [designationError, setDesignationError] = useState("");
    const [sessionCostError, setSessionCostError] = useState("");
    // Add this state variable
    const [selectedAvatar, setSelectedAvatar] = useState(state?.profile?.avatar || Avatar);
    const [selectedBackground, setSelectedBackground] = useState(state?.profile?.bgImage || Background);


    const [loading, setLoading] = useState(false);


    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        console.log('file ', file);
        const reader = new FileReader();
      
        reader.onload = () => {
          setAvatar(reader.result);
          setAvatarData(file);
          // Set the selected avatar image
          setSelectedAvatar(reader.result);
        };
      
        if (file) {
          reader.readAsDataURL(file);
        }
      };

    const handleBgImageChange = e => {
        const file = e.target.files[0];
        console.log('file ', file);
        const reader = new FileReader();
        
        reader.onload = () => {
            setBgImage(reader.result);
            setBgImageData(file);
            setSelectedBackground(reader.result);
        };
        
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleTimezoneChange = (valueT) => {
        setSelectedTimezone(valueT);
    };
    const handleNameChange = (e) => {
        setFullName(e.target.value)
        setNameError("");
    };
    const handleDesignationChange = (e) => {
        setDesignation(e.target.value)
        setDesignationError("");
    };
    const handleCompanyChange = (e) => {
        setCompany(e.target.value)
        setCompanyError("");
    };
    const handleSessionCostChange = (e) => {
        setSessionCost(e.target.value)
        setSessionCostError("");
    };

    const handleIntoForm = async (e) => {
        e.preventDefault();
        e.preventDefault();
        setNameError("");
        setCompanyError("");
        setDesignationError("");
        setSessionCostError("");
        const nameRegex = /^[a-zA-Z\s]+$/;
        const numberRegex = /^[0-9]+$/;
        if (!fullName.trim()) {
            setNameError("Name field cannot be left empty");
            return;
        }
        if (fullName.trim().length <= 3) {
            setNameError("Name must be longer than 3 characters");
            return;
        }
        if (fullName.trim().length > 20) {
            setNameError("Name should not exceed 20 characters");
            return;
        }
        if (!designation.trim()) {
            setDesignationError("Designation field cannot be left empty");
            return;
        }
        if (designation.trim().length < 3) {
            setDesignationError("Designation should be at least 3 characters");
            return;
        }
        if (designation.trim().length > 30) {
            setDesignationError("Designation should not exceed 30 characters");
            return;
        }
        if (!company.trim()) {
            setCompanyError("Company field cannot be left empty");
            return;
        }
        if (company.trim().length < 3) {
            setCompanyError("Company Name should be at least 3 characters");
            return;
        }
        if (company.trim().length > 30) {
            setCompanyError("Company Name should not exceed 30 characters");
            return;
        }
        if (sessionCost.trim().length > 4) {
            setSessionCostError("Session Cost should not exceed 4 digits");
            return;
        }
        if (!nameRegex.test(fullName)) {
            setNameError("Name should only contain letters and spaces");
            return;
        }
        if (!nameRegex.test(company)) {
            setCompanyError("Company name should only contain letters and spaces");
            return;
        }
        if (!nameRegex.test(designation)) {
            setDesignationError("Designation name should only contain letters and spaces");
            return;
        }
        try {
            setLoading(true);
            const formData = {
                fullName: fullName,
                designation: designation,
                company: company,
                country: country,
                hourlyRate: sessionCost,
            };
            if (avatarData) {
                formData.avatar = avatarData;
            }
            if (bgImageData) {
                formData.bgImage = bgImageData;
            }
            if (selectedTimezone !== state.profile?.tz) {
                formData.tz = selectedTimezone.value;
                formData.timeZone = selectedTimezone.label;
            }
            let res = await updateMentorProfile(formData);
            if(res){
                onclose();
                noti();
                setLoading(false);
                setNameError("");
            }
        } catch (error) {
            console.log(error);
        }
    };
    const userImage = state?.profile?.avatar == undefined || state?.profile?.avatar == null ? Avatar : state?.profile?.avatar
    const background = state?.profile?.bgImage == undefined || state?.profile?.bgImage == null ? Background : state?.profile?.bgImage

    return (
        <>
        <div className="insage-login">
        <div className="insage-login-wrapper">
            <div className="login-head"><span>intro</span></div>
            <div className="overview-profile-pop-form">
                <form action="post">
                    <div className='login-form-input flex flex-c'>
                        <label className='flex align' htmlFor="profile-photo">Update profile photo</label>
                        <div className="login-form-profile-wrapper flex align">
                            <div className="login-profile-image">
                                <img src={selectedAvatar} alt="Profile" />
                            </div>
                            <div className="login-profile-btn">
                                <span>The picture must be at least 200 px by 200px</span>
                            <label>
                            <input type="file" accept="image/*"  onChange={handleAvatarChange}/> 
                                    upload/edit profile picture
                            </label>
                            </div>
                        </div>  
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="banner-photo">Update banner photo</label>
                        <div className="login-form-profile-wrapper flex align">
                            <div className="login-profile-image">

                                <img src={selectedBackground} alt="Profile" />
                            </div>
                            <div className="login-profile-btn">
                                <span>The picture must be at least 1000 px by 563px</span>
                            <label>
                            <input type="file" accept="image/*"  onChange={handleBgImageChange}/> 
                                    upload/edit banner picture
                            </label>
                            </div>
                        </div>  
                            
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="fName">Full name</label>
                        {nameError && <p className="error-name-onb">{nameError}</p>}
                        <input placeholder='Miran Hasan' type="text" value={fullName} name="fullName" id="fName" onChange={(e) => handleNameChange(e)} />  
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="designation">Designation</label>
                        {designationError && <p className="error-name-onb">{designationError}</p>}
                        <input placeholder='Product Designer' type="text" name="designation" value={designation} id="designation" onChange={(e) => handleDesignationChange(e)}/>  
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="company">Company</label>
                        {companyError && <p className="error-name-onb">{companyError}</p>}
                        <input placeholder='SimplePlan Media' type="text" name="company" value={company} id="company" onChange={(e) => handleCompanyChange(e)}/>  
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="company">Session Cost</label>
                        {sessionCostError && <p className="error-name-onb">{sessionCostError}</p>}
                        <input placeholder='Cost per session' type="number" name="sessioncost" value={sessionCost} id="sessionCost" onChange={(e) => handleSessionCostChange(e)}/>  
                    </div>
                    <div className='login-form-input flex flex-c'>
                            <label htmlFor="country">Country</label>
                            <div className="select-wrapper">
                                <select
                                id="country"
                                name="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                                >
                                <option value="United States of America">United States of America</option>
                                <option value="India">India</option>
                                <option value="Australia">Australia</option>
                                <option value="United Kingdom">United Kingdom</option>
                                </select>
                            </div>
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="timezone">Select  your timezone</label>
                        <div className="select-wrapper">
                            {state?.profile?.tz && <TimezoneSelect
                                id="timezone"
                                name="timeZone"
                                placeholder="Eg. GMT+5:30 Delhi, Mumbai, Kolkata"
                                onChange={handleTimezoneChange}
                                value={selectedTimezone}
                            />}
                            </div>
                    </div>
                    <div className="overview-profile-pop-btn">
                        <button className='primary-btn' onClick={handleIntoForm}>
                        {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                            'save changes'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div className="login-close-button" onClick={onclose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
        </div>
         </>
    )
}
