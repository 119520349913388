import React, { useState, useRef, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './blogs.css'; 
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import Footer from '../../layouts/footer/Footer';
import Cookies from 'js-cookie';
import { unifiedPostRequest } from '../../context/api';
import CreateBlogPostPopup from '../../components/post/CreateBlogPostPopup';
import striptags from 'striptags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ChatContext } from '../../context/ChatContext';
import { AuthContext } from '../../context/AuthContext';
import '../../components/bloginner/BlogInner.css'; 

export default function CreateBlog() {

  const {state} = useContext(AuthContext);

  

    const [editorHtml, setEditorHtml] = useState('');
    const [postTitle, setPostTitle] = useState('');
    const quillRef = useRef(null);
    const [openCommunityPostPopup, setopenCommunityPostPopup] = useState(false);
    const [data2, setData2] = useState(null);
    const [industry, setIndustry] = useState('655c532dd9f4d1035b9ff811');
    const [bannerImage, setBannerImage] = useState('');
    const [readTime, setReadTime] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {handleTabIndex} = useContext(ChatContext);

    const token = Cookies.get('token');
  
    useEffect(() => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
  
        // Add custom handler for image
        quill.getModule('toolbar').addHandler('image', handleImage);
      }
    }, []);
  
    const modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'script': 'sub'}, { 'script': 'super' }], 
        ['link', 'image', 'video', 'formula'],
        ['clean'],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
      ],
    };
  
    const formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet','indent',
      'link', 'image','align','size','color','background','direction','video',
      'script','clean',
    ];
  
    const handleChange = (content, delta, source, editor) => {
      setEditorHtml(content);
      getReadTime();
    };
  
    const handleImage = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
    
      input.onchange = async () => {
        const file = input.files[0];
    
        const imageUrl = await uploadImageAndGetUrl(file);
    
        // console.log('file', file);
        const urlImage = imageUrl.result.image;
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        quill.clipboard.dangerouslyPasteHTML(range.index, `<img src="${urlImage}" alt="Image" />`);
      };
    };

      const uploadImageAndGetUrl = async (file) => {
        let url = process.env.REACT_APP_API_URL + '/mentor/upload-blog-image';
        const responseUpload = unifiedPostRequest(url, {'image':file}, true, true)
        return responseUpload;
      
      };

      const handleMoveToPublish = async () => {
        // Get the full content of the editor
        const fullEditorContent = quillRef.current.getEditor().root.innerHTML;

        let getUrl = process.env.REACT_APP_API_URL + '/mentor/list-blog-categories';
          const response2 = await fetch(getUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
          });
          if (response2.ok) {
            const data2 = await response2.json(); // Assuming the response contains JSON data
            setData2(data2);
        } else {
            throw new Error(`Error: ${response2.status} - ${response2.statusText}`);
        }
    
        // Save the data in a state
        setPostTitle(document.querySelector('.post-title input').value);
        setEditorHtml(fullEditorContent);

        setopenCommunityPostPopup(true);
        document.body.classList.add('popup-open');
      };

      // Open Popup
      const openCommunityPostPop = () => {
          setopenCommunityPostPopup(true);
          // setSelectedItemData(session);
          document.body.classList.add('popup-open');
      };
      // Open Popup

      const closeCommunityPostPop = () => {
        setopenCommunityPostPopup(false);
        document.body.classList.remove('popup-open');
    };

    const getCharacterCount = () => {
      const textContent = striptags(editorHtml);
      return textContent.length;
    };

      const getReadTime = () => {
        const characterCount = getCharacterCount();
        const wordsPerMinute = 200; // Adjust this value based on your reading speed
        const readingTimeInMinutes = characterCount / wordsPerMinute;
    
        // Round up to the nearest whole number
        const roundedReadingTime = Math.ceil(readingTimeInMinutes);
    
        // Update state using setReadTime
        setReadTime(roundedReadingTime);
    
        return roundedReadingTime;
      };

      const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Blog Created Successfully</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyD = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Blog Saved to Drafts Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyE = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#F45E5C"/>
                    <path d="M11.25 4.75L4.75 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.75 4.75L11.25 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
          <span>Error</span>
            </div>
            <div className="custom-toast-bot">
              <span>While creating the blog. Please try later!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container-error',
        }
      );


      const handleMoveToPreview = async () => {
        const blogData = {
          title: postTitle,
          content: editorHtml
        }
        // navigate("/blog/preview", { state: { blogData: blogData } });
        const queryParams = new URLSearchParams(blogData).toString();
        window.open(`/blog/preview?${queryParams}`, '_blank');
      }

      const handleMoveToDrafts = async () =>{
        const userToken = Cookies.get('token');

        setLoading(true);
        // const blogData = {
        //     title: postTitle,
        //     content: editorHtml
        // }

        const blogData = {
          title: postTitle,
          content: editorHtml,
          ...(industry !== '' && { categoryId: industry }),
          ...(bannerImage !== '' && { image: bannerImage }),
          readingTime: readTime,
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/mentor/blog/add-to-draft`,
            blogData,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {

            if(res){
                console.log(res);
                setLoading(false);
                notifyD();
                setTimeout(()=>{
                  handleTabIndex(7);
                  navigate('/mentor/dashboard');
                },2000)
            }
            else{
              setLoading(false);
            }
          })
          .catch((err) => {
             
          })
          .finally(() => {

          });
      }

      const goToDashboard = () => {
        handleTabIndex(7);
        navigate('/mentor/dashboard');
      }
      const handleTitleChange = (event) => {
        // Update the postTitle state as the user types
        setPostTitle(event.target.value);
      };
  return (
        <div className='post-page'>
            <div className='editor-header'>
                <div className='custom-container-fluid'>
                    <div className='editor-header-in'>
                        <div className='editor-back'>
                            <Link to="/blogs" className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19 12.5H19.5V11.5H19V12.5ZM5 11.5C4.72386 11.5 4.5 11.7239 4.5 12C4.5 12.2761 4.72386 12.5 5 12.5V11.5ZM19 11.5H5V12.5H19V11.5Z" fill="#555555"/>
                                    <path d="M12 19L5 12L12 5" stroke="#555555" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>
                            </Link>
                            <div className='wcounter'>
                                <div className='wc-wordCpunt'>
                                    <p>Character count: <span>{getCharacterCount()}</span></p>
                                </div>
                                <div className='wc-readTime'>
                                    <p>READING TIME: <span>{readTime} mins</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='editor-buttons'>
                        {/* <div className='eb-btns'>
                                <button onClick={handleMoveToPreview} className='primary-btn border'>
                                  Preview
                                </button>
                            </div> */}
                            <div className='eb-btns'>
                                <button className='primary-btn green' onClick={handleMoveToPublish}>publish now</button>
                            </div>
                            <div className='eb-btns'>
                                <button onClick={handleMoveToDrafts} className='primary-btn border'>
                                  {loading ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                      ) : (
                                    <>save to drafts</>
                                    )}
                                  </button>
                            </div>
                            {/* <div className='eb-btns'>
                                <button onClick={goToDashboard} className='primary-btn border'>
                                  {loading ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                      ) : (
                                    <>Dashboard</>
                                    )}
                                  </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='editor-content'>
                <div className='custom-container-fluid'>
                    <div className='post-title'>
                    <input
                      type='text'
                      placeholder='Blog Title'
                      value={postTitle}
                      onChange={handleTitleChange}
                    />
                    </div>
                    <div className='post-editor'>
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={editorHtml}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <Footer/>
            <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openCommunityPostPopup ? 'active' : ''}`}>
                  <CreateBlogPostPopup  
                      onclose={closeCommunityPostPop}
                      data={data2?.result}
                      noti={notify}
                      notiE={notifyE}
                      posttitle={postTitle}
                      editorhtml={editorHtml}
                      readTime={readTime}
                      industry={industry} 
                      setIndustry={setIndustry}
                      bannerImage={bannerImage} 
                      setBannerImage={setBannerImage}
                  />
                    
            </div>
            
        </div>
    
  )
}
