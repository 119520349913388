import React from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import Dashboard from '../../components/mentor-dashboard/mentor-dashboard';

export default function MentorDashboard() {
   return (
      <main className='isg-profile-main'>
         <Header/>
         <Dashboard/>
         <Footer/>
      </main>
   )
}
