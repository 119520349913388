import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NoSesh from '../../assets/images/no-sesh.svg';
import { Link } from 'react-router-dom';

const BadhboardSessionsEmpty = () => {
  return (
    <div className="fav-mentors-empty dashboard-empty-state">
         <div className="dash-empty-sessions-content">
            <div className="dash-empty-sess-head">sessions</div>
            <div className="dash-empty-sess-body"><p>Check all your sessions for today, tomorrow and whenever here.</p></div>
        </div>

        <div className="dash-session-list">
            <div className='dsl-tabs'>
            <Tabs>
                <div className='dsltabs-tabs'>
                    <TabList>
                        <Tab>All</Tab>
                        <Tab>Upcoming</Tab>
                        <Tab>Completed</Tab>
                        <Tab>Canceled</Tab>
                        <Tab>Cancel Requested</Tab>
                        <Tab>Reported</Tab>
                    </TabList>
                </div>
                <div className='dsltabs-data'>
                    <TabPanel>
                        <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                <div className="dash-emp-no-sess-body">
                    <p>Don't worry, our experienced mentors are available to connect with you and help you grow.</p>
                </div>
            <div className="dash-emp-no-sess-btn">
                <Link to="/explore-our-mentors" className="primary-btn black">explore mentors</Link>
            </div>
            </div>
                        </div>  
                    </TabPanel>
                    <TabPanel>
                        <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                <div className="dash-emp-no-sess-body">
                    <p>Don't worry, our experienced mentors are available to connect with you and help you grow.</p>
                </div>
            <div className="dash-emp-no-sess-btn">
                <Link to="/explore-our-mentors" className="primary-btn black">explore mentors</Link>
            </div>
            </div>
                        </div>  
                    </TabPanel>
                    <TabPanel>
                        <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                <div className="dash-emp-no-sess-body">
                    <p>Don't worry, our experienced mentors are available to connect with you and help you grow.</p>
                </div>
            <div className="dash-emp-no-sess-btn">
                <Link to="/explore-our-mentors" className="primary-btn black">explore mentors</Link>
            </div>
            </div>
                        </div>  
                    </TabPanel>
                    <TabPanel>
                        <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                <div className="dash-emp-no-sess-body">
                    <p>Don't worry, our experienced mentors are available to connect with you and help you grow.</p>
                </div>
            <div className="dash-emp-no-sess-btn">
                <Link to="/explore-our-mentors" className="primary-btn black">explore mentors</Link>
            </div>
            </div>
                        </div>  
                    </TabPanel>
                    <TabPanel>
                        <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                <div className="dash-emp-no-sess-body">
                    <p>Don't worry, our experienced mentors are available to connect with you and help you grow.</p>
                </div>
            <div className="dash-emp-no-sess-btn">
                <Link to="/explore-our-mentors" className="primary-btn black">explore mentors</Link>
            </div>
            </div>
                        </div>  
                    </TabPanel>
                    <TabPanel>
                        <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                <div className="dash-emp-no-sess-body">
                    <p>Don't worry, our experienced mentors are available to connect with you and help you grow.</p>
                </div>
            <div className="dash-emp-no-sess-btn">
                <Link to="/explore-our-mentors" className="primary-btn black">explore mentors</Link>
            </div>
            </div>
                        </div>  
                    </TabPanel>
                </div>
            </Tabs>
            </div>
        </div>

             
   </div>
  )
}

export default BadhboardSessionsEmpty
