import React, { useContext, useState } from 'react';
import '../../Dashboard/dashboardPopup.css';
import MultiSelectTags from '../multi-select/multiSelect';
import { AuthContext } from '../../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import axios from 'axios';

export default function ConfirmLogoutPopup ({onclose,noti }) {
  
    const {logout} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const handleCancel = (event) => {
        event.preventDefault();
        document.body.classList.remove('popup-open');
        logout();
      };

    return (
        <div className="insage-login dashboard-popups">
            <div className="insage-login-wrapper">
                <div className="login-head"><span>sure you want to logout?</span></div>
                <div className='popup-description'>
                    <p className='pd-para'>By logging out, you will miss some of the best features of the website.</p>
                </div>

                <div className="overview-profile-pop-form no-border">
                    <form  onSubmit={handleCancel} action="post">
                        
                        <div className="overview-profile-pop-btn flexbuttons">
                            <button className='popup-btn line-btn' onClick={() => onclose()}>
                                Cancel
                            </button>
                            <button className='popup-btn cancel-btn'>
                            {loading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                                  ) : (
                                  'Proceed'
                                  )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="login-close-button" onClick={() => onclose()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}
