import React, { useContext, useState } from 'react';
import './mentee-profile.css';
import '../onboarding/Onboarding-content.css';
import '../reusable/login/login.css';
import MultiSelectTags from '../reusable/multi-select/multiSelect';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function OverviewPop({onclose, noti}) {

    const { state, updateMenteeProfileOverView } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [bioError, setBioError] = useState("");
    const [langError, setLangError] = useState("");

    const [formData, setFormData] = useState({
        bio: state?.profile?.bio,
        industry:state?.profile?.industry,
        guidanceState:(state?.profile?.guidance || []).map((values, index) => ({ value: values, label: values, key: index })),
        guidance: Object.values(state?.profile?.guidance || []),
        languagesState:(state?.profile?.language || []).map((values, index) => ({ value: values, label: values, key: index })),
        language: Object.values(state?.profile?.language || []),
    })


    const handleOnboardingInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleGuidance = (e) => {
        handleOnboardingInputChange({
            target: {
                name: "guidanceState",
                value: e.target?.value,
            },
        });

        handleOnboardingInputChange({
            target: {
                name: "guidance",
                value: e.target?.value.map((option) => option.value),
            },
        });
    };

    const handleLanguage = (e) => {
        handleOnboardingInputChange({
            target: {
                name: "languagesState",
                value: e.target?.value,
            },
        });

        handleOnboardingInputChange({
            target: {
                name: "language",
                value: e.target?.value?.map((option) => option.value),
            },
        });
    };

    const handleOverviewForm = async (e) => {
       
        e.preventDefault();
        if(!formData.bio.trim()){
            setBioError("Bio Cannot be left blank");
            return;
        }
        else if(formData.bio.trim().length < 156){
            setBioError(`Bio cannot be less than 156 characters, current characters length is ${formData.bio.trim().length}`);
            return;
        }
        else{
            setBioError('');
        }
        if(formData.languagesState.length === 0){
            setLangError("Please select at least one language");
            return;
        }
        else{
            setLangError('');
        }
        try {
            setLoading(true);
            let res = await updateMenteeProfileOverView(formData);
            if(res){
                onclose();
                noti();
                setBioError("");
                setLangError("");
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="insage-login">
        <div className="insage-login-wrapper">
            <div className="login-head"><span>overview</span></div>
            <div className="overview-profile-pop-form">
                <form action="post">
                    <div className='login-form-input flex flex-c'>
                        <label className='flex bio-label align' htmlFor="aboutMe">About me</label>
                            {bioError && <p className="error-name-onb">{bioError}</p>}
                            <textarea id='aboutMe' name='bio' value={formData?.bio} onChange={handleOnboardingInputChange} />
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="guidance">Looking for guidance in</label>
                            <div className="select-wrapper">
                                <MultiSelectTags
                                    id="guidance"
                                    options={[
                                        { value: 'Domain Related', label: 'Domain Related' },
                                        { value: 'Organisational Culture ', label: 'Organisational Culture ' },
                                        { value: 'Management Skills ', label: 'Management Skills ' },
                                        { value: 'Career Path ', label: 'Career Path ' },
                                        { value: 'Feedback & Conflict Resolution', label: 'Feedback & Conflict Resolution' },
                                        { value: 'Effective communication', label: 'Effective communication' },
                                        { value: 'Networking', label: 'Networking' },
                                        { value: 'Leadership Skills', label: 'Leadership Skills' }
                                    ]}
                                    value={formData?.guidanceState}
                                    onChange={handleGuidance}
                                    required
                                />
                                </div>
                    </div>
                    <div className='login-form-input flex flex-c'>
                            <label htmlFor="industry">Industry</label>
                            <div className="select-wrapper">
                                <select
                                id="industry"
                                name="industry"
                                value={formData?.industry}
                                onChange={handleOnboardingInputChange}
                                required
                                >
                                <option value="" disabled>Select an industry</option>
                                <option value="Art & Design">Art & Design</option>
                                <option value="Coding & Dev">Coding & Dev</option>
                                <option value="Marketing">Marketing</option>
                                <option value="IT & Software">IT & Software</option>
                                <option value="Personal Development">Personal Development</option>
                                <option value="Business & Sales">Business & Sales</option>
                                <option value="Photography">Photography</option>
                                <option value="Management">Management</option>
                                <option value="Other">Other</option>
                                </select>
                            </div>
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="languages">Languages</label>
                            <div className="select-wrapper">
                            {langError && <p className="error-name-onb">{langError}</p>}
                                <MultiSelectTags
                                    id="languages"
                                    options={[
                                        { value: 'English', label: 'English' },
                                        { value: 'Hindi', label: 'Hindi' },
                                        { value: 'Urdu', label: 'Urdu' },
                                        { value: 'French', label: 'French' },
                                        { value: 'Spanish', label: 'Spanish' },
                                        { value: 'Arabic', label: 'Arabic' },
                                        { value: 'Portugese', label: 'Portugese' }
                                    ]}
                                    value={formData?.languagesState}
                                    onChange={handleLanguage}
                                    required
                                />
                                </div>    
                    </div>
                    <div className="overview-profile-pop-btn">
                        <button className='primary-btn' onClick={handleOverviewForm}>
                        {loading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                            'save changes'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div className="login-close-button" onClick={onclose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
        </div>
    )
}
