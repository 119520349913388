import React, { useCallback, useContext, useState } from 'react'
import './login.css';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../ErrorMessage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Googlesign from './Googlesign';
import LinkedInPage from './LinkedInPage';
import Loading from '../loading/Loading';
import Loader from '../loading/Loader';
import actionTypes from '../../../context/actionTypes';
// import { googleLogout } from '@react-oauth/google';

export default function Login({ onClose, onSignUpClick, onForgotClick }) {
    
    // const logout = useGoogleLogout();
    const { signIn, state, dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [signInFormData, setSignInFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({
        email: '',
        password: '',
      });
      const [isValidForm, setIsValidForm] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChangeSignIn = (e) => {
        const { name, value } = e.target;
        setErrors({
            email: '',
            password: '',
          });
        setSignInFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const validateForm = async (e) => {
        e.preventDefault();
        const newErrors = {
          email: '',
          password: '',
        };
    
        // Check email
        if (!signInFormData.email) {
          newErrors.email = 'Email is required';
          setErrors(newErrors);
          return;
        } else if (!/\S+@\S+\.\S+/.test(signInFormData.email)) {
          newErrors.email = 'Invalid email address';
          setErrors(newErrors);
          return;
        }
    
        // Check password
        if (!signInFormData.password) {
          newErrors.password = 'Password is required';
          setErrors(newErrors);
          return;
        }
        // setIsValidForm(valid);
        try {
            let res = await signIn(signInFormData);
            // console.log('res ', res);
            if(res === false){
                
            }else if(res && res.flag === 6){
                onClose();
                navigate('/mentee/dashboard');
                document.body.classList.remove('popup-open');
            }
           else{
                onClose();
                navigate('/mentee/onboarding');
                document.body.classList.remove('popup-open');
           }
          } catch (error) {
            console.log(error);
          }
      };
    

    const handleClose = () => {
        onClose(); // Call the onClose function passed from the parent component
        dispatch({ type: actionTypes.REMOVE_ERROR});
        setErrors({
            email: '',
            password: '',
          });
    };
    const onResponse = (resp) => {
        console.log(resp);
    };

   
    
          
  return (
    <div className="insage-login">
        <div className="insage-login-wrapper">
            <div className="login-head"><span>mentee login</span></div>
            {state.loading && <Loader/>}
            {state.error && <ErrorMessage errorData={state.error} />}
            <div className="login-form-wrapper">
                <form onSubmit={validateForm}>
                    <div className="login-form-input flex flex-c">
                        <label htmlFor="email">Email ID</label>
                        <input name='email' type="email" placeholder="Type here" value={signInFormData.email} onChange={handleInputChangeSignIn} />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className="login-form-input flex flex-c">
                        <label htmlFor="password">Password</label>
                        <div className="login-password-wrapper">
                        <input type={showPassword ? 'text' : 'password'} name='password' placeholder="Password" value={signInFormData.password} onChange={handleInputChangeSignIn} />
                        {errors.password && (
                    <span className="error-message">{errors.password}</span>
                )}
                        <svg className={`password-eye ${showPassword ? 'show' : ''}`} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <svg className={`password-eye-slash ${showPassword ? 'show' : ''}`} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.75 4.75L15.25 12" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </div>
                        <div className="forgot-password-btn flex align flex-jsb">
                            <span onClick={onForgotClick} className="fg-pass">forgot password?</span>
                        </div>
                    </div>
                    <button className='primary-btn black login-button' type="submit">Login</button>
                </form>
                <div className="login-with-text">
                   
                    <span>or login with</span>
                </div>
                <div className="login-socials-wrapper flex flex-jsc align">
                    
                {/* <GoogleOAuthProvider clientId="317676183347-io5gtaipe1mf5a47alo58kuh2aumkhgf.apps.googleusercontent.com"> */}
                    <Googlesign popClose={onClose} />
                {/* </GoogleOAuthProvider> */}
                {/* <LinkedInPage/> */}
                
                

                {/* <button onClick={
                    () => googleLogout()}>Logout</button> */}

                </div>
                <div className="login-already-have flex align-e flex-jsc">
                    <div className="login-already-have-text"><span>Don’t have an account?</span></div>
                    <div className="login-already-have-btn"><span onClick={onSignUpClick}>Signup</span></div>
                </div>
            </div>
        </div>
        <div className="login-close-button" onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round"/>
        </svg>
        </div>
    </div>
    )
}
