import React,{useRef} from 'react';
import './Homebanner.css';
import Ticker from '../reusable/ticker/Ticker';
import { beyond1, beyond2, beyond3 } from '../../assets/images';
import { useInView } from "framer-motion";

export default function Homebanner({props}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
  const data = props.bannerData;
  
  const texts=["Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive"];
  const dynamicStyles = {
    bg: {
      background: '#F8F6ED',
      color:'#444444',
      bottom: '-45px'
    },
    fill: {
     fill:'#FCD26E'
    }
  };
  
  return (
    <section className="home-banner pad-top">
        <div className='home-banner-wrap'>
            <div className="custom-container-fluid">
              <div className="home-banner-wrapper">
                <div ref={ref} className="home-banner-content flex flex-jsb align">
                  <div className="home-banner-heading">
                      <h1 style={{
                        transform: isInView ? "translateY(0)" : "translateY(200px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                      }}>{data?.bannerHeading}</h1>
                      <img style={{
                        transform: isInView ? "translateY(0)" : "translateY(200px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        transitionDelay: "0.9s"
                      }} className='img' src={beyond1} alt="" />
                      <img  style={{
                        transform: isInView ? "translateY(0)" : "translateY(200px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        transitionDelay: "0.9s"
                      }} className='img' src={beyond2} alt="" />
                      <img  style={{
                        transform: isInView ? "translateY(0)" : "translateY(200px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        transitionDelay: "0.9s"
                      }} className='img' src={beyond3} alt="" />
                  </div>
                  <div className="home-banner-body">
                    <p style={{
                        transform: isInView ? "translateY(0)" : "translateY(100%)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        transitionDelay: "1.8s"
                    }}>{data?.bannerPara}</p>
                  </div>
                </div>
                <div style={{
                      clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
                      opacity: isInView ? 1 : 0,
                      transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                      transitionDelay: "1.8s"
                    }} className="homebanner-img">
                  <img src={data?.bannerImg} alt="" className="img" />
                </div>
              </div>
            </div>
            <Ticker texts={texts} styles={dynamicStyles}/>
        </div>
    </section>
  )
}
