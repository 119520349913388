import React, { useContext, useState } from 'react';
// import './mentee-profile.css';
import '../onboarding/Onboarding-content.css';
import '../reusable/login/login.css';
import MultiSelectTags from '../reusable/multi-select/multiSelect';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import starFilled from '../../assets/images/star-filled.svg'
import starEmpty from '../../assets/images/star-empty.svg'

export default function MentorExpandedSessionPopup ({ data, noti, onclose,onCancel,onOpenNew }) {

    // const { state, updateMenteeProfileOverView } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const handleOpenNewPopup = () => {
        onCancel(data);
      };

    // console.log(data);


    // const handleOnboardingInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    // const handleGuidance = (e) => {
    //     handleOnboardingInputChange({
    //         target: {
    //             name: "guidanceState",
    //             value: e.target?.value,
    //         },
    //     });

    //     handleOnboardingInputChange({
    //         target: {
    //             name: "guidance",
    //             value: e.target?.value.map((option) => option.value),
    //         },
    //     });
    // };

    // const handleLanguage = (e) => {
    //     handleOnboardingInputChange({
    //         target: {
    //             name: "languagesState",
    //             value: e.target?.value,
    //         },
    //     });

    //     handleOnboardingInputChange({
    //         target: {
    //             name: "language",
    //             value: e.target?.value?.map((option) => option.value),
    //         },
    //     });
    // };

    // const handleOverviewForm = async (e) => {
    //     e.preventDefault();
    //     try {
    //         setLoading(true);
    //         let res = await updateMenteeProfileOverView(formData);
    //         if(res){
    //             onclose();
    //             noti();
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    console.log('expandedData', data)

    return (
        <div className="insage-login dashboard-popups extended-popup">
            <div className="insage-login-wrapper">
                <div className='expanded-session-pop'>
                    <div className='esp-image'>
                        <img src={data?.mentorAvatar}/>
                    </div>
                    <div className='esp-data'>
                        <div className='popup-description'>
                            <p className='pd-para'>Mentorship session with <span>{data?.mentorName}</span></p>
                        </div>
                        <div className='pd-datetime'>
                            <div class="pd-date">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 1.5V3.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4 1.5V3.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.5 5.5H10.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"></path></svg>
                                </span>
                                {data?.sessionDate}</div>
                            <div class="pd-time">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><g clip-path="url(#clip0_5004_40300)"><path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_5004_40300"><rect width="12" height="12" fill="white" transform="translate(0 0.5)"></rect></clipPath></defs></svg>
                                </span>
                                <div class="timer">{data?.startTime} - {data?.endTime} <span class="duration">{data?.duration}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="overview-profile-pop-form">
                <div className='slci-content-in'>
                            <div className='slci-in-content'>
                                <div className='slciic-topic'>
                                    <h6 className='micro'>Discussion Topic</h6>
                                    <p>{data?.agenda}</p>
                                </div>
                                <div className='slciic-detailed'>
                                    <h6 className='micro'>Detailed Session Agenda</h6>
                                    <p>{data?.message}</p>
                                </div>
                                
                            </div>
                        </div>
                    {/* <form action="post">
                        <div className="overview-profile-pop-btn">
                            <button className='popup-btn cancel-btn'>
                                {loading ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    'cancel session'
                                )}
                            </button>
                        </div>
                        
                    </form> */}
                    <div class="slci-foot">
                            <div class="foot-upcoming">
                                <div class="foot-ques">Not able to make it to your session?</div>
                                <div class="foot-cancel">
                                    <button class="cancel-text" onClick={handleOpenNewPopup}>Cancel Session</button>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className="login-close-button" onClick={onclose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}
