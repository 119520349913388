import React from 'react';
import axios from 'axios';
import Header from '../../../layouts/header/Header';
import Footer from '../../../layouts/footer/Footer';
import NewAccount from '../../../components/how-it-works/new-account';
import WeAreOn from '../../../components/how-it-works/we-are-on';
import OurNewWay from '../../../components/how-it-works/our-new-way';
import BannerPlatform from '../../../components/reusable/platform-banner/banner-platform';
import Cookies from 'js-cookie';

export default function HowItWorks() {
  const [hiwData, sethiwData] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("../hiw.json")
    .then((res) => sethiwData(res.data))
    .catch(err=>console.log(err))
  },[]);

  const userRole = Cookies.get('userRole');
  return (
        <main>
        <Header/>
        <BannerPlatform props={hiwData.bannerData} propsrole={userRole}/>
        <NewAccount props={hiwData.newAccount} propsrole={userRole}/>
        <WeAreOn  props={hiwData.weAreOn} propsrole={userRole}/>
        <OurNewWay props={hiwData.ourNewWay} propsrole={userRole}/>
        <Footer/>
        </main>
  )
}
