// MultiSelectTags.js
import React from 'react';
import Select from 'react-select';

const MultiSelectTags = ({ options, value, onChange, name }) => {

  const handleChange = (selectedOptions) => {
    // Create a new synthetic event to pass the necessary properties
    const event = {
      target: {
        name: name,
        value: selectedOptions,
      },
    };
    // Call the onChange function with the synthetic event
    onChange(event);
  };

  return (
    <Select
      isMulti
      options={options}
      name={name}
      value={value}
      onChange={handleChange}
      closeMenuOnSelect={false}
    />
  );
};

export default MultiSelectTags;
