import React, { useState, useEffect, useRef, useMemo } from "react";
import linkedin from '../../../assets/images/Sign Up/linkedin.png';
import ProfileCard from "./ProfileCard";

function LinkedInPage() {
  const isMountedRef = useRef(true); // Ref to track component's mounted state
  const [profile, setProfile] = useState({
    isAuthorized: false,
    firstName: null,
    lastName: null,
    profileURL: null,
    pictureURL: null,
  });

  const [cachedProfile, setCachedProfile] = useState(null);

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      isMountedRef.current = false;
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  const handlePostMessage = (event) => {
    if (event.data.type === "profile" && isMountedRef.current) {
      updateProfile(event.data.profile);
    }
  };

  const updateProfile = (profileData) => {
    // console.log(profileData);
    setProfile({
      isAuthorized: true,
      firstName: profileData.localizedFirstName || "",
      lastName: profileData.localizedLastName || "",
      profileURL: `https://www.linkedin.com/in/${profileData.vanityName || ""}`,
      pictureURL: profileData.profilePicture?.displayImage?.elements[0]?.identifiers[0]?.identifier || "",
    });
    setCachedProfile(profileData);
  };

  const requestProfile = () => {
    var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=773eb4z94jxeuk&scope=r_liteprofile&state=123456&redirect_uri=http://localhost:3000/linkedin`;
    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    
    window.open(
      oauthUrl,
      "Linkedin",
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  };

  const renderedProfile = useMemo(() => {
    if (!cachedProfile) {
      return null;
    }
    return (
      <ProfileCard
        firstName={cachedProfile.localizedFirstName}
        lastName={cachedProfile.localizedLastName}
        profileURL={cachedProfile.profileURL}
        pictureURL={cachedProfile.pictureURL}
      />
    );
  }, [profile]);

  useEffect(() => {
    setCachedProfile(profile);
  }, [profile]);

  // console.log(profile);

  return (
    <>
        <button onClick={requestProfile}><img src={linkedin ? linkedin : "" } alt="Profile Image" /></button>
          {renderedProfile}</>
      
  );
}

export default LinkedInPage;
