import React, { useEffect, useState } from 'react'

import axios from 'axios';

const ObStep8 = ({nextStep,prevStep, handleOnboardingInputChange, values}) => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [bioError, setBioError] = useState("");
  const [motiError, setMotiError] = useState("");
  const [topicError, setTopicError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

     if(!values.bio.trim()){
         setBioError("Please tell us a little about yourself");
         return;
     } else if (values.bio?.length < 10) {
        setBioError('Bio should be at least 10 characters');
        return;
      } else if (values.bio?.length > 500) {
          setBioError('Bio should not exceed 500 characters');
          return;
      } else {
          setBioError('')
      }


     if(!values.whyBecomeMentor.trim()){
      setMotiError("Please tell us your motivation");
      return;
     }else if (values.whyBecomeMentor?.length < 3) {
      setMotiError('motivation should be at least 3 characters');
      return;
    } else if (values.whyBecomeMentor?.length > 200) {
        setMotiError('motivation should not exceed 200 characters');
        return;
    } else {
        setMotiError('')
    }


  if(!values.preferredMentoredTopic.trim()){
    setTopicError("Please tell us a topic");
    return;
  }else if (values.preferredMentoredTopic?.length < 3) {
    setTopicError('topic should be at least 3 characters');
    return;
  } else if (values.preferredMentoredTopic?.length > 200) {
      setTopicError('topic should not exceed 200 characters');
      return;
  } else {
      setTopicError('')
  }

    nextStep();
  };
  const handleBack = () =>{
    prevStep();
  }


  const handleNameChange = () => {
    const name = values.firstName;
    const initialLetter = name.charAt(0).toUpperCase();
    setProfilePhoto(initialLetter);
    // ... other form data handling
  };
  const handlePhotoUpload = e => {
    const file = e.target.files[0];
    console.log('file ', file);
    const reader = new FileReader();
  
    reader.onload = () => {
      setProfilePhoto(reader.result);
    };
  
    if (file) {
      reader.readAsDataURL(file);
      handleOnboardingInputChange({
        target: {
            name: "avatar",
            value: file,
        },
    });
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      return response.data.map(country => ({
        value: country.name.common,
        label: country.name.common,
      }));
    } catch (error) {
      console.error('Error fetching countries:', error);
      return [];
    }
  };



  useEffect(() => {
    const getCountries = async () => {
      const countries = await fetchCountries();
      setCountryOptions(countries);
    };
    getCountries();
  }, []);
  // const [inputValue, setInputValue] = useState('');
  // const maxCharacterLimit = 100;
  // const handleInputChange = e => {
  //   const value = e.target.value;
  //   setInputValue(value);
  // };
  // const charactersRemaining = maxCharacterLimit - inputValue.length;
  return (
    <>
    <div className='stepLine'>
        <div className='slFilled width90'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 8 of 9
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Let's get to know you!</h5>
        </div>
        <div className="obHeadingSvg">
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="28" viewBox="0 0 37 28" fill="none">
            <path d="M4.5 1C4.5 1 4.5 0 5.5 0H11.5C11.5 0 12.5 0 12.5 1V3H4.5V1Z" fill="#66757F"/>
            <path d="M0.5 6C0.5 6 0.5 2 4.5 2H32.5C32.5 2 36.5 2 36.5 6V24C36.5 24 36.5 28 32.5 28H4.5C4.5 28 0.5 28 0.5 24V6Z" fill="#31373D"/>
            <path d="M21.5 25C27.0228 25 31.5 20.5228 31.5 15C31.5 9.47715 27.0228 5 21.5 5C15.9772 5 11.5 9.47715 11.5 15C11.5 20.5228 15.9772 25 21.5 25Z" fill="#CCD6DD"/>
            <path d="M21.5 23C25.9183 23 29.5 19.4183 29.5 15C29.5 10.5817 25.9183 7 21.5 7C17.0817 7 13.5 10.5817 13.5 15C13.5 19.4183 17.0817 23 21.5 23Z" fill="#31373D"/>
            <path d="M21.5 20C24.2614 20 26.5 17.7614 26.5 15C26.5 12.2386 24.2614 10 21.5 10C18.7386 10 16.5 12.2386 16.5 15C16.5 17.7614 18.7386 20 21.5 20Z" fill="#3B88C3"/>
            <path d="M33 7C33.8284 7 34.5 6.32843 34.5 5.5C34.5 4.67157 33.8284 4 33 4C32.1716 4 31.5 4.67157 31.5 5.5C31.5 6.32843 32.1716 7 33 7Z" fill="white"/>
            <path d="M12.5 5.5C12.5 6.329 11.829 7 11 7H6C5.171 7 4.5 6.329 4.5 5.5C4.5 4.671 5.171 4 6 4H11C11.829 4 12.5 4.671 12.5 5.5Z" fill="#F5F8FA"/>
            </svg>
        </div>
        </div>
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="timezone">Add profile picture</label>
                    <div className="login-form-profile-wrapper flex align">
                        <div className="login-profile-image">
                            {profilePhoto ? (
                            <img src={profilePhoto} alt="Profile" />
                            ) : (
                            <div className="initial-letter">{profilePhoto}</div>
                            )}
                        </div>
                        <div className="login-profile-btn">
                            <span>Make sure it doesn’t exceed 2mb.</span>
                            
                            {profilePhoto ? (
                          <label>
                            <input type="file" accept="image/*" onChange={handlePhotoUpload} /> 
                            Update file
                          </label>
                            ) : (
                                  <label>
                                  <input type="file" accept="image/*" onChange={handlePhotoUpload}/> 
                                    Upload a file
                                  </label>
                            )}
                        </div>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label className='flex bio-label align' htmlFor="bio">Describe yourself in a few words
                    </label>
                    {bioError && <p className="error-name-onb">{bioError}</p>}
                        <textarea
                        name='bio'
                        value={values.bio}
                        onChange={handleOnboardingInputChange}
                        placeholder='Type here'
                        // maxLength={maxCharacterLimit}
                        >
                            {/* {charactersRemaining} characters remaining */}
                        </textarea>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="whyBecomeMentor">What motivates you to become a mentor?</label>
                    {motiError && <p className="error-name-onb">{motiError}</p>}
                        <textarea
                            id="whyBecomeMentor"
                            name="whyBecomeMentor"
                            type="text"
                            placeholder='Type here'
                            value={values.whyBecomeMentor}
                            onChange={handleOnboardingInputChange}
                        ></textarea>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="preferredMentoredTopic">If you could mentor someone on just one topic, what would it be, and why? </label>
                    {topicError && <p className="error-name-onb">{topicError}</p>}
                    <textarea
                            id="preferredMentoredTopic"
                            name="preferredMentoredTopic"
                            type="text"
                            placeholder='Type here'
                            value={values.preferredMentoredTopic}
                            onChange={handleOnboardingInputChange}
                        ></textarea>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
</>
  )
}

export default ObStep8
