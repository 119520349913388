import React, {useRef, useEffect} from 'react';
import './features.css';
import '../reusable/top-categories/TopCatog.css';
import Image2 from '../../assets/images/what-we-offer.svg';
import Mentor1 from '../../assets/images/wwo-mentor-1.png';
import Mentor2 from '../../assets/images/wwo-mentor-2.png';
import Mentor3 from '../../assets/images/wwo-mentor-3.png';
import { useInView } from "framer-motion";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation} from "swiper";

export default function WhatWeOffer({props, topMentorData}) {
    // useEffect(() => {
    //   const delayAnimation = setTimeout(() => {
    //     animate();
    //     return () => clearTimeout(delayAnimation);
    //   }, 2000);
    
    // }, []);
    const ref = useRef(null);
    const element = useRef();
    const isInView = useInView(ref, { once: true });  
    const data = props;

    const gridcardPrevRef = React.useRef(null)
    const gridcardNextRef = React.useRef(null)
  
  return (
    <section className="features-what-we-offer">
       <div className="container">
       <div className="insage-top-categories-top-wrapper">
            <div ref={ref} style={{
                  transform: isInView ? "translateY(0%)" : "translateY(-100px)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="insage-top-categories-label flex flex-jsc">
              <span className="label col-white">{data?.wwoTag}</span>
              </div>
              <div className="insage-top-categories-heading">
                <h2 ref={ref} style={{
                  transform: isInView ? "translateY(0%)" : "translateY(100%)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{data?.wwoHead}</h2>
                </div>
              <div className="insage-top-categories-body">
                <p ref={ref} style={{
                  transform: isInView ? "translateY(0%)" : "translateY(100%)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{data?.wwoBody}</p>
                </div>
          </div>
         <div ref={element} className="what-we-offer-bottom">
         <Swiper modules={[Navigation]}  breakpoints={{
              0:{
                  slidesPerView: 1,
              },
              600:{
                  slidesPerView: 1,
              },                
              1200: {
                  slidesPerView: 1,
              }
          }}  slidesPerView={3} spaceBetween={20}  navigation={{ prevEl: gridcardPrevRef.current, nextEl: gridcardNextRef.current,}}  onSwiper={(swiper) => {
              // Delay execution for the refs to be defined
              setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = gridcardPrevRef.current
              swiper.params.navigation.nextEl = gridcardNextRef.current

              // Re-init navigation
              swiper.navigation.destroy()
              swiper.navigation.init()
              swiper.navigation.update()
              })
          }} className="mySwiper dash-top-rated-mentors-slider">
                <SwiperSlide>
                <div className="what-we-offer-bottom-wrapper flex align">
                  <div className="wwo-bottom-left">
                      <div className="wwo-bottom-left-img-wrapper">
                          <img src={data?.wwoImg} alt="" className="img" />
                      </div>
                      <div className="wwo-bottom-left-top-img">
                          <img src={Image2} alt="" />
                      </div>
                      <div className="wwo-bottom-left-bottom-mentors">
                          <div className="wwo-bottom-left-bottom-mentors-wrapper">
                              <div className="wwo-top-mentors-heading">our Top Mentors</div>
                              <div className="wwo-top-mentors-list">

                              {topMentorData?.result?.allMentor?.slice(0, 3)?.map((post,index) => {
                                  return (
                                  <div key={index} className="wwo-top-mentor-mentor flex align">
                                      <div className="wwo-top-mentor-image">
                                        <img src={post?.avatar} alt="avatar" className='img' />
                                      </div>
                                      <div className="wwo-top-mentor-desc">
                                          <div className="wwo-top-mentor-name">{post?.fullName}</div>
                                          <div className="wwo-top-mentor-designation">{post?.designation}<span>at {post?.company}</span></div>
                                      </div>
                                  </div>
                                  )

                              })}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="wwo-bottom-right">
                      <div className="wwo-bottom-right-wrapper">
                        <div  className="wwo-bottom-content">
                          <div className="wwo-bottom-content-wrapper">
                              <div className="wwo-content-heading"><h3>Connect with your ideal mentor</h3></div>
                              <div className="wwo-content-body"><p className='col-p'>Your aspirations meet enlightening guidance at Insage. Effortlessly discover mentors who resonate with your passion.</p></div>
                              <div className="wwo-content-points">
                                  <span className='col-p'>With Insage you can:</span>
                                  <ul>
                                      <li><p className="small">Filter mentors by specific fields to discover your ideal match.</p></li>
                                      <li><p className="small">Book real-time one-on-one sessions with experts.</p></li>
                                      <li><p className="small">Access global guidance seamlessly, all with a simple scroll.</p></li>
                                  </ul>
                              </div>
                              <div className="woo-content-btn">
                                  <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 border">Start your mentorship</Link>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="what-we-offer-bottom-wrapper flex align">
                  <div className="wwo-bottom-left">
                      <div className="wwo-bottom-left-img-wrapper">
                          <img src={data?.wwoImg} alt="" className="img" />
                      </div>
                      <div className="wwo-bottom-left-top-img">
                          <img src={Image2} alt="" />
                      </div>
                      <div className="wwo-bottom-left-bottom-mentors">
                          <div className="wwo-bottom-left-bottom-mentors-wrapper">
                              <div className="wwo-top-mentors-heading">our Top Mentors</div>
                              <div className="wwo-top-mentors-list">

                              {topMentorData?.result?.allMentor?.slice(0, 3)?.map((post,index) => {
                                  return (
                                  <div key={index} className="wwo-top-mentor-mentor flex align">
                                      <div className="wwo-top-mentor-image">
                                        <img src={post?.avatar} alt="avatar" className='img' />
                                      </div>
                                      <div className="wwo-top-mentor-desc">
                                          <div className="wwo-top-mentor-name">{post?.fullName}</div>
                                          <div className="wwo-top-mentor-designation">{post?.designation}<span>at {post?.company}</span></div>
                                      </div>
                                  </div>
                                  )

                              })}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="wwo-bottom-right">
                      <div className="wwo-bottom-right-wrapper">
                        <div className="wwo-bottom-content">
                          <div className="wwo-bottom-content-wrapper">
                              <div className="wwo-content-heading"><h3>Grow with like-minded peers</h3></div>
                              <div className="wwo-content-body"><p className='col-p'>Connect with fellow professionals who share your goals. Collaborate, learn, and contribute within a growth-driven environment.</p></div>
                              <div className="wwo-content-points">
                                  <span className='col-p'>Insage enables you to:</span>
                                  <ul>
                                      <li><p className="small">Engage with the community via questions and insightful discussions.</p></li>
                                      <li><p className="small">Freely interact with mentors and mentees and share insights.</p></li>
                                      <li><p className="small">Get multifaceted answers to professional queries from fellow platform users.</p></li>
                                  </ul>
                              </div>
                              <div className="woo-content-btn">
                              <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 border">Start your mentorship</Link>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="what-we-offer-bottom-wrapper flex align">
                  <div className="wwo-bottom-left">
                      <div className="wwo-bottom-left-img-wrapper">
                          <img src={data?.wwoImg} alt="" className="img" />
                      </div>
                      <div className="wwo-bottom-left-top-img">
                          <img src={Image2} alt="" />
                      </div>
                      <div className="wwo-bottom-left-bottom-mentors">
                          <div className="wwo-bottom-left-bottom-mentors-wrapper">
                              <div className="wwo-top-mentors-heading">our Top Mentors</div>
                              <div className="wwo-top-mentors-list">

                              {topMentorData?.result?.allMentor?.slice(0, 3)?.map((post,index) => {
                                  return (
                                  <div key={index} className="wwo-top-mentor-mentor flex align">
                                      <div className="wwo-top-mentor-image">
                                        <img src={post?.avatar} alt="avatar" className='img' />
                                      </div>
                                      <div className="wwo-top-mentor-desc">
                                          <div className="wwo-top-mentor-name">{post?.fullName}</div>
                                          <div className="wwo-top-mentor-designation">{post?.designation}<span>at {post?.company}</span></div>
                                      </div>
                                  </div>
                                  )

                              })}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="wwo-bottom-right">
                      <div className="wwo-bottom-right-wrapper">
                        <div  className="wwo-bottom-content">
                          <div className="wwo-bottom-content-wrapper">
                              <div className="wwo-content-heading"><h3>Access invaluable insights</h3></div>
                              <div className="wwo-content-body"><p className='col-p'>With insights shared by industry leaders, receive wisdom that goes beyond textbooks, Elevate your professional journey with Insage.</p></div>
                              <div className="wwo-content-points">
                                  <span className='col-p'>Insage gives you access to:</span>
                                  <ul>
                                      <li><p className="small">Mentor blogs to enhance expertise in a practical and meaningful way.</p></li>
                                      <li><p className="small">Interaction guides that empower both mentors and mentees with guidance on effective communication.</p></li>
                                      <li><p className="small">Diverse perspectives and enhanced clarity to mentees' growth journey.</p></li>
                                  </ul>
                              </div>
                              <div className="woo-content-btn">
                              <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 border">Start your mentorship</Link>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                </SwiperSlide>
          </Swiper>
              <div className="dash-empty-trm-slider-btns flex align features-slider-btns">
                  <div className="det-slider-prev" ref={gridcardPrevRef}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                          <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                  </div>
                  <div className="det-slider-next" ref={gridcardNextRef}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                          <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                  </div>
              </div>
            </div> 
       </div>
       <div className="features-what-we-offer-svg">
       <svg width="379" height="549" viewBox="0 0 379 549" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-142 312.293L35.5651 312.293L-89.9822 437.841L-41.4165 486.43L118.512 326.525L278.417 486.43L327.006 437.841L201.435 312.293L379 312.293L379 243.578L201.435 243.578L327.006 118.007L278.417 69.4419L118.512 229.347L-41.4165 69.4418L-89.9822 118.007L35.5651 243.578L-142 243.578L-142 312.293Z" fill="#FFECA1"/>
            <path d="M118.479 453.482C92.1296 453.482 70.7824 474.829 70.7824 501.178C70.7824 527.528 92.1296 548.875 118.479 548.875C144.828 548.875 166.176 527.528 166.176 501.178C166.176 474.829 144.828 453.482 118.479 453.482Z" fill="#FFECA1"/>
            <path d="M71.4266 47.0807C67.2066 73.083 84.8647 97.583 110.867 101.803C136.869 106.023 161.369 88.365 165.589 62.3627C169.809 36.3603 152.151 11.8602 126.149 7.64023C100.147 3.42022 75.6466 21.0783 71.4266 47.0807Z" fill="#FFECA1"/>
        </svg>

       </div>
    </section>
  )
}
