import React from 'react';
import starFilled from '../../assets/images/star-filled.svg';
import starEmpty from '../../assets/images/star-empty.svg';

export default function StarRating ({ rating }) {
  const renderStars = () => {
    const starCount = Math.round(rating); // Assuming the API returns a numeric rating

    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <img
          key={i}
          src={i <= starCount ? starFilled : starEmpty}
          alt={`Star ${i}`}
        />
      );
    }

    return stars;
  };

  return <ul className='review-stars'>{renderStars()}</ul>;
};