import React, {useState, useRef, useEffect, useContext} from 'react';
import './dashSession.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import starFilled from '../../../assets/images/star-filled.svg'
import starEmpty from '../../../assets/images/star-empty.svg'
import { AuthContext } from '../../../context/AuthContext';
import actionTypes from '../../../context/actionTypes';

export default function MentorDashSessionGridOngoing ({props,openReportSessionPopup}) {

// console.log('Ongoing sessions data' , props)

const {markAsCompleteAsMentor, state, dispatch } = useContext(AuthContext)

const handleMarkAsComplate = async () => {
    const res = await markAsCompleteAsMentor({bookingId:props.bookingId})

    if(res){
        let newArray = state?.dashboard?.gogingBookingsFetch.filter(obj => obj.bookingId !== props.bookingId);
        let newData = { ...state?.dashboard, gogingBookingsFetch: newArray };
        dispatch({ type: actionTypes.DASHBOARD_MENTEE, payload: newData});
    }
}

  return ( 
    <>
    <div className='session-ongoing-card-outer'>
        <div className='session-ongoing-card-in'>
            <div className='sogi-left'>
                <div className='sogi-left-in'>
                    <div className='sli-wrap'>
                        <div className='sliw-image'>
                            <img src={props?.menteeAvatar}/>
                        </div>
                        <div className='sliw-content'>
                            <div className='sliw-name'>{props?.menteeName}</div>
                            <div className='sliw-position'>
                                <div className='sliwp-designation'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path d="M10 4.5H2C1.44772 4.5 1 4.88376 1 5.35714V9.64286C1 10.1162 1.44772 10.5 2 10.5H10C10.5523 10.5 11 10.1162 11 9.64286V5.35714C11 4.88376 10.5523 4.5 10 4.5Z" fill="#C0C0C0" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8 3.5V1.72222C8 1.66329 7.89464 1.60676 7.70711 1.56509C7.51957 1.52341 7.26522 1.5 7 1.5H5C4.73478 1.5 4.48043 1.52341 4.29289 1.56509C4.10536 1.60676 4 1.66329 4 1.72222V3.5" stroke="#BABABA" strokeLinecap="square" strokeLinejoin="round"/>
                                            <path d="M8 11.5V4.5M4 4.5V11.5" stroke="#FFF6E1" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                    {props?.menteeDesignation}
                                </div>
                                <div className='dot-seperator'></div>
                                <div className='sliwp-company'>
                                    {props?.menteeCompany}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sogi-right'>
                <div className='sogi-right-in'>
                    <div className='sli-dd'>
                        <div className='slidd-heading'>Date</div>
                        <div className='slidd-content'>{props?.sessionDate}</div>
                    </div>
                    <div className='sli-dd'>
                        <div className='slidd-heading'>Duration</div>
                        <div className='slidd-content'>{props?.startTime} - {props?.endTime}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='ongoing-report'>
        <div className='or-report'>
            <div className='or-text'>Are you still in session with {props?.menteeName}?</div>
            <div className='or-button'>
                <div className='popup-btn primary-btn green' onClick={handleMarkAsComplate}>Mark As Complete</div>
                <button className='popup-btn cancel-btn'onClick={openReportSessionPopup} >
                    Report
                </button>
            </div>
        </div>
    </div>
    </>
  )
}
