import React, { useContext } from 'react';
import './profile.css';
import Patt from '../../assets/images/dash-empty.svg';
import NoSesh from '../../assets/images/no-sesh.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation} from "swiper";
import TrmCard from '../reusable/trm-card/TrmCard';
import { AuthContext } from '../../context/AuthContext';
import data from './data.json';
import { Link } from 'react-router-dom';


export default function DashEmpty({props}) {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const { state } = useContext(AuthContext);

    // console.log('props ', props?.topRated);
    return (
        <div className="dashboard-empty-state">
            <div className="dashboard-empty-state-banner">
                <div className="dashboard-empty-state-banner-wrapper">
                    <div className="dash-empty-banner-ptrn">
                        <img src={Patt} alt="" srcSet="" />
                    </div>
                    <div className="dash-empty-text-wrap">
                        <div className="dash-empty-name flex align">
                            <div className="dash-empty-name-text">Welcome, {state.profile && state.profile?.fullName}!</div>
                            <div className="dash-empty-name-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
                                <path d="M20.3795 6.22633C26.1791 12.0244 27.7462 19.8599 23.8801 23.7245C20.0139 27.5906 12.18 26.0235 6.38112 20.2255C6.18414 20.0285 6.00451 19.8237 5.81699 19.622L9.29708 16.1419C13.8069 20.6517 19.5522 22.2196 22.1286 19.6417C24.7058 17.0645 23.1387 11.3192 18.6296 6.80936L18.6469 6.82591L18.6304 6.80857C13.4768 1.65422 6.42524 0.348694 2.88211 3.89182C1.16846 5.60547 0.599608 8.14089 1.04004 10.8827C0.36403 7.66579 0.921854 4.68522 2.88211 2.72575C6.74748 -1.13962 14.5814 0.427482 20.3795 6.22633Z" fill="#FDD888"/>
                                <path d="M12.9236 27.3956C12.7251 27.3956 12.5273 27.335 12.3571 27.2136L8.35781 24.3449L4.3585 27.2136C4.01735 27.4579 3.56038 27.4579 3.22158 27.2097C2.88279 26.9639 2.74019 26.529 2.86704 26.1311L4.35929 21.3171L0.395437 18.524C0.0582213 18.2759 -0.0812346 17.8394 0.0471908 17.4407C0.177192 17.0436 0.546711 16.7734 0.965078 16.7702L5.80821 16.7608L7.43678 12.0673C7.56914 11.6702 7.94023 11.4023 8.3586 11.4023C8.77697 11.4023 9.14806 11.6702 9.28043 12.0673L10.7506 16.7608L15.7513 16.7702C16.1705 16.7734 16.5408 17.0436 16.6692 17.4407C16.7984 17.8394 16.6582 18.2759 16.321 18.524L12.3571 21.3171L13.8486 26.1311C13.9762 26.529 13.832 26.9647 13.4948 27.2097C13.3239 27.3342 13.1237 27.3956 12.9236 27.3956Z" fill="#FFAC33"/>
                                </svg>
                            </div>
                        </div>
                        <div className="dash-empty-body">
                            <p>Start with completing your profile!</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="dash-empty-upcoming-sessions">
                    <div className="dash-empty-sessions-content">
                        <div className="dash-empty-sess-head">upcoming sessions</div>
                        <div className="dash-empty-sess-body"><p>Check your sessions for today, tomorrow and whenever here.</p></div>
                    </div>
                    <div className="dash-emp-no-sess">
                        <div className="dash-emp-no-sess-wrap">
                            <div className="dash-emp-no-sess-img">
                                <img src={NoSesh} alt="" />
                            </div>
                            <div className="dash-emp-no-sess-head">You have no upcoming sessions</div>
                            <div className="dash-emp-no-sess-body">
                                <p> Don't worry, our experienced mentors are
                                    available to connect with you and help you grow.</p>
                            </div>
                            <div className="dash-emp-no-sess-btn">
                                <a href="/explore-our-mentors" className="primary-btn black">explore mentors</a>
                            </div>
                        </div>
                    </div>
            </div>
            {/* <div className="dash-empty-top-rated-mentors">
                <div className="dash-empty-sessions-content">
                        <div className="dash-empty-sess-head">New Mentors</div>
                        <div className="dash-empty-sess-body flex align flex-jsb">
                            <p>Checkout our new mentors in your industry!</p>
                            <div className="dash-empty-trm-slider-btns flex align">
                                <div className="det-slider-prev" ref={navigationPrevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                                <div className="det-slider-next" ref={navigationNextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                            </div>
                            </div>
                    </div>
                <div className="dash-emp-trm-slider flex">
                    <div className="dash-emp-trm-slider-left">
                    <div className="dash-emp-trm-slider-left-wrap flex flex-c">
                        <div className="dash-emp-trm-slider-left-head">Our<br/> Best<br/> Batch</div>
                        <div className="dash-emp-trm-slider-left-dash"></div>
                        <div className="dash-emp-trm-slider-left-cont">
                            <div className="dash-emp-trm-slider-left-cont-body">
                                <p>Discover all mentors
                                below across industries!</p>
                            </div>
                            <div className="dash-emp-trm-slider-left-cont-btn">
                                <Link to="/explore-our-mentors" className="primary-btn border">Dicover All Mentors</Link>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="dash-emp-trm-slider-right">
                       {props?.topRated?.length > 0 ?
                        <>
                         <Swiper  modules={[Navigation]}  breakpoints={{
                            0:{
                                slidesPerView: 1,
                            },                
                            1200: {
                            slidesPerView: 2,
                            }
                        }}  slidesPerView={2} spaceBetween={13}  navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current,}}  onSwiper={(swiper) => {
                            // Delay execution for the refs to be defined
                            setTimeout(() => {
                            // Override prevEl & nextEl now that refs are defined
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current

                            // Re-init navigation
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }} className="mySwiper dash-top-rated-mentors-slider">
                                {props?.topRated?.map((posts,index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <TrmCard  post={posts}/>
                                        </SwiperSlide>
                                    );
                                })} 
                            
                        </Swiper>
                        </>
                        :
                        <>
                        No new Mentors
                        </>
                            }
                        
                       
                    </div>
                </div>   
            </div> */}
        </div>
    )
}
