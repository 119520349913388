import React, {useRef} from 'react';
import './usecases.css';
import Mentor1 from '../../assets/images/usescases-mentor-1.png';
import Mentor2 from '../../assets/images/usecases-mentor-2.png';
import Mentor3 from '../../assets/images/usecases-mentor-3.png';
import Mentor4 from '../../assets/images/usecases-mentor-4.png';
import Mentor5 from '../../assets/images/usecases-mentor-5.png';
import { useInView } from "framer-motion";
import { Link } from 'react-router-dom';

export default function QualityMentors({topMentorData}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  

    console.log('topMentorData?.result?.allMentor ', topMentorData?.result?.allMentor);
  return (
   <section className="usecases-quality-mentors">
      <div className="usecases-container-fluid">
        <div className="usescases-quality-mentors-wrapper">
            <div className="quality-mentors-heading">
                <div className="quality-mentors-heading-wrapper">
                    <div className="quality-mentors-head">
                        <h2 ref={ref} style={{
                  transform: isInView ? "translateY(0%)" : "translateY(100%)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>Dedicated<br/> mentorship</h2>
                    </div>
                    <div className="quality-mentors-body">
                       <p ref={ref} style={{
                  transform: isInView ? "translateY(0%)" : "translateY(100%)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>Our mentors are more than just professionals, they’re passionate experts in their respective fields who possess a passion for their work and desire to share their insights with the newer generations. With Insage, you’re certain to discover the perfect mentor to meet your specific needs.</p>
                    </div>
                </div>
            </div>
            <div className="quality-mentors-ticker-wrapper">
                <div className="quality-mentors-top-ticker flex">
                <div className="quality-mentors-top-ticker-wrapper">

                {topMentorData?.result?.allMentor?.map((post,index) => {
                    return <Link to={`/mentors/${post?.username}`} key={index}>
                    <div className="quality-mentors-top-ticker-box">
                    <div className="quality-mentors-top-ticker-box-wrapper flex align">
                        <div className="quality-mentors-mentor-image">
                            <img src={post?.avatar} alt="avatar" className='img' />
                        </div>
                        <div className="quality-mentors-mentor-description">
                            <div className="quality-mentors-mentor-name"><h6>{post?.fullName}</h6></div>
                            <div className="quality-mentors-mentor-designation"><span>{post?.designation} at {post?.company}</span></div>
                            <div className="quality-mentors-mentor-category flex align">
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.1 2.78125H1.9C1.40294 2.78125 1 3.17922 1 3.67014V8.11458C1 8.6055 1.40294 9.00347 1.9 9.00347H9.1C9.59706 9.00347 10 8.6055 10 8.11458V3.67014C10 3.17922 9.59706 2.78125 9.1 2.78125Z" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.30312 9V1.88889C7.30312 1.65314 7.2083 1.42705 7.03952 1.26035C6.87074 1.09365 6.64182 1 6.40312 1H4.60312C4.36443 1 4.13551 1.09365 3.96673 1.26035C3.79795 1.42705 3.70313 1.65314 3.70312 1.88889V9" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <div className="quality-mentors-mentor-category-label">
                                {post?.industry}
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                    </Link>
                })}
                    </div>
                    <div aria-hidden="true" className="quality-mentors-top-ticker-wrapper">
                    {topMentorData?.result?.allMentor?.map((post,index) => {
                    return <Link to={`/mentors/${post?.username}`} key={index}>
                    <div className="quality-mentors-top-ticker-box">
                    <div className="quality-mentors-top-ticker-box-wrapper flex align">
                        <div className="quality-mentors-mentor-image">
                            <img src={post?.avatar} alt="avatar" className='img' />
                        </div>
                        <div className="quality-mentors-mentor-description">
                            <div className="quality-mentors-mentor-name"><h6>{post?.fullName}</h6></div>
                            <div className="quality-mentors-mentor-designation"><span>{post?.designation} at {post?.company}</span></div>
                            <div className="quality-mentors-mentor-category flex align">
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.1 2.78125H1.9C1.40294 2.78125 1 3.17922 1 3.67014V8.11458C1 8.6055 1.40294 9.00347 1.9 9.00347H9.1C9.59706 9.00347 10 8.6055 10 8.11458V3.67014C10 3.17922 9.59706 2.78125 9.1 2.78125Z" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.30312 9V1.88889C7.30312 1.65314 7.2083 1.42705 7.03952 1.26035C6.87074 1.09365 6.64182 1 6.40312 1H4.60312C4.36443 1 4.13551 1.09365 3.96673 1.26035C3.79795 1.42705 3.70313 1.65314 3.70312 1.88889V9" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <div className="quality-mentors-mentor-category-label">
                                {post?.industry}
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                    </Link>
                })}
                    </div>
                </div>
                <div className="quality-mentors-bottom-ticker flex">
                    <div className="quality-mentors-top-ticker-wrapper">


                         {topMentorData?.result?.allMentor?.map((post,index) => {
                    return <Link to={`/mentors/${post?.username}`} key={index}>
                    <div className="quality-mentors-top-ticker-box">
                    <div className="quality-mentors-top-ticker-box-wrapper flex align">
                        <div className="quality-mentors-mentor-image">
                            <img src={post?.avatar} alt="avatar" className='img' />
                        </div>
                        <div className="quality-mentors-mentor-description">
                            <div className="quality-mentors-mentor-name"><h6>{post?.fullName}</h6></div>
                            <div className="quality-mentors-mentor-designation"><span>{post?.designation} at {post?.company}</span></div>
                            <div className="quality-mentors-mentor-category flex align">
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.1 2.78125H1.9C1.40294 2.78125 1 3.17922 1 3.67014V8.11458C1 8.6055 1.40294 9.00347 1.9 9.00347H9.1C9.59706 9.00347 10 8.6055 10 8.11458V3.67014C10 3.17922 9.59706 2.78125 9.1 2.78125Z" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.30312 9V1.88889C7.30312 1.65314 7.2083 1.42705 7.03952 1.26035C6.87074 1.09365 6.64182 1 6.40312 1H4.60312C4.36443 1 4.13551 1.09365 3.96673 1.26035C3.79795 1.42705 3.70313 1.65314 3.70312 1.88889V9" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <div className="quality-mentors-mentor-category-label">
                                {post?.industry}
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                    </Link>
                })}
                    </div>
                    <div aria-hidden="true" className="quality-mentors-top-ticker-wrapper">

                    {topMentorData?.result?.allMentor?.map((post,index) => {
                    return <Link to={`/mentors/${post?.username}`} key={index}>
                    <div className="quality-mentors-top-ticker-box">
                    <div className="quality-mentors-top-ticker-box-wrapper flex align">
                        <div className="quality-mentors-mentor-image">
                            <img src={post?.avatar} alt="avatar" className='img' />
                        </div>
                        <div className="quality-mentors-mentor-description">
                            <div className="quality-mentors-mentor-name"><h6>{post?.fullName}</h6></div>
                            <div className="quality-mentors-mentor-designation"><span>{post?.designation} at {post?.company}</span></div>
                            <div className="quality-mentors-mentor-category flex align">
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.1 2.78125H1.9C1.40294 2.78125 1 3.17922 1 3.67014V8.11458C1 8.6055 1.40294 9.00347 1.9 9.00347H9.1C9.59706 9.00347 10 8.6055 10 8.11458V3.67014C10 3.17922 9.59706 2.78125 9.1 2.78125Z" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.30312 9V1.88889C7.30312 1.65314 7.2083 1.42705 7.03952 1.26035C6.87074 1.09365 6.64182 1 6.40312 1H4.60312C4.36443 1 4.13551 1.09365 3.96673 1.26035C3.79795 1.42705 3.70313 1.65314 3.70312 1.88889V9" stroke="#444444" strokeWidth="0.851953" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <div className="quality-mentors-mentor-category-label">
                                {post?.industry}
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                    </Link>
                })}
                    </div>
                </div>
            </div>
        </div>
      </div>
   </section>
  )
}
