import React, { useEffect, useState } from 'react'
import TimezoneSelect from 'react-timezone-select';
import "./Onboarding-content.css";

const ObStep1 = ({ nextStep, handleOnboardingInputChange, values }) => {

    const [selectedTimezone, setSelectedTimezone] =useState("");
    useEffect(() => {
        values.tz && setSelectedTimezone(values.tz);
        values.tz && handleTimezoneInputChange(values.timeZoneValue);
    }, []);

    const [timezoneError, setTimezoneError] = useState("");
    const [nameError, setNameError] = useState("");


    const handleSubmit = (e) => {
        e.preventDefault();
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!values.name.trim()) {
            setNameError("Name is required");
            return;
        }
        else if (values.name.length < 3) {
            setNameError("Name should be at least 3 characters");
            return;
        }else if (values.name.length > 20){
            setNameError("Name should not exceed 20 characters");
            return;
        }
        else if (!nameRegex.test(values.name)) {
            setNameError("Name should only contain letters and spaces");
            return;
        }
        else {
            setNameError('')
        }
    
        if (!selectedTimezone) {
            setTimezoneError("Time zone is required");
            return;
        }else {
            setTimezoneError('')
        }
        nextStep();
    };

    

    const handleTimezoneChange = (value) => {
        setSelectedTimezone(value);
        handleTimezoneInputChange(value);
        setTimezoneError("");
    };

    const handleTimezoneInputChange = (value) => {
        handleOnboardingInputChange({
            target: {
                name: "timeZoneValue",
                value: value,
            },
        });

        handleOnboardingInputChange({
            target: {
                name: "timeZone",
                value: value.label,
            },
        });

        handleOnboardingInputChange({
            target: {
                name: "tz",
                value: value.value,
            },
        });
    };


    return (
        <>
            <div className='stepLine'>
                <div className='slFilled'></div>
            </div>
            <div className='obStep'>
                <div className='obStepDetails'>
                    step 1 of 9
                </div>
                <div className="obHeadingWrapper flex flex-jsb">
                <div className='obHeading'>
                    <h5>Hello, let’s Start your mentor journey</h5>
                </div>
                <div className="obHeadingSvg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
                        <path d="M4.28597 7.09287C5.01797 6.58125 6.12141 6.67937 6.77865 7.22214L6.02485 6.12648C5.41823 5.26133 5.63549 4.32531 6.50142 3.71791C7.36736 3.11284 9.82109 4.73958 9.82109 4.73958C9.20902 3.86586 9.32349 2.75853 10.1972 2.14568C11.0709 1.53516 12.2764 1.74619 12.8885 2.62147L21.0027 14.0842L19.9686 24.1102L11.338 20.9626L3.81017 9.80125C3.19265 8.92208 3.40602 7.70961 4.28597 7.09287Z" fill="#EF9645"/>
                        <path d="M2.59948 13.4705C2.59948 13.4705 1.71797 12.1856 3.00363 11.3048C4.28774 10.4241 5.16847 11.7082 5.16847 11.7082L9.25752 17.6716C9.39846 17.4365 9.55265 17.2044 9.72475 16.9755L4.04945 8.70003C4.04945 8.70003 3.16872 7.41593 4.45361 6.5352C5.73771 5.65447 6.61844 6.93857 6.61844 6.93857L11.9566 14.7234C12.1551 14.5614 12.3584 14.3987 12.5678 14.2391L6.37937 5.21294C6.37937 5.21294 5.49865 3.92883 6.78353 3.0481C8.06764 2.16737 8.94836 3.45148 8.94836 3.45148L15.1368 12.476C15.3642 12.3366 15.5893 12.2159 15.8151 12.0882L10.0308 3.65316C10.0308 3.65316 9.15005 2.36906 10.4342 1.48833C11.7183 0.607599 12.599 1.89171 12.599 1.89171L18.715 10.8111L19.6448 12.1677C15.7917 14.8106 15.425 19.7827 17.6264 22.9934C18.0664 23.6358 18.7088 23.1959 18.7088 23.1959C16.0666 19.342 16.8734 15.0115 20.7272 12.3694L19.5911 6.68316C19.5911 6.68316 19.1667 5.1849 20.6642 4.75972C22.1624 4.33532 22.5876 5.83357 22.5876 5.83357L23.8997 9.73028C24.4199 11.2753 24.9736 12.8148 25.7064 14.271C27.7754 18.3826 26.5396 23.4926 22.6382 26.169C18.3825 29.0869 12.5647 28.0021 9.6461 23.7472L2.59948 13.4705Z" fill="#FFDC5D"/>
                        <path d="M9.84332 24.924C6.72845 24.924 3.58087 21.7764 3.58087 18.6615C3.58087 18.2309 3.26549 17.8828 2.83486 17.8828C2.40423 17.8828 2.02344 18.2309 2.02344 18.6615C2.02344 23.3338 5.17101 26.4814 9.84332 26.4814C10.274 26.4814 10.622 26.1006 10.622 25.67C10.622 25.2394 10.274 24.924 9.84332 24.924Z" fill="#5DADEC"/>
                        <path d="M5.95102 26.4424C3.61487 26.4424 2.05744 24.885 2.05744 22.5488C2.05744 22.1182 1.70935 21.7701 1.27872 21.7701C0.848087 21.7701 0.5 22.1182 0.5 22.5488C0.5 25.6637 2.83615 27.9998 5.95102 27.9998C6.38165 27.9998 6.72974 27.6518 6.72974 27.2211C6.72974 26.7905 6.38165 26.4424 5.95102 26.4424ZM19.1892 1.52344C18.7594 1.52344 18.4105 1.8723 18.4105 2.30216C18.4105 2.73201 18.7594 3.08087 19.1892 3.08087C22.3041 3.08087 25.419 5.87569 25.419 9.31061C25.419 9.74047 25.7678 10.0893 26.1977 10.0893C26.6275 10.0893 26.9764 9.74047 26.9764 9.31061C26.9764 5.01676 23.8615 1.52344 19.1892 1.52344Z" fill="#5DADEC"/>
                        <path d="M23.0834 0C22.6536 0 22.3047 0.316159 22.3047 0.746011C22.3047 1.17586 22.6536 1.55744 23.0834 1.55744C25.4196 1.55744 26.9443 3.29008 26.9443 5.41832C26.9443 5.84817 27.3251 6.19704 27.7557 6.19704C28.1863 6.19704 28.5017 5.84817 28.5017 5.41832C28.5017 2.43038 26.1983 0 23.0834 0Z" fill="#5DADEC"/>
                    </svg>
                </div>
                </div>
                <div className='obFormData'>
                    <form onSubmit={handleSubmit}>
                        <div className='login-form-input flex flex-c'>
                            <label htmlFor="name">What should we call you?</label>
                            {nameError && <p className="error-name-onb">{nameError}</p>}
                            <input
                                id="name"
                                name="name"
                                type="text"
                                placeholder='Type here'
                                onChange={handleOnboardingInputChange}
                                value={values.name}
                            />
                        </div>
                        <div className='login-form-input flex flex-c'>
                            <label htmlFor="timezone">Select your time zone</label>
                            <div className="select-wrapper">
                                <TimezoneSelect
                                id="timezone"
                                name="timeZone"
                                placeholder="Eg. GMT+5:30 Delhi, Mumbai, Kolkata"
                                onChange={handleTimezoneChange}
                                value={selectedTimezone}
                                />
                                {timezoneError && <p className="error-name-onb">{timezoneError}</p>}
                            </div>
                        </div>
                        <div className="obFromBtn">
                            <button className='primary-btn black' type="submit">Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ObStep1
