import actionTypes from './actionTypes';

const authReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.START_LOADING:
            return { ...state, loading: true, error: null };
        case actionTypes.SIGN_UP_SUCCESS:
            console.log(action.payload);
            return {
            ...state,
            loading: false,
            user: action.payload,
            token: action.payload.token,
            userRole: action.payload.role,
            error: null,
            };
        case actionTypes.ONBOARDING_MENTEE:
            return {...state, loading: false, error: null}
        case actionTypes.SIGN_IN_SUCCESS:
            return {...state, loading: false, user: action.payload, token: action.payload.token, userRole: action.payload.role, error: null}
        case actionTypes.DASHBOARD_MENTEE:
            return {...state, loading: false, dashboard: action.payload}
        case actionTypes.PROFILE_MENTEE:
            return {...state, loading: false, profile: action.payload}
        case actionTypes.UPDATE_PROFILE_MENTEE:
            return { ...state, loading: false, profile: { ...state.profile, ...action.payload } };
        case actionTypes.FORGOT_PASSWORD:
            return {...state, loading: false}
        case actionTypes.CHANGE_PASSWORD:
            return {...state, loading: false}
        case actionTypes.FAVORITE_MENTORS:
            return {...state, loading: false, favoriteMentor: action.payload}
        case actionTypes.REVIEWS_MENTORS:
            return {...state, loading: false, reviewsMentor: action.payload}
        case actionTypes.MENTOR_TRANS_HISTORY:
            return {...state, loading: false, mentorTransHitory: action.payload}
        case actionTypes.SESSION_DATA:
            return {...state, loading: false, sessionData: action.payload}
        case actionTypes.UPCOMING_SESSION_DATA:
                return {...state, loading: false, upcomingSessionData: action.payload}
        case actionTypes.COMPLETED_SESSION_DATA:
            return {...state, loading: false, completedSessionData: action.payload}
        case actionTypes.CANCELLED_SESSION_DATA:
            return {...state, loading: false, cancelledSessionData: action.payload}
        case actionTypes.CANCEL_REQUESTED_SESSION_DATA:
            return {...state, loading: false, cancelRequestedSessionData: action.payload}
        case actionTypes.REPORTED_SESSION_DATA:
            return {...state, loading: false, reportedSessionData: action.payload}
        case actionTypes.RESOURCES:
            return {...state, loading: false, resourcesData: action.payload}
        case actionTypes.RESOURCES_COMM:
            return {...state, loading: false, resourcesCommData: action.payload}
        case actionTypes.COMMUNITY:
            return {...state, loading: false, communityData: action.payload}    
        case actionTypes.MENTOR_BLOGS:
            return {...state, loading: false, mentorBlogs: action.payload}  
        case actionTypes.MENTOR_DRAFT_BLOGS:
            return {...state, loading: false, mentorDraftBlogs: action.payload}
        case actionTypes.MENTOR_PENDING_BLOGS:
            return {...state, loading: false, mentorPendingBlogs: action.payload}
        case actionTypes.MENTOR_PUBLISHED_BLOGS:
            return {...state, loading: false, mentorPublishedBlogs: action.payload}
        case actionTypes.MENTOR_REJECTED_BLOGS:
            return {...state, loading: false, mentorRejectedBlogs: action.payload}  
        case actionTypes.AVAILABILITY_STATUS:
            return {...state, loading: false, empty: action.payload}
        case actionTypes.COMMENT_ADD:
            return {...state, loading: false, empty: action.payload}
        case actionTypes.ADD_CLAP:
            return {...state, loading: false, empty: action.payload}
        case actionTypes.AVAILABILITY_EDIT:
            return {...state, loading: false, editCardAvaibility: action.payload}
        case actionTypes.AVAILABILITY_EDIT_DATA:
            return {...state, loading: false, availabilityData: action.payload}
        case actionTypes.MENTOR_EARNING:
            return {...state, loading: false, earningData: action.payload}
        case actionTypes.BLOG_LIST:
            return {...state, loading: false, blogData: action.payload}
        case actionTypes.COMMUNITY_LIST:
            return {...state, loading: false, commData: action.payload}
        case actionTypes.COMMUNITY_LIST_TOKEN:
            return {...state, loading: false, commLData: action.payload}        
        case actionTypes.BLOG_LISTING:
                return {...state, loading: false, blogLData: action.payload}    
        case actionTypes.MENTOR_EARNING_NOT_FOUND:
            return {...state, loading: false, earningData: null}
        case actionTypes.MENTOR_WITHDRAW:
            return {...state, loading: false, earningData: { ...state.earningData, ...action.payload }}
        case actionTypes.FAILURE:
            return { ...state, loading: false, error: action.payload};
        case actionTypes.LOGOUT:
            return { ...state, loading: false, error: null, user: null, token: null, userRole: null };
        case actionTypes.REMOVE_ERROR:
            return { ...state, loading: false, error: null };
        case actionTypes.MENTOR_BLOGS_EDIT:
            return {...state, loading: false, editBlogData: action.payload}   
        case actionTypes.PURCHASE_HISTORY_DATA:
                return {...state, loading: false, purchaseHistoryData: action.payload}
        case actionTypes.MENTEE_STATS:
                return {...state, loading: false, menteeStats: action.payload}
        case actionTypes.MENTOR_STATS:
                return {...state, loading: false, mentorStats: action.payload}
        default:
            return state;
    }
};

export default authReducer;
