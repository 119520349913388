import React, {useState, useContext, useEffect} from 'react';
// import './sessions.css';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../ErrorMessage';
import Loader from '../reusable/loading/Loader';
import DashSessionCard from '../reusable/session-cards/DashSessionCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SavedResourcesEmpty from './SavedResourcesEmpty';
import DashResourceCard from './DashResourceCard';
import DashCommunityCard from './DashCommunityCard';
import MentorSavedResourcesEmpty from './MentorSavedResourcesEmpty';
import NoSesh from '../../assets/images/no-sesh.svg';
import { Link } from 'react-router-dom';
import actionTypes from '../../context/actionTypes';

const MentorSavedResources = () => {

const { getSavedResources, state, dispatch, getSavedResourcesCoummunity } = useContext(AuthContext);

const handleLoadMore = async (pageNo) => {
    let res =await getSavedResources(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.resourcesData,
            ...res,
            result: [...state.resourcesData.result, ...res.result]
          };
        dispatch({ type: actionTypes.RESOURCES, payload: mergedData});
    }
}

const handleCommLoadMore = async (pageNo) => {
    let res =await getSavedResourcesCoummunity(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.resourcesCommData,
            ...res,
            result: [...state.resourcesCommData.result, ...res.result]
          };
        dispatch({ type: actionTypes.RESOURCES_COMM, payload: mergedData});
    }
}





// console.log("Saved Resources" , state.resourcesData)
return(
    <div className="fav-mentors-full dashboard-empty-state">
    {state.loading && <Loader/>}
        <div className="dash-empty-sessions-content">
            <div className="dash-empty-sess-head">Saved Resources</div>
            <div className="dash-empty-sess-body">
                <p>Check all your sessions for today, tomorrow and whenever here!</p>
            </div>              
        </div>
        <div className="dash-session-list">
            <div className='dsl-tabs'>
            <Tabs>
                <div className='dsltabs-tabs'>
                    <TabList>
                        {/* <Tab onClick={() => getSavedResources(1)}>blogs</Tab> */}
                        <Tab onClick={() => getSavedResourcesCoummunity(1)}>community posts</Tab>
                    </TabList>
                </div>
                <div className='dsltabs-data'>
                    
                    {/* <TabPanel>
                    {state?.resourcesData?.result?.length > 0 ? <>
                        {state?.resourcesData?.result && state?.resourcesData?.result?.map((data,index) => {
                            return (
                                <DashResourceCard get={getSavedResources} post={data} key={index} />
                            );
                        })}
                        {state?.resourcesData?.hasMorePages && <div onClick={() => handleLoadMore(state?.resourcesData?.currentPage)} className="favourite-mentors-list-button">
                            <a href="javascript:void(0)" className="isg-guide-load-more">load more</a>
                        </div>} 
                        </>
                        : 
                        <>
                         <div className="dash-emp-no-sess">
                            <div className="dash-emp-no-sess-wrap">
                                <div className="dash-emp-no-sess-img">
                                    <img src={NoSesh} alt="" />
                                </div>
                                <div className="dash-emp-no-sess-head">You have no saved blogs</div>
                                <div className="dash-emp-no-sess-body">
                                    <p>See your saved resources here in both "Blogs" & "Community"</p>
                                </div>
                            <div className="dash-emp-no-sess-btn">
                                <Link to={`/blogs`} className="primary-btn black">explore Blogs</Link>
                            </div>
                            </div>
                        </div>  
                        </> }
                    </TabPanel> */}
                    <TabPanel>
                    {state.resourcesCommData?.result?.length > 0 ? <>
                        {state.resourcesCommData?.result && state.resourcesCommData?.result?.map((data,index) => {
                            return (
                                <DashCommunityCard get={getSavedResources} post={data} key={index} />
                            );
                        })}
                         {state?.resourcesCommData?.hasMorePages && <div onClick={() => handleCommLoadMore(state?.resourcesCommData?.currentPage)} className="favourite-mentors-list-button">
                            <a href="javascript:void(0)" className="isg-guide-load-more">load more</a>
                        </div>}
                        </>
                        : 
                        <>
                        <div className="dash-emp-no-sess">
                            <div className="dash-emp-no-sess-wrap">
                                <div className="dash-emp-no-sess-img">
                                    <img src={NoSesh} alt="" />
                                </div>
                            <div className="dash-emp-no-sess-head">You have no saved community posts</div>
                            <div className="dash-emp-no-sess-body">
                                <p>See your saved resources here in both "Blogs" & "Community"</p>
                            </div>
                            <div className="dash-emp-no-sess-btn">
                                <Link to="/community" className="primary-btn black">explore Community</Link>
                            </div>
                            </div>
                        </div>  
                        </> }
                    </TabPanel>
                   
                </div>
            </Tabs>
            </div>
          
        </div>  

                
        
  </div>
  
  );

  
}

export default MentorSavedResources
