import React, {useRef, useLayoutEffect} from 'react';
import './about.css';
import { useInView } from "framer-motion";
import { gsap } from "gsap";

export default function AboutBanner({props}) {
    const ref = useRef(null);
    const comp = useRef();
    const isInView = useInView(ref, { once: true });  

    useLayoutEffect(() => {
  
        // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
      
          
          // Our animations can use selector text like ".box" 
          // this will only select '.box' elements that are children of the component
          gsap.to('.ab-image-1 img', {
            duration: 2.5,
            y: -26,
            x: -30,
            z: 20,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-2 img', {
            duration: 4,
            y: -10,
            x: 18,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-3 img', {
            duration: 3,
            y: 10,
            x: 10,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-4 img', {
            duration: 2,
            y: 20,
            x: -10,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-5 img', {
            duration: 2.5,
            y: -26,
            x: -30,
            z: 20,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-6 img', {
            duration: 4,
            y: -10,
            x: 18,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-7 img', {
            duration: 3,
            y: 10,
            x: 10,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-8 img', {
            duration: 2,
            y: 20,
            x: -10,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          gsap.to('.ab-image-9 img', {
            duration: 2,
            y: 20,
            x: -10,
            rotate: -1,
            repeat: -0.1,
            yoyo: true,
            ease: 'power1.inOut'
          });
          
    
        
      }, []);

    const data = props;
  return (
    <section ref={comp} className="about-banner">
        <div className="container">
            <div className="about-banner-content">
                <div className="about-banner-content-wrapper">
                    <div className="about-banner-heading">
                        <h2 ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} dangerouslySetInnerHTML={{ __html: data?.bannerHeading }}></h2>
                                    </div>
                    <div className="about-banner-body">
                        <p ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "0.9s"
        }}>{data?.bannerPara}</p>
                        </div>
                </div>
            </div>
            <div className="about-banner-images">
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-1">
                <img src={data?.bannerImg1} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-2">
                <img src={data?.bannerImg2} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-3">
                <img src={data?.bannerImg3} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-4">
                <img src={data?.bannerImg4} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-5">
                <img src={data?.bannerImg5} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-6">
                <img src={data?.bannerImg11} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-7">
                <img src={data?.bannerImg12} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-8">
                <img src={data?.bannerImg6} alt="" className="img" />
            </div>
            <div  style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.5s"
        }} className="about-banner-image ab-image-9">
                <img src={data?.bannerImg13} alt="" className="img" />
            </div>
        </div>
        </div>
    </section>
  )
}