import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NoSesh from '../../assets/images/no-sesh.svg';

const MentorSavedResourcesEmpty = () => {
  return (
    <div className="fav-mentors-empty dashboard-empty-state">
         <div className="dash-empty-sessions-content">
            <div className="dash-empty-sess-head">Saved Resources</div>
            <div className="dash-empty-sess-body"><p>Check all your sessions for today, tomorrow and whenever here.</p></div>
        </div>

        <div className="dash-session-list">
            <div className='dsl-tabs'>
                <div className='dsltabs-tabs'>
                    <ul>
                        <li>Blogs</li>
                        <li>Community Posts</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You have no saved blogs</div>
                <div className="dash-emp-no-sess-body">
                    <p>As a mentor, you haven't started your journey yet! Start taking sessions and sharing your expertise with mentees today</p>
                </div>
            <div className="dash-emp-no-sess-btn">
                <a href="#!" className="primary-btn black">explore Blogs</a>
            </div>
            </div>
        </div>       
   </div>
  )
}

export default MentorSavedResourcesEmpty
