import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation} from "swiper";
// import TrmCard from '../reusable/trm-card/TrmCard';
import PopularCards from './PopularCards';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import "./explore.css";

const NewMentors = ({props, scrollToSecondComponent}) => {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)

  return (
    <section>
        <div className='custom-container-fluid'>
            <div className="dash-empty-top-rated-mentors exploring">
                    <div className="dash-empty-sessions-content flex align flex-jsb">
                        <div className="dash-empty-sess-head">
                          <h3>New Mentors</h3>
                        </div>
                        <div className="dash-empty-sess-body">
                          <div className="dash-empty-trm-slider-btns flex align">
                            <div className="det-slider-prev" ref={navigationPrevRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="34" viewBox="0 0 29 34" fill="none">
                              <path d="M0.952342 20.9541L13.9584 33.9602L17.9071 30.0115L7.69056 19.798L7.70582 19.798L28.1514 19.7916L28.057 14.194L7.70606 14.2004L7.69385 14.2004L17.9168 3.97747L13.9691 0.0327773L0.954831 13.047L0.952342 20.9541Z" fill="#222222"/>
                            </svg>
                            </div>
                            <div className="det-slider-next" ref={navigationNextRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="34" viewBox="0 0 28 34" fill="none">
                              <path d="M27.9299 20.9541L14.9239 33.9602L10.9752 30.0115L21.1917 19.798L21.1764 19.798L0.730861 19.7916L0.825266 14.194L21.1762 14.2004L21.1884 14.2004L10.9655 3.97747L14.9132 0.0327773L27.9274 13.047L27.9299 20.9541Z" fill="#222222"/>
                            </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div className="dash-emp-trm-slider flex trmentors">
                        <div className="dash-emp-trm-slider-left">
                        <div className="dash-emp-trm-slider-left-wrap flex flex-c">
                            <div className="dash-emp-trm-slider-left-head">Our<br/> Best<br/> Batch</div>
                            <div className="dash-emp-trm-slider-left-dash"></div>
                            <div className="dash-emp-trm-slider-left-cont">
                                <div className="dash-emp-trm-slider-left-cont-body">
                                    <p>Discover all mentors
                                     across industries!</p>
                                </div>
                                <div className="dash-emp-trm-slider-left-cont-btn">
                                    <Link to="/explore-all-mentors"  className="primary-btn border">Dicover All Mentors</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="dash-emp-trm-slider-right">
                            <Swiper  modules={[Navigation]}  breakpoints={{
                                0:{
                                    slidesPerView: 1,
                                }, 
                                500:{
                                    slidesPerView: 2,
                                }, 
                                992:{
                                    slidesPerView: 3,
                                },                
                                1200: {
                                    slidesPerView: 4,
                                }
                            }}  slidesPerView={4} spaceBetween={13}  navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current,}}  onSwiper={(swiper) => {
                                // Delay execution for the refs to be defined
                                // setTimeout(() => {
                                // Override prevEl & nextEl now that refs are defined
                                swiper.params.navigation.prevEl = navigationPrevRef.current
                                swiper.params.navigation.nextEl = navigationNextRef.current

                                // Re-init navigation
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                                // })
                            }} className="mySwiper dash-top-rated-mentors-slider">
                                    {props?.map((posts,index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <PopularCards post={posts}/>
                                            </SwiperSlide>
                                        );
                                    })} 
                                
                            </Swiper>
                        </div>
                    </div>   
                </div>
        </div>
    </section>
  )
}

export default NewMentors
