import React from 'react';
import axios from 'axios';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import Homebanner from '../../components/home/Homebanner';
import Quote from '../../components/reusable/quote/Quote';
import ThreeCards from '../../components/home/three-cards';
import StatsSection from '../../components/home/stats-section';
import TestemonialsWithArrow from '../../components/home/testemonials-with-arrow';
import TopCatog from '../../components/reusable/top-categories/TopCatog';
import Testimonials from '../../components/testimonials/Testimonials';
import ExploreMentors from '../../components/reusable/explore-mentors/ExploreMentors';
import WhyChooseInsage from '../../components/home/why-choose-insage';
import Cookies from 'js-cookie';

export default function Homepage() {
  const [homeData, setHomeData] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("./home.json")
    .then((res) => setHomeData(res.data))
    .catch(err=>console.log(err))
  },[]);
  const userRole = Cookies.get('userRole');

  const [topMentorData, settopMentorData] = React.useState([]);
  React.useEffect(() => {
    const url = process.env.REACT_APP_API_URL+'/all-mentors'
    axios
    .get(url)
    .then((res) => settopMentorData(res.data))
    .catch(err=>console.log(err))
  },[]);


  return (
   
    <main className='hp-main'>
      <Header/>
      <Homebanner props={homeData}/>
      <Quote props={homeData.quoteOne} />
      <ThreeCards props={homeData.industryExperts} propsrole={userRole} />
      <StatsSection props={homeData.statsSection} />
      <TestemonialsWithArrow props={homeData.statsSection} />
      <WhyChooseInsage propsrole={userRole} />
      <TopCatog />
      <Testimonials/>
      <ExploreMentors topMentorData={topMentorData}/>
      <Quote props={homeData.quoteTwo}/>
      <Footer/>
    </main>
    
  )
}
