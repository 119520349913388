import React,{useContext, useEffect, useState} from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import Community_Post_Inner from '../../components/community/CommunityInner';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Loader from '../../components/reusable/loading/Loader';
import { AuthContext } from '../../context/AuthContext';
import actionTypes from '../../context/actionTypes';


export default function CommunityInner() {
     const { slug } = useParams();
     const navigation = useNavigate();
     let userRole = Cookies.get('userRole');

     const {state, dispatch } = useContext(AuthContext);

     const [loading, setLoading] = useState(true);
     const [commDetails, setCommDetails] = useState([]);
     const [error, setError] = useState(null);

     console.log('commDetails ', commDetails);

     const handleLikeDislike = async (communityId, reaction) => {

        const token = Cookies.get('token');
        try {
          const response = await axios.post(process.env.REACT_APP_API_URL+'/like-dislike-community', {
            communityId:communityId,
            reaction: reaction,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          setCommDetails(prevCommDetails => ({
            ...prevCommDetails,
            result: { ...prevCommDetails.result, ...response.data.result }
          }));
          
        } catch (error) {
          console.error('Error liking community:', error);
        }
      };
 
     useEffect(() => {
         setLoading(true);
 
         const token = Cookies.get('token');
 
         if(userRole){
             axios
                 .get(`${process.env.REACT_APP_API_URL}/detail-community/${slug}`, {
                     headers: {
                     Authorization: `Bearer ${token}`, // Include the token in the headers
                     },
                 })
                 .then((res) => {
                     setCommDetails(res.data);
                     setError(null); // Clear any previous errors
                 })
                 .catch((err) => {
                     setError(err);
                 })
                 .finally(() => {
                     // Set loading to false after the request is complete
                     setLoading(false);
                 });
 
         }else {
             axios
             .get(`${process.env.REACT_APP_API_URL}/detail-communities/${slug}`)
             .then((res) => {
                 setCommDetails(res.data);
                 setError(null); // Clear any previous errors
             })
             .catch(err => {
                 setError(err);
             })
             .finally(() => {
                 // Set loading to false after the request is complete
                 setLoading(false);
             });
         }
 
 
 
     }, [slug]);
 
     const posts = commDetails?.result;
    //  console.log('This is the post details', posts);
 
     // Redirect to 404 page if no results
     useEffect(() => {
         // Redirect to 404 page if no results
         if (!loading && !posts) {
             navigation.replace(`*`); // Replace the current route with the 404 route
         }
     }, [loading, posts, navigation, slug]);
  return (
    <main>
        <Header/>
        {loading && <Loader />}
        <Community_Post_Inner props={posts} handleLikeDislike={handleLikeDislike} commDetails={commDetails} setCommDetails={setCommDetails}/>
        <Footer/>
    </main>
  )
}
