import React, { useContext, useState } from 'react';
import '../Dashboard/dashboardPopup.css';
import MultiSelectTags from '../reusable/multi-select/multiSelect';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function MentorReportSessionPopup ({ data, noti, onclose }) {

    const [loading, setLoading] = useState(false);
    const [reportCategory, setCancelReason] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState('');

    const handleOpenNewPopup = (event) => {
        event.preventDefault();
        setLoading(true);
        const userToken = Cookies.get('token');
        let resourceId = data.bookingId; 
        let reportType = 'Session';
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/report/resource`,
            {
              resourceId,
              reportType,
              reportCategory,
              additionalDetails
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {
        
            if(res){
              setLoading(false);
              noti();
              onclose();
              
            }
          })
          .catch((err) => {
           
          })
          .finally(() => {
            setLoading(false);
          });
      };
      const handleReasonChange = (event) => {
        setCancelReason(event.target.value);
      };
    
      const handleDetailsChange = (event) => {
        setAdditionalDetails(event.target.value);
      };

    return (
        <div className="insage-login dashboard-popups">
            <div className="insage-login-wrapper">
                <div className="login-head"><span>report session</span></div>
                <div className='popup-description'>
                    <p className='pd-para'>Mentorship session with <span>{data?.mentorName}</span></p>
                </div>
                <div className='pd-datetime'>
                    <div class="pd-date">
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" stroke-linecap="round" strokeLinejoin="round"></path><path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"></path></svg></span>
                        {data?.sessionDate}</div>
                    <div class="pd-time">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><g clip-path="url(#clip0_5004_40300)"><path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"></path><path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"></path></g><defs><clipPath id="clip0_5004_40300"><rect width="12" height="12" fill="white" transform="translate(0 0.5)"></rect></clipPath></defs></svg>
                        </span>
                        <div class="timer">{data?.startTime} - {data?.endTime} <span class="duration">{data?.duration}</span></div>
                    </div>
                </div>

                <div className="overview-profile-pop-form">
                    <form action="post">
                        <div className='login-form-input flex flex-c'>
                                <label htmlFor="industry">Select a category 
                                
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="#555555" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5 10.1V7.5" stroke="#555555" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5 4.89844H7.5065" stroke="#555555" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </label>
                                <div className="select-wrapper">
                                    <select
                                    id="industry"
                                    name="industry"
                                    value={reportCategory}
                                    onChange={handleReasonChange}
                                    required
                                    >
                                    <option value="" disabled>Select reason</option>
                                    <option value="Art & Design">Art & Design</option>
                                    <option value="Coding & Dev">Coding & Dev</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="IT & Software">IT & Software</option>
                                    <option value="Personal Development">Personal Development</option>
                                    <option value="Business & Sales">Business & Sales</option>
                                    <option value="Photography">Photography</option>
                                    <option value="Management">Management</option>
                                    <option value="Other">Other</option>
                                    </select>
                                </div>
                        </div>
                        <div className='login-form-input flex flex-c'>
                            <label className='flex bio-label align' htmlFor="aboutMe">Please mention additional details for reporting</label>
                            <textarea id='aboutMe' name='bio' placeholder={'Type here'} value={additionalDetails} onChange={handleDetailsChange} />
                        </div>
                        <div className="overview-profile-pop-btn">
                            <button className='popup-btn cancel-btn full-width' onClick={handleOpenNewPopup}>
                            {loading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                                  ) : (
                                  'Report'
                                  )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="login-close-button" onClick={onclose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}

