import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './BlogInner.css';
import '../blogs/blogs.css';
import noImageAvailable from '../../assets/images/no-image-available.png'; 

function Similarblogs({props}) {

function limitAndAppend(text, limit) {
    if (text.length > limit) {
        return text.substring(0, limit) + "...";
    } else {
        return text;
    }
    }

const { slug } = useParams();

  return (
    <div className="blogInnersim">
        <div className="blog-container">
            <div className="bis-wrapper">
                <div className="bis-head">
                    <h3>Similar Blogs</h3>
                </div>
                <div className="similar-blogs">
                {props?.map((posts,index) => {
                        return (
                            <div className="item" key={index}>
                                <Link to={`/blogs/${posts.slug}`}>
                                    <div className="img-wrapper">
                                        <img src={posts?.image ? posts?.image : noImageAvailable} alt=""/>
                                    </div>
                                    <div className="blog-desp">
                                        <div className="auth-cat">
                                            <div className="auth-img-name">
                                                <div className="auth-img">
                                                    <img src={posts?.avatar} />
                                                </div>
                                                <div className="auth-name">
                                                    <p>{posts?.authorName}</p>
                                                </div>
                                            </div>
                                            <div className="cat-name"><p>{posts?.categoryName}</p></div>
                                        </div>
                                        <div className="blog-title">
                                            <h5>{posts?.title}</h5>
                                        </div>
                                        <div className="blog-para">
                                            <div dangerouslySetInnerHTML={{ __html: limitAndAppend(posts?.content, 150) }}></div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                        );
                    })}
                    
                </div>
            </div>
        </div>
    </div>
  );
}

export default Similarblogs;