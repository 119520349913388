import React, {useEffect, useContext, useState} from 'react';
import './community.css'; 
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import TrendingCommunity from '../../components/community/Trending';
import Community_Post from '../../components/community/CommunityPost';
import PostCommunity from '../../components/community/PostCommunity';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../../components/reusable/loading/Loader';
import Cookies from 'js-cookie';
import axios from "axios"
import actionTypes from '../../context/actionTypes';

export default function Community() {

  const { getCommunityListFilter, getCommunityListFilterAuth, state, dispatch } = useContext(AuthContext);
  let userRole = Cookies.get('userRole');
  const [displayLoadMore, setDisplayLoadMore] = useState(false);

  const [trandingPost , setTrandingPost] = useState([]);

  const getTrandingPost  = async (communityId, reaction) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+'/community/list-all-trending-posts');

      // console.log('response ', response.data);

      if(response.data.success){
        setTrandingPost(response.data.result)
      }else{
        setTrandingPost([])
      }
      
    } catch (error) {
      console.error('Error liking community:', error);
    }
  };

  const fetchCommunityPosts = async (userData, page, sortBy) => {
    let res;
    let sortByNew = sortBy === 'newest' ? 'asc' : 'desc'
    try {
      if(userRole){
        res = await getCommunityListFilterAuth(userData, page, sortByNew);
        if(typeof res === 'object'){
          let mergedData = {
            ...state.commData,
            ...res,
            result: [ ...state.commData.result, ...res.result]
          };

          // console.log('mergedData ', mergedData);
          dispatch({ type: actionTypes.COMMUNITY_LIST, payload: mergedData});
        }
    }else{
        res = await getCommunityListFilter(userData, page, sortByNew);
        if(typeof res === 'object'){
          let mergedData = {
            ...state.commData,
            ...res,
            result: [ ...state.commData.result, ...res.result]
          };

          // console.log('mergedData ', mergedData);
          dispatch({ type: actionTypes.COMMUNITY_LIST, payload: mergedData});
        }
    }
        return res;

    } catch (error) {
        console.log(error);
    }
  }


  // const url = process.env.REACT_APP_API_URL+`/${urlPost}/list-all-posts`;
    useEffect(() => {
        fetchCommunityPosts({}, 1, 'newest');
        getTrandingPost();
    }, []);

    useEffect(() => {
      setDisplayLoadMore(state?.commData?.hasMorePages);
    }) 

    const commData = state?.commData?.result;


    

    // console.log('commData ', commData);
    // const commLData = state?.commLData;
  //  console.log("Now this is community", commData)  
  return (
    <main className=''>
        <div className='blogs-all-wrapper'>
        <Header/>
          <div className='blog-container'>
             {userRole === "mentor" || userRole === "mentee" ? <TrendingCommunity props={trandingPost}/> : <TrendingCommunity props={trandingPost}/> }
              <PostCommunity/>
              {state.loading && <Loader/>}
              {userRole === "mentor" || userRole === "mentee" ? <Community_Post props={state?.commData?.result} fetchCommunityPosts={fetchCommunityPosts} displayLoadMore={displayLoadMore}/> : <Community_Post props={state?.commData?.result} fetchCommunityPosts={fetchCommunityPosts} displayLoadMore={displayLoadMore}/> }
          </div>
          <Footer/>
        </div>
    </main>
  )
}
