import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NoSesh2 from '../../assets/images/no-sesh.svg';
import Girl from '../../assets/images/messageIb.png';
import '../guides/guides.css';
import './profile.css';
import { ChatContext } from '../../context/ChatContext';
import UserChatProfile from './UserChatProfile';
import { AuthContext } from '../../context/AuthContext';
import ChatBox from './ChatBox';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


export default function Messages() {

    const [tabIndex , setTabIndex] = useState(0);
    const [clickChatRoom , setClickChatRoom] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const {state} = useContext(AuthContext);
    const { userChats, isUserChatLoading,scrollToBottom, userChatsErr, getMentors,UpdatePotentialUserList, isMentorListLoading, potentialChats, createChat, updateCurrentChat, onlineUsers } = useContext(ChatContext);
// console.log('isUserChatLoading ', isUserChatLoading);
// console.log('userChats ', userChats);
// console.log('potentialChats?.length ', potentialChats?.length);

const handleChatRoom = async (userId, mentorId) => {
    setClickChatRoom(false);
    await createChat(userId, mentorId)
    UpdatePotentialUserList(potentialChats, mentorId);
    setClickChatRoom(true);
    setTabIndex(0);
}

const handleUpdateCurrentChat = (chat) => {
    updateCurrentChat(chat);
    scrollToBottom();
}

const placeholderName = state.userRole === 'mentee' ? 'Search Mentor' : 'Search Mentee';
 
    return (
    <div className="dashboard-messages">
        <div className="dashboard-messages-wrap">
            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <div className="dashboard-messages-left">
                    <div className="dashboard-messages-head dash-empty-sess-head">
                    Messages
                    </div>
                    <div className="dashboard-messages-tabs insage-guides">
                        <div className="insage-guides-tab-buttons">
                            <TabList>
                                <Tab>Inbox</Tab>
                                {state.userRole === 'mentee' && <Tab onClick={getMentors}>Mentors</Tab>}
                            </TabList>
                        </div>
                        <div className="dashboard-messages-tabs-content">
                                <TabPanel>
                                    <div className="dashboard-messages-inbox-active">
                                        <div className="dashboard-messages-inbox-search">
                                            <div>
                                            <div className="dashboard-messages-inbox-search-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M5.92658 10.8547C3.19801 10.8547 1 8.65636 1 5.92736C1 3.19836 3.19801 1 5.92658 1C8.65515 1 10.8532 3.19836 10.8532 5.92736C10.8532 8.65636 8.65515 10.8547 5.92658 10.8547ZM5.92658 1.75806C3.61488 1.75806 1.75794 3.61529 1.75794 5.92736C1.75794 8.23943 3.61488 10.0967 5.92658 10.0967C8.23828 10.0967 10.0952 8.23943 10.0952 5.92736C10.0952 3.61529 8.23828 1.75806 5.92658 1.75806Z" fill="#24584B" stroke="#24584B" strokeWidth="0.5"/>
                                                <path d="M9.59836 9.05859L13.0015 12.4623L12.4656 12.9982L9.0625 9.59454L9.59836 9.05859Z" fill="#24584B" stroke="#24584B" strokeWidth="0.5"/>
                                                </svg>
                                            </div>
                                            <input type="search" placeholder={placeholderName} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                                            </div>
                                        </div>
                                        {userChats?.length < 1 ? (<div className="dashboard-messages-inbox-empty">
                                        <p>Your inbox seems empty, conversations you start will appear here.</p>
                                    </div>) : (
                                        <div className="dashboard-messages-inbox-people">
                                             {isUserChatLoading ? (
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                ) :(
                                            <div className="dashboard-messages-inbox-peep">
                                            {userChats && userChats?.filter(chat => {
                                                    const query = searchQuery?.toLowerCase();
                                                    if (!query) return true;
                                                    const includesQuery = chat.fullName.toLowerCase().includes(query);
                                                    return includesQuery;
                                                })
                                                ?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                                                ?.map((chat, index) => {
                                                return (
                                                    <div onClick={() => handleUpdateCurrentChat(chat)} className="dashboard-messages-inbox-peep-wrap" key={index}>
                                                        <UserChatProfile chat={chat} user={state.profile} onlineUsers={onlineUsers}/>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                            )}
                                        </div>
                                        )}
                                        
                                    </div>
                                </TabPanel>
                                {state.userRole === 'mentee' && <TabPanel>
                                    {potentialChats?.length >= 1 ? potentialChats.map((user , index) => {
                                        // console.log('state.profile.userId', state?.profile?.userId);
                                        return <div className="dashboard-messages-inbox-peep-wrap" key={index} onClick={() => clickChatRoom && handleChatRoom(state.profile.userId, user.mentorId)}>
                                            <div className="dashboard-messages-inbox-peep-img">
                                                <img src={user?.avatar} alt="avatar" />
                                                <div className={onlineUsers?.some((u) => u.userId === user.mentorId ) ? 'online-offline-dot online' : 'online-offline-dot'}></div>
                                            </div>
                                            <div className="dashboard-messages-inbox-peep-details">
                                                <div className="dashboard-messages-inbox-peep-name">{user?.fullName}</div>
                                                <div className="dashboard-messages-inbox-peep-desig">{user?.designation}</div>
                                            </div>
                                        </div>
                                    }) :<> <div className="no-mentor-found">No Mentor Found</div> </>}
                                </TabPanel> }
                        </div>
                    </div>
                </div>
                <div className="dashboard-messages-right">
                    <ChatBox/>
                    <div className="dashboard-messages-right-mentors-tab"> 
                        <div className="dashboard-messages-inbox-right-empty">
                        <div className="dash-emp-no-sess">
                    <div className="dash-emp-no-sess-wrap">
                        <div className="dash-emp-no-sess-img">
                            <img src={NoSesh2} alt="" />
                        </div>
                        <div className="dash-emp-no-sess-head">You haven’t favorited any mentors</div>
                        <div className="dash-emp-no-sess-body">
                            <p> Don't worry, our experienced mentors are
                                available to connect with you and help you grow.</p>
                        </div>
                        <div className="dash-emp-no-sess-btn">
                            <a href="#!" className="primary-btn black">explore mentors</a>
                        </div>
                    </div>
                </div>      
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    </div>
  )
}
