import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import Cookies from 'js-cookie';
import CreateBlog from './CreateBlog';

function ProtectedBlogCreate() {
  const { isLoggedIn, user } = useContext(AuthContext);
  let userRole = Cookies.get('userRole');
  const navigate = useNavigate();

  // Check if the user is logged in and has the role of "mentor"
  const isMentor = userRole === 'mentor';

  // If the user is not logged in or doesn't have the "mentor" role, redirect them
  if (!isMentor) {
    // You can redirect to a login page or show an unauthorized message
    navigate('*');
    // Alternatively, you can render an unauthorized message:
    // return <div>You are not authorized to access this page.</div>;
    return null;
  }

  // If the user is logged in and has the "mentor" role, render the component
  return <CreateBlog />;
}

export default ProtectedBlogCreate;