import React, { useState,useEffect,useRef } from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import BlogInnerhead from '../../components/bloginner/BlogInner-head';
import BlogInnerimg from '../../components/bloginner/BlogInner-img';
import BlogInnertxt from '../../components/bloginner/BlogInnertxt';
import BlogInnercomment from '../../components/bloginner/blogInner-comment';
import Similarblogs from '../../components/bloginner/blogInnersim';
import BlogCreate from '../../components/blogs/BlogCreate';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; 
import Cookies from 'js-cookie';
import Loader from '../../components/reusable/loading/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import ReportPop from '../../components/bloginner/ReportPop';
import CommPop from '../../components/bloginner/CommPop';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BlogInnerPage() {

    const { slug } = useParams();
    const navigation = useNavigate();
    const[reportPop, setReportPop] = useState(false);
    const[commPop, setCommPop] = useState(false);
    const [repostdata, setReportData] = useState({});
    const[selectedData, setSelectedData] = useState(false);

    let userRole = Cookies.get('userRole');

    const [loading, setLoading] = useState(true);
    const [blogDetails, setBlogDetails] = useState([]);
    const [error, setError] = useState(null);


    const fetchBlogDetails = (page=1) => {
        setLoading(true);

        const token = Cookies.get('token');

        if(userRole){
            axios
                .get(`${process.env.REACT_APP_API_URL}/bloging/${slug}?page=${page}`, {
                    headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the headers
                    },
                })
                .then((res) => {
                    setBlogDetails(res.data);
                    setError(null); // Clear any previous errors
                    setLoading(false);
                })
                .catch((err) => {
                    setError(err);
                    setLoading(false);
                })
                .finally(() => {
                    // Set loading to false after the request is complete
                    setLoading(false);
                });

        }else {
            axios
            .get(`${process.env.REACT_APP_API_URL}/blog/${slug}?page=${page}`)
            .then((res) => {
                setBlogDetails(res.data);
                setError(null); // Clear any previous errors
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            })
            .finally(() => {
                // Set loading to false after the request is complete
                setLoading(false);
            });
        }
    }


    // console.log('blogDetails ', blogDetails);

    useEffect(() => {
 
        fetchBlogDetails(1);

    }, [slug]);

    const posts = blogDetails?.result;

    // Redirect to 404 page if no results
    useEffect(() => {
        // Redirect to 404 page if no results
        if (!loading && !posts) {
            navigation.replace(`*`); // Replace the current route with the 404 route
        }
    }, [loading, posts, navigation, slug]);


    const openReportPop = (data)=>{
        setReportPop(true);
        setSelectedData(data);
        document.body.classList.add('popup-open');
    }
    const closeReportPop = ()=>{
        setReportPop(false);
        document.body.classList.remove('popup-open');
    }
    const openCommPop = ()=>{
        setCommPop(true);
        document.body.classList.add('popup-open');
    }
    const closeCommPop =()=>{
        setCommPop(false);
        document.body.classList.remove('popup-open');
    }
    const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Comment Posted for Review Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

      const notifyC = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Link Copied Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

      const notifyRe = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Comment Reported Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

      const notifyB = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Warning</span>
            </div>
            <div className="custom-toast-bot">
              <span>You need to log in first!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

    return (
        <main className='isg-profile-main'>
            <Header/>
            <div className='blog-in-design'>
                
                <div className='left-line'>
                    {loading && <Loader />} 
                    {error && <ErrorMessage errorData={error} />}
                    {!loading && ( 
                        <>
                            <BlogInnerimg props={posts?.image} />
                            <div className='container'>
                                <BlogInnerhead props={posts} />
                                <BlogInnertxt props={posts} noti={notifyC} notifyB={notifyB}/>
                                <BlogInnercomment props={posts} openR={openReportPop} openC={openCommPop} noti={notify} setBlogDetails={setBlogDetails} blogDetails={blogDetails}/>
                                {userRole === "mentor" ? <><BlogCreate props={posts} /> <div className='emptyspacer'></div></>: ""}
                            {posts?.similarBlogs && <Similarblogs props={posts?.similarBlogs} />}
                            </div>
                            
                        </>
                    )}
                </div>
                
            </div>
            <div className={`login-sign-up-pop-up flex flex-c flex-jsc report-pop-blog ${ reportPop ? 'active' : ''}`}>
                <ReportPop data={selectedData} closeR={closeReportPop} noti={notifyRe} />
            </div>
            <div className={`login-sign-up-pop-up flex flex-c flex-jsc comm-pop-blog ${ commPop ? 'active' : ''}`}>
                <CommPop closeC={closeCommPop} />
            </div>
            <Footer/>
        </main>
    );
  }
  
  export default BlogInnerPage;
