import React , { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Header.css';
import Login from '../../components/reusable/login/Login';
import LoginMentor from '../../components/reusable/login-mentor/Login'
import SignUp from '../../components/reusable/signup/SignUp';
import SignUpMentor from '../../components/reusable/signup-mentor/SignUp'
import ForgotPassword from '../../components/reusable/forgot-password/forgotPassword';
import ForgotPasswordMentor from '../../components/reusable/forgot-password/forgotPassword';
import ChangePassword from '../../components/reusable/change-password/changePassword';
import ChangePasswordMentor from '../../components/reusable/change-password/changePassword';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ChatContext } from '../../context/ChatContext';
import 'react-toastify/dist/ReactToastify.css';
import actionTypes from '../../context/actionTypes';

// dispatch({ type: actionTypes.REMOVE_ERROR});

export default function Header() {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isChangeOpen, setChangeOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setDropdownOpen3] = useState(false);
  const [isDdOpen, setisDdOpen] = useState(false);
  const [isDdOpen2, setisDdOpen2] = useState(false);
  const [isDdOpen3, setisDdOpen3] = useState(false);
  const [isPdOpen, setisPdOpen] = useState(false);

  const [isOpenMentor, setIsOpenMentor] = useState(false);
  const [isSignUpOpenMentor, setSignUpOpenMentor] = useState(false);
  const [isForgotOpenMentor, setForgotOpenMentor] = useState(false);
  const [isChangeOpenMentor, setChangeOpenMentor] = useState(false);




  const {handleTabIndex} = useContext(ChatContext);

  const { logout, state, dispatch, getUserProfileInfo, getMentorUserProfileInfo } = useContext(AuthContext);

  let token = Cookies.get('token');
  let userRole = Cookies.get('userRole');
  let flag = Cookies.get('flag') && JSON.parse(Cookies.get('flag'));
  let userDetail = Cookies.get('userDetail') && JSON.parse(Cookies.get('userDetail'));

  // console.log('userDetail ', userDetail?.flag);

  // useEffect(() => {
  //   // Check if the profile identifier exists in the cookie
  //   const profileExists = Cookies.get('profile');
  //   const userDetail = Cookies.get('userDetail');

  //   console.log('profileExists ', profileExists);
  //   console.log('token ', token);

  //   if(userRole === 'mentee' && userDetail.flag ==6 ){
  //     getUserProfileInfo();
  //   }

  //   if (token && typeof profileExists !== 'undefined') {
  //     if (!profileExists) {
  //       if (userRole === 'mentee') {
  //         getUserProfileInfo();
  //       } else {
  //         getMentorUserProfileInfo();
  //       }
  //     }
  //   }

  // }, [token]);
  

  const navigate = useNavigate();
  const handleLoginClick = () => {
    setIsOpen(true);
    setSignUpOpen(false);
    document.body.classList.add('popup-open');
  };

  const handleLogout = async () => {
    await logout();
  }
  const handleTabSelect = () =>{
    if (userRole === 'mentee'){
      handleTabIndex(2);
    }
    if (userRole === 'mentor'){
      handleTabIndex(3);
    }
    navigate(`/${userRole}/dashboard`);
  }
  const handleCloseLoginPopup = () =>{
    setIsOpen(false);
    document.body.classList.remove('popup-open');
  }


  const handleCloseLoginPopupMentor = () =>{
    setIsOpenMentor(false);
    document.body.classList.remove('popup-open');
  }
  const handleOpenSignUpMentor = () => {
    setSignUpOpenMentor(true);
    setIsOpenMentor(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseSignUpMentor = () => {
    setSignUpOpenMentor(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleOpenForgotMentor = () => {
    setForgotOpenMentor(true);
    setIsOpenMentor(false);
    setSignUpOpenMentor(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseForgotMentor = () => {
    setForgotOpenMentor(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  }; 
  const handleOpenChangeMentor = () => {
    setChangeOpenMentor(true);
    setIsOpenMentor(false);
    setSignUpOpenMentor(false);
    setForgotOpenMentor(false);
  };
  const handleCloseChangeMentor = () => {
    setChangeOpenMentor(false);
    document.body.classList.remove('popup-open');
  };

  const handleLoginClickMentor = () => {
    setIsOpenMentor(true);
    setSignUpOpenMentor(false);
    document.body.classList.add('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };

  // const handleDropdown = () => {
  //   setDropdownOpen(true);
  // };
  const handleDropdown = event => {
    event.preventDefault();
    setDropdownOpen(current => !current);
    setDropdownOpen1(false);
  setDropdownOpen2(false);
  setDropdownOpen3(false);
    setisDdOpen(current => !current);
  };
  const handleDropdown1 = event => {
    event.preventDefault();
    setDropdownOpen(false);
  setDropdownOpen1(current => !current);
  setDropdownOpen2(false);
  setDropdownOpen3(false);
  };
  const headerRef = useRef(null);
  // ... other state and functions ...

  useEffect(() => {
    // Add an event listener to the document to handle clicks outside of the header
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        // Click is outside the header, close the dropdown
        setDropdownOpen(false);
        setDropdownOpen1(false);
        setDropdownOpen2(false);
        setDropdownOpen3(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleDropdown2 = event => {
    setDropdownOpen2(current => !current);
    setDropdownOpen(false);
  setDropdownOpen1(false);
  setDropdownOpen3(false);
    setisDdOpen2(current => !current);
  };
  const handleDropdown3 = event => {
    setDropdownOpen(false);
  setDropdownOpen1(false);
  setDropdownOpen2(false);
    setDropdownOpen3(current => !current);
    setisDdOpen3(current => !current);
  };
  const handleSearchClick = () => {
    setIsSearchActive(!isSearchActive);
  };
  const handleOpenSignUp = () => {
    dispatch({ type: actionTypes.REMOVE_ERROR});
    setSignUpOpen(true);
    setIsOpen(false);
  };
  const handleCloseSignUp = () => {
    dispatch({ type: actionTypes.REMOVE_ERROR});
    setSignUpOpen(false);
    document.body.classList.remove('popup-open');
  };
  const handleOpenForgot = () => {
    dispatch({ type: actionTypes.REMOVE_ERROR});
    setForgotOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
  };
  const handleCloseForgot = () => {
    
    setForgotOpen(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleOpenChange = () => {
    setChangeOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
    setForgotOpen(false);
  };
  const handleCloseChange = () => {
    setChangeOpen(false);
    document.body.classList.remove('popup-open');
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
    console.log('isActive:', isActive);
    if (isActive) {
      document.body.classList.remove('popup-open');
    } else {
      document.body.classList.add('popup-open');
    }
      setIsHeaderActive(true);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  // Define your conditions based on the current path
  const isBecomeMentor = currentPath === '/become-a-mentor';

  const isDashBoard = currentPath === `/${state.userRole}/dashboard`;

  // Add more conditions as needed

  // console.log('dashboard ', dashboard);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const shouldHeaderBeActive = scrollPosition > 0; // Customize the scroll position threshold as needed
      setIsHeaderActive(shouldHeaderBeActive);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    const notify = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Email Sent Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );
    const notify2 = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Password Changed Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );
  return (
    <section className='header-wrapper'> 
     
        <header className={`header ${isHeaderActive ? 'active' : ''} `} ref={headerRef}>
        <div className="custom-container-fluid">
          <div className="header-nav flex align flex-jsb">
            <div className="header-left">
              <ul className='header-left-nav flex align'>
                <li className='logo-name'>
                  <a href="/">
                    <span>
                      <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23 10.4733H15.1613L20.7034 4.91143L18.5602 2.75781L11.5 9.84322L4.43983 2.75781L2.29665 4.91143L7.83875 10.4733H0V13.5176H7.83875L2.29665 19.0795L4.43983 21.2331L11.5 14.1477L18.5602 21.2331L20.7034 19.0795L15.1613 13.5176H23V10.4733Z" fill="#FEC14B"/>
                      <path d="M11.5053 4.22884C12.669 4.22884 13.6122 3.28225 13.6122 2.11442C13.6122 0.946585 12.669 0 11.5053 0C10.3416 0 9.39844 0.946585 9.39844 2.11442C9.39844 3.28225 10.3416 4.22884 11.5053 4.22884Z" fill="#FEC14B"/>
                      <path d="M11.5053 24.0023C12.6689 24.0023 13.6122 23.0556 13.6122 21.8879C13.6122 20.7201 12.6689 19.7734 11.5053 19.7734C10.3417 19.7734 9.39844 20.7201 9.39844 21.8879C9.39844 23.0556 10.3417 24.0023 11.5053 24.0023Z" fill="#FEC14B"/>
                    </svg>
                    </span>
                    Insage</a>
                  </li>
                <li className='header-left-dropdown flex align'>
                  <a className={`label ${ isDropdownOpen ? 'active' : ''}`} href onClick={handleDropdown}>Platform
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.35938 1.16406L5.03798 4.84267L8.71658 1.16406" stroke="#222222" strokeWidth="1.47144"/>
                    </svg>
                    </a>
                    <ul className={`dropdown ${ isDropdownOpen ? 'active' : ''}`} >
                      <li><Link to="/platform/how-it-works" className="label">How it works</Link></li>
                      <li><Link to="/platform/features" className="label">Features</Link></li>
                      <li><Link to="/platform/usecases" className="label">Usecases</Link></li>
                    </ul>
                  </li>
                  
                  {/* Render other components based on conditions */}
                {userRole === undefined ?   
                <li className='header-left-dropdown flex align'>
                  <Link to="/explore-our-mentors" className="label">explore our mentors</Link>
                </li>
                 : ""
                }
                {userRole === undefined ?   
                <li className='header-left-dropdown flex align'>
                  <Link to="/become-a-mentor" className="label">Join as Mentor</Link>
                </li>
                 : ""
                }
             {!isDashBoard && userRole !== undefined && (
                <li className="header-left-dropdown flex align">
                  <button onClick={handleTabSelect} className="label">
                    upcoming sessions
                  </button>
                </li>
              )}
                
                {userRole === undefined ? ""  
                : <li className='header-left-dropdown flex align'>
                <a className={`label ${ isDropdownOpen1 ? 'active' : ''}`} href onClick={handleDropdown1}>Resources
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.35938 1.16406L5.03798 4.84267L8.71658 1.16406" stroke="#222222" strokeWidth="1.47144"/>
                  </svg>
                  </a>
                  <ul className={`dropdown ${ isDropdownOpen1 ? 'active' : ''}`} >
                    <li><Link to="/community" className="label">Community</Link></li>
                    <li><Link target='_blank' to="https://blog.insage.co/" className="label">blogs</Link></li>
                  </ul>
                </li>
                }
              </ul>
            </div>
            <div className="header-right">
              <ul className='header-right-nav flex align'>
                {/* <li className={`header-search ${isSearchActive ? 'active' : ''}`}>
                    <svg  onClick={handleSearchClick} width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.906 24.3157C5.31189 24.3157 0 19.003 0 12.4078C0 5.81273 5.31189 0.5 11.906 0.5C18.5 0.5 23.8119 5.81273 23.8119 12.4078C23.8119 19.003 18.5 24.3157 11.906 24.3157ZM11.906 2.33198C6.31932 2.33198 1.83169 6.82032 1.83169 12.4078C1.83169 17.9954 6.31932 22.4837 11.906 22.4837C17.4926 22.4837 21.9802 17.9954 21.9802 12.4078C21.9802 6.82032 17.4926 2.33198 11.906 2.33198Z" fill="#222222"/>
                      <path d="M20.7794 19.9766L29.0036 28.2021L27.7086 29.4973L19.4844 21.2718L20.7794 19.9766Z" fill="#222222"/>
                    </svg>
                    <input placeholder="Search" className='search-input'/>
                </li> */}
                {isBecomeMentor ? null : (
  userRole === undefined ? (
    // <li className='header-link'>
    //   <Link to="/become-a-mentor" className="label">Mentor Login</Link>
    // </li>
    <button onClick={handleLoginClickMentor} className="primary-btn black">
    Mentor Login
  </button>
  ) : null
)}
                <li className='header-btn'>
                  {token && userRole ? (
                    // <button onClick={handleLogout} className="primary-btn border"></button>dashboard
                    userRole == 'mentee' && flag == 6 ? <div className='loggedinProfile'>
                    <Link to={`/${state.userRole}/dashboard`} className="primary-btn border">
                    Dashboard 
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <path d="M11 12.625V11.375C11 10.712 10.7366 10.0761 10.2678 9.60723C9.79893 9.13839 9.16304 8.875 8.5 8.875H3.5C2.83696 8.875 2.20107 9.13839 1.73223 9.60723C1.26339 10.0761 1 10.712 1 11.375V12.625" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.99939 6.375C7.3801 6.375 8.49939 5.25571 8.49939 3.875C8.49939 2.49429 7.3801 1.375 5.99939 1.375C4.61868 1.375 3.49939 2.49429 3.49939 3.875C3.49939 5.25571 4.61868 6.375 5.99939 6.375Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </span>
                    </Link>
                  </div> :  userRole == 'mentor' && flag == 10 ?
                    <div className='loggedinProfile'>
                    <Link to={`/${state.userRole}/dashboard`} className="primary-btn border">
                    Dashboard 
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <path d="M11 12.625V11.375C11 10.712 10.7366 10.0761 10.2678 9.60723C9.79893 9.13839 9.16304 8.875 8.5 8.875H3.5C2.83696 8.875 2.20107 9.13839 1.73223 9.60723C1.26339 10.0761 1 10.712 1 11.375V12.625" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.99939 6.375C7.3801 6.375 8.49939 5.25571 8.49939 3.875C8.49939 2.49429 7.3801 1.375 5.99939 1.375C4.61868 1.375 3.49939 2.49429 3.49939 3.875C3.49939 5.25571 4.61868 6.375 5.99939 6.375Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </span>
                    </Link>
                  </div> : userRole == 'mentee' ? <div className='loggedinProfile'>
                    <Link to={`/${state.userRole}/onboarding`} className="primary-btn border">
                    Onboarding 
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <path d="M11 12.625V11.375C11 10.712 10.7366 10.0761 10.2678 9.60723C9.79893 9.13839 9.16304 8.875 8.5 8.875H3.5C2.83696 8.875 2.20107 9.13839 1.73223 9.60723C1.26339 10.0761 1 10.712 1 11.375V12.625" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.99939 6.375C7.3801 6.375 8.49939 5.25571 8.49939 3.875C8.49939 2.49429 7.3801 1.375 5.99939 1.375C4.61868 1.375 3.49939 2.49429 3.49939 3.875C3.49939 5.25571 4.61868 6.375 5.99939 6.375Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </span>
                    </Link>
                  </div> : userRole == 'mentor' ? <div className='loggedinProfile'>
                      <Link to={`/${state.userRole}/onboarding`} className="primary-btn border">
                      Onboarding 
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                          <path d="M11 12.625V11.375C11 10.712 10.7366 10.0761 10.2678 9.60723C9.79893 9.13839 9.16304 8.875 8.5 8.875H3.5C2.83696 8.875 2.20107 9.13839 1.73223 9.60723C1.26339 10.0761 1 10.712 1 11.375V12.625" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M5.99939 6.375C7.3801 6.375 8.49939 5.25571 8.49939 3.875C8.49939 2.49429 7.3801 1.375 5.99939 1.375C4.61868 1.375 3.49939 2.49429 3.49939 3.875C3.49939 5.25571 4.61868 6.375 5.99939 6.375Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </span>
                      </Link>
                    </div> : ''
                  ) : (
                    <button onClick={handleLoginClick} className="primary-btn black">
                      Mentee Login
                    </button>
                  )}
                </li>
              </ul>
            </div>
            <div className={`header-hamburger-menu  ${ isActive ? 'active' : ''}`} onClick={handleClick}>
              <div className="header-hamburger-icon flex flex-c">
                <div className="header-hamburger-icon-top"></div>
                <div className="header-hamburger-icon-middle"></div>
                <div className="header-hamburger-icon-bottom"></div>
              </div>
            </div>
          </div>
        </div>
          
        </header>
        <div className={`header-mobile-menu  ${ isActive ? 'active' : ''}`}>
        <div className="header-mobile-menu-wrapper custom-container-fluid">
          {/* <div className="mobile-search">
            <input type="search" />
            <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.906 24.3157C5.31189 24.3157 0 19.003 0 12.4078C0 5.81273 5.31189 0.5 11.906 0.5C18.5 0.5 23.8119 5.81273 23.8119 12.4078C23.8119 19.003 18.5 24.3157 11.906 24.3157ZM11.906 2.33198C6.31932 2.33198 1.83169 6.82032 1.83169 12.4078C1.83169 17.9954 6.31932 22.4837 11.906 22.4837C17.4926 22.4837 21.9802 17.9954 21.9802 12.4078C21.9802 6.82032 17.4926 2.33198 11.906 2.33198Z" fill="#222222"/>
              <path d="M20.7794 19.9766L29.0036 28.2021L27.7086 29.4973L19.4844 21.2718L20.7794 19.9766Z" fill="#222222"/>
            </svg>
          </div> */}
          <ul className='mobile-menu-nav flex flex-c'>
            <li className="mobile-menu-links">
              <div className="label">platform</div>
              <ul className='dropdown active' >
                      <li><Link to="/platform/how-it-works" className="label" onClick={handleClick}>How it works</Link></li>
                      <li><Link to="/platform/features" className="label" onClick={handleClick}>Features</Link></li>
                      <li><Link to="/platform/usecases" className="label" onClick={handleClick}>Usecases</Link></li>
                    </ul>
            </li>
            <li className="mobile-menu-links"><Link to="/explore-our-mentors" className="label" onClick={handleClick}>explore our mentors</Link></li>
            
            {userRole === undefined ? <li className="mobile-menu-links"><Link to="/become-a-mentor" className="label" onClick={handleClick}>Join as Mentor</Link></li> : ""}
          </ul>
          <div className="mobile-btn">
            {token && userRole ? (
                  // <button onClick={handleLogout} className="primary-btn border"></button>
                  <div className='loggedinProfile'>
                    <Link to={`/${userRole}/dashboard`} className="primary-btn border" onClick={handleClick}>
                    Dashboard 
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                        <path d="M11 12.625V11.375C11 10.712 10.7366 10.0761 10.2678 9.60723C9.79893 9.13839 9.16304 8.875 8.5 8.875H3.5C2.83696 8.875 2.20107 9.13839 1.73223 9.60723C1.26339 10.0761 1 10.712 1 11.375V12.625" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.99939 6.375C7.3801 6.375 8.49939 5.25571 8.49939 3.875C8.49939 2.49429 7.3801 1.375 5.99939 1.375C4.61868 1.375 3.49939 2.49429 3.49939 3.875C3.49939 5.25571 4.61868 6.375 5.99939 6.375Z" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </span>
                    </Link>
                  </div>
                  
                ) : (
                  <div className='mnav-buttons'>
                    <button onClick={handleLoginClickMentor} className="primary-btn black">
                    Mentor Login
                  </button>
                    <button onClick={handleLoginClick} className="primary-btn black">
                    Mentee Login
                  </button>
                  </div>
                  
                )}
          </div>
        </div>
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpen ? 'active' : ''}`}>
         <Login onClose={handleCloseLoginPopup} onSignUpClick={handleOpenSignUp} onForgotClick={handleOpenForgot} />
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpen ? 'active' : ''}`}>
         <SignUp onLoginClick={handleLoginClick } onClose={handleCloseSignUp}/>
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpen ? 'active' : ''}`}>
         <ForgotPassword onClose={handleCloseForgot} handleOpenChange={handleOpenChange} noti={notify}/>
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpen ? 'active' : ''}`}>
         <ChangePassword noti={notify2}  onClose={handleCloseChange} handleLoginClick={handleLoginClick}/>
      </div>


      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpenMentor ? 'active' : ''}`}>
     <LoginMentor onClose={handleCloseLoginPopupMentor} onSignUpClick={handleOpenSignUpMentor} onForgotClick={handleOpenForgotMentor} />
  </div>
  <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpenMentor ? 'active' : ''}`}>
     <SignUpMentor onLoginClick={handleLoginClickMentor } onClose={handleCloseSignUpMentor}/>
  </div>
  <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpenMentor ? 'active' : ''}`}>
     <ForgotPasswordMentor noti={notify}  onClose={handleCloseForgotMentor} handleOpenChange={handleOpenChangeMentor}/>
  </div>
  <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpenMentor ? 'active' : ''}`}>
     <ChangePasswordMentor  onClose={handleCloseChangeMentor} handleLoginClick={handleLoginClickMentor}/>
  </div>
      
   </section>
  )
}
