import React, { useState, useEffect } from 'react'

const ObStep4 = ({nextStep,prevStep, handleOnboardingInputChange2, values}) => {

    const [experienceData, setExperienceData] = useState({
        positionName: values?.experience?.positionName ? values?.experience?.positionName : "",
        companyName: values?.experience?.companyName ? values?.experience?.companyName : "",
        startDate: "",  
        endDate: "",    
        startMonth: values?.experience?.from ? new Date(values?.experience?.from).getMonth() + 1 : "",
        startYear: values?.experience?.from ? new Date(values?.experience?.from).getFullYear() : "",
        endMonth: values?.experience?.to ? new Date(values?.experience?.to).getMonth() + 1 : "",
        endYear: values?.experience?.to ? new Date(values?.experience?.to).getFullYear() : "",
        });
    const [linkedinProfile, setLinkedinProfile] = useState(values?.linkedinProfile);
    const [years, setYears] = useState([]);
    const [endYears, setEndYears] = useState([]);

    const [dimmed, setDimmed] = useState(values?.experienceCheckbox);

    const toggleElementOpacity = () => {
          setDimmed(!dimmed);
          handleOnboardingInputChange2('experienceCheckbox', !dimmed)
            setExperienceData((prevData) => ({
              ...prevData,
              "endMonth": "",
              "endYear": "",
          }));
      };

    //   console.log('dimmed ', dimmed);

  useEffect(() => {
    // Generate the list of years when the component mounts
    const startYearY = 1980;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const endYearY = currentYear;
    const yearsArray = Array.from(
      { length: endYearY - startYearY + 1 },
      (_, index) => startYearY + index
    );
    setYears(yearsArray);
    setEndYears(yearsArray);
  }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setExperienceData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'startYear' || name === 'startMonth') {
            filterEndYears(value);
          }
    };
    const filterEndYears = (selectedStartYear) => {
        const startYear = parseInt(selectedStartYear, 10);
      
        if (!isNaN(startYear)) {
          const filteredEndYears = years.filter((year) => year > startYear);
          setEndYears(filteredEndYears);
        } else {
          setEndYears(years);
        }
      };

    // console.log('experienceData ', experienceData);
  const[posiError, setPosiError] = useState("");  
  const[compError, setCompError] = useState("");  
  const[startMError, setStartMError] = useState("");
  const[startYError, setStartYError] = useState("");
  const[linkedinError, setLinkedinError] = useState("");

const handleSubmit = (e) => {
    e.preventDefault();
    handleOnboardingInputChange2('experience', {
        positionName: experienceData.positionName,
        companyName: experienceData.companyName,
        from: experienceData.startYear+'-'+experienceData.startMonth+'-01',  
        to: experienceData.endYear ? experienceData.endYear+'-'+experienceData.endMonth+'-01': null,   
        })
        handleOnboardingInputChange2('linkedinProfile', linkedinProfile);
        if (!experienceData.positionName.trim()) {
            setPosiError("Position is Required");
            return;
        } else if (experienceData.positionName.length < 3) {
            setPosiError('Position should be at least 3 characters');
            return;
        } else if (experienceData.positionName.length > 30) {
            setPosiError('Position should not exceed 30 characters');
            return;
        } else {
            setPosiError('')
        }

        if (!experienceData.companyName.trim()) {
            setCompError("Company is Required");
            return;
        }else if (experienceData.companyName.length < 3) {
            setCompError('Company should be at least 3 characters');
            return;
        } else if (experienceData.companyName.length > 30) {
            setCompError('Company should not exceed 30 characters');
            return;
        } else {
            setCompError('')
        }

        // if(experienceData?.startMonth?.length === 0){
        //     setStartMError("Please select a month");
        //     return;
        // }else {
        //     setStartMError('')
        // }
        // if(experienceData?.startYear?.length === 0){
        //     setStartYError("Please select a year");
        //     return;
        // }else {
        //     setStartYError('')
        // }

        if (!experienceData.startMonth || !experienceData.startYear) {
            setStartMError("Please select both month and year for the start date");
            return;
        } else {
            setStartMError("");
        }
    
        if (experienceData.present) {
            // If present is selected, no need for end date
            nextStep();
            return;
        }

        if(!dimmed){
            if (!experienceData.endMonth || !experienceData.endYear) {
                setStartYError("Please select both month and year for the end date");
                return;
            } else {
                setStartYError("");
            }
        }
    
        
    

        if (!linkedinProfile.trim()) {
            setLinkedinError("Linkedin Profile is required");
            return;
        }else if (linkedinProfile?.length < 10) {
            setLinkedinError("Linkedin Profile should be at least 10 characters");
            return;
        }else if (linkedinProfile?.length > 100){
            setLinkedinError("Linkedin Profile should not exceed 100 characters");
            return;
        }else {
            setLinkedinError('')
        }

       
    nextStep();

};
const handleBack = () =>{
    prevStep();
}

// const startYearY = 1980;
// const endYearY = 2040;
// const years = Array.from({ length: endYearY - startYearY + 1 }, (_, index) => startYearY + index);

    return (
    <>
    <div className='stepLine'>
        <div className='slFilled width50'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 4 of 9
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Share Professional Background.</h5>
        </div>
        <div className="obHeadingSvg">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M34.1062 5.92188C32.1062 2.92188 25.1062 1.92188 18.1062 1.92188C11.1062 1.92188 3.10618 2.92188 2.10618 4.92188C0.199182 8.73588 1.10618 24.9219 1.10618 25.9219C1.10618 26.9219 6.10618 26.9219 6.10618 26.9219V13.9219L7.10618 11.9219V34.9219C7.10618 34.9219 10.1062 35.9219 18.1062 35.9219C26.1062 35.9219 29.1062 34.9219 29.1062 34.9219V11.9219L30.1062 13.9219V26.9219C30.1062 26.9219 35.1062 26.9219 35.1062 25.9219C35.1062 24.9219 36.4712 9.46988 34.1062 5.92188Z" fill="#E1E8ED"/>
            <path d="M7.10547 26.9219H6.10547V13.9219C6.10547 12.9219 7.10547 11.9219 7.10547 11.9219V26.9219ZM30.1055 26.9219H29.1055V11.9219C29.1055 11.9219 30.1055 12.9219 30.1055 13.9219V26.9219Z" fill="#99AAB5"/>
            <path d="M11.8867 1.92188L18.1677 13.2659L24.1057 1.92188L17.9967 0.671875L11.8867 1.92188Z" fill="#66757F"/>
            <path d="M12.1055 0.547C12.1055 0.547 14.3555 0 18.1055 0C21.8555 0 24.1055 0.547 24.1055 0.547V2.922C24.1055 2.922 22.4805 1.922 18.1055 1.922C13.7305 1.922 12.1055 2.922 12.1055 2.922V0.547Z" fill="#CCD6DD"/>
            <path d="M7.10547 34.9219C7.10547 34.9219 24.8535 3.26288 24.8535 2.28387C24.8535 1.28387 24.1055 0.546875 24.1055 0.546875L7.10547 31.9219V34.9219Z" fill="#99AAB5"/>
            <path d="M17.9225 14.6439C17.9225 14.6439 11.2305 3.26288 11.2305 2.28387C11.2305 1.28387 12.1045 0.546875 12.1045 0.546875L18.8655 13.1199L17.9225 14.6439Z" fill="#99AAB5"/>
            <path d="M7.10547 21.9219C7.10547 21.9219 12.1055 23.9219 18.1055 23.9219C24.1055 23.9219 29.1055 21.9219 29.1055 21.9219V24.9219C29.1055 24.9219 25.1055 26.9219 18.1055 26.9219C11.1055 26.9219 7.10547 24.9219 7.10547 24.9219V21.9219Z" fill="#292F33"/>
            <path d="M21.1055 23.9219C21.1055 23.9219 19.1055 22.9219 18.1055 22.9219C17.1055 22.9219 15.1055 23.9219 15.1055 23.9219C15.1055 23.9219 14.1055 23.9219 14.1055 24.9219C14.1055 25.9219 15.1055 26.9219 15.1055 26.9219C15.1055 26.9219 17.1055 27.9219 18.1055 27.9219C19.1055 27.9219 21.1055 26.9219 21.1055 26.9219C21.1055 26.9219 22.1055 25.9219 22.1055 24.9219C22.1055 23.9219 21.1055 23.9219 21.1055 23.9219Z" fill="#292F33"/>
            <path d="M12.1055 32.9219L15.1055 33.9219C15.1055 33.9219 16.1055 28.9219 18.1055 26.9219L16.1055 23.9219C16.1055 23.9219 13.1055 27.9219 12.1055 32.9219ZM24.1055 32.9219L21.1055 33.9219C21.1055 33.9219 20.1055 28.9219 18.1055 26.9219L20.1055 23.9219C20.1055 23.9219 23.1055 27.9219 24.1055 32.9219Z" fill="#292F33"/>
            </svg>
        </div>
        </div>
        <div className="obBodyWrapper">
            <p>Add your latest experience. You can add more later.</p>
        </div>
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="position">Position</label>
                    <div className="select-wrapper">
                    {posiError && <p className="error-name-onb">{posiError}</p>}   
                    <input
                            id="position"
                            name="positionName"
                            type="text"
                            placeholder='Ex. Civil Engineer'
                            onChange={handleInputChange}
                            value={experienceData.positionName}
                        />
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="company">Company name</label>
                    {compError && <p className="error-name-onb">{compError}</p>}
                    <input
                            id="company"
                            name="companyName"
                            type="text"
                            placeholder='Ex. L&T'
                            onChange={handleInputChange}
                            value={experienceData.companyName}
                        />
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="timezone">Start date</label>
                    {startMError && <p className="error-name-onb">{startMError}</p>}
                    {startYError && <p className="error-name-onb">{startYError}</p>}
                    <div className="select-wrapper flex">
                        <select
                        id="start-date-month"
                        name="startMonth"
                        onChange={handleInputChange}
                        value={experienceData.startMonth}
                        >
                        <option value="" disabled>Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                        </select>
                        <select
                        id="start-date-year"
                        name="startYear"
                        onChange={handleInputChange}
                        value={experienceData.startYear}
                        >
                        <option value="" disabled>Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                        <div className="is-present-checkbox">
                                                    <input type="checkbox" name="present" id="present" checked={dimmed}  onChange={toggleElementOpacity} />
                                                    <label htmlFor="present">
                                                        Present
                                                    </label>
                        </div>
                    </div>
                </div>
                <div className={dimmed ? 'login-form-input flex flex-c dimmed' : 'login-form-input flex flex-c'}>
                    <label htmlFor="timezone">End date</label>
                    <div className="select-wrapper flex">
                  
                        <select
                        id="end-date-month"
                        name="endMonth"
                        value={experienceData.endMonth}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                        </select>
                        <select
                        id="end-date-year"
                        name="endYear"
                        value={experienceData.endYear}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Year</option>
                        {endYears.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="linkedin">Provide the link to your LinkedIn</label>
                    <div className="select-wrapper">
                    {linkedinError && <p className="error-name-onb">{linkedinError}</p>}
                    <input
                            id="lnkedin"
                            name="linkedin"
                            type="text"
                            placeholder='Enter profile link here'
                            onChange={(e) => setLinkedinProfile(e.target.value)}
                            value={linkedinProfile}
                        />
                    </div>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
</>
)
}

export default ObStep4
