import React,{useRef, useState} from 'react';
import './why-choose-insage.css';
import GetAhead from '../../assets/images/get-ahead.png';
import { useInView } from "framer-motion";
import Login from '../../components/reusable/login/Login';
import SignUp from '../../components/reusable/signup/SignUp';
import ForgotPassword from '../../components/reusable/forgot-password/forgotPassword';
import ChangePassword from '../../components/reusable/change-password/changePassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const WhyChooseInsage = ({propsrole}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
    const [isOpen, setIsOpen] = useState(false);
    const [isSignUpOpen, setSignUpOpen] = useState(false);
    const [isForgotOpen, setForgotOpen] = useState(false);
    const [isChangeOpen, setChangeOpen] = useState(false);
    const handleLoginClick = () => {
      setIsOpen(true);
      setSignUpOpen(false);
      document.body.classList.add('popup-open');
    };

    const handleCloseLoginPopup = () =>{
      setIsOpen(false);
      document.body.classList.remove('popup-open');
    } 

    const handleOpenSignUp = () => {
      setSignUpOpen(true);
      setIsOpen(false);
    };
    const handleCloseSignUp = () => {
      setSignUpOpen(false);
      document.body.classList.remove('popup-open');
    };
    const handleOpenForgot = () => {
      setForgotOpen(true);
      setIsOpen(false);
      setSignUpOpen(false);
    };
    const handleCloseForgot = () => {
      setForgotOpen(false);
      document.body.classList.remove('popup-open');
    };
    const handleOpenChange = () => {
      setChangeOpen(true);
      setIsOpen(false);
      setSignUpOpen(false);
      setForgotOpen(false);
    };
    const handleCloseChange = () => {
      setChangeOpen(false);
      document.body.classList.remove('popup-open');
    };
    const notify = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Email Sent Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );
    const notify2 = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Password Changed Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );
  return (
    <section className="insage-get-ahead">
    <div className="custom-container-fluid">
        <div className="insage-knowledge-at-work-wrapper flex flex-jsb align">
            <div className="insage-knowledge-at-work-left flex align">
                 <div style={{
          transform: isInView ? "translateX(0px)" : "translateX(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-label flex flex-jsc">
                   <div className="label col-white">why choose insage</div>
                 </div>
                 <div className="insage-knowledge-at-work-content">
                    <div className="insage-knowledge-at-work-content-wrapper">
                        <div className="insage-knowledge-at-work-content-heading">
                            <h3 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>Get ahead of the curve with Insage.</h3>
                            </div>
                        <div className="insage-knowledge-at-work-content-body">
                            <div className="insage-knowledge-at-work-content-body-label">
                                <span  style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="label">Personalised guidance</span>
                            </div>
                            <div className="insage-knowledge-at-work-content-body-text">
                                <p  style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className='col-p'>We not only link you with industry leaders but also ensure personalised guidance aligned with YOUR strengths and aspirations. Secure a reliable mentor in a dynamic working environment with Insage</p>
                            </div>
                        </div>
                        <div className="insage-get-ahead-btn">
                                {propsrole === undefined ? (
                                <button onClick={handleLoginClick} style={{
                                  opacity: isInView ? 1 : 0,
                                  transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                }} className="primary-btn primary-btn-2 black">
                                  Become a Mentee
                                </button>
                              ) : propsrole === "mentee" ? (
                                 <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 black">Explore our Mentors</Link>
                              ) : propsrole === "mentor" ? (
                                 ""
                              ) : null}
                        </div>
                    </div>
                 </div>

            </div>
            <div className="insage-knowledge-at-work-right">
                <div  style={{
           clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-image">
                    <img className='img' src={GetAhead} alt="" />
                </div>
            </div>
        </div> 
    </div>
    <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpen ? 'active' : ''}`}>
         <Login onClose={handleCloseLoginPopup} onSignUpClick={handleOpenSignUp} onForgotClick={handleOpenForgot} />
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpen ? 'active' : ''}`}>
         <SignUp onLoginClick={handleLoginClick } onClose={handleCloseSignUp}/>
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpen ? 'active' : ''}`}>
         <ForgotPassword onClose={handleCloseForgot} handleOpenChange={handleOpenChange} noti={notify}/>
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpen ? 'active' : ''}`}>
         <ChangePassword noti={notify2}  onClose={handleCloseChange} handleLoginClick={handleLoginClick}/>
      </div>  
   </section>
  )
}

export default WhyChooseInsage
