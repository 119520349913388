import React, {useRef} from 'react';
import './about.css';
import '../home/three-cards.css';
import Ticker from '../reusable/ticker/Ticker';
import { useInView } from "framer-motion";

export default function AboutConnections({props}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });  
    const data = props;
    const texts=["Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive"];
    const dynamicStyles = {
        bg: {
          background: '#F8F6ED',
          color:'#444444',
          transform:'rotate(-1.22deg)'
        },
        fill: {
         fill:'#FCD26E'
        }
      };
  return (
    <section className="about-connections">
        <div className="container">
        <div className="knowledge-insage-content-wrapper flex align">
                <div ref={ref} style={{
                  clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="knowledge-insage-image">
                    <img src={data?.connImage} alt="" className="img" />
                </div>
                <div className="knowledge-insage-content-body">
                    <div className="knowledge-insage-content-heading">
                        <h3 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className='head-white'>{data?.connHeading}</h3>
                        </div>
                        <div  style={{
          transform: isInView ? "translateY(0)" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="knowledge-insage-arrow">
                           <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M47 10.946V46.9662H36.0641L36.0683 18.6758L36.0471 18.6969L7.7262 47L0.105723 39.1181L28.2956 10.946L28.3125 10.9291H0L0.0042298 0H36.0471L47 10.946Z" fill="#FFBB24"/>
                            </svg>
                        </div>
                    <div className="knowledge-insage-label">
                        <span  style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="label col-white">{data?.connSubHead}</span>
                        </div>   
                    <div className="knowledge-insage-body">
                        <p  style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="col-white">{data?.connBody}</p>
                          </div>    
                </div>
            </div>
        </div>
        <div className="about-connections-ticker">
          <Ticker texts={texts} styles={dynamicStyles} />
        </div>
    </section>
  )
}
