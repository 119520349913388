import React from 'react';
import './Ticker.css';
export default function Ticker({texts, styles}) {

  return (
    <div className='marquee-wrap'>
      <div style={styles.bg} className="insage-marquee flex">
          <div className="insage-marquee-wrapper">
              {texts.map((text, index) => (
              <div key={index} className="insage-marquee-text-wrapper flex align">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path style={styles.fill} d="M11.825 5.56009L8.15599 5.46128L10.8203 2.9367L9.84352 1.90584L6.45022 5.12116L3.2349 1.72786L2.20403 2.70465L4.7286 5.36897L1.05934 5.27015L1.02111 6.68988L4.69037 6.7887L2.02605 9.31327L3.00284 10.3441L6.39615 7.12882L9.61147 10.5221L10.6423 9.54533L8.11776 6.88101L11.7867 6.97982L11.825 5.56009Z"/>
                            <path d="M6.51859 2.49804C7.06298 2.5127 7.51622 2.08323 7.53088 1.53884C7.54554 0.994448 7.11607 0.541209 6.57168 0.526548C6.02729 0.511886 5.57405 0.941352 5.55939 1.48574C5.54473 2.03014 5.97419 2.48337 6.51859 2.49804Z" fill="#FCD26E"/>
                            <path style={styles.fill} d="M6.26859 11.7246C6.813 11.7393 7.26621 11.3098 7.28088 10.7654C7.29554 10.221 6.86609 9.76777 6.32168 9.75311C5.77727 9.73845 5.32405 10.1679 5.30939 10.7123C5.29473 11.2567 5.72417 11.7099 6.26859 11.7246Z" />
                    </svg>
                    <div className="insage-marquee-text">
                      {text}
                    </div>
              </div>
                  ))}
          </div>
          <div aria-hidden="true" className="insage-marquee-wrapper">
              {texts.map((text, index) => (
              <div key={index} className="insage-marquee-text-wrapper flex align">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path style={styles.fill} d="M11.825 5.56009L8.15599 5.46128L10.8203 2.9367L9.84352 1.90584L6.45022 5.12116L3.2349 1.72786L2.20403 2.70465L4.7286 5.36897L1.05934 5.27015L1.02111 6.68988L4.69037 6.7887L2.02605 9.31327L3.00284 10.3441L6.39615 7.12882L9.61147 10.5221L10.6423 9.54533L8.11776 6.88101L11.7867 6.97982L11.825 5.56009Z"/>
                            <path d="M6.51859 2.49804C7.06298 2.5127 7.51622 2.08323 7.53088 1.53884C7.54554 0.994448 7.11607 0.541209 6.57168 0.526548C6.02729 0.511886 5.57405 0.941352 5.55939 1.48574C5.54473 2.03014 5.97419 2.48337 6.51859 2.49804Z" fill="#FCD26E"/>
                            <path style={styles.fill} d="M6.26859 11.7246C6.813 11.7393 7.26621 11.3098 7.28088 10.7654C7.29554 10.221 6.86609 9.76777 6.32168 9.75311C5.77727 9.73845 5.32405 10.1679 5.30939 10.7123C5.29473 11.2567 5.72417 11.7099 6.26859 11.7246Z" />
                    </svg>
                    <div className="insage-marquee-text">
                      {text}
                    </div>
              </div>
                  ))}
          </div>
      </div>
    </div>
  )
}