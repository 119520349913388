import React, { useState, useEffect} from 'react';

const ObStep3 = ({nextStep,prevStep, handleOnboardingInputChange2, values}) => {

    // educationData.startYear+'-'+educationData.startMonth+'-01'

    const [educationData, setEducationData] = useState({
        degree: values?.education?.degree ? values?.education?.degree : "",
        college: values?.education?.collegeName ? values?.education?.collegeName : "",
        startDate: "",  
        endDate: "",    
        startMonth: values?.education?.from ? new Date(values?.education?.from).getMonth() + 1 : "",
        startYear: values?.education?.from ? new Date(values?.education?.from).getFullYear() : "",
        endMonth: values?.education?.to ? new Date(values?.education?.to).getMonth() + 1 : "",
        endYear: values?.education?.to ? new Date(values?.education?.to).getFullYear() : "",
        });

  const [years, setYears] = useState([]);
  const [endYears, setEndYears] = useState([]);

  const[posiError, setPosiError] = useState("");  
  const[compError, setCompError] = useState("");  
  const[startMError, setStartMError] = useState("");
  const[startYError, setStartYError] = useState("");
  const [dimmed, setDimmed] = useState(values?.educationCheckbox);

    const toggleElementOpacity = () => {
          setDimmed(!dimmed);
          handleOnboardingInputChange2('educationCheckbox', !dimmed)
          setEducationData((prevData) => ({
            ...prevData,
            "endMonth": "",
            "endYear": "",
        }));


    };

  useEffect(() => {
    const startYearY = 1980;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const endYearY = currentYear;
    const yearsArray = Array.from(
      { length: endYearY - startYearY + 1 },
      (_, index) => startYearY + index
    );
    setYears(yearsArray);
    setEndYears(yearsArray);
  }, []);
    

//   console.log('educationData ', educationData);
//   console.log('values education', values);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEducationData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    if (name === 'startYear' || name === 'startMonth') {
        filterEndYears(value);
      }
    };

    const filterEndYears = (selectedStartYear) => {
        const startYear = parseInt(selectedStartYear, 10);
      
        if (!isNaN(startYear)) {
          const filteredEndYears = years.filter((year) => year > startYear);
          setEndYears(filteredEndYears);
        } else {
          setEndYears(years);
        }
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleOnboardingInputChange2('education', {
        degree: educationData.degree,
        collegeName: educationData.college,
        from: educationData.startYear+'-'+educationData.startMonth+'-01',  
        to: educationData.endYear ? educationData.endYear+'-'+educationData.endMonth+'-01': null,   
        })

        // handleOnboardingInputChange2('educationCheckbox', dimmed)

        if (!educationData.degree.trim()) {
            setPosiError("Degree is Required");
            return;
        }
        if (!educationData.college.trim()) {
            setCompError("College is Required");
            return;
        }
        if(educationData.startMonth.length === 0){
            setStartMError("Please select a month");
            return;
        }
        if(educationData.startYear.length === 0){
            setStartYError("Please select a year");
            return;
        }
        nextStep();
    };

    const handleSkip =()=>{
        nextStep();
    }
    const handleBack = () =>{
        prevStep();
    }
    return (
    <>
    <div className='stepLine'>
        <div className='slFilled mWidth60'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 3 of 5
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Share Educational Background.</h5>
        </div>
        <div className="obHeadingSvg">
            <button className='obFormSkip' onClick={handleSkip}>Skip</button>
        </div>
        </div>
        <div className="obBodyWrapper">
            <p>Add your latest education. You can add more later.</p>
        </div>
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="degree">Degree name</label>
                    <div className="select-wrapper">
                    {posiError && <p className="error-name-onb">{posiError}</p>}      
                    <input
                            id="degree"
                            name="degree"
                            type="text"
                            placeholder='Ex. Bachelors of Technology'
                            onChange={handleInputChange}
                            value={educationData.degree}
                        />
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="colleges">College name</label>
                    <div className="select-wrapper">
                    {compError && <p className="error-name-onb">{compError}</p>}   
                    <input
                            id="colleges"
                            name="college"
                            type="text"
                            placeholder='Ex. Indian Institute of Technology , Bombay'
                            onChange={handleInputChange}
                            value={educationData.college}
                        />
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="timezone">Start date</label>
                    {startMError && <p className="error-name-onb">{startMError}</p>}
                    {startYError && <p className="error-name-onb">{startYError}</p>}
                    <div className="select-wrapper flex">
                        <select
                        id="start-date-month"
                        name="startMonth"
                        value={educationData.startMonth}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Month</option>
                        <option value="1" >January</option>
                        <option value="2" >February</option>
                        <option value="3" >March</option>
                        <option value="4" >April</option>
                        <option value="5" >May</option>
                        <option value="6" >June</option>
                        <option value="7" >July</option>
                        <option value="8" >August</option>
                        <option value="9" >September</option>
                        <option value="10" >October</option>
                        <option value="11" >November</option>
                        <option value="12" >December</option>
                        </select>
                        <select
                        id="start-date-year"
                        name="startYear"
                        value={educationData.startYear}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                        <div className="is-present-checkbox">
                            <input type="checkbox" name="present" id="present" checked={dimmed}  onChange={toggleElementOpacity} />
                            <label htmlFor="present">
                                Present
                            </label>
                        </div>
                    </div>
                </div>
                <div className={dimmed ? 'login-form-input flex flex-c dimmed' : 'login-form-input flex flex-c'}>
                    <label htmlFor="timezone">End date</label>
                    <div className="select-wrapper flex">
                        <select
                        id="end-date-month"
                        name="endMonth"
                        value={educationData.endMonth}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Month</option>
                        <option value="1" >January</option>
                        <option value="2" >February</option>
                        <option value="3" >March</option>
                        <option value="4" >April</option>
                        <option value="5" >May</option>
                        <option value="6" >June</option>
                        <option value="7" >July</option>
                        <option value="8" >August</option>
                        <option value="9" >September</option>
                        <option value="10" >October</option>
                        <option value="11" >November</option>
                        <option value="12" >December</option>
                        </select>
                        <select
                        id="end-date-year"
                        name="endYear"
                        value={educationData.endYear}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Year</option>
                        {endYears.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
</>
    )
}

export default ObStep3
