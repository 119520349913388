import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function TopCards({post}) {
    
    const { id } = useParams();

    const names = post?.fullName.split(' ');
    const firstName = names[0];
    const lastName = names.length > 1 ? names[names.length - 1] : '';
    const displayText = lastName ? `${firstName} ${lastName.charAt(0)}.` : firstName;

    // console.log(post);

  return (

        <Link to={`/mentors/${post.username}`}>
        <div className="mentors-card">
            <div className="mentors-card-wrapper">
            <div className="mentors-card-image-wrapper">
                <div className="mentors-card-image">
                    <img className='img' src={post?.avatar} alt="" />
                
                </div>
                <div className="mentor-card-label flex flex-jsc">
                    <span className="label">{post?.industry}</span>
                </div>
            </div>
            <div className="mentor-details">
                <div className="mentor-details-wrapper">
                    <div className="mentor-name-designation-wrapper">
                        <div className='mentorCard-top'>
                            <div className="mentor-name">
                                <h3>{displayText}</h3>
                            </div>
                            <div className="mentor-designation-experience">
                                <span className="designation">{post?.designation} at {post?.company}</span>
                                <div className="mentor-designation-separator"></div> 
                                <span className="designation">{post?.levelOfExp} of exp</span>
                            </div>
                            <div className="mentor-separator"></div>
                        </div>
                        <div className='mentorCard-bottm'>
                            
                            <div className="mentor-body">
                                <p className="small">{post?.bio}</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
 </Link>
  )
}
