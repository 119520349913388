import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Switch } from 'react-router-dom';
import Homepage from './pages/homepage/Home';
import BecomeAMentor from './pages/become-a-mentor/Become-a-Mentor';
import Onboarding from './pages/onboarding/onboarding';
import MentorOnboarding from './pages/onboarding/mentorOnboarding';
import About from './pages/about/About';
import HowItWorks from './pages/platform/how-it-works/how-it-works';
import Features from './pages/platform/features/features';
import UseCases from './pages/platform/usecases/usecases';
import Guides from './pages/guides/guides';
import GuideInner1 from './pages/guide-inner/GuideInner';
import GuideInner2 from './pages/guide-inner/GuideInner2';
import FAQs from './pages/faqs/FAQs';
import Contact from './pages/contact/Contact';
import TermsCondition from "./pages/terms-condition/terms-condition";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import ErrorPage from './pages/404/404';
import Cookies from 'js-cookie';
import { AuthContext } from './context/AuthContext';
import MenteeDashboard from './pages/dashboard/menteeDashboard';
import MenteeProfile from './pages/menteeProfile/menteeProfile';
import MentorProfile from './pages/mentorProfile/mentorProfile';
import MentorProfilePage from './pages/mentorProfile/mentorProfilePage';
import Verify from './pages/login/verify'; 
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LinkedInPage from './components/reusable/login/LinkedInPage';
import MentorDashboard from './pages/dashboard/mentorDashboard';
import ScrollToTop from './components/go-to-top/Go-to-top';
import ExploreAllMentors from './pages/explore-mentors/Explore-all-mentors';
import { ChatContextProvider } from './context/ChatContext';
import Calendar from './components/Calendar/Calendar';
import SlotCalculator from './components/Slots/Slot';
import PaymentPage from './components/Payment/PaymentPage';
import MentorBooking from './pages/mentor-booking/MentorBooking';
import Blogs from './pages/blogs/blogs';
import BlogInnerPage from './pages/blogs/bloginner';
import Community from './pages/community/Community';
import CommunityInner from './pages/community/CommunityInner';
import CreatePost from './pages/community/CreatePost';
import CreateBlog from './pages/blogs/CreateBlog';
import { ToasterProvider } from './components/Dashboard/ToasterContext';
import ProtectedBlogCreate from './pages/blogs/ProtectedBlogCreate';
import Sitemap from './pages/sitemap/Sitemap';
import ProtectedEditBlog from './pages/blogs/ProtectedEditBlog'
import MentorFilter from './pages/FilterPage/MentorFilter';
import PreviewBlog from './pages/blogs/PreviewBlog';


// Custom function to check if the user is authenticated and retrieve user role
const isAuthenticated = () => {
  const userRole = Cookies.get('userRole');
  const token = Cookies.get('token');
  return userRole !== null && token !== null;
};

// Custom function to retrieve the user's role
const getUserRole = () => {
  return Cookies.get('userRole');
};


function LogoutComponent() {
  const { logout } = useContext(AuthContext);

  const handleLogout =  async () => {
    const res  = await logout();
    if(res){
      return <Navigate to="/" />;
    }
    
  };

  return (
    <div>
      <h1>Logging Out...</h1>
      {handleLogout()}
    </div>
  );
}


const CommonRoutes = () => (
  <Routes>
    {/* <Route path="/" element={<Home />} />
    <Route path="/dashboard" element={<Dashboard />} />

    
    <Route path="/settings" element={<Settings />} /> */}
  </Routes>
);

// Authenticated Routes for Mentor
const MentorRoutes = ({state}) => {
  const userRole = state.userRole? state.userRole:getUserRole();

  if (!isAuthenticated() || userRole !== 'mentor') {
    return <Navigate to="/" />;
  }

  
  return (
    <Routes>
      <Route exact path="/onboarding" element={<MentorOnboarding />} />
      <Route exact path="/dashboard" element={<MentorDashboard />} />
      <Route exact path="/profile" element={<MentorProfile />} /> 
      <Route path="/*" element={<CommonRoutes />} />
    </Routes> 
  );
};

// Authenticated Routes for Mentee
const MenteeRoutes = ({state}) => {
  const userRole = state.userRole? state.userRole:getUserRole();
  if (!isAuthenticated() || userRole !== 'mentee') {
    return <Navigate to="/" />;
  }
 
  return (
    <Routes>
      <Route exact path="/onboarding" element={<Onboarding />} />
      <Route exact path="/dashboard" element={<MenteeDashboard />} />
      <Route exact path="/profile" element={<MenteeProfile />} />
      <Route path="/*" element={<CommonRoutes />} />
    </Routes>
  );
};




// App component that handles overall routing
const App = () => {
  const { state } = useContext(AuthContext);

  // const disabledDates = ['2023-10-12', '2023-10-15', '2023-10-22'];

  let dataUser = state.profile ? state.profile : state.dashboard

  return (
    <ToasterProvider>
    <ChatContextProvider user={dataUser}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/mentor/*" element={<MentorRoutes state={state} />} />
          <Route path="/mentee/*" element={<MenteeRoutes state={state} />} />
          <Route exact path="/about" element={<About />}  />
          <Route exact path="/platform/how-it-works" element={<HowItWorks />}  />
          <Route exact path="/platform/features" element={<Features />}  />
          <Route exact path="/platform/usecases" element={<UseCases />}  />
          {/* <Route exact path="/guides" element={<Guides />}  /> */}
          {/* <Route exact path="/guides/guide-1" element={<GuideInner1 />}  /> */}
          {/* <Route exact path="/guides/guide-2" element={<GuideInner2 />}  /> */}
          {/* <Route exact path="/blogs" element={<Blogs />}  /> */}
          <Route exact path="/community" element={<Community />}  />
          <Route exact path="/community/create" element={<CreatePost />}  />
          <Route exact path="/blogs/create" element={<ProtectedBlogCreate />}  />
          <Route exact path="/blogs/edit/:id" element={<ProtectedEditBlog />}  />
          <Route exact path="/community/:slug" element={<CommunityInner />}  />
          {/* <Route exact path="/blogs/blogsinner" element={<BlogInnerPage />}  /> */}
          <Route exact path="/faqs" element={<FAQs />}  />
          <Route exact path="/terms-condition" element={<TermsCondition />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route exact path="/contact" element={<Contact />}  />
          <Route exact path="/become-a-mentor" element={<BecomeAMentor />} />
          <Route path="/explore-our-mentors" element={<ExploreAllMentors/>}/>
          <Route path="/explore-all-mentors" element={<MentorFilter/>}/>
          <Route path="/mentors/:id" element={<MentorProfilePage/>}/>
          <Route path="/blogs/:slug" element={<BlogInnerPage/>}/>
          {/* <Route path="/blog/preview" element={<PreviewBlog/>}/> */}
          {/* <Route exact path="/calendar" element={<Calendar disabledDates={disabledDates}/>} /> */}
          <Route exact path="/payment" element={<PaymentPage />} />
          <Route exact path="/mentor/booking/:id" element={<MentorBooking />} />
          <Route path="/sitemaps.xml" element={<Sitemap/>}/>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </ChatContextProvider>
    </ToasterProvider>

    
  );
};

export default App;