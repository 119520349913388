import React, {useRef, useState} from 'react';
import '../home/why-choose-insage.css';
import './how-it-works.css';
import { useInView } from "framer-motion";
import Login from '../reusable/login/Login';
import SignUp from '../reusable/signup/SignUp';
import ForgotPassword from '../reusable/forgot-password/forgotPassword';
import ChangePassword from '../reusable/change-password/changePassword';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function OurNewWay({props,propsrole}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
    const data = props;
    const [isOpen, setIsOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isChangeOpen, setChangeOpen] = useState(false);

  const handleLoginClick = () => {
    setIsOpen(true);
    setSignUpOpen(false);
    document.body.classList.add('popup-open');
  };
  const handleCloseLoginPopup = () =>{
    setIsOpen(false);
    document.body.classList.remove('popup-open');
  }
  const handleOpenSignUp = () => {
    setSignUpOpen(true);
    setIsOpen(false);
  };
  const handleCloseSignUp = () => {
    setSignUpOpen(false);
    document.body.classList.remove('popup-open');
  };
  const handleOpenForgot = () => {
    setForgotOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
  };
  const handleCloseForgot = () => {
    setForgotOpen(false);
    document.body.classList.remove('popup-open');
  }; 
  const handleOpenChange = () => {
    setChangeOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
    setForgotOpen(false);
  };
  const handleCloseChange = () => {
    setChangeOpen(false);
    document.body.classList.remove('popup-open');
  };

  const notify = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Email Sent Successfully!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );

  return (
    <section className="hiw-our-new-way">
          <div className="custom-container-fluid">
        <div className="insage-knowledge-at-work-wrapper flex flex-jsb align">
            <div className="insage-knowledge-at-work-left flex align">
                 <div ref={ref} style={{
          transform: isInView ? "translateX(0)" : "translateX(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-label flex flex-jsc">
                   <div className="label col-white">{data?.newTag}</div>
                 </div>
                 <div className="insage-knowledge-at-work-content">
                    <div className="insage-knowledge-at-work-content-wrapper">
                        <div className="insage-knowledge-at-work-content-heading">
                            <h3 ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>{data?.newHead}</h3>
                            </div>
                        <div className="insage-knowledge-at-work-content-body">
                            <div className="insage-knowledge-at-work-content-body-label">
                                <span ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="label">{data?.newSubHead}</span>
                            </div>
                            <div className="insage-knowledge-at-work-content-body-text">
                                <p ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className='col-p'>{data?.newBody}</p>
                            </div>
                        </div>
                        <div className="insage-get-ahead-btn">
                            <div ref={ref} style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="">
          {propsrole === undefined ? (
                                <button onClick={handleLoginClick} className="primary-btn primary-btn-2 border">
                                  Become a Mentee
                                </button>
                              ) : propsrole === "mentee" ? (
                                 <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 border">Explore our Mentors</Link>
                              ) : propsrole === "mentor" ? (
                                 ""
                              ) : null}
               
        </div>
                        </div>
                    </div>
                 </div>

            </div>
            <div className="insage-knowledge-at-work-right">
                <div ref={ref} style={{
            clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-image">
                    <img className='img' src={data?.newImg} alt="" />
                </div>
            </div>
        </div> 
            </div>
            <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpen ? 'active' : ''}`}>
    <Login onClose={handleCloseLoginPopup} onSignUpClick={handleOpenSignUp} onForgotClick={handleOpenForgot} />
 </div>
 <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpen ? 'active' : ''}`}>
    <SignUp onLoginClick={handleLoginClick } onClose={handleCloseSignUp}/>
 </div>
 <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpen ? 'active' : ''}`}>
    <ForgotPassword noti={notify}  onClose={handleCloseForgot} handleOpenChange={handleOpenChange}/>
 </div>
 <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpen ? 'active' : ''}`}>
    <ChangePassword  onClose={handleCloseChange} handleLoginClick={handleLoginClick}/>
 </div>        
    </section>
  )
}
