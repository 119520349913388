import React,{useState, useRef, useEffect, useContext} from 'react';
import './community.css';
import post1 from '../../assets/images/community.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReportPopup from './ReportPopup';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import axios from 'axios';
import ReportComment from './ReportComment';

export default function Community_Post_Inner({props, handleLikeDislike, commDetails, setCommDetails}) {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuOpen2, setMenuOpen2] = useState(false);
    const [loadingCom, setLoadingCom] = useState(false);
    const menuRef = useRef(null);
    const menuRef2 = useRef(null);
    const { addCommentComm} = useContext(AuthContext);
    const [loading, setLoading]= useState(false);
    const [commentError, setCommentError] = useState('');
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [commData, setCommData] = useState(props);
    const[selectedData, setSelectedData] = useState(false);
    const[reportPop, setReportPop] = useState(false);

    const userToken = Cookies.get('token');
    let userRole = Cookies.get('userRole');

    const [comment, setCommentData] = useState({
        communityId: commData?.communityId, 
        comment: '',
        avatar: commData?.avatar,
        createdAt: "0 minutes ago",
        fullName:commData?.fullName,
        userType:userRole
      });

      // console.log('comment ', comment);

      useEffect(()=>{
        setCommData((prevData) => (
            props
        ));   
      },[props])

      useEffect(()=>{
        setCommentData((prevData) => ({
            ...prevData,
            communityId: commData?.communityId
        }));   
      },[commData])


      const handleLoadMore = (page) => {
        fetchLoadMore(Number(page)+1)
      }


      const fetchLoadMore = (page=2) => {

        setLoadingCom(true);

        let userRole = Cookies.get('userRole');
        const token = Cookies.get('token');

        if(userRole){
          axios
              .get(`${process.env.REACT_APP_API_URL}/detail-community/${props.slug}?page=${page}`, {
                  headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the headers
                  },
              })
              .then((res) => {
                let mergedData = {...props, ...res.data.result , communityComments: [...props.communityComments, ...res.data.result.communityComments]}
                setCommDetails({ ...commDetails , result: mergedData})
                setLoadingCom(false);
              })

      }else {
          axios
          .get(`${process.env.REACT_APP_API_URL}/detail-communities/${props.slug}?page=${page}`)
          .then((res) => {
              let mergedData = {...props, ...res.data.result , communityComments: [...props.communityComments, ...res.data.result.communityComments]}
              setCommDetails({ ...commDetails , result: mergedData})
              setLoadingCom(false);
          })
      }
      }
      
      const handleCommentChange = (e) => {
        const { name, value } = e.target;
        setCommentData((prevData) => ({
            ...prevData,
            [name]: value,
        }));   
      }

      const postComment = async () => {
        try {
         
          setLoading(true);
      
          if (!comment.comment.trim()) {
             setLoading(false);
             setCommentError('Comment cannot be empty');
             return;
          }else{
            setCommentError('');
          }
      
          const response = await addCommentComm(comment);
          // console.log('Comment posted:', response);
          if(response){
            setLoading(false);
            notifyCom();
            let newMergedData = {
              communityId: response?.communityId, 
              comment: response?.comment,
              avatar: response?.avatar,
              createdAt: response?.createdAt,
              fullName:response?.fullName,
              userType:response?.userType
            };
            setCommData({ ...commData, communityComments: [...commData.communityComments, newMergedData]});
            setCommentData({...comment, comment: ''}); 
           
          }
         
        } catch (error) {
          console.error('Error posting comment:', error);
        }
      };

  const menuButtonRef = useRef(null);
  const menuButtonRef2 = useRef(null);
  
    const handleMenuClick = () => {
        setMenuOpen(!isMenuOpen);
    };
    const handleMenuClick2 = () => {
      setMenuOpen2(!isMenuOpen2);
  };
    const setReportOpen = () => {
        setPopUpOpen(true);
        document.body.classList.add('active');
    }
    const setReportClose = () =>{
        setPopUpOpen(false);
        document.body.classList.remove('active');
    }

    const handleIncrement = (categoryId, action) => {
      handleLikeDislike(categoryId, action)
    }
  
      useEffect(() => {
        const closeMenuOnClickOutside = (e) => {
          if (
            menuRef.current &&
            !menuRef.current.contains(e.target) &&
            menuButtonRef.current &&
            !menuButtonRef.current.contains(e.target)
          ) {
            setMenuOpen(false);
          }
        };
    
        if (isMenuOpen) {
          window.addEventListener('click', closeMenuOnClickOutside);
        }
    
        return () => {
          window.removeEventListener('click', closeMenuOnClickOutside);
        };
      }, [isMenuOpen]);

      useEffect(() => {
        const closeMenuOnClickOutside2 = (e) => {
          if (
            menuRef2.current &&
            !menuRef2.current.contains(e.target) &&
            menuButtonRef2.current &&
            !menuButtonRef2.current.contains(e.target)
          ) {
            setMenuOpen2(false);
          }
        };
    
        if (isMenuOpen2) {
          window.addEventListener('click', closeMenuOnClickOutside2);
        }
    
        return () => {
          window.removeEventListener('click', closeMenuOnClickOutside2);
        };
      }, [isMenuOpen2]);

      const location = window.location.href;

      const handleCopyClick = (event) => {
        const slug = event.currentTarget.getAttribute('data-slug');
        var link = window.location.href;
        navigator.clipboard.writeText(link)
          .then(() => {
            notifyC();
          })
          .catch((err) => {
            console.error('Unable to copy URL to clipboard', err);
          });
      };   
      const handleDeleteClick =  (blogId,action) => () =>{

        const userToken = Cookies.get('token');
    
    
            const resourceData = {
                resourceId: blogId,
                resourceType: "Community",
                action: action
            }
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/add/remove/resource`,
                resourceData,
                {
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                }
              )
              .then((res) => {
    
                if(res.data.success){
                  setCommData({ ...commData, isBookmarked: action === 'add' ? true : false });
                }
              })
              .catch((err) => {
                console.log(err);
              })
    
    }   

    const openReportPop = (data)=>{
      setReportPop(true);
      setSelectedData(data);
      document.body.classList.add('popup-open');
  }
  const closeReportPop = ()=>{
    setReportPop(false);
    document.body.classList.remove('popup-open');
}
      const notifyC = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Link Copied Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyRe = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Post Reported Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyCom = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Comment Posted Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyComR = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Comment Reported Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyE = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#F45E5C"/>
                    <path d="M11.25 4.75L4.75 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.75 4.75L11.25 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
          <span>Error</span>
            </div>
            <div className="custom-toast-bot">
              <span>You must be logged in!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container-error',
        }
      );

  return (
    <>
    <section className="Community_post_section_inner">
         <div className="container-900">
            <div className="Community_post-inner_wrappper">
            <div className="Trending_title flex-title">
                <Link to={'/community'}><h1><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2806_242385)">
                        <path d="M15 12L1 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 19L1 12L8 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_2806_242385">
                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </span>
                    Community Post</h1>
                    </Link>    
            </div>
             <div className="Community_post_wrapper">
             <div className="Community_post_box">
              {commData?.avatar && <div className="communuity_post_box_author_img">
                              <img src={commData?.avatar} alt=""></img>
                          </div>}
                          <div className="Community_post_box_content">
                              <div className="Community_post_blog_info_flex">
                                  <div className="Community_post_blog_info">
                                      <div className="Community_post_author_name">
                                          <h6>{commData?.authorName}</h6>
                                      </div>
                                      <div className="Community_post_date">
                                          <p>{commData?.createdAt}</p>
                                      </div>
                                      <div className="Community_post_category">
                                        {commData?.categoryName}
                                      </div>
                                  </div>
                                  <div className="Commuity_popup_button">

                    <span className="Community_popup_svg" ref={menuButtonRef} onClick={handleMenuClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4" fill="none">
                            <path d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    {isMenuOpen && (
                        <div className='threedotsMenu' ref={menuRef}>
                            <button class="report-button" onClick={userToken ? () => setReportOpen() : () => notifyE()}>Report</button>
                        </div>
                    )}
                    </div>
                              </div>
                            <div className="Community_post-content_data">
                                <p dangerouslySetInnerHTML={{ __html: commData?.content }}>
                                  
                                </p>
                                
                            </div>
                            {commData?.image ? <div className="Community_Main-Img">
                                <img src={commData?.image} alt="image"></img>
                            </div> : ''}
                            <div className="Community_Ppost-comment-flex">
                                <div className="Community_post_option">
                                    <div className="Post_like" onClick={userToken ? () => handleIncrement(props?.communityId, 'like') : () => notifyE()}>
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                                            <path d="M6 9.49994L10 0.499939C10.7956 0.499939 11.5587 0.81601 12.1213 1.37862C12.6839 1.94123 13 2.70429 13 3.49994V7.49994H18.66C18.9499 7.49666 19.2371 7.55644 19.5016 7.67516C19.7661 7.79387 20.0016 7.96867 20.1919 8.18745C20.3821 8.40623 20.5225 8.66376 20.6033 8.94219C20.6842 9.22062 20.7035 9.5133 20.66 9.79994L19.28 18.7999C19.2077 19.2768 18.9654 19.7115 18.5979 20.0239C18.2304 20.3363 17.7623 20.5054 17.28 20.4999H6M6 9.49994V20.4999M6 9.49994H3C2.46957 9.49994 1.96086 9.71065 1.58579 10.0857C1.21071 10.4608 1 10.9695 1 11.4999V18.4999C1 19.0304 1.21071 19.5391 1.58579 19.9142C1.96086 20.2892 2.46957 20.4999 3 20.4999H6" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        {commData?.likeCount}
                                    </div>
                                    <div className="Post_dislike" onClick={userToken ? () => handleIncrement(props?.communityId, 'dislike') :() => notifyE()}>
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <path d="M15.3653 11.5002L11.3653 20.5002C10.5696 20.5002 9.80655 20.1841 9.24394 19.6215C8.68134 19.0589 8.36526 18.2958 8.36526 17.5002V13.5002H2.70527C2.41536 13.5035 2.1282 13.4437 1.86369 13.325C1.59919 13.2062 1.36365 13.0314 1.17341 12.8127C0.983163 12.5939 0.84276 12.3364 0.761925 12.0579C0.681091 11.7795 0.661757 11.4868 0.705265 11.2002L2.08526 2.20018C2.15759 1.72327 2.39983 1.28857 2.76736 0.976172C3.13489 0.663773 3.60294 0.494726 4.08526 0.500178H15.3653M15.3653 11.5002V0.500178M15.3653 11.5002H18.0353C18.6012 11.5102 19.1512 11.312 19.5807 10.9434C20.0102 10.5747 20.2894 10.0611 20.3653 9.50018V2.50018C20.2894 1.93924 20.0102 1.42568 19.5807 1.057C19.1512 0.688313 18.6012 0.490169 18.0353 0.500178H15.3653" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                       
                                        </span>
                                        {commData?.dislikeCount}
                                    </div>  
                                    <div className="Post_comment">
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                            <path d="M19.3652 9.00003C19.3687 10.3199 19.0603 11.6219 18.4652 12.8C17.7597 14.2118 16.675 15.3992 15.3327 16.2293C13.9904 17.0594 12.4435 17.4994 10.8652 17.5C9.54537 17.5035 8.24335 17.1951 7.06523 16.6L1.36523 18.5L3.26523 12.8C2.67017 11.6219 2.36179 10.3199 2.36523 9.00003C2.36585 7.42179 2.80585 5.87488 3.63595 4.53258C4.46606 3.19028 5.65349 2.1056 7.06523 1.40003C8.24335 0.80496 9.54537 0.496587 10.8652 0.500028H11.3652C13.4496 0.61502 15.4183 1.49479 16.8944 2.97089C18.3705 4.44699 19.2502 6.41568 19.3652 8.50003V9.00003Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                           
                                        </span>
                                        {commData?.commentCount}
                                    </div>  
                                </div>
                                <div className="Community_share-option">
                                    <div className="Community-share-link">
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
                                            <path d="M5 7H1V17.4C1 18.0896 1.21071 18.7509 1.58579 19.2385C1.96086 19.7261 2.46957 20 3 20H15C15.5304 20 16.0391 19.7261 16.4142 19.2385C16.7893 18.7509 17 18.0896 17 17.4V7H13" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13 5L9 1L5 5" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9 1V10.5" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <div className="share-links">
                                            <div className="share-links-container">
                                                <a onClick={handleCopyClick} rel='noreferrer' data-slug={commData?.slug}  href="javascript:void(0)">
                                                                                        <div className="copy-link flex align">
                                                                                        <div className="copy-link-icon flex">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                                <g clip-path="url(#clip0_6256_8194)">
                                                                                                <path d="M5 6.50001C5.21473 6.78708 5.48868 7.0246 5.80328 7.19648C6.11787 7.36836 6.46575 7.47057 6.82333 7.49618C7.1809 7.52179 7.53979 7.4702 7.87567 7.3449C8.21155 7.21961 8.51656 7.02355 8.77 6.77001L10.27 5.27001C10.7254 4.79851 10.9774 4.167 10.9717 3.51151C10.966 2.85602 10.7031 2.22899 10.2395 1.76547C9.77603 1.30195 9.14899 1.03902 8.4935 1.03333C7.83801 1.02763 7.20651 1.27962 6.735 1.73501L5.875 2.59001" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                                <path d="M6.99912 5.49999C6.7844 5.21293 6.51044 4.9754 6.19585 4.80352C5.88125 4.63164 5.53337 4.52943 5.1758 4.50382C4.81823 4.47822 4.45933 4.52981 4.12345 4.6551C3.78757 4.78039 3.48257 4.97646 3.22912 5.22999L1.72912 6.72999C1.27373 7.2015 1.02174 7.833 1.02744 8.48849C1.03313 9.14399 1.29606 9.77102 1.75958 10.2345C2.2231 10.6981 2.85013 10.961 3.50562 10.9667C4.16111 10.9724 4.79262 10.7204 5.26412 10.265L6.11912 9.40999" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                                </g>
                                                                                                <defs>
                                                                                                <clipPath id="clip0_6256_8194">
                                                                                                    <rect width="12" height="12" fill="white"/>
                                                                                                </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div className="copy-link-text">
                                                                                            copy link
                                                                                        </div>
                                                                                        </div>
                                                </a>
                                                <Link rel='noreferrer' target="_blank" to={`//twitter.com/share?text=${commData?.title}&amp;url=${location}/${commData?.slug}`}>
                                                                                    <div className="share-twitter flex align">
                                                                                        <div className="share-twitter-icon flex">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                            <path d="M11.5 1.49999C11.0212 1.83773 10.4911 2.09605 9.93 2.26499C9.62887 1.91875 9.22867 1.67334 8.78352 1.56196C8.33837 1.45057 7.86975 1.47859 7.44104 1.64222C7.01233 1.80585 6.64422 2.09719 6.38649 2.47685C6.12876 2.85651 5.99385 3.30616 6 3.76499V4.26499C5.12132 4.28778 4.25064 4.0929 3.4655 3.69772C2.68037 3.30253 2.00516 2.71931 1.5 1.99999C1.5 1.99999 -0.5 6.49999 4 8.49999C2.97026 9.19898 1.74358 9.54946 0.5 9.49999C5 12 10.5 9.49999 10.5 3.74999C10.4995 3.61072 10.4861 3.47179 10.46 3.33499C10.9703 2.83174 11.3304 2.19635 11.5 1.49999Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                        </svg>
                                                                                        </div>
                                                                                        <div className="share-twitter-text">
                                                                                        share on twitter
                                                                                        </div>
                                                                                    </div>
                                                </Link>
                                                <Link rel='noreferrer' target="_blank" to={`https://www.linkedin.com/shareArticle?mini=true&url=${location}/${commData?.slug}`}>
                                                                                    <div className="share-linkedin flex align">
                                                                                        <div className="share-linkedin-icon flex">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                            <g clip-path="url(#clip0_6256_8207)">
                                                                                                <path d="M8.20465 4.03659C9.0349 4.03659 9.83114 4.38139 10.4182 4.99515C11.0053 5.60891 11.3351 6.44134 11.3351 7.30932V11.1275H9.24813V7.30932C9.24813 7.01999 9.13819 6.74251 8.9425 6.53793C8.74681 6.33334 8.4814 6.21841 8.20465 6.21841C7.92791 6.21841 7.66249 6.33334 7.4668 6.53793C7.27111 6.74251 7.16118 7.01999 7.16118 7.30932V11.1275H5.07422V7.30932C5.07422 6.44134 5.40403 5.60891 5.9911 4.99515C6.57817 4.38139 7.37441 4.03659 8.20465 4.03659Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                                <path d="M2.98735 4.58203H0.900391V11.1275H2.98735V4.58203Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                                <path d="M1.94387 2.94567C2.52017 2.94567 2.98735 2.45726 2.98735 1.85476C2.98735 1.25227 2.52017 0.763855 1.94387 0.763855C1.36757 0.763855 0.900391 1.25227 0.900391 1.85476C0.900391 2.45726 1.36757 2.94567 1.94387 2.94567Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_6256_8207">
                                                                                                <rect width="12" height="12" fill="white"/>
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div className="share-linkedin-text">share on linkedin</div>
                                                                                    </div>
                                                </Link>
                                                <Link rel='noreferrer' target="_blank" to={`//www.facebook.com/sharer.php?u=${location}/${commData?.slug}`}>
                                                                                    <div className="share-facebook flex align">
                                                                                        <div className="share-facebook-icon flex">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                            <path d="M9 1H7.5C6.83696 1 6.20107 1.26339 5.73223 1.73223C5.26339 2.20107 5 2.83696 5 3.5V5H3.5V7H5V11H7V7H8.5L9 5H7V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3H9V1Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                        </svg>
                                                                                        </div>
                                                                                        <div className="share-facebook-text">
                                                                                            share on facebook
                                                                                        </div>
                                                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={userToken ? handleDeleteClick(commData?.communityId, commData?.isBookmarked ? 'remove' : 'add'): () => notifyE()} className="Community-fav-link">
                                        <span>
                                        {commData?.isBookmarked ? 
                                        <>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="none">
                                        <path d="M14 18.5L7 13.5L0 18.5V2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5H12C12.5304 0.5 13.0391 0.710714 13.4142 1.08579C13.7893 1.46086 14 1.96957 14 2.5V18.5Z" fill="#222222" stroke="#222222"/>
                                        </svg>
                                        </>
                                        : 
                                        <>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="none">
                                        <path d="M14 18.5L7 13.5L0 18.5V2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5H12C12.5304 0.5 13.0391 0.710714 13.4142 1.08579C13.7893 1.46086 14 1.96957 14 2.5V18.5Z" fill="white" stroke="#222222" stroke-width="2"/>
                                        </svg>
                                        </>}    
                                       
                                        </span>
                                    </div>
                                </div>

                            </div>
                            {commentError && <p className="error-name-onb">{commentError}</p>}
                            <div className="Community_comment_box">
                               
                                <div className="emoji_btn">
                                    <a href="javascript:void(0)" className="emoji_link">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M11 21.0001C16.5228 21.0001 21 16.523 21 11.0001C21 5.47727 16.5228 1.00012 11 1.00012C5.47715 1.00012 1 5.47727 1 11.0001C1 16.523 5.47715 21.0001 11 21.0001Z" stroke="#666666" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7 13.0001C7 13.0001 8.5 15.0001 11 15.0001C13.5 15.0001 15 13.0001 15 13.0001" stroke="#666666" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8 8.00012H8.01" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14 8.00012H14.01" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    </a>        
                                </div>
                                <div className="Community-textarea">
                                    <textarea value={comment.comment} onChange={handleCommentChange} name="comment" placeholder='Write a comment...'></textarea>
                                </div>
                                <div className="Community_comment_button">
                                    <button className='primary-btn green' onClick={userToken? () => postComment(): ()=> notifyE() }>
                                    {loading ? (
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                            ) : (
                                                'Post'
                                        )}
                                    </button>
                                 </div>
                               
                            </div>
                            <div className="Community-comment-info-wrapper">
                                {commData?.communityComments?.map((data,index) => {
                                return (
                                    <div key={index} className="Community_comment_info_box">
                                    <div className="Commuity-top-comment-bar">
                                            <div className="Community_comment_author-info">
                                                <div className="Community_comment-author-img">
                                                    <img src={data?.avatar} alt=""></img> 
                                                </div>
                                                <div className="Community-comment_author-infor-box">
                                                    <div className="Community_Comment_author-title">
                                                        <div className="authorname">
                                                            {data?.fullName}
                                                        </div>     
                                                        <div className="Comment-date">
                                                           {data?.createdAt}
                                                        </div>
                                                    </div> 
                                                    <div className="author-Category">
                                                        {data?.userType}
                                                    </div>     
                                                </div>
                                            </div>
                                            <div className="Commuity_popup_button">
                                            <a  href="javascript:void(0)" className="Community_popup_svg">
                                                    <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4" fill="none">
                                                        <path d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    </span>
                                                   
                                                      <div className='threedotsMenu' ref={menuRef2}>
                                                          <button class="report-button" onClick={userToken?  () => openReportPop(data): ()=> notifyE()}>Report</button>
                                                      </div>
                                                 
                                            </a>
                                        </div>
                                    </div>
                                    <div className="Comment-Info">
                                        <p>
                                        {data?.comment}
                                        </p>
                                    </div>
                                    </div>
                                );
                                })}

{props?.hasMorePages && <div className='loadmore-btn'><div className='primary-btn black' onClick={() => handleLoadMore(props?.currentPage)}>{loadingCom ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    'Load More Comments'
                                )}</div></div>}
                            </div>
                          </div>
                      </div>
             </div>
            </div>
         
        </div>
     </section>
      <div className="mentee-profile-pop-ups">
      <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ popUpOpen? 'active' : ''}`}>
          {popUpOpen && (
              <ReportPopup
                  data={props}
                  noti={notifyRe}
                  onclose={setReportClose}
              />
          )}
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc report-pop-blog ${ reportPop ? 'active' : ''}`}>
                <ReportComment data={selectedData} closeR={closeReportPop} noti={notifyComR} />
      </div>
  </div>
  </>
  )
}
