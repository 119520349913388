import React from 'react';
import { useState } from 'react'
import './BlogInner.css';
import '../blogs/blogs.css';
import bloginnerimg1 from '../../assets/images/blog-inner1.png'; 

function BlogInnerimg({props}) {


  return (
    <div className="blogInner-img">
        <div className="bii-container">
          <div className="bii-img">
            <img src={props} />
          </div>
        </div>
    </div>
  );
}

export default BlogInnerimg;