const actionTypes = {
    START_LOADING: 'START_LOADING',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    ONBOARDING_MENTEE: 'ONBOARDING_MENTEE',
    DASHBOARD_MENTEE: 'DASHBOARD_MENTEE',
    PROFILE_MENTEE: 'PROFILE_MENTEE',
    UPDATE_PROFILE_MENTEE: 'UPDATE_PROFILE_MENTEE',
    UPDATE_EDUCATION_MENTEE: 'UPDATE_PROFILE_MENTEE',
    FAVORITE_MENTORS: 'FAVORITE_MENTORS',
    AVAILABILITY_STATUS:'AVAILABILITY_STATUS',
    AVAILABILITY_EDIT:'AVAILABILITY_EDIT',
    AVAILABILITY_EDIT_DATA:'AVAILABILITY_EDIT_DATA',
    MENTOR_TRANS_HISTORY:'MENTOR_TRANS_HISTORY',
    MENTOR_EARNING:'MENTOR_EARNING',
    MENTOR_EARNING_NOT_FOUND: 'MENTOR_EARNING_NOT_FOUND',
    MENTOR_WITHDRAW: 'MENTOR_WITHDRAW',
    FAILURE: 'FAILURE',
    LOGOUT: 'LOGOUT',
    BLOG_LIST:'BLOG_LIST',
    COMMUNITY_LIST:'COMMUNITY_LIST',
    COMMUNITY_LIST_TOKEN:'COMMUNITY_LIST_TOKEN',
    BLOG_LISTING: 'BLOG_LISTING',
    RESOURCES:'RESOURCES',
    COMMUNITY:'COMMUNITY',
    MENTOR_BLOGS:'MENTOR_BLOGS',
    MENTOR_DRAFT_BLOGS:'MENTOR_DRAFT_BLOGS',
    MENTOR_PENDING_BLOGS:'MENTOR_PENDING_BLOGS',
    MENTOR_PUBLISHED_BLOGS:'MENTOR_PUBLISHED_BLOGS',
    MENTOR_REJECTED_BLOGS:'MENTOR_REJECTED_BLOGS',
    REMOVE_ERROR:'REMOVE_ERROR',
    REVIEWS_MENTORS:'REVIEWS_MENTORS',
    MENTOR_BLOGS_EDIT:'MENTOR_BLOGS_EDIT',
    RESOURCES_COMM: 'RESOURCES_COMM',
    UPCOMING_SESSION_DATA:'UPCOMING_SESSION_DATA',
    COMPLETED_SESSION_DATA:'COMPLETED_SESSION_DATA',
    CANCELLED_SESSION_DATA:'CANCELLED_SESSION_DATA',
    CANCEL_REQUESTED_SESSION_DATA:'CANCEL_REQUESTED_SESSION_DATA',
    REPORTED_SESSION_DATA:'REPORTED_SESSION_DATA', 
    PURCHASE_HISTORY_DATA:'PURCHASE_HISTORY_DATA', 
    MENTEE_STATS:'MENTEE_STATS', 
    MENTOR_STATS:'MENTOR_STATS', 
};

export default actionTypes;