import React,{useEffect, useContext,useState} from 'react';
import NoSesh from '../../assets/images/no-sesh.svg';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../reusable/loading/Loader';
import { Link } from 'react-router-dom';
import CommunityCard from './CommunityCard';
import Cookies from 'js-cookie';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import actionTypes from '../../context/actionTypes';


export default function MentorCommunity() {
  const { getCommunityCards, state, dispatch } = useContext(AuthContext);

  const [communityData, setCommunityData] = useState(state.communityData);

  const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (index) => {
        setActiveTab(index);
      };
  
  const userRole = Cookies.get('userRole');
  useEffect(()=>{
    setCommunityData((prevData)=> (
      state.communityData
     ))
  },[state.communityData]);

  const handleLoadMore = async (pageNo) => {
    let res =await getCommunityCards(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.communityData,
            ...res,
            result: [...state.communityData.result, ...res.result]
          };
        dispatch({ type: actionTypes.COMMUNITY, payload: mergedData});
    }
}
  
//   console.log("getCommunityCards" , state.communityData)
  return(
      <div className="fav-mentors-full dashboard-empty-state">
      {state.loading && <Loader/>}
          <div className="dash-empty-sessions-content">
              <div className="dash-empty-sess-head">my community posts</div>
              <div className="dash-empty-sess-body">
                  <p>Checks all your posts for community, tomorrow and whenever here!</p>
              </div>  
              <div className='newblogbtn'>
                    <Link to={`/community/create`} className="primary-btn border">create new post</Link>
                </div>             
          </div>
          <div className="dash-session-list">
              <div className='dsl-tabs'>
              <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
                    <div className='dsltabs-tabs'>
                        <TabList>
                            <Tab onClick={() => getCommunityCards(1)}>All</Tab>
                            {/* <Tab onClick={() => getMentorDraftBlogs(1)}>Drafts</Tab> */}
                        </TabList>
                    </div>
                    <div className='dsltabs-data'>
                        {/* All Blog Post */}
                        <TabPanel>
                        {communityData?.result?.length > 0 ? <>
                          {communityData?.result && communityData?.result?.map((data,index) => {
                              return (
                                  <CommunityCard get={getCommunityCards} post={data} key={index} />
                              );
                          })}
                           { state?.communityData?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMore(state.communityData.currentPage)}>
                                Load More
                            </div>
                            </div> }
                          </>
                          : 
                          <>
                           <div className="dash-emp-no-sess">
                 <div className="dash-emp-no-sess-wrap">
                     <div className="dash-emp-no-sess-img">
                         <img src={NoSesh} alt="" />
                     </div>
                     <div className="dash-emp-no-sess-head">You haven't created a post!</div>
                     <div className="dash-emp-no-sess-body">
                     {userRole === 'mentor' ? (<p>As a mentor, you haven't started your journey yet! Start taking sessions and sharing your expertise with mentees today</p>) : (<p>you haven't written any community post yet</p>)} 
                     </div>
                 <div className="dash-emp-no-sess-btn">
                     <Link to={`/community`} className="primary-btn black">explore community</Link>
                 </div>
                 </div>
                             </div>  
                          </> }
                        </TabPanel>
                        {/* All Blog Post */}

                        {/* Drafts Blog Post */}
                        {/* <TabPanel>
                        {
                            state?.mentorDraftBlogs?.result?.length > 0 ? (
                                state?.mentorDraftBlogs?.result?.map((data, index) => (
                                <BlogCard
                                post={data}
                                key={index}
                                />
                            ))
                            ) : (
                            <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">There's nothing in your drafts yet</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>Create a blog by clicking on the button below.</p>
                                    </div>
                                    <div className="dash-emp-no-sess-btn">
                                        <Link to={`/blogs/create`} className="primary-btn black">create new blog</Link>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                           { state?.mentorDraftBlogs?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMoreDrafts(state.mentorDraftBlogs.currentPage)}>
                                Load More
                            </div>
                            </div> }
                        </TabPanel> */}
                        {/* Drafts Blog Post */}
                    </div>
                </Tabs>
                  
            
              </div>
            
          </div>  
  
                  
          
    </div>
    
    );
  
}
