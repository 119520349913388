import React from 'react';
import './Testimonials.css';
import Ticker from '../reusable/ticker/Ticker';
import Testi from '../../assets/images/testimonials.png';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, EffectFade } from "swiper";
import "swiper/css/effect-fade";

export default function Testimonials() {
  const texts=["Guiding brand","Community oriented brand","Uplifting brand","Guiding brand","Community oriented brand","Uplifting brand"];
  const dynamicStyles = {
    bg: {
      background: '#F8F6ED',
      color:'#444444',
      bottom: '56px'
    },
    fill: {
     fill:'#FCD26E'
    }
  };
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
   <section className="insage-testimonials">
       <div className="container">
       <Swiper  autoHeight={false} effect={"fade"} speed={0}  navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current,}} modules={[EffectFade,Navigation]} className="mySwiper testimonials-swiper">
       <SwiperSlide>
        <div className="testimonials-wrapper flex">
           <div className="testimonials-image">
            <img src="https://simpletesting.website/insage-images/testimonials.jpeg" alt="" className="img" />
           </div>
           <div className="testimonials-content">
            <div className="testimonials-content-wrapper">
                <div className="testimonials-content-body">
                    <h3>“Navigating the early years of my career was daunting until I found Insage. The mentors I connected with not only offered expert advice but also instilled the belief that 'the sky is the limit'.”</h3>
                    </div>
                  <div className="testimonials-content-separator"></div>
                  <div className="testimonials-content-details">
                    <div className="testimonials-content-name">
                        <h6>Jennifer</h6>
                    </div>
                    <div className="testimonials-content-designation">
                        <span className="label">Marketing Executive</span>
                    </div>
                    </div>  
            </div>
           </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonials-wrapper flex">
           <div className="testimonials-image">
            <img src="https://simpletesting.website/insage-images/testimonials.jpeg" alt="" className="img" />
           </div>
           <div className="testimonials-content">
            <div className="testimonials-content-wrapper">
                <div className="testimonials-content-body">
                    <h3>“I’m so grateful that I came across Insage. It matched me with a mentor who shared my passion for writing. Not only did they give feedback on my craft, but they also told me about new doors opening up in my field.”</h3>
                    </div>
                  <div className="testimonials-content-separator"></div>
                  <div className="testimonials-content-details">
                    <div className="testimonials-content-name">
                        <h6>Alex</h6>
                    </div>
                    <div className="testimonials-content-designation">
                        <span className="label">Aspiring Writer</span>
                    </div>
                    </div>  
            </div>
           </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonials-wrapper flex">
           <div className="testimonials-image">
            <img src="https://simpletesting.website/insage-images/testimonials.jpeg" alt="" className="img" />
           </div>
           <div className="testimonials-content">
            <div className="testimonials-content-wrapper">
                <div className="testimonials-content-body">
                    <h3>“Insage is a hub of knowledge for aspiring entrepreneurs. My mentors helped me get a sense of the market. And the community helped me network with people I’d collaborate with. I would never recommend missing this step in your entrepreneurial journey.”</h3>
                    </div>
                  <div className="testimonials-content-separator"></div>
                  <div className="testimonials-content-details">
                    <div className="testimonials-content-name">
                        <h6>Michael Martinez</h6>
                    </div>
                    <div className="testimonials-content-designation">
                        <span className="label">Entrepreneurial Enthusiast</span>
                    </div>
                    </div>  
            </div>
           </div>
        </div>
        </SwiperSlide>
        <div className="prev-btn yellow" ref={navigationPrevRef}>
            <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.256229 20.9111L12.7305 33.3854L16.5177 29.5981L6.71894 19.8023L6.73358 19.8023L26.3432 19.7961L26.2526 14.4274L6.7338 14.4336L6.72209 14.4336L16.5271 4.62862L12.7407 0.845215L0.258618 13.3273L0.256229 20.9111Z" />
            </svg>
        </div>
        <div className="next-btn yellow" ref={navigationNextRef}>
            <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.7438 20.9111L14.2695 33.3854L10.4823 29.5981L20.2811 19.8023L20.2664 19.8023L0.656813 19.7961L0.747358 14.4274L20.2662 14.4336L20.2779 14.4336L10.4729 4.62862L14.2593 0.845215L26.7414 13.3273L26.7438 20.9111Z"/>
            </svg>
        </div>
        </Swiper>
       </div>
       <Ticker texts={texts} styles={dynamicStyles}/>
   </section>
  )
}
