import React, {useEffect, useState} from 'react'
import {getRequest} from '../context/api.js'

function useFetchRecipient(chat, user) {

    const [recipientUser , setRecipientUser] = useState(null);
    const [error, setError] = useState(null);

    const recipientId = chat?.members.find((id) => id !== user?.userId);
    useEffect(() => {

        const getUser = async () => {
            if(!recipientId) return null;
            const response = await getRequest(`${process.env.REACT_APP_API_URL}/users/find/${recipientId}`);
            if(response.error){
                return setError(response);
            }
            setRecipientUser(response);
        }
        getUser();
    },[recipientId])
    
    return {recipientUser}
}

export default useFetchRecipient