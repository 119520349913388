import React, { useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import NoSesh from '../../assets/images/no-sesh.svg';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const SavedResourcesEmpty = () => {

    const { getSavedResources, getSavedResourcesCoummunity } = useContext(AuthContext);

  return (
    <div className="fav-mentors-empty dashboard-empty-state">
         <div className="dash-empty-sessions-content">
            <div className="dash-empty-sess-head">Saved Resources</div>
            <div className="dash-empty-sess-body"><p>Check all your sessions for today, tomorrow and whenever here.</p></div>
        </div>

        <div className="dash-session-list">
            <div className='dsl-tabs'>
                <Tabs>
                    <div className='dsltabs-tabs'>
                            <TabList>
                            <Tab onClick={() => getSavedResourcesCoummunity(1)}>community posts</Tab>
                            </TabList>
                    </div>
                    <div className='dsltabs-data'>
                        <TabPanel>
                            <div className="dash-emp-no-sess">
                <div className="dash-emp-no-sess-wrap">
                    <div className="dash-emp-no-sess-img">
                        <img src={NoSesh} alt="" />
                    </div>
                    <div className="dash-emp-no-sess-head">You have no saved blogs</div>
                    <div className="dash-emp-no-sess-body">
                         <p>See your saved resources here in both "Blogs" & "Community"</p>
                    </div>
                <div className="dash-emp-no-sess-btn">
                <Link to={`/blogs`} className="primary-btn black">explore Blogs</Link>
                </div>
                </div>
                            </div> 
                        </TabPanel>
                        <TabPanel>
                            <div className="dash-emp-no-sess">
                <div className="dash-emp-no-sess-wrap">
                    <div className="dash-emp-no-sess-img">
                        <img src={NoSesh} alt="" />
                    </div>
                    <div className="dash-emp-no-sess-head">You have no saved Community</div>
                    <div className="dash-emp-no-sess-body">
                         <p>See your saved resources here in both "Blogs" & "Community"</p>
                    </div>
                <div className="dash-emp-no-sess-btn">
                <Link to={`/community`} className="primary-btn black">explore community</Link>
                </div>
                </div>
                            </div> 
                        </TabPanel>
                    </div>
                </Tabs>
            </div>

        </div>

             
   </div>
  )
}

export default SavedResourcesEmpty
