import React, { useState,useEffect,useRef } from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import BlogInnerhead from '../../components/bloginner/BlogInner-head';
import BlogInnerimg from '../../components/bloginner/BlogInner-img';
import BlogInnertxt from '../../components/bloginner/BlogInnertxt';
import BlogInnercomment from '../../components/bloginner/blogInner-comment';
import Similarblogs from '../../components/bloginner/blogInnersim';
import BlogCreate from '../../components/blogs/BlogCreate';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; 
import Cookies from 'js-cookie';
import Loader from '../../components/reusable/loading/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import ReportPop from '../../components/bloginner/ReportPop';
import CommPop from '../../components/bloginner/CommPop';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PreviewBlog() {

    // const { slug } = useParams();
    // const navigation = useNavigate();
    // const[reportPop, setReportPop] = useState(false);
    // const[commPop, setCommPop] = useState(false);
    // const [repostdata, setReportData] = useState({});
    // const[selectedData, setSelectedData] = useState(false);

    // let userRole = Cookies.get('userRole');

    // const [loading, setLoading] = useState(true);
    // const [blogDetails, setBlogDetails] = useState([]);
    // const [error, setError] = useState(null);






    return (
        <main className='isg-profile-main'>
            <Header/>
            <div className='blog-in-design'>
                

                
            </div>
            <Footer/>
        </main>
    );
  }
  
  export default PreviewBlog;
