import React from 'react';
import './BlogInner.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination} from "swiper";

export default function CommPop({closeC}) {
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
  return (
    <div className="insage-login dashboard-popups">
    <div className="insage-login-wrapper">
        <div className="login-head"><span>community guidelines</span></div>
        <div className='popup-description'>
            {/* <p className='small'>Lorem ipsum dolor sit amet consectetur. Tortor nascetur viverra semper lectus. Proin congue diam gravida sodales sollicitudin ullamcorper ut at. Nisl rhoncus congue.</p> */}
        </div>
         <div className="community-guidleines-swiper">
            <Swiper modules={[Navigation]} breakpoints={{
                                    0:{
                                        slidesPerView: 1,
                                    },                
                                    1200: {
                                    slidesPerView: 1,
                                    }
                                }}  slidesPerView={1} spaceBetween={0}  navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current,}}  onSwiper={(swiper) => {
                                    // Delay execution for the refs to be defined
                                    setTimeout(() => {
                                    // Override prevEl & nextEl now that refs are defined
                                    swiper.params.navigation.prevEl = navigationPrevRef.current
                                    swiper.params.navigation.nextEl = navigationNextRef.current

                                    // Re-init navigation
                                    swiper.navigation.destroy()
                                    swiper.navigation.init()
                                    swiper.navigation.update()
                                    })
                                }} className="mySwiper dash-top-rated-mentors-slider">
                                        <div className="community-slider-navigation flex align">
                                            <div className="community-slider-left" ref={navigationPrevRef}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M6 12L10 8L6 4" stroke="#484848" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                            <div className="community-slider-middle">
                                                
                                            </div>
                                            <div className="community-slider-right" ref={navigationNextRef}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M6 12L10 8L6 4" stroke="#484848" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                    <SwiperSlide>
                                        <div className="community-guidelines-slide-top flex align">
                                            <div className="community-guidelines-slide-top-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M18 5.89541C18 3.15289 15.7768 0.929688 13.0348 0.929688C11.3701 0.929688 9.90126 1.75144 9 3.00746C8.09874 1.75144 6.62986 0.929688 4.96573 0.929688C2.2232 0.929688 0 3.15239 0 5.89541C0 6.2839 0.0493151 6.6603 0.133855 7.02312C0.82326 11.307 5.58619 15.8264 9 17.0673C12.4133 15.8264 17.1767 11.307 17.8651 7.02362C17.9507 6.6608 18 6.2844 18 5.89541Z" fill="#F4ABBA"/>
                                                <path d="M15.7535 6.67212C15.7535 4.61347 14.0853 2.94531 12.0271 2.94531C10.7782 2.94531 9.67562 3.56175 8.9988 4.50478C8.32248 3.56175 7.21993 2.94531 5.97095 2.94531C3.9128 2.94531 2.24414 4.61347 2.24414 6.67212C2.24414 6.96348 2.28138 7.24629 2.34428 7.51803C2.86209 10.7331 6.43643 14.1257 8.9988 15.0567C11.5607 14.1252 15.1355 10.7331 15.6528 7.51853C15.7167 7.24629 15.7535 6.96348 15.7535 6.67212Z" fill="#EA596E"/>
                                                <path d="M13.6261 7.39996C13.6261 5.99046 12.4838 4.84766 11.0748 4.84766C10.2193 4.84766 9.46452 5.26985 9.00106 5.91598C8.5381 5.26985 7.78277 4.84766 6.92731 4.84766C5.5178 4.84766 4.375 5.99046 4.375 7.39996C4.375 7.59974 4.40016 7.79297 4.44344 7.97916C4.7982 10.1812 7.24584 12.5041 9.00055 13.1416C10.7543 12.5036 13.2034 10.1807 13.5572 7.97916C13.6014 7.79297 13.6261 7.59924 13.6261 7.39996Z" fill="#DD2E44"/>
                                                </svg>
                                            </div>
                                            <div className="community-guidelines-slide-top-head">
                                            Be respectful
                                            </div>
                                        </div>
                                        <div className="community-guidelines-slide-bottom">
                                            <p className='small'>Treat others as you would like to be treated. Do not insult, harass, or bully anyone. Avoid using profanity, hate speech, or personal attacks.</p>
                                        </div>
                                        
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="community-guidelines-slide-top flex align">
                                            <div className="community-guidelines-slide-top-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M18 5.89541C18 3.15289 15.7768 0.929688 13.0348 0.929688C11.3701 0.929688 9.90126 1.75144 9 3.00746C8.09874 1.75144 6.62986 0.929688 4.96573 0.929688C2.2232 0.929688 0 3.15239 0 5.89541C0 6.2839 0.0493151 6.6603 0.133855 7.02312C0.82326 11.307 5.58619 15.8264 9 17.0673C12.4133 15.8264 17.1767 11.307 17.8651 7.02362C17.9507 6.6608 18 6.2844 18 5.89541Z" fill="#F4ABBA"/>
                                                <path d="M15.7535 6.67212C15.7535 4.61347 14.0853 2.94531 12.0271 2.94531C10.7782 2.94531 9.67562 3.56175 8.9988 4.50478C8.32248 3.56175 7.21993 2.94531 5.97095 2.94531C3.9128 2.94531 2.24414 4.61347 2.24414 6.67212C2.24414 6.96348 2.28138 7.24629 2.34428 7.51803C2.86209 10.7331 6.43643 14.1257 8.9988 15.0567C11.5607 14.1252 15.1355 10.7331 15.6528 7.51853C15.7167 7.24629 15.7535 6.96348 15.7535 6.67212Z" fill="#EA596E"/>
                                                <path d="M13.6261 7.39996C13.6261 5.99046 12.4838 4.84766 11.0748 4.84766C10.2193 4.84766 9.46452 5.26985 9.00106 5.91598C8.5381 5.26985 7.78277 4.84766 6.92731 4.84766C5.5178 4.84766 4.375 5.99046 4.375 7.39996C4.375 7.59974 4.40016 7.79297 4.44344 7.97916C4.7982 10.1812 7.24584 12.5041 9.00055 13.1416C10.7543 12.5036 13.2034 10.1807 13.5572 7.97916C13.6014 7.79297 13.6261 7.59924 13.6261 7.39996Z" fill="#DD2E44"/>
                                                </svg>
                                            </div>
                                            <div className="community-guidelines-slide-top-head">
                                            Be relevant
                                            </div>
                                        </div>
                                        <div className="community-guidelines-slide-bottom">
                                            <p className='small'>Post questions and comments that are related to the topic and purpose of the forum. Provide feedback and suggestions that are helpful and polite. Do not spam, troll, or derail the conversation.</p>
                                        </div>
                                        
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="community-guidelines-slide-top flex align">
                                            <div className="community-guidelines-slide-top-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M18 5.89541C18 3.15289 15.7768 0.929688 13.0348 0.929688C11.3701 0.929688 9.90126 1.75144 9 3.00746C8.09874 1.75144 6.62986 0.929688 4.96573 0.929688C2.2232 0.929688 0 3.15239 0 5.89541C0 6.2839 0.0493151 6.6603 0.133855 7.02312C0.82326 11.307 5.58619 15.8264 9 17.0673C12.4133 15.8264 17.1767 11.307 17.8651 7.02362C17.9507 6.6608 18 6.2844 18 5.89541Z" fill="#F4ABBA"/>
                                                <path d="M15.7535 6.67212C15.7535 4.61347 14.0853 2.94531 12.0271 2.94531C10.7782 2.94531 9.67562 3.56175 8.9988 4.50478C8.32248 3.56175 7.21993 2.94531 5.97095 2.94531C3.9128 2.94531 2.24414 4.61347 2.24414 6.67212C2.24414 6.96348 2.28138 7.24629 2.34428 7.51803C2.86209 10.7331 6.43643 14.1257 8.9988 15.0567C11.5607 14.1252 15.1355 10.7331 15.6528 7.51853C15.7167 7.24629 15.7535 6.96348 15.7535 6.67212Z" fill="#EA596E"/>
                                                <path d="M13.6261 7.39996C13.6261 5.99046 12.4838 4.84766 11.0748 4.84766C10.2193 4.84766 9.46452 5.26985 9.00106 5.91598C8.5381 5.26985 7.78277 4.84766 6.92731 4.84766C5.5178 4.84766 4.375 5.99046 4.375 7.39996C4.375 7.59974 4.40016 7.79297 4.44344 7.97916C4.7982 10.1812 7.24584 12.5041 9.00055 13.1416C10.7543 12.5036 13.2034 10.1807 13.5572 7.97916C13.6014 7.79297 13.6261 7.59924 13.6261 7.39996Z" fill="#DD2E44"/>
                                                </svg>
                                            </div>
                                            <div className="community-guidelines-slide-top-head">
                                            Be honest
                                            </div>
                                        </div>
                                        <div className="community-guidelines-slide-bottom">
                                            <p className='small'>Use your real name and identity when posting. Do not impersonate anyone or misrepresent your affiliation. Do not post any false or misleading information.</p>
                                        </div>
                                        
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="community-guidelines-slide-top flex align">
                                            <div className="community-guidelines-slide-top-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M18 5.89541C18 3.15289 15.7768 0.929688 13.0348 0.929688C11.3701 0.929688 9.90126 1.75144 9 3.00746C8.09874 1.75144 6.62986 0.929688 4.96573 0.929688C2.2232 0.929688 0 3.15239 0 5.89541C0 6.2839 0.0493151 6.6603 0.133855 7.02312C0.82326 11.307 5.58619 15.8264 9 17.0673C12.4133 15.8264 17.1767 11.307 17.8651 7.02362C17.9507 6.6608 18 6.2844 18 5.89541Z" fill="#F4ABBA"/>
                                                <path d="M15.7535 6.67212C15.7535 4.61347 14.0853 2.94531 12.0271 2.94531C10.7782 2.94531 9.67562 3.56175 8.9988 4.50478C8.32248 3.56175 7.21993 2.94531 5.97095 2.94531C3.9128 2.94531 2.24414 4.61347 2.24414 6.67212C2.24414 6.96348 2.28138 7.24629 2.34428 7.51803C2.86209 10.7331 6.43643 14.1257 8.9988 15.0567C11.5607 14.1252 15.1355 10.7331 15.6528 7.51853C15.7167 7.24629 15.7535 6.96348 15.7535 6.67212Z" fill="#EA596E"/>
                                                <path d="M13.6261 7.39996C13.6261 5.99046 12.4838 4.84766 11.0748 4.84766C10.2193 4.84766 9.46452 5.26985 9.00106 5.91598C8.5381 5.26985 7.78277 4.84766 6.92731 4.84766C5.5178 4.84766 4.375 5.99046 4.375 7.39996C4.375 7.59974 4.40016 7.79297 4.44344 7.97916C4.7982 10.1812 7.24584 12.5041 9.00055 13.1416C10.7543 12.5036 13.2034 10.1807 13.5572 7.97916C13.6014 7.79297 13.6261 7.59924 13.6261 7.39996Z" fill="#DD2E44"/>
                                                </svg>
                                            </div>
                                            <div className="community-guidelines-slide-top-head">
                                            Be responsible
                                            </div>
                                        </div>
                                        <div className="community-guidelines-slide-bottom">
                                            <p className='small'>Follow the rules and policies of the forum. Report any violations or problems. Accept the consequences of your actions and learn from your mistakes.</p>
                                        </div>
                                        
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="community-guidelines-slide-top flex align">
                                            <div className="community-guidelines-slide-top-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M18 5.89541C18 3.15289 15.7768 0.929688 13.0348 0.929688C11.3701 0.929688 9.90126 1.75144 9 3.00746C8.09874 1.75144 6.62986 0.929688 4.96573 0.929688C2.2232 0.929688 0 3.15239 0 5.89541C0 6.2839 0.0493151 6.6603 0.133855 7.02312C0.82326 11.307 5.58619 15.8264 9 17.0673C12.4133 15.8264 17.1767 11.307 17.8651 7.02362C17.9507 6.6608 18 6.2844 18 5.89541Z" fill="#F4ABBA"/>
                                                <path d="M15.7535 6.67212C15.7535 4.61347 14.0853 2.94531 12.0271 2.94531C10.7782 2.94531 9.67562 3.56175 8.9988 4.50478C8.32248 3.56175 7.21993 2.94531 5.97095 2.94531C3.9128 2.94531 2.24414 4.61347 2.24414 6.67212C2.24414 6.96348 2.28138 7.24629 2.34428 7.51803C2.86209 10.7331 6.43643 14.1257 8.9988 15.0567C11.5607 14.1252 15.1355 10.7331 15.6528 7.51853C15.7167 7.24629 15.7535 6.96348 15.7535 6.67212Z" fill="#EA596E"/>
                                                <path d="M13.6261 7.39996C13.6261 5.99046 12.4838 4.84766 11.0748 4.84766C10.2193 4.84766 9.46452 5.26985 9.00106 5.91598C8.5381 5.26985 7.78277 4.84766 6.92731 4.84766C5.5178 4.84766 4.375 5.99046 4.375 7.39996C4.375 7.59974 4.40016 7.79297 4.44344 7.97916C4.7982 10.1812 7.24584 12.5041 9.00055 13.1416C10.7543 12.5036 13.2034 10.1807 13.5572 7.97916C13.6014 7.79297 13.6261 7.59924 13.6261 7.39996Z" fill="#DD2E44"/>
                                                </svg>
                                            </div>
                                            <div className="community-guidelines-slide-top-head">
                                            Be supportive 
                                            </div>
                                        </div>
                                        <div className="community-guidelines-slide-bottom">
                                            <p className='small'>Respect the diversity and opinions of others. Do not discriminate or exclude anyone based on their identity, background, or beliefs.</p>
                                        </div>
                                        
                                    </SwiperSlide>
            </Swiper>
         </div>
         {/* <div className="community-guidelines-button">
             <button className="primary-btn border">
                Read More
             </button>
         </div> */}
    </div>
    <div className="login-close-button" onClick={closeC}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </div>
</div>
  )
}
