import React, { useState } from 'react';
import MultiSelectTags from '../reusable/multi-select/multiSelect';

const ObStep3 = ({nextStep,prevStep, handleOnboardingInputChange, values}) => {


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // setEducationData((prevData) => ({
        //     ...prevData,
        //     [name]: value,
        // }));
    };

    

    const handleGuidance = (e) => {
        handleOnboardingInputChange({
            target: {
                name: "guidanceState",
                value: e.target.value,
            },
        });

        handleOnboardingInputChange({
            target: {
                name: "guidance",
                value: e.target.value.map((option) => option.value),
            },
        });
    };

    const[areaError, setAreaError] = useState('');
    const[indusError, setIndusError] = useState('');
    const[expError, setExpError] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        if(values.guidanceState.length === 0){
            setAreaError("Please select at least one option");
            return;
        }  
        if(values.industry.length === 0){
            setIndusError("Please select an industry");
            return;
        }  
        if(values.levelOfExp.length === 0){
            setExpError("Please select a level");
            return;
        }  
        nextStep();
    };

    const handleBack = () =>{
        prevStep();
    }
    return (
    <>
      <div className='stepLine'>
            <div className='slFilled width40'></div>
        </div>
        <div className='obStep'>
            <div className='obStepDetails'>
                step 3 of 9
            </div>
            <div className="obHeadingWrapper flex flex-jsb">
            <div className='obHeading'>
                <h5>Tell Us More About Your Background.</h5>
            </div>
            <div className="obHeadingSvg">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" viewBox="0 0 35 33" fill="none">
                <path d="M33.8488 14.893L24.9498 11.599L21.6268 0.708C21.4988 0.288 21.1098 0 20.6708 0C20.2318 0 19.8428 0.288 19.7148 0.708L16.3928 11.599L7.49284 14.893C7.09984 15.039 6.83984 15.412 6.83984 15.831C6.83984 16.249 7.09984 16.624 7.49284 16.769L16.3878 20.062L19.7118 31.285C19.8378 31.709 20.2278 32 20.6708 32C21.1128 32 21.5038 31.709 21.6298 31.284L24.9538 20.061L33.8498 16.768C34.2408 16.624 34.5018 16.25 34.5018 15.831C34.5018 15.413 34.2408 15.039 33.8488 14.893Z" fill="#FFAC33"/>
                <path d="M13.847 25.8939L11.533 25.0379L10.633 21.7379C10.515 21.3019 10.12 20.9999 9.669 20.9999C9.218 20.9999 8.823 21.3019 8.704 21.7369L7.804 25.0369L5.491 25.8929C5.098 26.0379 4.838 26.4129 4.838 26.8309C4.838 27.2489 5.098 27.6239 5.491 27.7689L7.792 28.6219L8.699 32.2439C8.811 32.6879 9.21 32.9999 9.669 32.9999C10.128 32.9999 10.527 32.6879 10.639 32.2429L11.546 28.6209L13.847 27.7679C14.24 27.6239 14.5 27.2489 14.5 26.8309C14.5 26.4129 14.24 26.0379 13.847 25.8939ZM9.509 4.23089L7.145 3.35589L6.269 0.990891C6.124 0.59789 5.75 0.337891 5.331 0.337891C4.913 0.337891 4.539 0.59789 4.393 0.990891L3.518 3.35589L1.153 4.23089C0.76 4.37689 0.5 4.75089 0.5 5.16889C0.5 5.58689 0.76 5.96189 1.153 6.10689L3.518 6.98189L4.393 9.34689C4.539 9.73989 4.913 9.99989 5.331 9.99989C5.749 9.99989 6.123 9.73989 6.269 9.34689L7.144 6.98189L9.509 6.10689C9.902 5.96089 10.162 5.58689 10.162 5.16889C10.162 4.75089 9.902 4.37689 9.509 4.23089Z" fill="#FFCC4D"/>
            </svg>
            </div>
            </div>
            <div className='obFormData'>
                <form onSubmit={handleSubmit}>
                    
                    <div className='login-form-input flex flex-c'>
                    <label htmlFor="industry/expertise">What is your industry/expertise?</label>
                    <div className="select-wrapper">
                    {indusError && <p className="error-name-onb">{indusError}</p>}    
                    <select
                            id="industry-expertise"
                            name="industry"
                            value={values.industry}
                            onChange={handleOnboardingInputChange}
                            >
                            <option value="" disabled>Eg. Design, Development, Marketing etc.</option>
                            <option value="Art & Design">Art & Design</option>
                            <option value="Coding & Dev">Coding & Dev</option>
                            <option value="Marketing">Marketing</option>
                            <option value="IT & Software">IT & Software</option>
                            <option value="Personal Development">Personal Development</option>
                            <option value="Business & Sales">Business & Sales</option>
                            <option value="Photography">Photography</option>
                            <option value="Management">Management</option>
                            <option value="Other">Other</option>
                            </select>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                        <label htmlFor="name">What areas do you feel confident in providing guidance for?</label>
                        <div className="select-wrapper">
                        {areaError && <p className="error-name-onb">{areaError}</p>}    
                        <MultiSelectTags
                        id="assistance"
                        name="guidance"
                        options={[
                            { value: 'Domain Related', label: 'Domain Related' },
                            { value: 'Organisational Culture', label: 'Organisational Culture' },
                            { value: 'Management Skills', label: 'Management Skills' },
                            { value: 'Career Path', label: 'Career Path' },
                            { value: 'Feedback & Conflict Resolution', label: 'Feedback & Conflict Resolution' },
                            { value: 'Effective communication', label: 'Effective communication' },
                            { value: 'Networking', label: 'Networking' },
                            { value: 'Leadership Skills', label: 'Leadership Skills' }
                            // Add more options as needed

                        ]}
                        value={values.guidanceState}
                        onChange={handleGuidance}
                        />
                        </div>
                    </div>
                    <div className='login-form-input flex flex-c'>
                        <label htmlFor="levelOfExp">What is your level of experience?</label>
                        <div className="select-wrapper">
                        {expError && <p className="error-name-onb">{expError}</p>}     
                        <select
                        id="levelOfExp"
                        name="levelOfExp"
                        value={values.levelOfExp}
                        onChange={handleOnboardingInputChange}
                        >
                        <option value="" disabled>Select</option>
                        <option value="0-3 Years" >0-3 Years</option>
                        <option value="3-6 Years" >3-6 Years</option>
                        <option value="6-10 Years" >6-10 Years</option>
                        <option value="10-15 Years" >10-15 Years</option>
                        <option value="15+ Years" >15+ Years</option>
                        </select>  
                        </div>
                    </div>
                    <div className="obFromBtn flex align flex-jsb">
                    <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>

                        <button className='primary-btn black' type="submit">Continue</button>
                    </div>
                </form>
            </div>
        </div>
</>
    )
}

export default ObStep3
