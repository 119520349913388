import React from 'react';
import { useState } from 'react'
import './BlogInner.css';
import '../blogs/blogs.css';

function BlogInnerhead({props}) {

  console.log('props',props);

  return (
    <div className="blogInner-head">
      <div className="blog-container-for-line">
        <div className="bih-head">
          <h3>{props?.title}</h3>
        </div>
        <div className="cat-read-time">
          <div className="cat-name"><p>{props?.categoryName}</p></div>
          <p className="read-time">{props?.readingTime > 10 ? '10' : props?.readingTime} min read</p>
          {console.log(props?.readingTime)}
        </div>
        <div className="blogauth-name">
          <div className="bi-img">
            <img src={props?.avatar} />
          </div>
          <div className="bi-auth">
            <p className="auth">{props?.authorName}</p>
            <p className="date">{props?.createdAt}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogInnerhead;