import React, { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Slot.css';

const SlotCalculator = ({slots2,slotDuration, setSlotDuration, slots, setSlots, selectedSlots, setSelectedSlots}) => {
    const totalMinutes = 24 * 60; // Total minutes in a day
    
    const handleSlotClick = (slot) => {
        const isSlotSelected = selectedSlots.some(
            (selectedSlot) =>
                selectedSlot.startTime === convertTo12HourFormat(slot.startTime) &&
                selectedSlot.endTime === convertTo12HourFormat(slot.endTime)
        );
    
        if (isSlotSelected) {
            // Slot is already selected, remove it
            const updatedSelectedSlots = selectedSlots.filter(
                (selectedSlot) =>
                    selectedSlot.startTime !== convertTo12HourFormat(slot.startTime) ||
                    selectedSlot.endTime !== convertTo12HourFormat(slot.endTime)
            );
            setSelectedSlots(updatedSelectedSlots);
        } else {
            // Slot is not selected, add it
            setSelectedSlots([
                ...selectedSlots,
                {
                    startTime: convertTo12HourFormat(slot.startTime),
                    endTime: convertTo12HourFormat(slot.endTime),
                    status: true,
                },
            ]);
        }
    };

    useEffect(() => {
    calculateSlots();
    }, [slotDuration]);

    const calculateSlots = () => {
    const calculatedSlots = [];
    let currentTime = 0; // Start at midnight (0 minutes)

    while (currentTime < totalMinutes) {
        const startTime = formatTime(currentTime);
        const endTime = formatTime(currentTime + slotDuration);

        calculatedSlots.push({
        startTime,
        endTime,
        });

        currentTime += slotDuration;
    }
    setSlots(calculatedSlots);
    };

    const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMins = String(mins).padStart(2, '0');

    return `${formattedHours}:${formattedMins}`;
    };

    function convertTo12HourFormat(time24Hour) {
        const [hours, minutes] = time24Hour.split(':');
        const hoursInt = parseInt(hours);
        const period = hoursInt >= 12 ? 'PM' : 'AM';
        let hours12 = hoursInt % 12;
        hours12 = hours12 === 0 ? 12 : hours12;
        const formattedHours = hours12.toString().padStart(2, '0');
        const time12Hour = `${formattedHours}:${minutes} ${period}`;
        return time12Hour;
    }

    return (
    <div className='slot-booking-slots'>
        <div className="slot-booking-note">
            <div className="slot-booking-note-wrap">
                <div className="slot-booking-note-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M4.11267 7.78644L7.78906 4.11029L4.63748 1.48438L1.48624 4.63541L4.11267 7.78644Z" fill="#BE1931"/>
                        <path d="M0.433686 4.63648C1.01357 5.217 1.95407 5.217 2.53396 4.63648L4.6349 2.53602C5.21545 1.95618 5.21545 1.01506 4.6349 0.434883C4.05535 -0.144961 3.11451 -0.144961 2.53396 0.434883L0.433686 2.53602C-0.146533 3.11586 -0.146533 4.05664 0.433686 4.63648Z" fill="#DD2E44"/>
                        <path d="M7.35212 5.94922C7.35212 5.94922 12.2521 11.7262 11.9898 11.9894C11.7273 12.2523 5.94959 7.35166 5.94959 7.35166L7.35212 5.94922Z" fill="#99AAB5"/>
                        <path d="M3.58463 9.36273C4.16417 9.94324 5.10501 9.94324 5.6859 9.36273L9.36196 5.68624C9.94185 5.10606 9.94185 4.16596 9.36196 3.58578C8.78174 3.00526 7.8409 3.00526 7.26069 3.58578L3.58463 7.26193C3.00441 7.84244 3.00441 8.78221 3.58463 9.36273Z" fill="#DD2E44"/>
                    </svg>
                </div>
                <div className="slot-booking-note-text">
                    <strong>Note: </strong> Each session would be 15/30/45/60 minutes long.<br/>
                    Ex: A 15 minute session that starts at 6:00 am would end at 6:15 am.
                </div>
            </div>
        </div>
        <Tabs>
        <TabList>
            <Tab>12 am - 6 am</Tab>
            <Tab>6 am - 12 pm</Tab>
            <Tab>12 pm - 6 pm</Tab>
            <Tab>6 pm - 12 am</Tab>
        </TabList>

        <TabPanel>
        <ul>
                    {slots.map((slot, index) => {
                        const startTime = parseInt(slot.startTime.split(':')[0]);
                        if (startTime >= 0 && startTime < 6) {
                            const formattedStartTime = convertTo12HourFormat(slot.startTime);
                            const formattedEndTime = convertTo12HourFormat(slot.endTime);
                            const isSlotSelected = selectedSlots.some(
                                (selectedSlot) =>
                                    selectedSlot.startTime === formattedStartTime &&
                                    selectedSlot.endTime === formattedEndTime
                            );

                            const isSlotSelectedFalse = selectedSlots.some(
                                (selectedSlot) =>
                                    selectedSlot.startTime === formattedStartTime &&
                                    selectedSlot.endTime === formattedEndTime &&
                                    selectedSlot?.status === false
                            );

                            return (
                                <li
                                    key={index}
                                    onClick={() => handleSlotClick(slot)}
                                    className={`${
                                        isSlotSelected ? 'selected-slot' : ''
                                    } ${isSlotSelectedFalse? 'fully-disabled' : ''}`}
                                >
                                {formattedStartTime} 
                                <div className="slot-selected-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                        <path d="M10.668 3L5.16797 8.5L2.66797 6" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
        </TabPanel>
        <TabPanel>
            <ul>
            {slots.map((slot, index) => {
                const startTime = parseInt(slot.startTime.split(':')[0]);
                if (startTime >= 6 && startTime < 12) {
                    const formattedStartTime = convertTo12HourFormat(slot.startTime);
                    const formattedEndTime = convertTo12HourFormat(slot.endTime);
                    const isSlotSelected = selectedSlots.some(
                        (selectedSlot) =>
                            selectedSlot.startTime === formattedStartTime &&
                            selectedSlot.endTime === formattedEndTime
                    );

                    const isSlotSelectedFalse = selectedSlots.some(
                        (selectedSlot) =>
                            selectedSlot.startTime === formattedStartTime &&
                            selectedSlot.endTime === formattedEndTime &&
                            selectedSlot?.status === false
                    );
                    return (
                        <li
                            key={index}
                            onClick={() => handleSlotClick(slot)}
                            className={`${
                                isSlotSelected ? 'selected-slot' : ''
                            } ${isSlotSelectedFalse? 'fully-disabled' : ''}`}
                        >
                            {formattedStartTime}
                            <div className="slot-selected-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                        <path d="M10.668 3L5.16797 8.5L2.66797 6" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                        </li>
                    );
                }
                return null;
            })}
            </ul>
        </TabPanel>
        <TabPanel>
            <ul>
            {slots.map((slot, index) => {
                const startTime = parseInt(slot.startTime.split(':')[0]);
                if (startTime >= 12 && startTime < 18) {
                    const formattedStartTime = convertTo12HourFormat(slot.startTime);
                    const formattedEndTime = convertTo12HourFormat(slot.endTime);
                    const isSlotSelected = selectedSlots.some(
                        (selectedSlot) =>
                            selectedSlot.startTime === formattedStartTime &&
                            selectedSlot.endTime === formattedEndTime
                    );

                    const isSlotSelectedFalse = selectedSlots.some(
                        (selectedSlot) =>
                            selectedSlot.startTime === formattedStartTime &&
                            selectedSlot.endTime === formattedEndTime &&
                            selectedSlot?.status === false
                    );
                    return (
                        <li
                            key={index}
                            onClick={() => handleSlotClick(slot)}
                            className={`${
                                isSlotSelected ? 'selected-slot' : ''
                            } ${isSlotSelectedFalse? 'fully-disabled' : ''}`}
                        >
                            {formattedStartTime}
                            <div className="slot-selected-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                        <path d="M10.668 3L5.16797 8.5L2.66797 6" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                        </li>
                    );
                }
                return null;
            })}
            </ul>
        </TabPanel>
        <TabPanel>
            <ul>
            {slots.map((slot, index) => {
                const startTime = parseInt(slot.startTime.split(':')[0]);
                if (startTime >= 18 || startTime < 0) {
                    const formattedStartTime = convertTo12HourFormat(slot.startTime);
                    const formattedEndTime = convertTo12HourFormat(slot.endTime);
                    const isSlotSelected = selectedSlots.some(
                        (selectedSlot) =>
                            selectedSlot.startTime === formattedStartTime &&
                            selectedSlot.endTime === formattedEndTime
                    );

                    const isSlotSelectedFalse = selectedSlots.some(
                        (selectedSlot) =>
                            selectedSlot.startTime === formattedStartTime &&
                            selectedSlot.endTime === formattedEndTime &&
                            selectedSlot?.status === false
                    );
                    return (
                        <li
                            key={index}
                            onClick={() => handleSlotClick(slot)}
                            className={`${
                                isSlotSelected ? 'selected-slot' : ''
                            } ${isSlotSelectedFalse? 'fully-disabled' : ''}`}
                        >
                            {formattedStartTime}
                            <div className="slot-selected-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                        <path d="M10.668 3L5.16797 8.5L2.66797 6" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                        </li>
                    );
                }
                return null;
            })}
            </ul>
        </TabPanel>
        </Tabs>
    </div>
    );
};

export default SlotCalculator;
