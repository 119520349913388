import axios from 'axios';
import Cookies from 'js-cookie';


export const unifiedPostRequest = async (url, payload = {}, useToken = false, useMultiPart = false) => {
    const headers = {
        'Accept': 'application/json',
    };

    if (useToken) {
        const token = Cookies.get('token');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        } else {
            console.log('Token not found');
            return;
        }
    }

    if (useMultiPart) {
        headers['Content-Type'] = 'multipart/form-data';
    }

    try {
        const response = await axios.post(url, payload, { headers });
        return response.data;
    } catch (err) {
        return { error: err.response.data };
    }
};

export const putRequest = async (url, payload = {}) => {
    const data = await axios.put(url, payload)
        .then(resp => resp.data)
        .catch(err => (
            { error: err.response.data }
        ));
    return data;
}

export const getRequest = async (url, payload = {}) => {
    const token = Cookies.get('token');
    const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

    try {
        const response = await axios.get(url, { data: payload, headers });
        return response.data;
    } catch (err) {
        return { error: err.response.data };
    }
};

export const deleteRequest = async (url, payload = {}) => {
    const token = Cookies.get('token');
    const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

    try {
        const response = await axios.delete(url, {
            data: payload,  // Set the payload here
            headers
        });
        return response.data;
    } catch (err) {
        return { error: err.response.data };
    }
};