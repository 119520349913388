import React, { useContext, useState } from 'react';
import '../Dashboard/dashboardPopup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function ReportComment ({ data, noti, closeR }) {

    // const { state, updateMenteeProfileOverView } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [reportCategory, setCancelReason] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState('');

    const handleOpenNewPopup = (event) => {
        event.preventDefault();
        setLoading(true);
        const userToken = Cookies.get('token');
        let resourceId = data.commentId; 
        let reportType = 'CommunityComment';
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/report/resource`,
            {
              resourceId,
              reportType,
              reportCategory,
              additionalDetails
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {
        
            if(res){
              setLoading(false);
              noti();
              closeR();
              
            }
          })
          .catch((err) => {
           
          })
          .finally(() => {
            setLoading(false);
          });
      };
      const handleReasonChange = (event) => {
        setCancelReason(event.target.value);
      };
    
      const handleDetailsChange = (event) => {
        setAdditionalDetails(event.target.value);
      };

    return (
        <div className="insage-login dashboard-popups">
            <div className="insage-login-wrapper">
                <div className="login-head"><span>report</span></div>
                <div className='popup-description'>
                    <p className='pd-para'>Comment by <span>{data?.fullName}</span></p>
                </div>

                <div className="overview-profile-pop-form">
                    <form action="post">
                        <div className='login-form-input flex flex-c'>
                                <label htmlFor="industry">Select a category 
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="#555555" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5 10.1V7.5" stroke="#555555" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5 4.89844H7.5065" stroke="#555555" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </label>
                                <div className="select-wrapper">
                                    <select
                                    id="industry"
                                    name="industry"
                                    required
                                    value={reportCategory}
                                    onChange={handleReasonChange}
                                    >
                                     <option value="" disabled>Select reason</option>
                                    <option value="Abusive language">Abusive language</option>
                                    <option value="inappropriate behaviour">inappropriate behaviour</option>
                                    <option value="Hateful language">Hateful language</option>
                                    <option value="Other">Other</option>
                                    </select>
                                </div>
                        </div>
                        <div className='login-form-input flex flex-c'>
                            <label className='flex bio-label align' htmlFor="aboutMe">Please mention additional details for reporting</label>
                            <textarea id='aboutMe' name='bio' placeholder={'Type here'} value={additionalDetails} onChange={handleDetailsChange} />
                        </div>
                        <div className="overview-profile-pop-btn">
                            <button className='popup-btn cancel-btn full-width' onClick={handleOpenNewPopup}>
                                {loading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                                  ) : (
                                  'Report'
                                  )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="login-close-button" onClick={closeR}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}
