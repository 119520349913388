import React, {useState, useContext, useEffect} from 'react';
// import './sessions.css';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../ErrorMessage';
import Loader from '../reusable/loading/Loader';
import DashSessionCard from '../reusable/session-cards/DashSessionCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SavedResourcesEmpty from './SavedResourcesEmpty';
import DashResourceCard from './DashResourceCard';
import DashCommunityCard from './DashCommunityCard';
import { ToastContainer, toast } from 'react-toastify';
import RemoveSaved from '../Dashboard/RemoveSaved';
import { Link } from 'react-router-dom';
import NoSesh from '../../assets/images/no-sesh.svg';
import { getRequest } from '../../context/api';
import actionTypes from '../../context/actionTypes';


const SavedResources = () => {

    const { getSavedResources, state, dispatch, getSavedResourcesCoummunity } = useContext(AuthContext);

    // console.log('state?.resourcesData ', state?.resourcesData);

const notify = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Details Saved Successfully</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );

const [openCancelSessionPopup, setOpenCancelSessionPopup] = useState(false);
const [newPopupOpen, setNewPopupOpen] = useState(false);
const [selectedItemData, setSelectedItemData] = useState(null);
const [currentPopupOpen, setCurrentPopupOpen] = useState(false);

const handleLoadMore = async (pageNo) => {
    let res =await getSavedResources(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.resourcesData,
            ...res,
            result: [...state.resourcesData.result, ...res.result]
          };
        dispatch({ type: actionTypes.RESOURCES, payload: mergedData});
    }
}

const handleCommLoadMore = async (pageNo) => {
    let res =await getSavedResourcesCoummunity(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.resourcesCommData,
            ...res,
            result: [...state.resourcesCommData.result, ...res.result]
          };
        dispatch({ type: actionTypes.RESOURCES_COMM, payload: mergedData});
    }
}



//Open Cancel Session Popup
const openCancelSessionPop = (session) => {
    setSelectedItemData(session);
    setOpenCancelSessionPopup(true);
    document.body.classList.add('popup-open');
};
//Close Cancel Session Popup
const closeCancelSessionPopup = () => {
    setOpenCancelSessionPopup(false);
    document.body.classList.remove('popup-open');
};

    const data = state?.resourcesData?.result?.length > 0 || state?.resourcesCommData?.result?.length > 0 ? (
    
        <> <div className="fav-mentors-full dashboard-empty-state">
        {state.loading && <Loader/>}
            <div className="dash-empty-sessions-content">
                <div className="dash-empty-sess-head">Saved Resources</div>
                <div className="dash-empty-sess-body">
                    <p>Check all your sessions for today, tomorrow and whenever here!</p>
                </div>              
            </div>
            <div className="dash-session-list">
                <div className='dsl-tabs'>
                <Tabs>
                    <div className='dsltabs-tabs'>
                        <TabList>
                            <Tab onClick={() => getSavedResourcesCoummunity(1)}>community posts</Tab>
                        </TabList>
                    </div>
                    <div className='dsltabs-data'>
                        {/* <TabPanel>
                        {state?.resourcesData?.result?.length > 0 ? <>
                        {state?.resourcesData?.result?.map((data,index) => {
                            return (
                                    <DashResourceCard 
                                        post={data}
                                        key={index} 
                                        get={getSavedResources}
                                        
                                    />
                                    );
                                })}
                                {state?.resourcesData?.hasMorePages && <div onClick={() => handleLoadMore(state?.resourcesData?.currentPage)} className="favourite-mentors-list-button">
                                    <a href="javascript:void(0)" className="isg-guide-load-more">load more</a>
                                </div>} 
                                 </>
                                : 
                                <>
                                <div className="dash-emp-no-sess">
                    <div className="dash-emp-no-sess-wrap">
                        <div className="dash-emp-no-sess-img">
                            <img src={NoSesh} alt="" />
                        </div>
                        <div className="dash-emp-no-sess-head">You have no saved blogs</div>
                        <div className="dash-emp-no-sess-body">
                            <p>See your saved resources here in both "Blogs" & "Community"</p>
                        </div>
                    <div className="dash-emp-no-sess-btn">
                        <Link to={`/blogs`} className="primary-btn black">explore Blogs</Link>
                    </div>
                    
                    </div>
                    
                                </div>  
                                </> }
                        </TabPanel> */}
                        <TabPanel>
                        {state?.resourcesCommData?.result?.length > 0 ? <>
                        {state?.resourcesCommData?.result && state?.resourcesCommData?.result?.map((data,index) => {
                            return (
                                <DashCommunityCard get={getSavedResources} post={data} key={index} />
                                    );
                                })}
                                {state?.resourcesCommData?.hasMorePages && <div onClick={() => handleCommLoadMore(state?.resourcesCommData?.currentPage)} className="favourite-mentors-list-button">
                                    <a href="javascript:void(0)" className="isg-guide-load-more">load more</a>
                                </div>} 
                                 </>
                                : 
                                <>
                                <div className="dash-emp-no-sess">
                    <div className="dash-emp-no-sess-wrap">
                        <div className="dash-emp-no-sess-img">
                            <img src={NoSesh} alt="" />
                        </div>
                        <div className="dash-emp-no-sess-head">You have no saved community posts</div>
                        <div className="dash-emp-no-sess-body">
                            <p>See your saved resources here in both "Blogs" & "Community"</p>
                        </div>
                    <div className="dash-emp-no-sess-btn">
                        <Link to={`/community`} className="primary-btn black">explore community</Link>
                    </div>
                    </div>
                                </div>  
                                </> }
                        </TabPanel>
                    </div>
                </Tabs>
                </div>
              
            </div>     
            
        </div>
        
        <div className="mentee-profile-pop-ups">
            <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openCancelSessionPopup ? 'active' : ''}`}>
                <RemoveSaved data={selectedItemData} onclose={closeCancelSessionPopup} />
            </div>
        </div>
    </>
        
        ) : <SavedResourcesEmpty/>

return data

}



export default SavedResources
