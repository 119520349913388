import React, { useContext, useState, useEffect } from 'react';
// import './mentee-profile.css';
import '../onboarding/Onboarding-content.css';
import '../reusable/login/login.css';
import MultiSelectTags from '../reusable/multi-select/multiSelect';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import starFilled from '../../assets/images/star-filled.svg';
import starEmpty from '../../assets/images/star-empty.svg';
import axios from 'axios';
import Cookies from 'js-cookie';
import StarRating from '../Reviews/StarRatings';
import { Link } from 'react-router-dom';

export default function CompletedExpandedSessionPopup ({data, noti, onclose,onCancel,onOpenNew,onReview }) {

    const [loading, setLoading] = useState(false);
    const [bookingDetails,setBookingDetails ] = useState([]);
    const [error, setError] = useState(null);

    // let userToken = Cookies.get('token') ? JSON.parse(Cookies.get('token')): null
    
    let bookingId = data?.bookingId;


    useEffect(() => {
        setLoading(true);
        const userToken = Cookies.get('token');

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/view/review`,
                { bookingId },
                // If you have any data to send in the request body, you can include it here
                // data: { key: 'value' },
                 // Set data to null if you don't have any data to send
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((res) => {
                setBookingDetails(res.data?.result);
                setError(null); // Clear any previous errors
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                // Set loading to false after the request is complete
                setLoading(false);
            });
    }, []);

    // const reviewsDetails = bookingDetails?.result;

    // console.log('reviewsDetails',reviewsDetails);

    const handleOpenNewPopup = () => {
        onCancel();
      };

      const handleOpenReviewPopup = (data) => {
        onReview(data);
      };

    console.log('bookingDetails', bookingDetails)

    return (
        <div className="insage-login dashboard-popups extended-popup">
            <div className="insage-login-wrapper">
                <div className='expanded-session-pop'>
                    <div className='esp-image'>
                        <img src={data?.mentorAvatar}/>
                    </div>
                    <div className='esp-data'>
                        <div className='popup-description'>
                            <p className='pd-para'>Mentorship session with <span>{data?.mentorName}</span></p>
                        </div>
                        <div className='pd-datetime'>
                            <div className="pd-date">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 1.5V3.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4 1.5V3.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.5 5.5H10.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"></path></svg>
                                </span>
                                {data?.sessionDate}</div>
                            <div className="pd-time">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><g clip-path="url(#clip0_5004_40300)"><path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_5004_40300"><rect width="12" height="12" fill="white" transform="translate(0 0.5)"></rect></clipPath></defs></svg>
                                </span>
                                <div className="timer">{data?.startTime} - {data?.endTime} <span className="duration">{data?.duration}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="overview-profile-pop-form">
                <div className='slci-content-in'>
                            <div className='slci-in-content'>
                                <div className='slciic-topic'>
                                    <h6 className='micro'>Discussion Topic</h6>
                                    <p>{data?.agenda}</p>
                                </div>
                                <div className='slciic-detailed'>
                                    <h6 className='micro'>Detailed Session Agenda</h6>
                                    <p>{data?.message}</p>
                                </div>
                                <div className='slciic-reviews'>
                                    <h6 className='micro'>
                                        My Reviews
                                        <StarRating rating={bookingDetails?.avarage} />
                                    </h6>
                                    <div className='reviewBox'>
                                        <div className='re-in'>
                                            <div className='rb-defined'>
                                                <div className='rb-defined-in'>
                                                    <div className='rbd-tag'>approachability</div>
                                                    <div className='rbd-stars'>
                                                        <StarRating rating={bookingDetails?.approachability} />
                                                    </div>
                                                </div>
                                                <div className='rb-defined-in'>
                                                    <div className='rbd-tag'>content delivery</div>
                                                    <div className='rbd-stars'>
                                                        <StarRating rating={bookingDetails?.contentDelivery} />
                                                    </div>
                                                </div>
                                                <div className='rb-defined-in'>
                                                    <div className='rbd-tag'>value for money</div>
                                                    <div className='rbd-stars'>
                                                        <StarRating rating={bookingDetails?.valueForMoney} />
                                                    </div>
                                                </div>
                                                {/* <div className='rb-defined-in'>
                                                    <div className='rbd-tag'>criteria 04</div>
                                                    <div className='rbd-stars'>
                                                        <StarRating rating={reviewsDetails?.critera04} />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='rb-content'>
                                                <p>{bookingDetails?.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* <form action="post">
                        <div className="overview-profile-pop-btn">
                            <button className='popup-btn cancel-btn'>
                                {loading ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    'cancel session'
                                )}
                            </button>
                        </div>
                        
                    </form> */}
                    <div className="slci-foot">
                            <div className="foot-upcoming">
                                <div className="foot-ques">Liked the mentoring session with {data?.mentorName}?</div>
                                <div className="foot-cancel">
                                    {data?.review > 0 ?
                                    <>
                                   
                                    </>
                                    :
                                    <>
                                      <button className="review-button" onClick={() => handleOpenReviewPopup(data)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M5.47296 2.05469H1.99399C1.73037 2.05469 1.47754 2.15941 1.29113 2.34582C1.10472 2.53223 1 2.78506 1 3.04868V10.0066C1 10.2702 1.10472 10.5231 1.29113 10.7095C1.47754 10.8959 1.73037 11.0006 1.99399 11.0006H8.95194C9.21556 11.0006 9.46838 10.8959 9.65479 10.7095C9.8412 10.5231 9.94593 10.2702 9.94593 10.0066V6.52765" stroke="#C49D00" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9.20283 1.30879C9.40055 1.11108 9.66871 1 9.94833 1C10.2279 1 10.4961 1.11108 10.6938 1.30879C10.8915 1.50651 11.0026 1.77467 11.0026 2.05429C11.0026 2.3339 10.8915 2.60206 10.6938 2.79978L5.97236 7.52124L3.98438 8.01824L4.48137 6.03026L9.20283 1.30879Z" stroke="#C49D00" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>Review
                                        </button>
                                    </>
                                    }
                                    <Link onClick={handleOpenNewPopup} to={`/mentors/${data?.mentorUsername}`} className='book-button'>Book Again</Link>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div className="login-close-button" onClick={onclose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            
        </div>
    )
}
