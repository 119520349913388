import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="317676183347-io5gtaipe1mf5a47alo58kuh2aumkhgf.apps.googleusercontent.com">
    <React.StrictMode>
        <AuthProvider>
          <App />
      </AuthProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

