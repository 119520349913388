import React, {useState, useRef, useEffect} from 'react';
import './faqs.css';
import FAQbanner from '../../assets/images/FAQ-banner.png';
import { Link } from 'react-router-dom';

export default function FAQsPage({props, props2, props3, props4}) {
    const data= props;
    const data2= props2;
    const data3= props3;
    const data4= props4;
    const [activeFAQ, setActiveFAQ] = useState(0);

    

    const toggleFAQ = (index) => {
        setActiveFAQ(index === activeFAQ ? -1 : index);
    };
    const [activeFAQ1, setActiveFAQ1] = useState(0);

    const toggleFAQ1 = (index) => {
        setActiveFAQ1(index === activeFAQ1 ? -1 : index);
    };
    const [activeFAQ2, setActiveFAQ2] = useState(0);

    const toggleFAQ2 = (index) => {
        setActiveFAQ2(index === activeFAQ2 ? -1 : index);
    };
    const [activeFAQ3, setActiveFAQ3] = useState(0);

    const toggleFAQ3 = (index) => {
        setActiveFAQ3(index === activeFAQ3 ? -1 : index);
    };
    const [activeBtn, setActiveBtn] = useState('catOne');
    const toggleActive = (tabId) => {
        setActiveBtn(tabId);
        const targetElement = document.getElementById(tabId);

    // Check if the target element exists
    if (targetElement) {
        // Calculate the new scroll position with an offset of 50px
        const offset = targetElement.offsetTop - 100;

        // Scroll to the target element with the adjusted position
        window.scroll({
            top: offset,
            behavior: 'smooth', // You can use 'auto' for instant scrolling
        });
    }
    };

    const sectionRefs = {
        catOne: useRef(null),
        catTwo: useRef(null),
        catThree: useRef(null),
        catFour: useRef(null),
      };
    
      useEffect(() => {
        const observerOptions = {
          root: null,
          rootMargin: '10px',
          threshold: 0.5, // Percentage of the section visible in the viewport
        };
    
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            const { target } = entry;
            const sectionName = target.getAttribute('id');
    
            if (entry.isIntersecting) {
              setActiveBtn(sectionName);
            } else if (activeBtn === sectionName) {
              setActiveBtn(null); // Reset activeBtn when the section is out of the viewport
            }
          });
        };
    
        const observer = new IntersectionObserver(handleIntersection, observerOptions);
    
        // Observe each section
        Object.values(sectionRefs).forEach((ref) => {
          if (ref.current) {
            observer.observe(ref.current);
          }
        });
    
        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
      }, [activeBtn]);

    
  return (
    <section className="insage-faq-page pad-top pad-vot">
        <div className="insage-faq-page-wrapper">
            <div className="insage-faq-page-heading-wrapper">
                <div className="isg-faq-page-heading"><h1>Frequently Asked Questions</h1></div>
                <div className="isg-faq-page-body"><p>Insage is devoted to streamlining knowledge-sharing within its community.<br/>Learn more about Insage here to gain a clearer understanding of how the platform operates.</p></div>
                <div className="isg-faq-page-banner"><img src={FAQbanner} alt="" /></div>
            </div>
            <div className="isg-faq-page-tabs">
                    
                    <div className="isg-faq-page-tabs-wrapper flex ">
                        <div className="isg-faq-page-tabs-buttons">
                           <ul className='react-tabs__tab-list'>
                                <li onClick={() => toggleActive('catOne')} className={activeBtn === 'catOne' ? 'react-tabs__tab active' : 'react-tabs__tab'} ><a href="javascript:void(0)">Mentor</a></li>
                                <li onClick={() => toggleActive('catTwo')}  className={activeBtn === 'catTwo' ? 'react-tabs__tab active' : 'react-tabs__tab'}><a href="javascript:void(0)">Mentee</a></li>
                                <li onClick={() => toggleActive('catThree')}  className={activeBtn === 'catThree' ? 'react-tabs__tab active' : 'react-tabs__tab'}><a href="javascript:void(0)">General</a></li>
                                <li onClick={() => toggleActive('catFour')}  className={activeBtn === 'catFour' ? 'react-tabs__tab active' : 'react-tabs__tab'}><a href="javascript:void(0)">Technical Handbook</a></li>
                            </ul>
                        </div>
                        <div className="isg-faq-page-tabs-contents flex flex-c">
                            
                                <div id="catOne" ref={sectionRefs.catOne} className="isg-faq-page-tabs-content-wrapper">
                                    <div className="isg-faq-page-tab-heading">
                                        <h2>Mentor</h2>
                                    </div>
                                    <div className="isg-faq-page-tab-faqs">
                                        <ul className="flex flex-c">
                            {data?.faqs?.map((faq,index) => {
                                return(
                                <li key={index}  className={activeFAQ === index ? 'active' : ''}>
                                <div onClick={() => toggleFAQ(index)} className="isg-faq-page-tab-faq  flex flex-jsb align">
                                        <div className="isg-faq-page-tab-faq-heading" >{faq.faqQues}</div>
                                        <div clclassNameass="isg-faq-page-tab-faq-arrow">
                                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L7 7L13 1" stroke="#444444" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                <div className="isg-faq-page-tab-faq-body">
                                    <div>
                                    <p>{faq.faqAns}</p>
                                    </div>
                                </div>
                                </li>
                                );
                                })}
                                        </ul>
                                    </div>
                                </div>
                           
                           
                                <div id="catTwo" ref={sectionRefs.catTwo} className="isg-faq-page-tabs-content-wrapper">
                                    <div className="isg-faq-page-tab-heading">
                                        <h2>Mentee</h2>
                                    </div>
                                    <div className="isg-faq-page-tab-faqs">
                                        <ul className="flex flex-c">
                            {data2?.faqs?.map((faq,index) => {
                                return(
                                <li key={index} onClick={() => toggleFAQ1(index)} className={activeFAQ1 === index ? 'active' : ''}>
                                <div className="isg-faq-page-tab-faq  flex flex-jsb align">
                                    <div className="isg-faq-page-tab-faq-heading" >{faq.faqQues}</div>
                                    <div className="isg-faq-page-tab-faq-arrow">
                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 7L13 1" stroke="#444444" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    </div>
                                <div className="isg-faq-page-tab-faq-body">
                                    <div>
                                    <p>{faq.faqAns}</p>
                                    </div>
                                </div>
                                </li>
                                );
                                })}
                                        </ul>
                                    </div>
                                </div>
                           
                          
                                <div id="catThree" ref={sectionRefs.catThree} className="isg-faq-page-tabs-content-wrapper">
                                    <div className="isg-faq-page-tab-heading">
                                        <h2>General</h2>
                                    </div>
                                    <div className="isg-faq-page-tab-faqs">
                                        <ul className="flex flex-c">
                            {data3?.faqs?.map((faq,index) => {
                                return(
                                <li key={index} onClick={() => toggleFAQ2(index)} className={activeFAQ2 === index ? 'active' : ''}>
                                <div className="isg-faq-page-tab-faq  flex flex-jsb align">
                                    <div className="isg-faq-page-tab-faq-heading" >{faq.faqQues}</div>
                                    <div className="isg-faq-page-tab-faq-arrow">
                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 7L13 1" stroke="#444444" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    </div>
                                <div className="isg-faq-page-tab-faq-body">
                                    <div>
                                    <p>{faq.faqAns}</p>
                                    </div>
                                </div>
                                </li>
                                );
                                })}
                                        </ul>
                                    </div>
                                </div>

                                <div id="catFour" ref={sectionRefs.catFour} className="isg-faq-page-tabs-content-wrapper">
                                    <div className="isg-faq-page-tab-heading">
                                        <h2>Technical Handbook</h2>
                                    </div>
                                    <div className="isg-faq-page-tab-faqs">
                                        <ul className="flex flex-c">
                            {data4?.faqs?.map((faq,index) => {
                                return(
                                <li key={index} onClick={() => toggleFAQ3(index)} className={activeFAQ3 === index ? 'active' : ''}>
                                <div className="isg-faq-page-tab-faq  flex flex-jsb align">
                                    <div className="isg-faq-page-tab-faq-heading" >{faq.faqQues}</div>
                                    <div className="isg-faq-page-tab-faq-arrow">
                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 7L13 1" stroke="#444444" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    </div>
                                <div className="isg-faq-page-tab-faq-body">
                                    <div>
                                    <p>{faq.faqAns}</p>
                                    </div>
                                </div>
                                </li>
                                );
                                })}
                                        </ul>
                                    </div>
                                </div>
                            
                        </div>
                        </div>
                    

                    <div className="isg-faq-page-callout">
                        <div className="isg-faq-page-callout-wrapper flex align flex-jsb">
                            <div className="isg-faq-page-callout-content">
                                <div className="isg-faq-page-callout-content-heading">Still have doubts?</div>
                                <div className="isg-faq-page-callout-content-body">In case you still have unaddressed questions, feel free to reach out! Contact us <Link to="/contact">here.</Link></div>
                            </div>
                            <div className="isg-faq-page-callout-button">
                                <Link to="/contact" className="primary-btn black">Get in Touch</Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </section>
  )
}
