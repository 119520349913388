import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { getRequest,unifiedPostRequest } from './api';
import { io } from "socket.io-client";

export const ChatContext = createContext();

export const ChatContextProvider = ({ children, user }) => {
    const [userChats, setUserChats] = useState(null);
    const [isUserChatLoading, setIsUserChatLoading] = useState(false);
    const [userChatsErr, setUserChatsErr] = useState(null);
    const [potentialChats, setPotentialChats] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [isMentorListLoading, setIsMentorListLoading] = useState(false);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState(null);
    const [isMessagesLoading, setIsMessagesLoading] = useState(false);
    const [messagesErr, setMessagesErr] = useState(null);
    const [sendTextMessagesErr, setSendTextMessagesErr] = useState(null);
    const [newMessage, setNewMessage] = useState(null);
    const [socket, setSocket] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [notification, setNotification] = useState([]);
    const [dashTabIndex , setDashTabIndex] = useState(0);

    const scrollContainerRef = useRef(null);


    // console.log('user ', user);

    // https://insagedev.online
    // http://localhost:8000

      // initial socket
    useEffect(() => {
    const newSocket = io("https://insage.online");
    setSocket(newSocket);

    newSocket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
    });

    return () => {
        newSocket.disconnect();
    };
    }, [user]);
    
    const handleTabIndex = (index) =>{
        setDashTabIndex(index);
    }
      // add online users
    useEffect(() => {
        if (socket === null) return;
        socket.emit("addNewUser", user?.userId);
        socket.on("getOnlineUsers", (res) => {
            setOnlineUsers(res);
        });

        return () => {
            socket.off("getOnlineUsers");
        };
    }, [socket]);

      // logout users
    const logoutUserOnline = () => {
        if (socket === null) return;
        socket.emit("userLogout", user?.userId);
        socket.on("getOnlineUsers", (res) => {
            setOnlineUsers(res);
        });
    
        return () => {
            socket.off("getOnlineUsers");
        };
    }

      // send message
    useEffect(() => {
        if (socket === null) return;
        const recipientId = currentChat?.members.find((id) => id !== user?.userId);
        socket.emit("sendMessage", { ...newMessage, recipientId });
    }, [newMessage]);

  // received message and notification
    useEffect(() => {
    if (socket === null) return;

    socket.on("getMessage", (res) => {
        if (currentChat?._id !== res.chatId) return;

        setMessages((pre) => [...pre, res]);
    });

    socket.on("getNotification", (res) => {
        const isChatOpen = currentChat?.members.some((id) => id == res.senderId);

        if (isChatOpen) {
        setNotification((pre) => [{ ...res, isRead: true }, ...pre]);
        } else {
        setNotification((pre) => [res, ...pre]);
        }
    });

    return () => {
        socket.off("getMessage");
        socket.off("getNotification");
    };
    }, [socket, currentChat]);


    const unreadNotificationsFun = (notifications) => {
        return notifications.filter((n) => n.isRead === false);
    }

    // get all chat from the user id
    const getUserChats = useCallback(async () => {
        setIsUserChatLoading(true);
        setUserChatsErr(null);

        console.log('user?.userId ', user?.userId);

        if (user?.userId) {
            const response = await getRequest(`${process.env.REACT_APP_API_URL}/chat/${user?.userId}`);
            setIsUserChatLoading(false);

            if (response.error) {
            return setUserChatsErr(response);
            }

            // console.log('response setUserChats', response);

            setUserChats(response);
        }
    }, [user]);

     // get all users
        const getMentors = async () => {
        setIsMentorListLoading(true);
        const response = await getRequest(`${process.env.REACT_APP_API_URL}/mentor/all`);
        if (response.success === false) {
            return console.log("Error fetching users", response);
        }

        const pChats = await response.result.filter((fit) => {
            let isChatCreated = false;

            if (userChats) {
            isChatCreated = userChats?.some((chat) => {
                return chat.members[0] === fit.mentorId || chat.members[1] === fit.mentorId;
            });
            }
            
            return !isChatCreated;
        });

        setPotentialChats(pChats);
        setAllUsers(response.result.allMentor);
        setIsMentorListLoading(false);
    };

    const UpdatePotentialUserList = useCallback(async (potentialChats, mentorId) => {
    const filteredData = await potentialChats.filter(item => item.mentorId !== mentorId);
    setPotentialChats(filteredData);
    }, []);


    const updateCurrentChat = useCallback((chat) => {
        setCurrentChat(chat);
    }, []);

    const createChat = useCallback(async (firstId, secondId) => {
        const response = await unifiedPostRequest(
            `${process.env.REACT_APP_API_URL}/chat`,{ firstId, secondId}
        );

        if (response.error) {
            return console.log("Error createChat ", response);
        }

        setUserChats((prev) => {
            return [...(Array.isArray(prev) ? prev : []), response];
          });
        }, []);


        //  send Messages
        const sendTextMessage = useCallback(
            async (textMessage, sender, currentChatId, setTextMessage) => {
            if (!textMessage) return console.log("You must type something");
        
            const response = await unifiedPostRequest(
                `${process.env.REACT_APP_API_URL}/messages`,
                {
                chatId: currentChatId,
                senderId: sender.userId,
                text: textMessage,
                }
            );
        
            if (response.error) {
                return setSendTextMessagesErr(response);
            }
        
            setNewMessage(response);
            setMessages((pre) => [...pre, response]);
            setTextMessage("");
            },
            []
        );

  // get all messages
    useEffect(() => {
    const getMessages = async () => {
        setIsMessagesLoading(true);
        setMessagesErr(null);
        const response = await getRequest(
        `${process.env.REACT_APP_API_URL}/messages/${currentChat?._id}`
        );
        setIsMessagesLoading(false);

        if (response.error) {
        return setMessagesErr(response);
        }

        setMessages(response);
        
    };

    getMessages();
    }, [currentChat]);

    const markThisUserNotificationAsRead = useCallback(
        (thisUserNotification, notifications) => {
            // mark notification as read

            const mNotifications = notifications.map((el) => {
            let notification;

            thisUserNotification.forEach((n) => {
                if (n.secondId === el.secondId) {
                notification = { ...n, isRead: true };
                } else {
                notification = el;
                }
            });

            return notification;
            });

            setNotification(mNotifications);
        },
    []);

    const scrollToBottom = () => {
        if (scrollContainerRef.current) {
            // const element = scrollContainerRef.current;
            // const currentHeight = element.clientHeight;
            // const currentScrollTop = element.scrollTop;
            // const scrollableHeight = element.scrollHeight;
            // console.log('Current Height:', currentHeight);
            // console.log('Current ScrollTop:', currentScrollTop);
            // console.log('Scrollable Height:', scrollableHeight);
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    };

    return (
        <ChatContext.Provider
            value={{
                userChats, isUserChatLoading, userChatsErr, getUserChats, getMentors, 
                isMentorListLoading, potentialChats, createChat, 
                updateCurrentChat, messages, isMessagesLoading, messagesErr, currentChat, sendTextMessage,
                onlineUsers, logoutUserOnline, unreadNotificationsFun, notification, markThisUserNotificationAsRead, UpdatePotentialUserList,
                dashTabIndex , setDashTabIndex, handleTabIndex, scrollContainerRef, scrollToBottom
            }}
        >
            {children}
        </ChatContext.Provider>
        );
    };
