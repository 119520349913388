import React from 'react';
import axios from 'axios';
import Header from '../../../layouts/header/Header';
import Footer from '../../../layouts/footer/Footer';
import BannerPlatform from '../../../components/reusable/platform-banner/banner-platform';
import WhatWeOffer from '../../../components/features/what-we-offer';
import Cookies from 'js-cookie';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// const animateOnScroll = () =>{
//   gsap.registerPlugin(ScrollTrigger);
//   const mediaQuery = window.matchMedia('(min-width: 1200px)')
//   if (mediaQuery.matches) {
//         gsap.to(".wwo-bottom-right",
//             {
//               scrollTrigger: {
//               trigger: ".what-we-offer-bottom",
//               pin:true,
//               scrub: 4,
//               start: "70% center",
//               end: "180% center",
//               markers:false,
              
//               onUpdate: (self) => {
//                 const progress = self.progress;
//                 // Define rotation values based on scroll progress
//                 if( progress === 0){
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(1)",{ autoAlpha:1, zIndex:3});
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(2)",{ autoAlpha:0, zIndex:0});
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(3)",{ autoAlpha:0, zIndex:0});
//                 }
//                 else if (progress <= 0.50) {
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(2)",{ autoAlpha:1, zIndex:3});
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(1)",{ autoAlpha:0, zIndex:0});
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(3)",{ autoAlpha:0, zIndex:0});
//                 } else if (progress <= 1) {
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(3)",{ autoAlpha:1, zIndex:3});
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(2)",{ autoAlpha:0, zIndex:0});
//                   gsap.set(".wwo-bottom-right .wwo-bottom-right-wrapper:nth-child(1)",{ autoAlpha:0, zIndex:0});
//                 } else{
                
//                 }
//               }
//             }
//           }
//         );
//       }
  
// }

export default function Features() {
  const [featuresData, setFeaturesData] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("../features.json")
    .then((res) => setFeaturesData(res.data))
    .catch(err=>console.log(err))
  },[]);

  const [topMentorData, settopMentorData] = React.useState([]);
  React.useEffect(() => {
    const url = process.env.REACT_APP_API_URL+'/all-mentors'
    axios
    .get(url)
    .then((res) => settopMentorData(res.data))
    .catch(err=>console.log(err))
  },[]);

  const userRole = Cookies.get('userRole');

  return (
    <main className="insage-features">
     <Header/>
     <BannerPlatform props={featuresData.bannerData} propsrole={userRole}/>
     <WhatWeOffer props={featuresData.whatWeOffer}  topMentorData={topMentorData}/>
     <Footer/>

    </main>
  )
}
