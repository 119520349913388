import React from 'react';
import './testemonials-with-arrow.css';
import Ticker from '../reusable/ticker/Ticker';
import Person1 from '../../assets/images/become-better.png';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, EffectFade } from "swiper";
import "swiper/css/effect-fade";
import { Link } from 'react-router-dom';

export default function TestemonialsWithArrow({props}) {
    const texts=["Guiding brand","Community oriented brand","Uplifting brand","Guiding brand","Community oriented brand","Uplifting brand"];
    const dynamicStyles = {
        bg: {
            background: '#F9E491',
            color:'#444444',
            top: '-20px'
        },
        fill: {
             fill:'#444444'
        }
    };
    const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <section className="insage-become-better">
       <Ticker texts={texts} styles={dynamicStyles} />
       <div className="container">
       <Swiper  effect={"fade"} speed={0}  navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current,}} modules={[EffectFade,Navigation]} className="mySwiper insage-become-better-swiper">
       <SwiperSlide>
        <div className="insage-become-better-wrapper flex flex-jsb">
            <div className="insage-become-better-left">
                <div className="insage-become-better-img">
                    <img className='img' src="https://insagedev.online/uploads/DSC09240-1703601135772-1706695144575.jpeg" alt="" />
                </div>
            </div>
            <div className="insage-become-better-right">
                <div className="insage-become-better-content-wrapper">
                    <div className="insage-become-better-heading">
                        <h3>Boost Your<br/> Marketing Potential</h3>
                    </div>
                    <div className="insage-become-better-body">
                        <p>“I have over 5 years of experience in business and sales, and I know the secrets of marketing that can make you stand out from the crowd. I have also built and run my own company, so I can give you valuable insights from my personal journey. Join me for a session and discover how to unleash your marketing potential.”</p>
                    </div>
                    <div className="separator-div"></div>
                    <div className="insage-become-better-mentor-wrapper">
                        <div className="insage-become-better-mentor-name">
                            <h5>Yogesh G.</h5>
                        </div>
                        <div className="insage-become-better-mentor-designation">
                            <span className="label">Entrepeneur SimplePlan Media</span>
                        </div>
                        <div className="insage-become-better-mentor-btn">
                            <Link to={'/mentors/yogesh-gangotia'} className="primary-btn primary-btn-2 white">view mentor profile</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="insage-become-better-wrapper flex flex-jsb">
            <div className="insage-become-better-left">
                <div className="insage-become-better-img">
                    <img className='img' src="https://insagedev.online/uploads/rahul_linkedin-1705042689424-1706701709186.jpeg" alt="" />
                </div>
            </div>
            <div className="insage-become-better-right">
                <div className="insage-become-better-content-wrapper">
                    <div className="insage-become-better-heading">
                        <h3>Enhance Your<br/> Marketing Skills</h3>
                    </div>
                    <div className="insage-become-better-body">
                        <p>“I have 8 years of experience in marketing, and I know the strategies and techniques that can make you stand out from the crowd. I have also helped many clients achieve their marketing goals, so I can give you practical advice and feedback. Join me for a session and discover how to boost your marketing skills.”</p>
                    </div>
                    <div className="separator-div"></div>
                    <div className="insage-become-better-mentor-wrapper">
                        <div className="insage-become-better-mentor-name">
                            <h5>Rahul</h5>
                        </div>
                        <div className="insage-become-better-mentor-designation">
                            <span className="label">Marketing Head at SimplePlan Media</span>
                        </div>
                        <div className="insage-become-better-mentor-btn">
                            <Link to={'/mentors/rahul-aswal'} className="primary-btn primary-btn-2 white">view mentor profile</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </SwiperSlide>
        {/* <SwiperSlide>
        <div className="insage-become-better-wrapper flex flex-jsb">
            <div className="insage-become-better-left">
                <div className="insage-become-better-img">
                    <img className='img' src={Person1} alt="" />
                </div>
            </div>
            <div className="insage-become-better-right">
                <div className="insage-become-better-content-wrapper">
                    <div className="insage-become-better-heading">
                        <h3>Become 10x<br/>better at design</h3>
                    </div>
                    <div className="insage-become-better-body">
                        <p>“Lorem ipsum dolor sit amet consectetur. Facilisis donec nunc aliquet non et in.
                             Leo hac diam volut pat vitae nulla elementum duis purus eget. 
                             Temp or a sit massa ac condimentum enim.”</p>
                    </div>
                    <div className="separator-div"></div>
                    <div className="insage-become-better-mentor-wrapper">
                        <div className="insage-become-better-mentor-name">
                            <h5>michael f.</h5>
                        </div>
                        <div className="insage-become-better-mentor-designation">
                            <span className="label">creative director at apple</span>
                        </div>
                        <div className="insage-become-better-mentor-btn">
                            <button className="primary-btn primary-btn-2 white">view mentor profile</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </SwiperSlide> */}
        <div className="prev-btn yellow" ref={navigationPrevRef}>
            <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.256229 20.9111L12.7305 33.3854L16.5177 29.5981L6.71894 19.8023L6.73358 19.8023L26.3432 19.7961L26.2526 14.4274L6.7338 14.4336L6.72209 14.4336L16.5271 4.62862L12.7407 0.845215L0.258618 13.3273L0.256229 20.9111Z" />
            </svg>
        </div>
        <div className="next-btn yellow" ref={navigationNextRef}>
            <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.7438 20.9111L14.2695 33.3854L10.4823 29.5981L20.2811 19.8023L20.2664 19.8023L0.656813 19.7961L0.747358 14.4274L20.2662 14.4336L20.2779 14.4336L10.4729 4.62862L14.2593 0.845215L26.7414 13.3273L26.7438 20.9111Z"/>
            </svg>
        </div>
        </Swiper>
       </div>
       <div className="insage-become-better-svg">
       <svg width="440" height="504" viewBox="0 0 440 504" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M440 117.378V503.637H322.73L322.775 200.268L322.548 200.494L18.8512 504L-62.8663 419.479L239.425 117.378L239.606 117.197H-64L-63.9546 0H322.548L440 117.378Z" fill="white"/>
        </svg>
       </div>
    </section>
  )
}
