import React, { useState, useEffect } from 'react';
import axios from 'axios'
import XMLViewer from 'react-xml-viewer'
import convert from 'xml-js';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const Sitemap = () => {

    const [sitemapData, setSitemapData] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [jsonData, setJsonData] = useState(null);

    const [xmlData, setXmlData] = useState('');

    useEffect(() => {
        let url = process.env.REACT_APP_API_URL+'/sitemap';
      const fetchXmlData = async () => {
        try {
          const response = await fetch(url);
          const xmlText = await response.text();
          setXmlData(xmlText);
        } catch (error) {
          console.error('Error fetching XML data:', error);
        }
      };
  
      fetchXmlData();
    }, []);


    // useEffect(() => {
    //     let url = process.env.REACT_APP_API_URL+'/sitemap';
    //     const fetchData = async () => {
    //       try {
    //         const response = await axios.get(url);
    //         // console.log('response ', response.data);
    //         // const xmlData = await response.text();
    
    //         // Convert XML to JSON
    //         // const options = { compact: true, ignoreComment: true, spaces: 4 };
    //         // const jsonResult = convert.xml2json(response.data, { compact: true, spaces: 2 });

    //         // console.log('jsonResult ', jsonResult);
           
    //         const result = JSON.stringify(response.data.result);

    //         const result2 = JSON.parse(result);
    //         // console.log('result ', result2);
            
    //         const xmlResult = convert.json2xml(result2, { compact: true });
    //         // console.log('xmlResult ', xmlResult);
    
    //         setJsonData(result);
    //         setXmlData(xmlResult);
    //       } catch (error) {
    //         console.error('Error fetching data:', error);
    //       }
    //     };
    
    //     fetchData();
    //   }, []);

    //   useEffect(() => {
    //     let url = process.env.REACT_APP_API_URL+'/sitemap';
    //     axios.get(url)
    //       .then(response => response.data)
    //       .then(xmlData => {
    //         setSitemapData(xmlData);
    //         setLoading(false);
    //       })
    //       .catch(error => {
    //         console.error('Error fetching data:', error);
    //         setError(error);
    //         setLoading(false);
    //       });
    //   }, []);

{/* <div>
          <SyntaxHighlighter language="xml" style={a11yDark}>
            {xmlData}
          </SyntaxHighlighter>
        </div>
        <div> */}

        return <>
        {xmlData && (
                <div>
                    {xmlData}
                  <XMLViewer xml={xmlData} />
                </div>
              )}
        </>
              
}

export default Sitemap;