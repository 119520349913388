import React, { createContext, useContext, useState } from 'react';
import { ToastContainer, toast as toastify ,Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToasterContext = createContext();

export const ToasterProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [popups, setPopups] = useState([]);

  const showToast = (message, options) => {
    const newToast = {
      id: Date.now(),
      content: (
        <div className="custom-toast-container">
          {message}
        </div>
      ),
      options: { ...options },
    };

    setToasts((prevToasts) => [...prevToasts, newToast]);

    // Use toastify directly to display the toast
    toastify(newToast.content, newToast.options);
  };

  const showPopup = (content) => {
    const newPopup = {
      id: Date.now(),
      content,
    };
    document.body.classList.add('popup-open');
    setPopups((prevPopups) => [...prevPopups, newPopup]);
  };

  const removePopup = (id) => {
    document.body.classList.remove('popup-open');
    setPopups((prevPopups) => prevPopups.filter((popup) => popup.id !== id));
  }

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToasterContext.Provider value={{ showToast, showPopup, removeToast }}>
      {children}
      <ToastContainer autoClose={3000} transition={Slide} />
      {toasts.map((toast) => (
        <div key={toast.id} onClick={() => removeToast(toast.id)}>
          {toast.content}
        </div>
      ))}
      {popups.map((popup) => (
        <div className="payment-successful" key={popup.id}>
            <div className="payment-successful-cont">
                {popup.content}
                <div onClick={() => removePopup(popup.id)} className="payment-success-cross">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                </div>
          </div>
        </div>
      ))}
    </ToasterContext.Provider>
  );
};

export const useToaster = () => {
  return useContext(ToasterContext);
};
