import React,{useContext, useRef,useState} from 'react';
import './explore-our-mentor.css';
import BannerImgSvg from '../../assets/images/become-a-mentor-bg-svg.png';
import Ticker from '../reusable/ticker/Ticker';
import { useInView } from "framer-motion";
import Login from '../reusable/login-mentor/Login';
import SignUp from '../reusable/signup-mentor/SignUp';
import ForgotPassword from '../reusable/forgot-password/forgotPassword';
import ChangePassword from '../reusable/change-password/changePassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import actionTypes from '../../context/actionTypes';
import { AuthContext } from '../../context/AuthContext';

export default function BannerExplore({props , propsrole, scrollToSecondComponent}) {
  const ref = useRef(null);
  const {dispatch } = useContext(AuthContext);
  const isInView = useInView(ref, { once: true }); 
  const data = props;
  const texts=["Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive"];
  const dynamicStyles = {
    bg: {
      background: '#F8F6ED',
      color:'#222222'
    },
    fill: {
     fill:'#24584B'
    } 
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isChangeOpen, setChangeOpen] = useState(false);

  const handleLoginClick = () => {
    setIsOpen(true);
    setSignUpOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
    document.body.classList.add('popup-open');
  };
  const handleCloseLoginPopup = () =>{
    setIsOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
    document.body.classList.remove('popup-open');
  }
  const handleOpenSignUp = () => {
    setSignUpOpen(true);
    setIsOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseSignUp = () => {
    setSignUpOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
    document.body.classList.remove('popup-open');
  };
  const handleOpenForgot = () => {
    setForgotOpen(true);
    setIsOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
    setSignUpOpen(false);
  };
  const handleCloseForgot = () => {
    setForgotOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
    document.body.classList.remove('popup-open');
  }; 
  const handleOpenChange = () => {
    setChangeOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
    setForgotOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
  };
  const handleCloseChange = () => {
    setChangeOpen(false);
    dispatch({ type: actionTypes.REMOVE_ERROR});
    document.body.classList.remove('popup-open');
  };
  
  const notify = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Email Sent Successfully!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );
  return (
    <>
   <section className="insage-become-a-mentor-banner explore">
        <div className="custom-container-fluid">
          <div className="container">
            <div className="insage-bam-banner-content-wrapper flex align">
              <div className="insage-bam-banner-left">
                <div className="insage-bam-banner-left-wrapper">
                  <div className="bam-banner-heading">
                    <h2 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className='' dangerouslySetInnerHTML={{ __html: data?.bannerHeading }}></h2>
                  </div>
                  <div className="bam-banner-body">
                    <p style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "0.9s"
        }} className=''>{data?.bannerPara}</p>
                  </div>
                  <div style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "0.9s"
        }} className="bam-banner-btn">
                    <a href="javascript:void(0)" onClick={scrollToSecondComponent} className="primary-btn primary-btn-2 black">Explore our mentors</a>
                  </div>
                </div>
              </div>
              <div className="insage-bam-banner-right">
                <div style={{
          clipPath: isInView ? "inset(-10% -10% -10% -10%)" : "inset(0 100% 0 0)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "1.2s"
        }} className="bam-banner-right-wrapper">
                  <div className="bam-banner-img">
                    <img src={data?.bannerImg} alt="" className="img" />
                  </div>
                  <div className="bam-banner-img-svg">
                    <img className='img' src={BannerImgSvg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bam-bannner-bg">
                <svg width="736" height="1009" viewBox="0 0 904 756" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1247 261.244H822.003L1122.5 -39.2502L1006.26 -155.547L623.472 227.181L240.744 -155.547L124.447 -39.2502L424.997 261.244H0V425.712H424.997L124.447 726.262L240.744 842.503L623.472 459.775L1006.26 842.503L1122.5 726.262L822.003 425.712H1247V261.244Z" fill="#FEC14B"/>
                    <path d="M736.179 896.03C746.279 833.794 704.015 775.153 641.779 765.053C579.543 754.952 520.903 797.217 510.802 859.452C500.702 921.688 542.966 980.329 605.202 990.429C667.438 1000.53 726.078 958.265 736.179 896.03Z" fill="#FEC14B"/>
                </svg>
          </div>
        </div>
        
   </section>
     <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpen ? 'active' : ''}`}>
     <Login onClose={handleCloseLoginPopup} onSignUpClick={handleOpenSignUp} onForgotClick={handleOpenForgot} />
  </div>
  <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpen ? 'active' : ''}`}>
     <SignUp onLoginClick={handleLoginClick } onClose={handleCloseSignUp}/>
  </div>
  <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpen ? 'active' : ''}`}>
     <ForgotPassword noti={notify}  onClose={handleCloseForgot} handleOpenChange={handleOpenChange}/>
  </div>
  <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpen ? 'active' : ''}`}>
     <ChangePassword  onClose={handleCloseChange} handleLoginClick={handleLoginClick}/>
  </div>
  </>
  )
}
