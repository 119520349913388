import React, {useRef, useState} from 'react';
import './three-cards.css';
import Cards from '../reusable/cards/Cards';
import { useInView } from "framer-motion";
import Login from '../../components/reusable/login/Login';
import SignUp from '../../components/reusable/signup/SignUp';
import ForgotPassword from '../../components/reusable/forgot-password/forgotPassword';
import ChangePassword from '../../components/reusable/change-password/changePassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

export default function ThreeCards({props, propsrole}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
    const data = props?.imageWithText
    const [isOpen, setIsOpen] = useState(false);
    const [isSignUpOpen, setSignUpOpen] = useState(false);
    const [isForgotOpen, setForgotOpen] = useState(false);
    const [isChangeOpen, setChangeOpen] = useState(false);
    const handleLoginClick = () => {
      setIsOpen(true);
      setSignUpOpen(false);
      document.body.classList.add('popup-open');
    };

    const handleCloseLoginPopup = () =>{
      setIsOpen(false);
      document.body.classList.remove('popup-open');
    } 

    const handleOpenSignUp = () => {
      setSignUpOpen(true);
      setIsOpen(false);
    };
    const handleCloseSignUp = () => {
      setSignUpOpen(false);
      document.body.classList.remove('popup-open');
    };
    const handleOpenForgot = () => {
      setForgotOpen(true);
      setIsOpen(false);
      setSignUpOpen(false);
    };
    const handleCloseForgot = () => {
      setForgotOpen(false);
      document.body.classList.remove('popup-open');
    };
    const handleOpenChange = () => {
      setChangeOpen(true);
      setIsOpen(false);
      setSignUpOpen(false);
      setForgotOpen(false);
    };
    const handleCloseChange = () => {
      setChangeOpen(false);
      document.body.classList.remove('popup-open');
    };
    const notify = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Email Sent Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );
    const notify2 = () => toast.success(
      <div className="custom-toast">
          <div className="custom-toast-top flex align">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
              <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
              <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
              </svg>
        <span>Success</span>
          </div>
          <div className="custom-toast-bot">
            <span>Password Changed Successfully!</span>
          </div>
      </div>,
      {
        className: 'custom-toast-container',
      }
    );
  return (
    <section className="knowledge-insage">
        <div className="container">

            <Cards posts={props}/>

            <div className="knowledge-insage-content-wrapper flex align">
                <div ref={ref} style={{
                  clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="knowledge-insage-image">
                    <img src={data?.iwtImage} alt="" className="img" />
                </div>
                <div className="knowledge-insage-content-body">
                    <div className="knowledge-insage-content-heading">
                        <h3 className='head-white' style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>{data?.iwtHeading}</h3>
                        </div>
                        <div style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="knowledge-insage-arrow">
                           <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M47 10.946V46.9662H36.0641L36.0683 18.6758L36.0471 18.6969L7.7262 47L0.105723 39.1181L28.2956 10.946L28.3125 10.9291H0L0.0042298 0H36.0471L47 10.946Z" fill="#FFBB24"/>
                            </svg>
                        </div>
                    <div className="knowledge-insage-label">
                        <span style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="label col-white">{data?.iwtParaHeading}</span>
                        </div>   
                    <div className="knowledge-insage-body">
                        <p  style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="col-white">{data?.iwtPara}</p>
                          </div>     
                    <div style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="knowledge-insage-btn">
                        {propsrole === undefined ? (
                                <button onClick={handleLoginClick} className="primary-btn primary-btn-2 black">
                                  {data?.iwtButtonText}
                                </button>
                              ) : propsrole === "mentee" ? (
                                 <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 black">Explore our Mentors</Link>
                              ) : propsrole === "mentor" ? (
                                 ""
                              ) : null}
                    </div>      
                </div>
            </div>
        </div>
        <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpen ? 'active' : ''}`}>
         <Login onClose={handleCloseLoginPopup} onSignUpClick={handleOpenSignUp} onForgotClick={handleOpenForgot} />
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpen ? 'active' : ''}`}>
         <SignUp onLoginClick={handleLoginClick } onClose={handleCloseSignUp}/>
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpen ? 'active' : ''}`}>
         <ForgotPassword onClose={handleCloseForgot} handleOpenChange={handleOpenChange} noti={notify}/>
      </div>
      <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpen ? 'active' : ''}`}>
         <ChangePassword noti={notify2}  onClose={handleCloseChange} handleLoginClick={handleLoginClick}/>
      </div>  
    </section>
  )
}
