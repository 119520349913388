import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import Google from '../../../assets/images/Sign Up/unnamed 1.png';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';

const Googlesign = ({popClose}) => {
  const { googleSignIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const userInfoResponse = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: 'application/json',
          },
        });
  
        const res = await googleSignIn({
          googleToken: response.access_token,
          email: userInfoResponse.data.email,
        });
        if(res === false){

        } else if(res && res.flag === 6){
          popClose();
          navigate('/mentee/dashboard');
        }else{
              popClose();
              navigate('/mentee/onboarding');
        }
      } catch (error) {
        console.log('Error:', error);
      }
    },
    onError: (error) => console.log(`Login Failed: ${error}`),
  });


  return  <button onClick={login}><img src={Google ? Google : "" } alt="Profile Image" /></button>
};

export default Googlesign;
