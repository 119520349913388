import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './Payment';

const stripePromise = loadStripe('pk_test_51NaCpESAu303uAjj3GWlA7DFUvZ2uMSuCRMH2PjLKoQ1JUjB37ZSYEbyDhwIvO80XCqHtDWrAP76ZVo9Lst6XayI00HF3QfRfv');

function PaymentPage({onClose, agenda, sessionCost, description, mentorId, availabilityId, slotId}) {
    return (
        <div>
            <Elements stripe={stripePromise}>
                <div className="login-head"><span>Payment</span></div>
                <Payment agenda={agenda} sessionCost={sessionCost} description={description} mentorId={mentorId} availabilityId={availabilityId} slotId={slotId} close={onClose}/>
                <div onClick={onClose} className="confirm-schedule-close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </Elements>
        </div>
    )
}

export default PaymentPage