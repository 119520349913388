import React from 'react'

const ObStep6 = ({nextStep,prevStep, handleOnboardingInputChange, values}) => {

    const handleSubmit = async (e) => {
        nextStep();
    };
    const handleBack = () =>{
        prevStep();
    }

return (
    <>
    <div className='stepLine'>
        <div className='slFilled width70'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 6 of 9
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Tell Us Your Mentorship Preferences.</h5>
        </div>
        <div className="obHeadingSvg">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#DD2E44"/>
            <path d="M18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5Z" fill="white"/>
            <path d="M18 28C23.5228 28 28 23.5228 28 18C28 12.4772 23.5228 8 18 8C12.4772 8 8 12.4772 8 18C8 23.5228 12.4772 28 18 28Z" fill="#DD2E44"/>
            <path d="M18 24C21.3137 24 24 21.3137 24 18C24 14.6863 21.3137 12 18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24Z" fill="white"/>
            <path d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z" fill="#DD2E44"/>
            <path opacity="0.2" d="M18.2391 18.2822L31.3831 30.0362C31.3831 30.0362 28.7361 33.4122 23.4931 35.1452L17.5781 18.4202L18.2391 18.2822Z" fill="black"/>
            <path d="M18.2926 19C18.0376 19 17.7836 18.903 17.5886 18.708C17.1996 18.319 17.1996 17.69 17.5886 17.301L18.1516 16.738C18.5406 16.349 19.1696 16.349 19.5596 16.738C19.9476 17.127 19.9476 17.756 19.5596 18.145L18.9956 18.708C18.8016 18.903 18.5476 19 18.2926 19Z" fill="#FFAC33"/>
            <path d="M24.015 6.98081C23.612 9.05981 24.015 11.6718 24.015 11.6718L31.069 4.28381C31.36 2.82981 30.541 0.351814 29.351 0.0458144C28.161 -0.260186 25.272 0.848814 24.015 6.98081ZM29.018 11.9838C26.939 12.3868 24.327 11.9838 24.327 11.9838L31.715 4.92981C33.169 4.63881 35.647 5.45781 35.953 6.64781C36.259 7.83781 35.15 10.7268 29.018 11.9838Z" fill="#55ACEE"/>
            <path d="M32.7991 4.48477L21.1771 17.5868C20.8151 17.9488 19.5041 18.4688 18.6671 17.6328C17.8311 16.7968 18.2481 15.5528 18.6101 15.1898L31.8161 3.50077C31.8161 3.50077 32.4921 2.86577 32.9751 3.34877C33.4581 3.83177 32.7991 4.48477 32.7991 4.48477Z" fill="#3A87C2"/>
            </svg>
        </div>
        </div>
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor='yes_no_radio'>Have you ever mentored someone before?</label>
                    <div className="login-form-radio-wrap flex align ">
                        <label className='label-input-radio'>
                        <input type="radio" name="isMentoredBefore" value="Yes" onChange={handleOnboardingInputChange} checked={values.isMentoredBefore == "Yes"}/>Yes
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="isMentoredBefore" value="No" onChange={handleOnboardingInputChange} checked={values.isMentoredBefore == "No"}/>No
                        </label>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor='yes_no_radio'>How many hours can you dedicate to this each week?</label>
                    <div className="login-form-radio-wrap flex align ">
                        <label className='label-input-radio'>
                        <input type="radio" name="mentoringHours" value="0-3" onChange={handleOnboardingInputChange} checked={values.mentoringHours == "0-3"}/>0-3
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="mentoringHours" value="3-5" onChange={handleOnboardingInputChange} checked={values.mentoringHours == "3-5"}/>3-5
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="mentoringHours" value="5-7" onChange={handleOnboardingInputChange} checked={values.mentoringHours == "5-7"}/>5-7
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="mentoringHours" value="7+" onChange={handleOnboardingInputChange} checked={values.mentoringHours == "7+"}/>7+
                        </label>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor='yes_no_radio'>Will you be charging for mentorship?</label>
                    <div className="login-form-radio-wrap flex align ">
                        <label className='label-input-radio'>
                        <input type="radio" name="isChargingForMentoring" value="Yes" onChange={handleOnboardingInputChange} checked={values.isChargingForMentoring == "Yes"}/>Yes
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="isChargingForMentoring" value="No" onChange={handleOnboardingInputChange} checked={values.isChargingForMentoring == "No"}/>No
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="isChargingForMentoring" value="Maybe-Later" onChange={handleOnboardingInputChange} checked={values.isChargingForMentoring == "Maybe-Later"}/>Maybe Later
                        </label>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor='yes_no_radio'>Are you open to charging only after receiving three reviews?</label>
                    <div className="login-form-radio-wrap flex align ">
                        <label className='label-input-radio'>
                        <input type="radio" name="acceptThreeReviewTnC" value="Yes" onChange={handleOnboardingInputChange} checked={values.acceptThreeReviewTnC == "Yes"}/>Yes
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="acceptThreeReviewTnC" value="No" onChange={handleOnboardingInputChange} checked={values.acceptThreeReviewTnC == "No"}/>No
                        </label>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor='yes_no_radio'>Would you be willing to communicate with mentees exclusively via in-app chats and Zoom calls?</label>
                    <div className="login-form-radio-wrap flex align ">
                        <label className='label-input-radio'>
                        <input type="radio" name="acceptCallAndChatTnC" value="Yes" onChange={handleOnboardingInputChange} checked={values.acceptCallAndChatTnC == "Yes"}/>Yes
                        </label>
                        <label className='label-input-radio'>
                        <input type="radio" name="acceptCallAndChatTnC" value="No" onChange={handleOnboardingInputChange} checked={values.acceptCallAndChatTnC == "No"}/>No
                        </label>
                    </div>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
</>
  )
}

export default ObStep6
