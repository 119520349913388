import React,{useContext, useState, useEffect} from 'react'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import Loader from '../reusable/loading/Loader';

const ObStep9 = ({nextStep,prevStep, handleOnboardingInputChange, values}) => {

  const { onboardingFormMentor, state } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        let res = await onboardingFormMentor(values);
        console.log(res);
        res && navigate('/mentor/dashboard'); 
    } catch (error) {
        console.log(error);
    }
  };
  const handleBack = () =>{
    prevStep();
  }
  const [profileVideo, setProfileVideo] = useState(null);

  // const handleNameChange = () => {
  //   const name = values.firstName;
  //   const initialLetter = name.charAt(0).toUpperCase();
  //   setProfilePhoto(initialLetter);
  //   // ... other form data handling
  // };
  const handleVideoUpload = e => {
    const file = e.target.files[0];
    console.log('file ', file);
    const reader = new FileReader();
  
    reader.onload = () => {
      setProfileVideo(reader.result);
    };
  
    if (file) {
      reader.readAsDataURL(file);
      handleOnboardingInputChange({
        target: {
            name: "bioVideo",
            value: file,
        },
    });
    }
  };
  // const [inputValue, setInputValue] = useState('');
  // const maxCharacterLimit = 100;
  // const handleInputChange = e => {
  //   const value = e.target.value;
  //   setInputValue(value);
  // };
  // const charactersRemaining = maxCharacterLimit - inputValue.length;
  return (
    <>
    <div className='stepLine'>
        <div className='slFilled width100'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 9 of 9
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Introduce yourself to your mentees</h5>
        </div>
        <div className="obHeadingSvg">
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
            <path d="M28.0319 24.4729L25.2039 27.3009L20.2539 22.351L23.0819 19.5229L28.0319 24.4729Z" fill="#9AAAB4"/>
            <path d="M35.3278 28.9408L29.4488 23.0618C28.6678 22.2808 27.4018 22.2808 26.6208 23.0618L23.7928 25.8898C23.0118 26.6708 23.0118 27.9368 23.7928 28.7178L29.6718 34.5968C31.2338 36.1598 33.7678 36.1598 35.3298 34.5968C36.8898 33.0358 36.8888 30.5028 35.3278 28.9408Z" fill="#66757F"/>
            <path d="M14.2305 27C21.6863 27 27.7305 20.9558 27.7305 13.5C27.7305 6.04416 21.6863 0 14.2305 0C6.77462 0 0.730469 6.04416 0.730469 13.5C0.730469 20.9558 6.77462 27 14.2305 27Z" fill="#8899A6"/>
            <path d="M14.2305 23C19.4772 23 23.7305 18.7467 23.7305 13.5C23.7305 8.25329 19.4772 4 14.2305 4C8.98376 4 4.73047 8.25329 4.73047 13.5C4.73047 18.7467 8.98376 23 14.2305 23Z" fill="#BBDDF5"/>
            </svg>
        </div>
        </div>
        {state.loading && <Loader/>}
        {state.error && <ErrorMessage errorData={state.error} />}
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label className='flex bio-label align' htmlFor="bio">Introductory video (Optional)
                    <span className='flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                            <path d="M5.5 11C8.26142 11 10.5 8.76142 10.5 6C10.5 3.23858 8.26142 1 5.5 1C2.73858 1 0.5 3.23858 0.5 6C0.5 8.76142 2.73858 11 5.5 11Z" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.5 8V6" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.5 4H5.505" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                    </span>
                    </label>
                    <div className="login-form-profile-wrapper flex align">
                        <div className="login-profile-image">
                            {/* {profileVideo ? (
                            <img src={profileVideo} alt="Profile" />
                            ) : ( */}
                            <video autoplay="false" playsinline controls="true" src={profileVideo}></video>
                            {/* <div className="initial-letter">{profileVideo}</div> */}
                            {/* // )} */}
                        </div>
                        <div className="login-profile-btn">
                            <span>Make sure it doesn’t exceed 25mb.</span>
                            
                            {profileVideo ? (
                          <label>
                            <input type="file" accept="video/*" onChange={handleVideoUpload} /> 
                            Update file
                          </label>
                            ) : (
                                  <label>
                                  <input type="file" accept="video/*" onChange={handleVideoUpload}/> 
                                    Upload a file
                                  </label>
                            )}
                        </div>
                    </div>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</>
  )
}

export default ObStep9
