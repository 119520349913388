import React, { createContext, useReducer, useCallback, useState } from 'react';
import { getRequest,unifiedPostRequest, deleteRequest } from './api';
import Cookies from 'js-cookie';
import authReducer from './authReducer';
import actionTypes from './actionTypes';


const initialState = {
    user: null,
    loading: false,
    error: null,
    token: Cookies.get('token'),
    userRole: Cookies.get('userRole'),
    dashboard: Cookies.get('dashboard') ? JSON.parse(Cookies.get('dashboard')): null,
    profile: Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null,
    userDetail: Cookies.get('userDetail') ? JSON.parse(Cookies.get('userDetail')): null,
    flag: Cookies.get('flag') ? JSON.parse(Cookies.get('flag')): null,
    favoriteMentor: null,
    sessionData: null,
    resourcesData: [],
    resourcesCommData: [],
    upcomingSessionData:[],
    completedSessionData:[],
    cancelledSessionData:[],
    reportedSessionData:[],
    communityData:null,
    mentorBlogs:null,
    empty: false,
    editCardAvaibility: {},
    availabilityData: null,
    earningData: null,
    reviewsMentor: [],
    editBlogData: null,
    menteeStats: {},
    mentorStats: {}
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const [trueState, setTrueState] = useState(false);
    const [resultState, setResultState] = useState(null);
    const [editAvailability, setEditAvailability] = useState(false);
    const [availableDates, setAvailableDates] = useState([]);
    const [cancelReason, setCancelReason] = useState(null);
    const [additionalDetails, setAdditionalDetails] = useState(null);
    const [showPayemntSuccessPopUp, setShowPayemntSuccessPopUp] = useState(false);
    const [mentorIds , setMentorIds] = useState('');

    const verifyTokenFunction = useCallback(async () => {

          try {
            let url = process.env.REACT_APP_API_URL+'/verify-token';
            const res = await unifiedPostRequest(url ,{}, true);
    
            // console.log('verify-token ', res.success); 

            if(!res.success){
                logout();
            }

          } catch (error) {
            console.error('Error verifying token:', error);
            
        }
    }, []);



    const signUp = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/signup';
            const res = await unifiedPostRequest(url ,userData);
            if(res.success){
                Cookies.set('token', res.result.token);
                Cookies.set('userRole', res.result.role);
                Cookies.set('userDetail', JSON.stringify(res.result));
                Cookies.set('flag', 1);
                dispatch({ type: actionTypes.SIGN_UP_SUCCESS, payload: res.result });
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const signUpMentor = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/signup';
            const res = await unifiedPostRequest(url ,userData);
            console.log(res)
            if(res.success){
                Cookies.set('token', res.result.token);
                Cookies.set('userRole', res.result.role);
                Cookies.set('userDetail', JSON.stringify(res.result));
                Cookies.set('flag', 1);
                dispatch({ type: actionTypes.SIGN_UP_SUCCESS, payload: res.result });
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const signIn = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/signIn';
            const res = await unifiedPostRequest(url ,userData);
            if(res.success){
                Cookies.set('token', res.result.token);
                Cookies.set('userRole', res.result.role);
                Cookies.set('userDetail', JSON.stringify(res.result));
                Cookies.set('flag', res.result.flag);
                dispatch({ type: actionTypes.SIGN_IN_SUCCESS, payload: res.result });
                return res.result;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const googleSignIn = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/social-signup';
            console.log('url ', url);
            const res = await unifiedPostRequest(url ,userData);
            console.log('res google ', res);
            if(res.success){
                Cookies.set('token', res.result.token);
                Cookies.set('userRole', res.result.role);
                Cookies.set('userDetail', JSON.stringify(res.result));
                Cookies.set('flag', res.result.flag);
                dispatch({ type: actionTypes.SIGN_IN_SUCCESS, payload: res.result });
                // Cookies.set('flag', res.result.flag);
                return res.result;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const googleSignInMentor = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/social-signup';
            const res = await unifiedPostRequest(url ,userData);
            if(res.success){
                Cookies.set('token', res.result.token);
                Cookies.set('userRole', res.result.role);
                Cookies.set('userDetail', JSON.stringify(res.result));
                Cookies.set('flag', res.result.flag);
                dispatch({ type: actionTypes.SIGN_IN_SUCCESS, payload: res.result });
                // Cookies.set('flag', res.result.flag);
                return res.result;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const signInMentor = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/signIn';
            const res = await unifiedPostRequest(url ,userData);
            if(res.success){
                Cookies.set('token', res.result.token);
                Cookies.set('userRole', res.result.role);
                Cookies.set('userDetail', JSON.stringify(res.result));
                Cookies.set('flag', res.result.flag);
                dispatch({ type: actionTypes.SIGN_IN_SUCCESS, payload: res.result });
                // Cookies.set('flag', res.result.flag);
                return res.result;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const onboardingForm = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/onboarding';
            const res = await unifiedPostRequest(url ,userData, true, true);
            if(res.success){
                Cookies.set('flag', 6);
                dispatch({ type: actionTypes.ONBOARDING_MENTEE});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const onboardingFormMentor = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/onboarding';
            const res = await unifiedPostRequest(url ,userData, true, true);
            if(res.success){
                Cookies.set('flag', 10);
                dispatch({ type: actionTypes.ONBOARDING_MENTEE});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const logout = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            Cookies.get('token') && Cookies.remove('token')
            Cookies.get('userRole') && Cookies.remove('userRole');
            Cookies.get('userDetail') && Cookies.remove('userDetail');
            Cookies.get('profile') && Cookies.remove('profile');
            Cookies.get('dashboard') && Cookies.remove('dashboard');
            Cookies.get('flag') && Cookies.remove('flag');
            Cookies.get('forgot_token') && Cookies.remove('forgot_token');
            Cookies.get('forgot_userId') && Cookies.remove('forgot_userId');
            Cookies.get('user') && Cookies.remove('user');
            dispatch({ type: actionTypes.LOGOUT });
            return true;
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getUserDashboardInfo = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/dashboard';
            const res = await getRequest(url);
            if(res.success){
                Cookies.set('dashboard', JSON.stringify(res.result));
                dispatch({ type: actionTypes.DASHBOARD_MENTEE, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                logout()
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getUserDashboardInfoMentor = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/dashboard';
            const res = await getRequest(url);
            if(res.success){
                // console.log('res.result ', res.result);
                Cookies.set('dashboard', JSON.stringify(res.result));
                dispatch({ type: actionTypes.DASHBOARD_MENTEE, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                logout();
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const markAsCompleteAsMentor = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/mark-session-complete';
            const res = await unifiedPostRequest(url ,userData,true);
            if(res.success){
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const deleteMentorAccount = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/deactivate';
            const res = await unifiedPostRequest(url ,userData,true);
            if(res.success){
                dispatch({ type: actionTypes.REMOVE_ERROR });
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const deleteMenteeAccount = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/deactivate';
            const res = await unifiedPostRequest(url ,userData,true);
            if(res.success){
                dispatch({ type: actionTypes.REMOVE_ERROR });
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getUserProfileInfo = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/profile';
            const res = await getRequest(url);
            if(res.success){
                Cookies.set('profile', JSON.stringify(res.result));
                dispatch({ type: actionTypes.PROFILE_MENTEE, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getmenteeUserProfileInfoStats = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/stats';
            const res = await getRequest(url);
            if(res.success){
                dispatch({ type: actionTypes.MENTEE_STATS, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getmentorUserProfileInfoStats = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/stats';
            const res = await getRequest(url);
            if(res.success){
                dispatch({ type: actionTypes.MENTOR_STATS, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorUserProfileInfo = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/profile';
            const res = await getRequest(url);
            if(res.success){
                Cookies.set('profile', JSON.stringify(res.result));
                dispatch({ type: actionTypes.PROFILE_MENTEE, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getUserMentorProfileInfo = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/profile';
            const res = await getRequest(url);
            if(res.success){
                Cookies.set('profile', JSON.stringify(res.result));
                dispatch({ type: actionTypes.PROFILE_MENTEE, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getFavoriteMentor = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/favorite/list';
            const res = await getRequest(url);
            if(res.success){
                dispatch({ type: actionTypes.FAVORITE_MENTORS, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getReviewsMentor = useCallback(async (pageNo=1, getReviewsMentor='latest') => {

        let newSortBy;

        if (getReviewsMentor === "latest") {
            newSortBy = 'asc';
        } else if (getReviewsMentor === "oldest") {
            newSortBy = 'desc';
        } else {
            newSortBy = getReviewsMentor.toLowerCase();
        }
        
        
        // getReviewsMentor ===  ? 'asc': 'desc'


        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/view/review-mentor?page=${pageNo}&sortOrder=${newSortBy}`
            const res = await getRequest(url);
            if(pageNo === 1){
                dispatch({ type: actionTypes.REVIEWS_MENTORS, payload: res});
                return true;
            }else {
                dispatch({ type: actionTypes.REMOVE_ERROR, payload: res });
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateMenteeProfile = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            let url = process.env.REACT_APP_API_URL+'/mentee/update-mentee-basic-info';
            const res = await unifiedPostRequest(url ,userData,true,true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateMentorProfile = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            let url = process.env.REACT_APP_API_URL+'/mentor/update-mentor-basic-info';
            const res = await unifiedPostRequest(url ,userData,true,true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateMenteeProfileOverView = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/update-mentee-overview';
            const res = await unifiedPostRequest(url ,userData,true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateMentorProfileOverView = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/update/overview';
            const res = await unifiedPostRequest(url ,userData,true,true);
            if(res.success){
                const mergedDataFirst = { ...existingProfileData, ...userData };
                const mergedData = { ...mergedDataFirst, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const ForgotPasswordFun = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/forget-password';
            const res = await unifiedPostRequest(url ,{email:userData});
            if(res.success){
                Cookies.set('forgot_userId', res.result.userId);
                dispatch({ type: actionTypes.FORGOT_PASSWORD});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const ForgotPasswordFunMentor = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/forget-password';
            const res = await unifiedPostRequest(url ,{email:userData});
            if(res.success){
                Cookies.set('forgot_userId', res.result.userId);
                dispatch({ type: actionTypes.FORGOT_PASSWORD});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const changePasswordFun = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/change-password';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                dispatch({ type: actionTypes.CHANGE_PASSWORD});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const changeMentorPasswordFun = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/change-password';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                dispatch({ type: actionTypes.CHANGE_PASSWORD});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const ResetPasswordFun = useCallback( async (userData) => {
        try {
            // console.log('ia am clicked mentee');
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/reset-password';
            const userId = Cookies.get('forgot_userId');
            const token = Cookies.get('forgot_token');
            const password = userData;
            console.log('userId ', userId);
            const url2 = `${url}?userId=${userId}&token=${token}&password=${password}`;
            
            const res = await getRequest(url2);
            console.log(res);
            if(res.success){
                Cookies.remove('forgot_token');
                Cookies.remove('forgot_userId');
                dispatch({ type: actionTypes.FORGOT_PASSWORD});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const ResetPasswordFunMentor = useCallback( async (userData) => {
        try {

            console.log('ia am clicked');
            // dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/reset-password';
            const userId = Cookies.get('forgot_userId');
            const token = Cookies.get('forgot_token');
            const password = userData;

            // console.log('userId ', userId);
            
            const url2 = `${url}?userId=${userId}&token=${token}&password=${password}`;
            
            const res = await getRequest(url2);
            console.log(res);
            if(res.success){
                Cookies.remove('forgot_token');
                Cookies.remove('forgot_userId');
                dispatch({ type: actionTypes.FORGOT_PASSWORD});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateEducationMentee = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/update/education';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateEducationMentor = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/update/education';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateExperienceMentee = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/update/experiance';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const updateExperienceMentor = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/update/experience';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const addEducationMentee = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/add/education';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const addEducationMentor = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/add/education';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const addExperienceMentee = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/add/experiance';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const addExperienceMentor = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/add/experience';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                const mergedData = { ...existingProfileData, ...res.result };
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const deleteEducationMentee = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/delete/education';
            const res = await deleteRequest(url , userData);
            if(res.success){
                const mergedData = { ...existingProfileData, education: existingProfileData.education.filter((edu) => edu._id !== userData.educationId)};
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const deleteEducationMentor = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/delete/education';
            const res = await deleteRequest(url , userData);
            if(res.success){
                const mergedData = { ...existingProfileData, education: existingProfileData.education.filter((edu) => edu._id !== userData.educationId)};
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const deleteExperienceMentee = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentee/delete/experiance';
            const res = await deleteRequest(url , userData);
            if(res.success){
                const mergedData = { ...existingProfileData, experience: existingProfileData.experience.filter((exp) => exp._id !== userData.experianceId)};
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const deleteExperienceMentor = useCallback( async (userData) => {
        const existingProfileData = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')): null;
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/delete/experience';
            const res = await deleteRequest(url , userData);
            if(res.success){
                const mergedData = { ...existingProfileData, experience: existingProfileData.experience.filter((exp) => exp._id !== userData.experianceId)};
                Cookies.set('profile', JSON.stringify(mergedData))
                dispatch({ type: actionTypes.UPDATE_PROFILE_MENTEE, payload: mergedData});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMenteeSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentee/all-bookings?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMenteeUpcomingSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentee/bookings-upcoming?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.UPCOMING_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMenteeCompletedSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentee/bookings-completed?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.COMPLETED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMenteeCancelRequestedSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentee/bookings-cancel-requested?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.CANCEL_REQUESTED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMenteeCancelledSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentee/bookings-cancelled?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.CANCELLED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMenteeReportedSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentee/bookings-reported?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.REPORTED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);
    const getSavedResources = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/saved-blog-resources?page=${page}`;
            const res = await getRequest(url);

            // console.log('saved-blog-resources ', res);

            if(res.success){
                if(page==1){
                    dispatch({ type: actionTypes.RESOURCES, payload: res});
                    return true;
                }else{
                    return res;
                }
                
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getSavedResourcesCoummunity = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/saved-community-resources?page=${page}`;
            const res = await getRequest(url);

            // console.log('saved-blog-resources ', res);

            if(res.success){
                if(page==1){
                    dispatch({ type: actionTypes.RESOURCES_COMM, payload: res});
                    return true;
                }else{
                    return res;
                }
                
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getCommunityCards = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/list-community?page=${page}`;
            const res = await getRequest(url);
            if(page===1){
                dispatch({ type: actionTypes.COMMUNITY, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);
    // const getMentorBlogs = useCallback(async () => {
    //     try {
    //         dispatch({ type: actionTypes.START_LOADING });
    //         let url = process.env.REACT_APP_API_URL+'/mentor/bloglist';
    //         const res = await getRequest(url);
    //         if(res.success){
    //             dispatch({ type: actionTypes.MENTOR_BLOGS, payload: res.result});
    //             return true;
    //         }else {
    //             dispatch({ type: actionTypes.FAILURE, payload: res });
    //             return false;
    //         }
    //     } catch (error) {
    //         dispatch({ type: actionTypes.FAILURE, payload: error.message });
    //         return false;
    //     }
    // }, []);
    const getMentorBlogs = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bloglist?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.MENTOR_BLOGS, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorDraftBlogs = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bloglist-draft?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.MENTOR_DRAFT_BLOGS, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorPendingBlogs = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bloglist-pending-approval?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.MENTOR_PENDING_BLOGS, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorPublishedBlogs = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bloglist-published?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.MENTOR_PUBLISHED_BLOGS, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorRejectedBlogs = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bloglist-rejected?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.MENTOR_REJECTED_BLOGS, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const deleteMentorBlog = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/blog/delete';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                // dispatch({ type: actionTypes.MENTOR_BLOGS, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const editMentorBlog = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/edit-blog';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                dispatch({ type: actionTypes.MENTOR_BLOGS_EDIT, payload: res.result});
                return res.result;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/all-bookings?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorUpcomingSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bookings-upcoming?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.UPCOMING_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorCompletedSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bookings-completed?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.COMPLETED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorCancelledSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bookings-cancelled?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.CANCELLED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorCancelRequestedSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bookings-cancel-requested?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.CANCEL_REQUESTED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorReportedSessions = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/bookings-reported?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.REPORTED_SESSION_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    // Create Availability
    const createAvailability = useCallback( async (userData) => {
        try {
            // dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/create/availability';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                // dispatch({ type: actionTypes.FAILURE, payload: res });
                return true;
            }else {
                // dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            // dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getAvailabilityStatus = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/availability-check-mentor';
            const res = await getRequest(url);
            // console.log(res);
            // console.log('check' , res.result == "false");
            if(res.success){
                let status = res.result === "false" ? true : false;
                dispatch({ type: actionTypes.AVAILABILITY_STATUS, payload: status});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const checkAvailabilityByDate = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/check-schedule-by-date';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                dispatch({ type: actionTypes.AVAILABILITY_EDIT, payload: res.result});
                setResultState(true)
                return true;
            }else {
                dispatch({ type: actionTypes.AVAILABILITY_EDIT, payload: res.result});
                setResultState(false);
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const availabilityEditData = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/edit-session';
            const res = await unifiedPostRequest(url , userData, true);
            console.log('res.result ', res.result);
            if(res.success){
                dispatch({ type: actionTypes.AVAILABILITY_EDIT_DATA, payload: res.result});
                setEditAvailability(true);
                return true;
            }else {
                dispatch({ type: actionTypes.AVAILABILITY_EDIT_DATA, payload: res.result});
                setEditAvailability(false);
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    // update
    const updateAvailability = useCallback( async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/update-session';
            const res = await unifiedPostRequest(url , userData, true);
            console.log('res update ', res);
            if(res.success){
                dispatch({ type: actionTypes.AVAILABILITY_EDIT_DATA, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorTransactionHistory = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentor/all-transactions?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                if(res.success){
                    dispatch({ type: actionTypes.MENTOR_TRANS_HISTORY, payload: res});
                    return true;
                }else {
                    dispatch({ type: actionTypes.FAILURE, payload: res });
                    return false;
                }
            }else {
                return res;
            }
            
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getMentorEarning = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/earnings';
            const res = await getRequest(url);
            if(res.success){
                dispatch({ type: actionTypes.MENTOR_EARNING, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.MENTOR_EARNING_NOT_FOUND });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getWithdrawEarning = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/request-debit';
            const res = await unifiedPostRequest(url , userData, true);
            if(res.success){
                // const mergedData = { ...state.earningData, ...res.result };
                dispatch({ type: actionTypes.MENTOR_WITHDRAW, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getBlogList = useCallback(async (userData, page=1, sortBy='asc') => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/blog/list?page=${page}&sortOrder=${sortBy}`;
            const res = await unifiedPostRequest(url, userData);
            if(page==1){
                dispatch({ type: actionTypes.BLOG_LIST, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getBlogListing = useCallback(async (userData, page=1, sortBy='asc') => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/blog/listing?page=${page}&sortOrder=${sortBy}`;
            const res = await unifiedPostRequest(url,userData, true);
            if(page==1){
                dispatch({ type: actionTypes.BLOG_LISTING, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getBlogListingFilterAndSort = useCallback(async (url , userData, page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            // let url = process.env.REACT_APP_API_URL+'/blog/listing';
            const res = await unifiedPostRequest(url,userData, true);
            if(page==1){
                dispatch({ type: actionTypes.BLOG_LISTING, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getBlogListFilterAndSort = useCallback(async (url, userData, page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            // let url = process.env.REACT_APP_API_URL+'/blog/list?page=1';
            const res = await unifiedPostRequest(url, userData);
            if(page==1){
                dispatch({ type: actionTypes.BLOG_LIST, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getCommunityList = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/community/list-all-posts';
            const res = await unifiedPostRequest(url);
            if(res.success){
                dispatch({ type: actionTypes.COMMUNITY_LIST, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);
    const getCommunityListT = useCallback(async () => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/communities/list-all-posts';
            const res = await getRequest(url);
            if(res.success){
                dispatch({ type: actionTypes.COMMUNITY_LIST, payload: res.result});
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getCommunityListFilter = useCallback(async (userData, page=1, sortBy='asc') => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            const url = process.env.REACT_APP_API_URL+`/community/list-all-posts?page=${page}&sortOrder=${sortBy}`;
            const res = await unifiedPostRequest(url, userData);
            if(page==1){
                dispatch({ type: actionTypes.COMMUNITY_LIST, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);

    const getCommunityListFilterAuth = useCallback(async (userData, page=1, sortBy='asc') => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            const url = process.env.REACT_APP_API_URL+`/communities/list-all-posts?page=${page}&sortOrder=${sortBy}`;
            const res = await unifiedPostRequest(url, userData, true);
            if(page==1){
                dispatch({ type: actionTypes.COMMUNITY_LIST, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);


    const addComment = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/mentor/blog/add-comment';
            const res = await unifiedPostRequest(url ,userData, true);
            if(res.success){
                dispatch({ type: actionTypes.COMMENT_ADD, payload: res.result });
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);
    const addCommentComm = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/add-comment-community';
            const res = await unifiedPostRequest(url ,userData, true);
            if(res.success){
                dispatch({ type: actionTypes.COMMENT_ADD, payload: res.result });
                return res.result;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);
    const countIncrease = useCallback(async (userData) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+'/add/clap';
            const res = await unifiedPostRequest(url ,userData, true);
            if(res.success){
                dispatch({ type: actionTypes.ADD_CLAP, payload: res.result });
                return true;
            }else {
                dispatch({ type: actionTypes.FAILURE, payload: res });
                return false;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);
    const getMenteePurchaseHistory = useCallback(async (page=1) => {
        try {
            dispatch({ type: actionTypes.START_LOADING });
            let url = process.env.REACT_APP_API_URL+`/mentee/purchase-history?page=${page}`;
            const res = await getRequest(url);
            if(page ===1){
                dispatch({ type: actionTypes.PURCHASE_HISTORY_DATA, payload: res});
                return true;
            }else {
                return res;
            }
        } catch (error) {
            dispatch({ type: actionTypes.FAILURE, payload: error.message });
            return false;
        }
    }, []);
    
    // const value = useMemo(() => {
    //     return { state, signUp, logout, onboardingForm, getUserDashboardInfo, signIn, ForgotPasswordFun, ResetPasswordFun, getUserProfileInfo, updateMenteeProfile, updateMenteeProfileOverView, changePasswordFun, getFavoriteMentor };
    // }, [state, signUp, logout, onboardingForm, getUserDashboardInfo, signIn, ForgotPasswordFun, ResetPasswordFun, getUserProfileInfo, updateMenteeProfile, updateMenteeProfileOverView, changePasswordFun, getFavoriteMentor]);
    



    const value = { state, signUp, logout, onboardingForm, getUserDashboardInfo,getUserDashboardInfoMentor, signIn, ForgotPasswordFun, ResetPasswordFun, getUserProfileInfo, updateMenteeProfile, updateMenteeProfileOverView, changePasswordFun, getFavoriteMentor, updateEducationMentee, addEducationMentee, deleteEducationMentee, updateExperienceMentee, 
        addExperienceMentee, deleteExperienceMentee, signUpMentor, signInMentor, onboardingFormMentor, 
        getUserMentorProfileInfo, googleSignIn,googleSignInMentor, getMentorUserProfileInfo, updateMentorProfileOverView, 
        updateMentorProfile, updateEducationMentor, addEducationMentor, deleteEducationMentor, updateExperienceMentor, 
        addExperienceMentor, deleteExperienceMentor, changeMentorPasswordFun, ForgotPasswordFunMentor, ResetPasswordFunMentor,getMentorTransactionHistory,
        getMenteeSessions,getMenteeUpcomingSessions,getMenteeCompletedSessions,getMenteeCancelledSessions, getMenteeReportedSessions, createAvailability, getAvailabilityStatus, trueState, setTrueState, checkAvailabilityByDate, resultState, setResultState, editAvailability, setEditAvailability, availabilityEditData, updateAvailability, getMentorSessions, availableDates,getMentorUpcomingSessions,getMentorCompletedSessions,getMentorCancelledSessions,getMentorCancelRequestedSessions,getMenteeCancelRequestedSessions, getMentorReportedSessions, verifyTokenFunction,
        setAvailableDates, getMentorEarning, getWithdrawEarning,getBlogList, getCommunityList,  getCommunityCards, addComment, addCommentComm, countIncrease, markAsCompleteAsMentor, getCommunityListT, getSavedResources, 
        getBlogListing, getMentorBlogs,getMentorDraftBlogs,getMentorPendingBlogs,getMentorPublishedBlogs,getMentorRejectedBlogs, dispatch, deleteMentorBlog, getReviewsMentor, getBlogListingFilterAndSort, getCommunityListFilter, getBlogListFilterAndSort, deleteMentorAccount, getCommunityListFilterAuth, deleteMenteeAccount, 
        editMentorBlog, getSavedResourcesCoummunity,getMenteePurchaseHistory , getmenteeUserProfileInfoStats, getmentorUserProfileInfoStats, cancelReason, setCancelReason,additionalDetails, setAdditionalDetails,showPayemntSuccessPopUp, setShowPayemntSuccessPopUp, mentorIds , setMentorIds};


    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export { AuthContext, AuthProvider };