import React, { useContext, useState } from 'react';
import NoSesh from '../../assets/images/no-sesh.svg';
import '../Dashboard/profile.css';
import Calendar from '../Calendar/Calendar';
import FullySession from './FullySession';
import { AuthContext } from '../../context/AuthContext';

export default function AvailabilityEmpty() {
    // const [empty ,setEmpty] = useState(false);
    const [calendar, setCalendar] = useState(false);
    const [selectedDateC, setSelectedDate] = useState('dd / mm / yyyy');

        const {state, setTrueState, checkAvailabilityByDate, resultState } = useContext(AuthContext);

    const empty = state.empty;

    const handleDateChange = (date, date2) => {
        setSelectedDate(date);
    };

    const setCalendarTrue = ()=>{
        setCalendar(true);
        document.body.classList.add('popup-open');
    }
    const setCalendarFalse = ()=>{
        setCalendar(false);
        document.body.classList.remove('popup-open');
    }
    const disabledDates = [];


    const handleSendSchedule = async () => {
        var inputDate = selectedDateC;
        inputDate = inputDate.replace(/\s/g, '');
        var parts = inputDate.split("/");
        var day = parts[0];
        var month = parts[1];
        var year = parts[2];
        var formattedDate = year + "-" + month + "-" + day;
        const res = await checkAvailabilityByDate({bookingDate : formattedDate});
    }


  return (
      <div className="manage-availability">
        <div className="manage-availability-wrap">
            {empty ? 
                 <>
                 <div className="manage-availability-top">
                     <div className="manage-availability-top-left">
                         <div className="dash-empty-sess-head">Your schedules</div>
                         <div className="dash-empty-sess-body"><p>Manage your availability here</p></div>
                     </div>
                     <div className="manage-availability-top-right" onClick={() => setTrueState(true)}>
                         <button className="primary-btn">
                             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                 <path d="M6 3V10" stroke="#2A8751" strokeLinecap="round" strokeLinejoin="round"/>
                                 <path d="M2.5 6.5H9.5" stroke="#2A8751" strokeLinecap="round" strokeLinejoin="round"/>
                             </svg> 
                             create new
                         </button>
                     </div>
                 </div>
                 <div className="manage-availability-bottom">
                     <div className="manage-availability-bottom-empty">
                        <div className="dash-emp-no-sess">
                             <div className="dash-emp-no-sess-wrap">
                                 <div className="dash-emp-no-sess-img">
                                     <img src={NoSesh} alt="" />
                                 </div>
                                 <div className="dash-emp-no-sess-head">You have no created a schedule</div>
                                 <div className="dash-emp-no-sess-body">
                                     <p> Don't worry, our experienced mentors are
                                         available to connect with you and help you grow.</p>
                                 </div>
                                 <div className="manage-availability-top-right" onClick={() => setTrueState(true)}>
                                     <button className="primary-btn">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                             <path d="M6 3V10" stroke="#2A8751" strokeLinecap="round" strokeLinejoin="round"/>
                                             <path d="M2.5 6.5H9.5" stroke="#2A8751" strokeLinecap="round" strokeLinejoin="round"/>
                                         </svg> 
                                         create new
                                     </button>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
                 </>
                :
                <>
                <div className="create-new-schedule-top">
                    <div className="dashboard-empty-state-banner">
                        <div className="dashboard-empty-state-banner-wrapper">
                            <div className="dash-empty-text-wrap">
                                <div className="dash-empty-name flex align">
                                    <div className="dash-empty-name-text">Create new schedule</div>
                                </div>
                                <div className="dash-empty-body">
                                    <p>Create new schedules or edit older ones.</p>
                                </div>
                            </div>
                            <div className="create-new-schedule-top-btn">
                                <div className="manage-availability-top-right" onClick={() => setTrueState(true)}>
                                    <button className="primary-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                            <path d="M6 3V10" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M2.5 6.5H9.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        create new
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-new-schedule-bottom">
                    <div className="create-new-schedule-bottom-top">
                        <div className="dash-empty-sess-head">Check Existing schedule</div>
                         <div className="dash-empty-sess-body"><p>Enter a date you want check your schedule for.</p></div>
                    </div>
                    <div className="create-new-schedule-bottom-middle">
                            <div onClick={setCalendarTrue} className="schedule-details-set-dropdown-left">
                                <div className="schedule-details-set-dropdown-wrap">
                                    <div className="schedule-details-set-dropdown-label">{selectedDateC}</div>
                                    <div className="schedule-details-set-dropdown-svg">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                                            <path d="M22 19.5566C22 20.9066 20.9055 22.0011 19.5556 22.0011H2.44444C1.0945 22.0011 0 20.9066 0 19.5566V5.50109C0 4.15114 1.0945 3.05664 2.44444 3.05664H19.5556C20.9055 3.05664 22 4.15114 22 5.50109V19.5566Z" fill="#E0E7EC"/>
                                            <path d="M14.4583 11.684H10.9218C10.1762 11.684 9.89938 11.2006 9.89938 10.7307C9.89938 10.2473 10.1615 9.77734 10.9218 9.77734H15.9231C16.6283 9.77734 16.918 10.4129 16.918 10.7722C16.918 11.0485 16.7658 11.3387 16.6002 11.684L13.1737 18.6476C12.7869 19.4213 12.6347 19.6969 12.0132 19.6969C11.253 19.6969 10.8662 19.117 10.8662 18.7161C10.8662 18.5505 10.9077 18.4264 11.0043 18.2327L14.4583 11.684ZM6.6556 11.6107H6.35005C5.68699 11.6107 5.41016 11.164 5.41016 10.694C5.41016 10.21 5.75543 9.77734 6.35005 9.77734H7.58143C8.17543 9.77734 8.50727 10.2057 8.50727 10.7582V18.6201C8.50727 19.3107 8.16627 19.6975 7.57227 19.6975C6.97827 19.6975 6.6556 19.3107 6.6556 18.6201V11.6107Z" fill="#66757F"/>
                                            <path d="M20.7778 0H18.7752C18.8803 0.180278 18.9444 0.387444 18.9444 0.611111C18.9444 1.28578 18.3969 1.83333 17.7222 1.83333C17.0476 1.83333 16.5 1.28578 16.5 0.611111C16.5 0.387444 16.5642 0.180278 16.6693 0H5.33072C5.43583 0.180278 5.5 0.387444 5.5 0.611111C5.5 1.28578 4.95244 1.83333 4.27778 1.83333C3.60311 1.83333 3.05556 1.28578 3.05556 0.611111C3.05556 0.387444 3.11972 0.180278 3.22483 0H1.22222C0.547556 0 0 0.547556 0 1.22222V7.94444H22V1.22222C22 0.547556 21.4524 0 20.7778 0Z" fill="#DD2F45"/>
                                            <path d="M8.05733 2.8126C8.05733 2.50705 8.25288 2.33594 8.51566 2.33594C8.77783 2.33594 8.97338 2.50705 8.97338 2.8126V5.87855H10.0605C10.3722 5.87855 10.5067 6.11077 10.5005 6.31855C10.4883 6.52022 10.3294 6.72127 10.0605 6.72127H8.53399C8.23455 6.72127 8.05733 6.52633 8.05733 6.22077V2.8126ZM3.83455 2.8126C3.83455 2.50705 4.0301 2.33594 4.29288 2.33594C4.55566 2.33594 4.75122 2.50705 4.75122 2.8126V4.94416C4.75122 5.50638 5.11116 5.95188 5.69172 5.95188C6.24722 5.95188 6.6261 5.48194 6.6261 4.94416V2.8126C6.6261 2.50705 6.82105 2.33594 7.08383 2.33594C7.3466 2.33594 7.54216 2.50705 7.54216 2.8126V4.99305C7.54216 6.0191 6.69883 6.79522 5.69172 6.79522C4.67177 6.79522 3.83455 6.03133 3.83455 4.99305V2.8126ZM3.35666 5.49966C3.35666 6.52572 2.70338 6.7946 2.14727 6.7946C1.72622 6.7946 1.02344 6.6296 1.02344 6.09794C1.02344 5.93355 1.16399 5.72577 1.36566 5.72577C1.6101 5.72577 1.82399 5.95188 2.09838 5.95188C2.4406 5.95188 2.4406 5.6341 2.4406 5.43855V2.8126C2.4406 2.50705 2.63616 2.33594 2.89833 2.33594C3.16172 2.33594 3.35666 2.50705 3.35666 2.8126V5.49966Z" fill="#F5F8FA"/>
                                            <path d="M19.5547 6.11046C19.5547 6.44779 19.8279 6.72157 20.1658 6.72157C20.5037 6.72157 20.7769 6.44779 20.7769 6.11046C20.7769 5.77313 20.5037 5.49935 20.1658 5.49935C19.8279 5.49935 19.5547 5.77313 19.5547 6.11046ZM19.5547 4.27713C19.5547 4.61446 19.8279 4.88824 20.1658 4.88824C20.5037 4.88824 20.7769 4.61446 20.7769 4.27713C20.7769 3.93979 20.5037 3.66602 20.1658 3.66602C19.8279 3.66602 19.5547 3.93979 19.5547 4.27713ZM17.7214 6.11046C17.7214 6.44779 17.9945 6.72157 18.3325 6.72157C18.6704 6.72157 18.9436 6.44779 18.9436 6.11046C18.9436 5.77313 18.6704 5.49935 18.3325 5.49935C17.9945 5.49935 17.7214 5.77313 17.7214 6.11046ZM17.7214 4.27713C17.7214 4.61446 17.9945 4.88824 18.3325 4.88824C18.6704 4.88824 18.9436 4.61446 18.9436 4.27713C18.9436 3.93979 18.6704 3.66602 18.3325 3.66602C17.9945 3.66602 17.7214 3.93979 17.7214 4.27713ZM15.888 6.11046C15.888 6.44779 16.1612 6.72157 16.4991 6.72157C16.8371 6.72157 17.1102 6.44779 17.1102 6.11046C17.1102 5.77313 16.8371 5.49935 16.4991 5.49935C16.1612 5.49935 15.888 5.77313 15.888 6.11046ZM15.888 4.27713C15.888 4.61446 16.1612 4.88824 16.4991 4.88824C16.8371 4.88824 17.1102 4.61446 17.1102 4.27713C17.1102 3.93979 16.8371 3.66602 16.4991 3.66602C16.1612 3.66602 15.888 3.93979 15.888 4.27713ZM14.0547 4.27713C14.0547 4.61446 14.3279 4.88824 14.6658 4.88824C15.0037 4.88824 15.2769 4.61446 15.2769 4.27713C15.2769 3.93979 15.0037 3.66602 14.6658 3.66602C14.3279 3.66602 14.0547 3.93979 14.0547 4.27713ZM14.0547 6.11046C14.0547 6.44779 14.3279 6.72157 14.6658 6.72157C15.0037 6.72157 15.2769 6.44779 15.2769 6.11046C15.2769 5.77313 15.0037 5.49935 14.6658 5.49935C14.3279 5.49935 14.0547 5.77313 14.0547 6.11046Z" fill="#F4ABBA"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="show-schedule-button" onClick={handleSendSchedule}>
                                <button className="primary-btn black">
                                    <div>Show schedule</div>
                                </button>
                            </div>
                    </div>
                    <div className="create-new-schedule-bottom-bottom">
                        {resultState == null ? <></> : resultState ? 
                            <>
                             <FullySession/>
                            </>
                            :
                            <>
                            <div className="create-new-schedule-bottom-bottom-empty">
                                <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">You haven’t created any sessions for this date.</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>  Don't worry, our experienced mentors are
available to connect with you and help you grow.</p>
                                    </div>
                                    <div className="manage-availability-top-right" onClick={() => setTrueState(true)}>
                                        <button className="primary-btn">
                                            create new
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                </div>
                <div className={`availability-calendar ${ calendar ? 'active' : ''}`}>
                <Calendar selectedDate={selectedDateC} onDateChange={handleDateChange} disabledDates={disabledDates} onClose={setCalendarFalse} />
                 </div>
                </>
            }
        </div>
      </div>
  )
}
