import React from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import Profile from '../../components/menteeProfile/profile';

export default function MenteeProfile() {
  return (
    <main className='mentee-profile-main'>
      <Header/>
        <Profile/>
      <Footer/>
    </main>
  )
}
