import React, { useState, useEffect } from 'react'

const ObStep4 = ({nextStep,prevStep, handleOnboardingInputChange2, values}) => {

    const [experienceData, setExperienceData] = useState({
        positionName: values?.experience?.positionName ? values?.experience?.positionName : "",
        companyName: values?.experience?.companyName ? values?.experience?.companyName : "",
        startDate: "",  
        endDate: "",    
        startMonth: values?.experience?.from ? new Date(values?.experience?.from).getMonth() + 1 : "",
        startYear: values?.experience?.from ? new Date(values?.experience?.from).getFullYear() : "",
        endMonth: values?.experience?.to ? new Date(values?.experience?.to).getMonth() + 1 : "",
        endYear: values?.experience?.to ? new Date(values?.experience?.to).getFullYear() : "",
        });

        const [years, setYears] = useState([]);
        const [endYears, setEndYears] = useState([]);

        const[posiError, setPosiError] = useState("");  
        const[compError, setCompError] = useState("");  
        const[startMError, setStartMError] = useState("");
        const[startYError, setStartYError] = useState("");
        const [dimmed, setDimmed] = useState(values?.experienceCheckbox);

        const toggleElementOpacity = () => {
            setDimmed(!dimmed);
            handleOnboardingInputChange2('experienceCheckbox', !dimmed)
            setExperienceData((prevData) => ({
              ...prevData,
              "endMonth": "",
              "endYear": "",
          }));
        };

        useEffect(() => {
            // Generate the list of years when the component mounts
            const startYearY = 1980;
            const endYearY = 2040;
            const yearsArray = Array.from(
            { length: endYearY - startYearY + 1 },
            (_, index) => startYearY + index
            );
            setYears(yearsArray);
            setEndYears(yearsArray);
        }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setExperienceData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'startYear' || name === 'startMonth') {
            filterEndYears(value);
          }
    };
    const filterEndYears = (selectedStartYear) => {
        const startYear = parseInt(selectedStartYear, 10);
      
        if (!isNaN(startYear)) {
          const filteredEndYears = years.filter((year) => year > startYear);
          setEndYears(filteredEndYears);
        } else {
          setEndYears(years);
        }
      };
    // console.log('experienceData ', experienceData);

const handleSubmit = (e) => {
    e.preventDefault();
    handleOnboardingInputChange2('experience', {
        positionName: experienceData.positionName,
        companyName: experienceData.companyName,
        from: experienceData.startYear+'-'+experienceData.startMonth+'-01',  
        to: experienceData.endYear ? experienceData.endYear+'-'+experienceData.endMonth+'-01': null,   
        })

        if (!experienceData.positionName.trim()) {
            setPosiError("Position is Required");
            return;
        }
        if (!experienceData.companyName.trim()) {
            setCompError("Company is Required");
            return;
        }
        if(experienceData.startMonth.length === 0){
            setStartMError("Please select a month");
            return;
        }
        if(experienceData.startYear.length === 0){
            setStartYError("Please select a year");
            return;
        }    
    nextStep();

};
const handleSkip =()=>{
    nextStep();
}
const handleBack = () =>{
    prevStep();
}

// const startYearY = 1980;
// const endYearY = 2040;
// const years = Array.from({ length: endYearY - startYearY + 1 }, (_, index) => startYearY + index);

    return (
    <>
    <div className='stepLine'>
        <div className='slFilled mWidth80'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 4 of 5
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Share Professional Background.</h5>
        </div>
        <div className="obHeadingSvg">
            <button className='obFormSkip' onClick={handleSkip}>Skip</button>
        </div>
        </div>
        <div className="obBodyWrapper">
            <p>Add your latest experience. You can add more later.</p>
        </div>
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="position">Position</label>
                    <div className="select-wrapper">
                    {posiError && <p className="error-name-onb">{posiError}</p>}      
                    <input
                            id="position"
                            name="positionName"
                            type="text"
                            placeholder='Ex. Civil Engineer'
                            onChange={handleInputChange}
                            value={experienceData.positionName}
                        />
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="company">Company name</label>
                    {compError && <p className="error-name-onb">{compError}</p>}
                    <input
                            id="company"
                            name="companyName"
                            type="text"
                            placeholder='Ex. L&T'
                            onChange={handleInputChange}
                            value={experienceData.companyName}
                        />
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="timezone">Start date</label>
                    {startMError && <p className="error-name-onb">{startMError}</p>}
                    {startYError && <p className="error-name-onb">{startYError}</p>}
                    <div className="select-wrapper flex">
                        <select
                        id="start-date-month"
                        name="startMonth"
                        onChange={handleInputChange}
                        value={experienceData.startMonth}
                        >
                        <option value="" disabled>Month</option>
                        <option value="1" >January</option>
                        <option value="2" >February</option>
                        <option value="3" >March</option>
                        <option value="4" >April</option>
                        <option value="5" >May</option>
                        <option value="6" >June</option>
                        <option value="7" >July</option>
                        <option value="8" >August</option>
                        <option value="9" >September</option>
                        <option value="10" >October</option>
                        <option value="11" >November</option>
                        <option value="12" >December</option>
                        </select>
                        <select
                        id="start-date-year"
                        name="startYear"
                        onChange={handleInputChange}
                        value={experienceData.startYear}
                        >
                        <option value="" disabled>Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                        <div className="is-present-checkbox">
                                                    <input type="checkbox" name="present" id="present" checked={dimmed} onChange={toggleElementOpacity} />
                                                    <label htmlFor="present">
                                                        Present
                                                    </label>
                        </div>
                    </div>
                </div>
                <div className={dimmed ? 'login-form-input flex flex-c dimmed' : 'login-form-input flex flex-c'}>
                    <label htmlFor="timezone">End date</label>
                    <div className="select-wrapper flex">
                        <select
                        id="end-date-month"
                        name="endMonth"
                        value={experienceData.endMonth}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Month</option>
                        <option value="1" >January</option>
                        <option value="2" >February</option>
                        <option value="3" >March</option>
                        <option value="4" >April</option>
                        <option value="5" >May</option>
                        <option value="6" >June</option>
                        <option value="7" >July</option>
                        <option value="8" >August</option>
                        <option value="9" >September</option>
                        <option value="10" >October</option>
                        <option value="11" >November</option>
                        <option value="12" >December</option>
                        </select>
                        <select
                        id="end-date-year"
                        name="endYear"
                        value={experienceData.endYear}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Year</option>
                        {endYears.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
</>
)
}

export default ObStep4
