import React, {useRef, useState} from 'react';
import './banner-platform.css';
import Ticker from '../../../components/reusable/ticker/Ticker';
import { useInView } from "framer-motion";
import { Link } from 'react-router-dom';
import Login from '../login/Login';
import SignUp from '../signup/SignUp';
import ForgotPassword from '../forgot-password/forgotPassword';
import ChangePassword from '../change-password/changePassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function BannerPlatform({props,propsrole}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
    const data = props;
    const texts=["Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive","Connect","Learn","Grow","Thrive"];
    const dynamicStyles = {
        bg: {
        background: '#F8F6ED',
        color:'#444444',
        bottom: '-45px'
        },
        fill: {
        fill:'#FCD26E'
        }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [isChangeOpen, setChangeOpen] = useState(false);

  const handleLoginClick = () => {
    setIsOpen(true);
    setSignUpOpen(false);
    document.body.classList.add('popup-open');
  };
  const handleCloseLoginPopup = () =>{
    setIsOpen(false);
    document.body.classList.remove('popup-open');
  }
  const handleOpenSignUp = () => {
    setSignUpOpen(true);
    setIsOpen(false);
  };
  const handleCloseSignUp = () => {
    setSignUpOpen(false);
    document.body.classList.remove('popup-open');
  };
  const handleOpenForgot = () => {
    setForgotOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
  };
  const handleCloseForgot = () => {
    setForgotOpen(false);
    document.body.classList.remove('popup-open');
  }; 
  const handleOpenChange = () => {
    setChangeOpen(true);
    setIsOpen(false);
    setSignUpOpen(false);
    setForgotOpen(false);
  };
  const handleCloseChange = () => {
    setChangeOpen(false);
    document.body.classList.remove('popup-open');
  };

  const notify = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Email Sent Successfully!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );
  return (
    <>
    <section className="banner-platform">
        <div className="container">
            <div className="banner-platform-wrapper flex">
                <div className="banner-platform-left">
                    <div className="banner-platform-left-wrapper">
                        <div className="banner-platform-heading">
                            <h2 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} dangerouslySetInnerHTML={{ __html: data?.bannerHeading }}></h2>
                        </div>
                        <div className="banner-platform-body">
                            <p ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "0.9s"
        }}>{data?.bannerPara}</p>
                        </div>
                        <div ref={ref} style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          transitionDelay: "0.9s"
        }} className="banner-platform-button">
                            {propsrole === undefined ? (
                                <button onClick={handleLoginClick} className="primary-btn primary-btn-2 insage">
                                  Become a Mentee
                                </button>
                              ) : propsrole === "mentee" ? (
                                 <Link to="/explore-our-mentors" className="primary-btn primary-btn-2 insage">Explore our Mentors</Link>
                              ) : propsrole === "mentor" ? (
                                 ""
                              ) : null}
                        </div>
                    </div>
                </div>
                <div className="banner-platform-right flex flex-c">
                <div className="banner-platform-right-wrapper">
                      <div ref={ref} style={{
                  clipPath: isInView ? "inset(-10%)" : "inset(0 100% 0 0)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                  transitionDelay: "1.9s"
                }} className="banner-platform-right-image">
                        <img className='img' src={data?.bannerImg} alt="" />
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="37" cy="37" r="37" fill="#FF7352"/>
                                        <path d="M60.299 33.4945H44.4197L55.6472 22.2671L51.304 17.9219L37.002 32.2218L22.702 17.9219L18.3568 22.2671L29.5863 33.4945H13.707V39.6396H29.5863L18.3568 50.8691L22.702 55.2123L37.002 40.9123L51.304 55.2123L55.6472 50.8691L44.4197 39.6396H60.299V33.4945Z" fill="white"/>
                                        <path d="M37.0037 20.8668C39.3601 20.8668 41.2691 18.9577 41.2691 16.6014C41.2691 14.245 39.3601 12.3359 37.0037 12.3359C34.6473 12.3359 32.7383 14.245 32.7383 16.6014C32.7383 18.9577 34.6473 20.8668 37.0037 20.8668Z" fill="white"/>
                                        <path d="M41.2135 57.2109C41.5909 54.8856 40.0118 52.6946 37.6864 52.3172C35.3611 51.9398 33.1701 53.519 32.7927 55.8443C32.4153 58.1696 33.9944 60.3606 36.3198 60.738C38.6451 61.1154 40.8361 59.5363 41.2135 57.2109Z" fill="white"/>
                        </svg>
                      </div>
                </div>
                </div>
                <div className="banner-platform-bg">
                        <svg width="558" height="621" viewBox="0 0 558 621" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M589 267.458H388.259L530.193 125.525L475.289 70.5938L294.487 251.369L113.711 70.5938L58.7805 125.525L200.741 267.458H0V345.142H200.741L58.7805 487.102L113.711 542.006L294.487 361.231L475.289 542.006L530.193 487.102L388.259 345.142H589V267.458Z" fill="#FFECA1"/>
                            <path d="M294.524 107.844C324.312 107.844 348.446 83.7105 348.446 53.922C348.446 24.1335 324.312 0 294.524 0C264.735 0 240.602 24.1335 240.602 53.922C240.602 83.7105 264.735 107.844 294.524 107.844Z" fill="#FFECA1"/>
                            <path d="M347.716 567.292C352.487 537.896 332.524 510.199 303.128 505.428C273.732 500.657 246.034 520.62 241.263 550.016C236.493 579.412 256.455 607.11 285.851 611.88C315.248 616.651 342.945 596.688 347.716 567.292Z" fill="#FFECA1"/>
                        </svg>
                      </div>
            </div>
        </div>
       <div ref={ref} style={{
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                  opacity: isInView ? 1 : 0,
                  transitionDelay: "1.9s"
                }} className="banner-platform-ticker">
       <Ticker texts={texts} styles={dynamicStyles}/>
       </div>
    </section>
    <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isOpen ? 'active' : ''}`}>
    <Login onClose={handleCloseLoginPopup} onSignUpClick={handleOpenSignUp} onForgotClick={handleOpenForgot} />
 </div>
 <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isSignUpOpen ? 'active' : ''}`}>
    <SignUp onLoginClick={handleLoginClick } onClose={handleCloseSignUp}/>
 </div>
 <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isForgotOpen ? 'active' : ''}`}>
    <ForgotPassword noti={notify}  onClose={handleCloseForgot} handleOpenChange={handleOpenChange}/>
 </div>
 <div className={`login-sign-up-pop-up flex flex-c flex-jsc ${ isChangeOpen ? 'active' : ''}`}>
    <ChangePassword  onClose={handleCloseChange} handleLoginClick={handleLoginClick}/>
 </div>
 </>
  )
}
