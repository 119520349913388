import React from 'react';
import './contact.css';
import Contact1 from '../../assets/images/contact-1.png';
import Contact2 from '../../assets/images/contact-2.png';
import Contact3 from '../../assets/images/contact-3.png';
import Contact4 from '../../assets/images/contact-4.png';
import Contact5 from '../../assets/images/contact-5.png';
import Contact6 from '../../assets/images/contact-6.png';
import Contact7 from '../../assets/images/contact-7.png';
import Contact8 from '../../assets/images/contact-8.png';
import Contact9 from '../../assets/images/contact-9.png';

export default function ContactBanner() {
  return (
   <section className="insage-contact-banner">
      <div className="insage-contact-banner-wrapper">
        <div className="contact-top-images flex flex-jsc">
            <div className="contact-image-1">
                <img src={Contact1} alt="" />
            </div>
            <div className="contact-image">
            <img src={Contact2} alt="" />
            </div>
            <div className="contact-image">
            <img src={Contact3} alt="" />
            </div>
            <div className="contact-image">
            <img src={Contact4} alt="" />
            </div>
        </div>
        <div className="contact-bottom-images flex flex-jsc">
            <div className="contact-image">
                <img src={Contact5} alt="" />
            </div>
            <div className="contact-image">
            <img src={Contact6} alt="" />
            </div>
            <div className="contact-image">
            <img src={Contact7} alt="" />
            </div>
            <div className="contact-image">
            <img src={Contact8} alt="" />
            </div>
            <div className="contact-image-2">
                <img src={Contact9} alt="" />
            </div>
        </div>
      </div>
   </section>
  )
}
