import React, { useContext, useState } from 'react';
import '../Dashboard/profile.css';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useToaster } from '../Dashboard/ToasterContext';
import SuccImg from '../../assets/images/payment-succes.png';
import { ChatContext } from '../../context/ChatContext';
import { AuthContext } from '../../context/AuthContext';
import PaymentConfPopUp from '../Dashboard/PaymentConfPopUp';

const Payment = ({agenda, sessionCost, description, mentorId, availabilityId, slotId, close}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState('');
    const [loadingIcon, setLoadingIcon] = useState(false);
    const {setDashTabIndex} = useContext(ChatContext);
    const navigate = useNavigate();
    const { showPopup } = useToaster();
    const {state, showPayemntSuccessPopUp, setShowPayemntSuccessPopUp, mentorIds , setMentorIds} = useContext(AuthContext);
    const {handleTabIndex} = useContext(ChatContext);
    const {createChat, UpdatePotentialUserList, potentialChats} = useContext(ChatContext)



    // const handleMessageToMentor = async () => {
    //     const res = await createChat(state?.profile?.userId, mentorId);
    //     UpdatePotentialUserList(potentialChats, mentorId);
    //     handleTabIndex(1);
    //     navigate("/mentee/dashboard");
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingIcon(true);
        setPaymentError('');
        try {

            //  loader start


        const result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        });
    
        if (result.error) {
        setPaymentError(result.error.message);
        } else {

            const token = Cookies.get('token');

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/mentee/session/payment`, { payment_method_id: result.paymentMethod.id,agenda:agenda, amount: sessionCost}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });


            const result2 = await stripe.confirmCardPayment(response.data.result, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            const data = {
                amount: sessionCost,
                agenda: agenda,
                paymentStatus: result2.paymentIntent.status,
                description: description,
                paymentType: 'card',
                mentorId: mentorId,
                availabilityId: availabilityId,
                slotId: slotId,
                paymentIntentId: result2.paymentIntent.id,

            }

            if(paymentError){
                setLoadingIcon(false);

                showPopup(
                    <>
                   <div className="payment-successful-top">
                       <div className="payment-success-head">There was an error while processing your payment!</div>
                       <div className="payment-sucess-body">Please try again later</div>
                   </div>
                   <div className="payment-success-img">
                       <img src={SuccImg} alt="" />
                   </div>
                   </>
         );

            } else {
                const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/mentee/session/create`, data , {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
    
                // console.log('response2 ', response2)
                setLoadingIcon(false);
    
                if(response2.data.success){
                    console.log("Payment Done Successfully");
                    setShowPayemntSuccessPopUp(true);
                    setMentorIds(mentorId);
                    close();
                }else {
                    close();
                    showPopup(
                        <>
                       <div className="payment-successful-top">
                           <div className="payment-success-head">There was an error while processing your payment!</div>
                           <div className="payment-sucess-body">Please try again later</div>
                       </div>
                       <div className="payment-success-img">
                           <img src={SuccImg} alt="" />
                       </div>
                       </>
             );
                }
            }



        }
        } catch (error) {
            setLoadingIcon(false);
        }
    };
    const cardElementOptions = {
        style: {
            base: {
                color: '#32325d',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            idealBank:{
               padding: '10px'
            },
            invalid: {
                color: '#fa755a',
            },
        },
    };

    return (
        <>
        <form onSubmit={handleSubmit}>
            <div className="stripe-card-element">    
                <CardElement />
            </div>
        {loadingIcon ? (<button className='primary-btn'>
        <FontAwesomeIcon icon={faSpinner} spin />
        </button>) : (<button className='primary-btn' type="submit">
            <>Pay</>
        </button>)}
        
        {paymentError && <div>{paymentError}</div>}
        </form>
        
        </>
    );
};

export default Payment;
