import React,{useState} from 'react';
import '../Dashboard/profile.css';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ConfirmSched({selectedDate, dura, slot, onClose, selectedSlots, selectedDateC, noti, noti2}) {
    const [loading, setLoading] = useState(false);

    const {createAvailability} = useContext(AuthContext);

    var inputDate = selectedDateC;
    inputDate = inputDate.replace(/\s/g, '');
    var parts = inputDate.split("/");
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];
    var formattedDate = year + "-" + month + "-" + day;

    const data = {
            sessionDate: formattedDate,
            duration: dura,
            slots: selectedSlots
        }


const handleCreateAvailability =  async () => {
    setLoading(true);

    try{
        let res = await createAvailability(data);

        if(res){
            setLoading(false);
            onClose();
            noti();
            setTimeout(()=>{
                window.location.href = '/mentor/dashboard';
            },2000)
        }
        else{
            setLoading(false);
            onClose();
            noti2();
        }
    }
    catch(error){
        console.log(error);
    }
    


}

  return (
    <div className='confirm-schedule-pop'>
        <div className="confirm-schedule-pop-wrap">
                <div className="schedule-steps-label">Confirm schedule</div>
                <div className="login-head"><span>{selectedDate}</span></div>
                <div className="confirm-sched-bottom">
                    <div className="schedule-details-summary-cont">
                        <div className="dash-empty-sess-head">Details</div>
                        <div className="schedule-details-set-cont-body"><p>This is the final confirmation. Review your schedule creation details below.</p></div>
                    </div>
                    <div className="schedule-details-summary-separator"></div>
                    <div className="schedule-details-summary-timings">
                        <div className="schedule-details-summary-timings-wrap">
                            <div className="schedule-details-summary-timings-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                        <path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"/>
                                </svg>
                            </div>
                            <div className="schedule-details-summary-timings-title">Creating schedule for</div>
                            <div className="schedule-details-summary-timings-dot"></div>
                            <div className="schedule-details-summary-timings-time">{selectedDate}</div>
                        </div>
                        <div className="schedule-details-summary-timings-wrap">
                        <div className="schedule-details-summary-timings-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <g clipPath="url(#clip0_4438_77677)">
                                    <path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_4438_77677">
                                    <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="schedule-details-summary-timings-title">Each session duration</div>
                            <div className="schedule-details-summary-timings-dot"></div>
                            <div className="schedule-details-summary-timings-time">{dura} mins</div>
                        </div>
                        <div className="schedule-details-summary-timings-wrap">
                        <div className="schedule-details-summary-timings-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <path d="M4.5 6L6 7.5L11 2.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.5 6.5V10C10.5 10.2652 10.3946 10.5196 10.2071 10.7071C10.0196 10.8946 9.76522 11 9.5 11H2.5C2.23478 11 1.98043 10.8946 1.79289 10.7071C1.60536 10.5196 1.5 10.2652 1.5 10V3C1.5 2.73478 1.60536 2.48043 1.79289 2.29289C1.98043 2.10536 2.23478 2 2.5 2H8" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="schedule-details-summary-timings-title">Sessions created</div>
                            <div className="schedule-details-summary-timings-dot"></div>
                            <div className="schedule-details-summary-timings-time">{slot}</div>
                        </div>
                    </div>
                    <div className="schedule-details-summary-separator"></div>
                    <div className="schedule-details-summary-button">
                        <div onClick={handleCreateAvailability} className="download-guide-btn">
                                {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                <>Create Schedule</>
                                )}
                        </div>
                    </div>
                </div>
        </div>
        <div onClick={onClose} className="confirm-schedule-close">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    </div>
  )
}
