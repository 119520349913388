import React from 'react';
import './trending.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function TrendingCommunity({props}) {
    const { id } = useParams();

    console.log(props?.content)
    
  return (
    <section className="Trending_post_section">
        <div className="blog-container-for-line">
            <div className="Trending_title">
                <h1>trending now</h1>
            </div>
            <div className="Trending_post_wrapper">
                <div className="Trending_post_grid">
                {props?.slice(0, 3).map((prop,index) => {
                    return(
                    <Link key={index} to={`/community/${prop?.slug}`}>
                        <div className="Trending_post_box">
                            <div className="Trending_post_img">
                                <img src={prop?.image} alt=""/>
                            </div>
                            <div className="Trending_post_content">
                                <div className="Trending_post-title">
                                    <h6>{prop?.title}</h6>  
                                </div>
                                <div className="Trending_post_para">
                                    <p dangerouslySetInnerHTML={{ __html: prop?.content }}></p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    );
                     })}    
                </div>
            </div>
        </div>
    </section>
  )
}
