import React,{useRef} from 'react';
import { motion, useInView } from 'framer-motion';
import CountUp from 'react-countup';

export default function DataHead({targetNumber,currentText, isLastItem}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true }); 
    // console.log('key ', key); 
  return (
    <motion.div ref={ref} className="insage-knowledge-single-data">
        <motion.div className="insage-knowledge-single-data-head"> {isInView ? <CountUp end={targetNumber} /> : null} <span>{isLastItem ? '%' : '+'}</span></motion.div>
        <motion.div className="insage-knowledge-single-data-body">{currentText}</motion.div>
    </motion.div>
  )
}
