import React from 'react';
import axios from 'axios';
import Header from '../../../layouts/header/Header';
import Footer from '../../../layouts/footer/Footer';
import BannerPlatform from '../../../components/reusable/platform-banner/banner-platform';
import QualityMentors from '../../../components/usecases/quality-mentors';
import ProfessionalNetworking from '../../../components/usecases/professional-networking';
import LearningCommunity from '../../../components/usecases/learning-community';
import Cookies from 'js-cookie';

export default function UseCases() {
  const [usecasesData, setUsecasesData] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("../usecases.json")
    .then((res) => setUsecasesData(res.data))
    .catch(err=>console.log(err))
  },[]);
  const userRole = Cookies.get('userRole');

  const [topMentorData, settopMentorData] = React.useState([]);
React.useEffect(() => {
  const url = process.env.REACT_APP_API_URL+'/all-mentors'
  axios
  .get(url)
  .then((res) => settopMentorData(res.data))
  .catch(err=>console.log(err))
},[]);

  return (
    <main className="insage-usecases">
        <Header/>
        <BannerPlatform props={usecasesData.bannerData} propsrole={userRole}/>
        <QualityMentors topMentorData={topMentorData}/>
        <ProfessionalNetworking props={usecasesData.networking}/>
        <LearningCommunity props={usecasesData.community}/>
        <Footer/>
    </main>
  )
}
