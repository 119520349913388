import React from 'react';
import axios from 'axios';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import AboutBanner from '../../components/about/about-banner';
import AboutConnections from '../../components/about/about-connections';
import AboutInspires from '../../components/about/about-inspires';
import AboutEmpowering from '../../components/about/about-empowering';
import AboutMeaningful from '../../components/about/about-meaningful';

export default function About() {
  const [aboutData, setAboutData] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("./about.json")
    .then((res) => setAboutData(res.data))
    .catch(err=>console.log(err))
  },[]);
  return (
    <main>
        <Header/>
        <AboutBanner props={aboutData.bannerData}/>
        <AboutConnections props={aboutData.connections}/>
        <AboutInspires props={aboutData.inspires}/>
        <AboutEmpowering props={aboutData.empowers}/>
        <AboutMeaningful props={aboutData.meaningful}/>
        <Footer/>
    </main>
  )
}
