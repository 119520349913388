import React, { useState, useRef, useEffect,useContext } from 'react';
import '../resources/resources.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChatContext } from '../../context/ChatContext';
import { useNavigate } from 'react-router-dom';
import CreateCommunityPostPopup from '../post/CreateCommunityPostPopup';
import UpdateCommunityPostPopup from '../post/UpdateCommunityPostPopup';

export default function CommunityCard ({post, get}) {

    const token = Cookies.get('token');
    const [editorHtml, setEditorHtml] = useState('');
    const [postTitle, setPostTitle] = useState('');
    const quillRef = useRef(null);
    const [openCommunityPostPopup, setopenCommunityPostPopup] = useState(false);
    const [data2, setData2] = useState(null);
    const [loading, setLoading] = useState(false);
    const [industry, setIndustry] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [readTime, setReadTime] = useState(0);
    const navigate = useNavigate();
    const {handleTabIndex} = useContext(ChatContext);
    const [editData, setEditData] = useState('');

    const additionalClass = !post?.image ? 'no-image' : '';

    // console.log('this card for communty post');
    const { id } = useParams();
    const userToken = Cookies.get('token');
    console.log('userToken ', post);

    const handleDeleteClick =  (communityId) => () =>{
        const token = Cookies.get('token');
            const resourceData = {
                communityId: communityId,
            }
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/delete-community`,
                resourceData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              ).then((res) => {
              console.log(res);
              get();
            })
            .catch((err) => {
              // Handle errors
            });
    }

    const handleMoveToPublish = async () => {
        try {
            // Get the full content of the editor
            const fullEditorContent = post?.content;
    
            let getUrl = process.env.REACT_APP_API_URL + '/mentor/list-blog-categories';
            const response2 = await fetch(getUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });  
            if (response2.ok) {
                const data2 = await response2.json(); // Assuming the response contains JSON data
                setData2(data2);
            } else {
                throw new Error(`Error: ${response2.status} - ${response2.statusText}`);
            }
            // Save the data in states
            setPostTitle(post?.title);
            setEditorHtml(fullEditorContent);
    
            setopenCommunityPostPopup(true);
            document.body.classList.add('popup-open');
        } catch (error) {
            console.error('Error occurred:', error);
            // Handle error here
        }
    };

    const closeCommunityPostPop = () => {
        setopenCommunityPostPopup(false);
        document.body.classList.remove('popup-open');
    };

    const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Community post created successfully</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
    const notifyE = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#F45E5C"/>
                    <path d="M11.25 4.75L4.75 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.75 4.75L11.25 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
          <span>Error</span>
            </div>
            <div className="custom-toast-bot">
              <span>While creating the community post. Please try later!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container-error',
        }
      );

      const notifyDel = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Community post deleted successfully</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

    // const handleDeleteClick =  (blogId) => () =>{

    //     const userToken = Cookies.get('token');


    //         const resourceData = {
    //             resourceId: blogId,
    //             resourceType: "Community",
    //             action: "remove"
    //         }
    //         axios
    //           .post(
    //             `${process.env.REACT_APP_API_URL}/add/remove/resource`,
    //             resourceData,
    //             {
    //               headers: {
    //                 Authorization: `Bearer ${userToken}`,
    //               },
    //             }
    //           )
    //           .then((res) => {

    //             if(res){
    //               console.log(res);
    //               get();
    //             }
    //           })
    //           .catch((err) => {

    //           })
    //           .finally(() => {

    //           });
    // }


// console.log(post);

  return ( 
    <>
        <div className='resources-card'>
            {post?.status === 1 ? 
            <div className='resource-card-tag published'>
                <div className='rct-name'>Published</div>
            </div> : 
            post?.status === 2 ?
            <div className='resource-card-tag drafts'>
                <div className='rct-name'>Draft</div>
            </div> : ""}
            <div className='resources-card-in'>
                {post?.image && 
                    <Link to={`/community/${post?.slug}`}>
                        <div className='rci-image'>
                            <img src={post?.image} alt="post image" />
                        </div> 
                    </Link>
                }
                <div className={`rci-content ${additionalClass}`}>
                    <div className='rcic-head'>
                        <div className='rcich-top'>
                            <div className='author-details'>
                                <div className='author-pic'>
                                    <img src={post?.avatar} alt="avatar" />
                                </div>
                                <div className='author-name'>
                                   {post?.authorName}
                                </div>
                            </div>
                            <div className='post-date'>
                               {post?.createdAt}
                            </div>
                        </div>
                        <div className='rcich-bottom'>
                            <div className='rcic-blog-heading'>
                                <Link to={`/community/${post?.slug}`}>{post?.title}</Link>
                            </div>
                            <div className='rcic-blog-para'>
                            <Link to={`/community/${post?.slug}`}>
                                <p dangerouslySetInnerHTML={{ __html: post?.content }}></p></Link>
                            </div>
                        </div>
                    </div>
                    <div className='rcic-foot'>
                        <div className='rcicf-left'>
                            <div className='category-tag'>
                                <div className='ctag'>{post?.categoryName}</div>
                            </div>
                            <div className='read-time'>{post?.readingTime}</div>
                        </div>
                        <div className='rcicf-right'>
                            {post?.status === 2 ? 
                            <button className='deleteicon-button' onClick={handleDeleteClick(post?.communityId)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <path d="M1.5 3.5H2.5H10.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.5 3.5V10.5C9.5 10.7652 9.39464 11.0196 9.20711 11.2071C9.01957 11.3946 8.76522 11.5 8.5 11.5H3.5C3.23478 11.5 2.98043 11.3946 2.79289 11.2071C2.60536 11.0196 2.5 10.7652 2.5 10.5V3.5M4 3.5V2.5C4 2.23478 4.10536 1.98043 4.29289 1.79289C4.48043 1.60536 4.73478 1.5 5 1.5H7C7.26522 1.5 7.51957 1.60536 7.70711 1.79289C7.89464 1.98043 8 2.23478 8 2.5V3.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5 6V9" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7 6V9" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button> : ""}
                            {post?.status === 2 ? 
                            <button className='primary-btn green' onClick={() => handleMoveToPublish(post?.communityId)}>Publish</button> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openCommunityPostPopup ? 'active' : ''}`}>
        <UpdateCommunityPostPopup  
            onclose={closeCommunityPostPop}
            data={data2?.result}
            noti={notify}
            notiE={notifyE}
            posttitle={postTitle}
            editorhtml={editorHtml}
            readTime={post?.readingTime}
            blogId= {post?.communityId}
            editData={editData}
            industry={industry} 
            setIndustry={setIndustry}
            bannerImage={post?.image} 
            setBannerImage={setBannerImage}
        />
        
        </div>
        </>
  )
}