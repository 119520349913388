import React, { useContext , useState } from 'react';
// import './profile.css';
import Patt from '../../assets/images/dash-empty.svg';
import NoSesh from '../../assets/images/no-sesh.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation} from "swiper";
import { ToastContainer, toast } from 'react-toastify';
import TrmCard from '../reusable/trm-card/TrmCard';
import MentorDashSessionGrid from '../reusable/session-cards/MentorDashSessionGrid';
import MentorDashSessionGridOngoing from '../reusable/session-cards/MentorDashSessionOngoing';
import { AuthContext } from '../../context/AuthContext';
import data from './data.json';
import { Link } from 'react-router-dom';
import MentorExpandedSessionPopup from './MentorExpandedSessionPopup';
import MentorCancelSessionPopup from './MentorCancelSessionPopup';
import MentorConfirmCancelSessionPopup from './MentorConfirmCancelSessionPopup';
import MentorReportSessionPopup from './MentorReportSessionPopup';
import MentorConfirmReportSessionPopup from './MentorConfirmReportSessionPopup';
import greenboxbg from '../../assets/images/greenbox-bg.svg';
import MentorSchedule from './MentorSchedule';


export default function MentorDashFilled({props,onclicking,gotoSchedule}) {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const upcomingcardPrevRef = React.useRef(null)
    const upcomingcardNextRef = React.useRef(null)
    const gridcardPrevRef = React.useRef(null)
    const gridcardNextRef = React.useRef(null)
    const activecardPrevRef = React.useRef(null)
    const activecardNextRef = React.useRef(null)
    const { state } = useContext(AuthContext);
    // const limitedItems = props.slice(0, 1);
    const [openExpandSessionPopup, setOpenExpandSessionPopup] = useState(false);
    const [openCancelSessionPopup, setOpenCancelSessionPopup] = useState(false);
    const [openWriteReviewPopup, setOpenWriteReviewPopup] = useState(false);
    const [openReportSessionPopup, setOpenReportSessionPopup] = useState(false);
    const [openReportConfirmPopup, setopenReportConfirmPopup] = useState(false);
    const [selectedItemData, setSelectedItemData] = useState(null);
    const [currentPopupOpen, setCurrentPopupOpen] = useState(false);
    const [expandPopupOpen, setexpandPopupOpen] = useState(false);
    const [newPopupOpen, setNewPopupOpen] = useState(false);
    const [newPopupOpen2, setNewPopupOpen2] = useState(false);
    
    const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Details Saved Successfully</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyC = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Session delete request has been sent to admin!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

    const ongoingSession = props?.gogingBookingsFetch
    const upcomingSession = props?.upcomingBookingsFetch
    const futureSession = props?.futureData

    // console.log('ongoingSession ', ongoingSession);

    //Open Expanded Session Popup
    const openExpandSessionPop = (session) => {
        setSelectedItemData(session);
        setOpenExpandSessionPopup(true);
        setexpandPopupOpen(true);
        document.body.classList.add('popup-open');
    };
    //Close Expanded Session Popup
    const closeExpandSessionPopup = () => {
        setOpenExpandSessionPopup(false);
        setexpandPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    //Open Cancel Session Popup
    const openCancelSessionPop = (session) => {
        setSelectedItemData(session);
        setOpenCancelSessionPopup(true);
        setCurrentPopupOpen(true);
        setOpenExpandSessionPopup(false);
        document.body.classList.add('popup-open');
    };
    //Close Cancel Session Popup
    const closeCancelSessionPopup = () => {
        setOpenCancelSessionPopup(false);
        setOpenExpandSessionPopup(false);
        setCurrentPopupOpen(false);
        setexpandPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    //Open Confirm Cancel Session Popup
    const openNewPopup = () => {
        setCurrentPopupOpen(false);
        setNewPopupOpen2(true);
        setOpenCancelSessionPopup(false);
        setOpenExpandSessionPopup(false);
    };
    //Close Confirm Cancel Session Popup
    const closeNewPopup = () => {
        setNewPopupOpen2(false);
        setOpenCancelSessionPopup(false);
        document.body.classList.remove('popup-open');
    };
    //Close Confirm Cancel & Open Cancel Session Popup
    const closeNewAndOpenCancelPopup = () => {
        setNewPopupOpen(false);
        setCurrentPopupOpen(true);
        setOpenCancelSessionPopup(true);
    };
    //Open Report Session Popup
    const openReportSessionPop = (ongoingSession) => {
        setSelectedItemData(ongoingSession);
        setOpenReportSessionPopup(true);
        setCurrentPopupOpen(true);
        document.body.classList.add('popup-open');
    };
    //Close Report Session Popup
    const closeReportSessionPop = () => {
        setOpenReportSessionPopup(false);
        setCurrentPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    //Open Confirm Report Session Popup
    const openConfirmReportPop = () => {
        setCurrentPopupOpen(false);
        setOpenReportSessionPopup(false)
        setNewPopupOpen(true);
    };
    //Close Confirm Report Session Popup
    const closeConfirmReportPop = () => {
        setNewPopupOpen(false);
        setOpenReportSessionPopup(false);
        document.body.classList.remove('popup-open');
    };

    return (
        <div className="dashboard-empty-state">
            <div className="dashboard-empty-state-banner">
                <div className="dashboard-empty-state-banner-wrapper">
                    <div className="dash-empty-banner-ptrn">
                        <img src={Patt} alt="" srcSet="" />
                    </div>
                    <div className="dash-empty-text-wrap">
                        <div className="dash-empty-name flex align">
                            <div className="dash-empty-name-text">Welcome, {props && props?.fullName}!</div>
                            <div className="dash-empty-name-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
                                    <path d="M20.3795 6.22633C26.1791 12.0244 27.7462 19.8599 23.8801 23.7245C20.0139 27.5906 12.18 26.0235 6.38112 20.2255C6.18414 20.0285 6.00451 19.8237 5.81699 19.622L9.29708 16.1419C13.8069 20.6517 19.5522 22.2196 22.1286 19.6417C24.7058 17.0645 23.1387 11.3192 18.6296 6.80936L18.6469 6.82591L18.6304 6.80857C13.4768 1.65422 6.42524 0.348694 2.88211 3.89182C1.16846 5.60547 0.599608 8.14089 1.04004 10.8827C0.36403 7.66579 0.921854 4.68522 2.88211 2.72575C6.74748 -1.13962 14.5814 0.427482 20.3795 6.22633Z" fill="#FDD888"/>
                                    <path d="M12.9236 27.3956C12.7251 27.3956 12.5273 27.335 12.3571 27.2136L8.35781 24.3449L4.3585 27.2136C4.01735 27.4579 3.56038 27.4579 3.22158 27.2097C2.88279 26.9639 2.74019 26.529 2.86704 26.1311L4.35929 21.3171L0.395437 18.524C0.0582213 18.2759 -0.0812346 17.8394 0.0471908 17.4407C0.177192 17.0436 0.546711 16.7734 0.965078 16.7702L5.80821 16.7608L7.43678 12.0673C7.56914 11.6702 7.94023 11.4023 8.3586 11.4023C8.77697 11.4023 9.14806 11.6702 9.28043 12.0673L10.7506 16.7608L15.7513 16.7702C16.1705 16.7734 16.5408 17.0436 16.6692 17.4407C16.7984 17.8394 16.6582 18.2759 16.321 18.524L12.3571 21.3171L13.8486 26.1311C13.9762 26.529 13.832 26.9647 13.4948 27.2097C13.3239 27.3342 13.1237 27.3956 12.9236 27.3956Z" fill="#FFAC33"/>
                                </svg>
                            </div>
                        </div>
                        <div className="dash-empty-body">
                            <p>Start your mentor journey with completing your profile!</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* Upcoming Sessions Starts*/}
            {upcomingSession.length > 0 ?
            <div className="dash-empty-upcoming-sessions less-space">
                    <div className="dash-empty-sessions-content">
                        <div className="dash-empty-sess-head">upcoming sessions</div>
                        <div className="dash-empty-sess-body flex align flex-jsb">
                            <p>Check your sessions for today, tomorrow and whenever here.</p>
                            <div className="dash-empty-trm-slider-btns flex align">
                                <div className="det-slider-prev" ref={upcomingcardPrevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                                <div className="det-slider-next" ref={upcomingcardNextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                            </div>
                            </div>
                    </div>
                    <div className='dash-sessions-data'>
                    <Swiper modules={[Navigation]}  breakpoints={{
                            0:{
                                slidesPerView: 1,
                            },
                            600:{
                                slidesPerView: 2,
                            },                
                            1200: {
                                slidesPerView: 3,
                            }
                        }}  slidesPerView={3} spaceBetween={13}  navigation={{ prevEl: upcomingcardPrevRef.current, nextEl: upcomingcardNextRef.current,}}  onSwiper={(swiper) => {
                            // Delay execution for the refs to be defined
                            setTimeout(() => {
                            // Override prevEl & nextEl now that refs are defined
                            swiper.params.navigation.prevEl = upcomingcardPrevRef.current
                            swiper.params.navigation.nextEl = upcomingcardNextRef.current

                            // Re-init navigation
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }} className="mySwiper dash-top-rated-mentors-slider">
                                {upcomingSession.map((session,index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            {index === 0 && <div className='next-up nextupmentor'>Next Up</div>}
                                            <MentorDashSessionGrid 
                                                props={session}
                                                openExpandSessionPopup={() => openExpandSessionPop(session)}
                                                openCancelSessionPopup={() => openCancelSessionPop(session)}
                                            />
                                        </SwiperSlide>
                                    );
                                })} 
                        </Swiper>
                    </div>
            </div> : 
            <div className="dash-empty-upcoming-sessions">
            <div className="dash-empty-sessions-content">
                <div className="dash-empty-sess-head">upcoming sessions</div>
                <div className="dash-empty-sess-body"><p>Check your sessions for today, tomorrow and whenever here.</p></div>
            </div>
            <div className="dash-emp-no-sess">
                <div className="dash-emp-no-sess-wrap">
                    <div className="dash-emp-no-sess-img">
                        <img src={NoSesh} alt="" />
                    </div>
                    <div className="dash-emp-no-sess-head">You don’t have upcoming sessions</div>
                    <div className="dash-emp-no-sess-body">
                        <p>As a mentor, you haven't started your journey yet! Start taking sessions and sharing your expertise with mentees today.</p>
                    </div>
                    <div className="dash-emp-no-sess-btn">
                        <button className="primary-btn black" onClick={gotoSchedule}>Create Availability</button>
                    </div>
                </div>
            </div>
            </div>}
            {/* Upcoming Sessions Ends*/}

            {/* Create Schedule Starts*/}

            {props?.futureData?.length > 0 ? "" : 
            <>
                <div className='greenCTABox'>
                <div className='gctab-image'>
                    <img src={greenboxbg}/>
                </div>
                <div className='gctab-in'>
                    <div className='gctabi-heading'>
                        <h5>Create new schedule</h5>
                        <p>Create new schedules or edit older ones.</p>
                    </div>
                    <div className='gctabi-button'>
                        <button className="primary-btn" onClick={gotoSchedule}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><path d="M6 3V10" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2.5 6.5H9.5" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            create new
                        </button>
                    </div>
                </div>
            </div> 
             
            <div className='gb-border'></div> 
            </>
             }
            {/* Create Schedule Ends*/}

            {/* Ongoing Sessions Starts*/}
            {ongoingSession?.length > 0 ? 
            <div className="dash-empty-upcoming-sessions less-space ongoing-empty">
                <div className='dash-ongoing-wrap'>
                    <div className="dash-empty-sessions-content">
                        <div className="dash-empty-sess-head">active session(s)</div>
                        <div className="dash-empty-sess-body flex align flex-jsb">
                            <p>Check the session you are taking at the moment.</p>
                            <div className="dash-empty-trm-slider-btns flex align">
                                <div className="det-slider-prev" ref={activecardPrevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                                <div className="det-slider-next" ref={activecardNextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Swiper modules={[Navigation]}  breakpoints={{
                            0:{
                                slidesPerView: 1,
                            },
                            600:{
                                slidesPerView: 1,
                            },                
                            1200: {
                                slidesPerView: 1,
                            }
                        }}  slidesPerView={1} spaceBetween={10}  navigation={{ prevEl: activecardPrevRef.current, nextEl: activecardNextRef.current,}}  onSwiper={(swiper) => {
                            // Delay execution for the refs to be defined
                            setTimeout(() => {
                            // Override prevEl & nextEl now that refs are defined
                            swiper.params.navigation.prevEl = activecardPrevRef.current
                            swiper.params.navigation.nextEl = activecardNextRef.current

                            // Re-init navigation
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }} className="mySwiper dash-sessions-data">
                                {ongoingSession.map((session,index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <MentorDashSessionGridOngoing 
                                                props={session} 
                                                openReportSessionPopup={() => openReportSessionPop(ongoingSession[index])}
                                            />
                                        </SwiperSlide>
                                    );
                                })} 
                        </Swiper>
                    {/* <div className='dash-sessions-data'>
                        <MentorDashSessionGridOngoing 
                            props={ongoingSession[0]} 
                            openReportSessionPopup={() => openReportSessionPop(ongoingSession[0])}
                        />
                    </div> */}
                    
                </div>
            </div> : ""
            }
            {/* Ongoing Sessions Ends*/}

            {/* Your Schedules Starts*/}
            {props?.futureData?.length > 0 ?
            <div className="dash-empty-upcoming-sessions less-space">
                    <div className="dash-empty-sessions-content">
                        <div className="dash-empty-sess-head">Your Schedules</div>
                        <div className="dash-empty-sess-body flex align flex-jsb">
                            <p>Check your schedules for today, tomorrow and whenever here.</p>
                            <div className="dash-empty-trm-slider-btns flex align">
                                <div className="det-slider-prev" ref={gridcardPrevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                                <div className="det-slider-next" ref={gridcardNextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                            </div>
                            </div>
                    </div>
                    <div className='dash-sessions-data schedule-cards'>
                    <Swiper modules={[Navigation]}  breakpoints={{
                            0:{
                                slidesPerView: 1,
                            },
                            600:{
                                slidesPerView: 2,
                            },                
                            1200: {
                                slidesPerView: 3,
                            }
                        }}  slidesPerView={3} spaceBetween={13}  navigation={{ prevEl: gridcardPrevRef.current, nextEl: gridcardNextRef.current,}}  onSwiper={(swiper) => {
                            // Delay execution for the refs to be defined
                            setTimeout(() => {
                            // Override prevEl & nextEl now that refs are defined
                            swiper.params.navigation.prevEl = gridcardPrevRef.current
                            swiper.params.navigation.nextEl = gridcardNextRef.current

                            // Re-init navigation
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }} className="mySwiper dash-top-rated-mentors-slider">
                                {props?.futureData.map((session,index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <MentorSchedule 
                                                props={session}
                                                buttonClick={onclicking}
                                                openExpandSessionPopup={() => openExpandSessionPop(session)}
                                                openCancelSessionPopup={() => openCancelSessionPop(session)}
                                            />
                                        </SwiperSlide>
                                    );
                                })} 
                        </Swiper>
                    </div>
            </div> : 
            // <div className="dash-empty-upcoming-sessions">
            //     <div className="dash-empty-sessions-content">
            //         <div className="dash-empty-sess-head">Manage availability</div>
            //         <div className="dash-empty-sess-body"><p>Manage your availability here, create new schedules or edit old ones.</p></div>
            //     </div>
            //     <div className="dash-emp-no-sess">
            //         <div className="dash-emp-no-sess-wrap">
            //             <div className="dash-emp-no-sess-img">
            //                 <img src={NoSesh} alt="" />
            //             </div>
            //             <div className="dash-emp-no-sess-head">You haven’t created a schedule yet</div>
            //             <div className="dash-emp-no-sess-body">
            //                 <p>As a mentor, you haven't started your journey yet! Start taking sessions and sharing your expertise with mentees today.</p>
            //             </div>
            //             <div className="dash-emp-no-sess-btn">
            //                 <button  className="primary-btn black" onClick={gotoSchedule}>Create Schedule</button>
            //             </div>
            //         </div>
            //     </div>
            // </div>
            ''
            }
            {/* Your Schedules Ends*/}

            <div className="mentee-profile-pop-ups">
                
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openExpandSessionPopup ? 'active' : ''}`}>
                    {expandPopupOpen && (
                        <MentorExpandedSessionPopup
                            data={selectedItemData} 
                            noti={notify} 
                            onclose={closeExpandSessionPopup}
                            onCancel={openCancelSessionPop} 
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openCancelSessionPopup ? 'active' : ''}`}>
                    {currentPopupOpen && (
                        <MentorCancelSessionPopup
                            data={selectedItemData}
                            noti={notify}
                            onclose={closeCancelSessionPopup}
                            onOpenNew={openNewPopup} // Pass the function to open the new popup
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ newPopupOpen2 ? 'active' : ''}`}>
                    {newPopupOpen2 && <MentorConfirmCancelSessionPopup data={selectedItemData} onclose={closeNewPopup} dontCancel={closeNewAndOpenCancelPopup} noti={notifyC} />}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openReportSessionPopup ? 'active' : ''}`}>
                    {currentPopupOpen && (
                        <MentorReportSessionPopup
                            data={selectedItemData}
                            noti={notify}
                            onclose={closeReportSessionPop}
                            onOpenNew={openConfirmReportPop} // Pass the function to open the new popup
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ newPopupOpen ? 'active' : ''}`}>
                    {newPopupOpen && <MentorConfirmReportSessionPopup data={selectedItemData} onclose={closeConfirmReportPop} />}
                </div>
                {/* <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openExpandSessionPopup ? 'active' : ''}`}>
                    {expandPopupOpen && (
                        <CompletedExpandedSessionPopup 
                            data={selectedItemData} 
                            noti={notify} 
                            onclose={closeExpandSessionPopup}
                            onCancel={openCancelSessionPop} 
                        />
                    )}
                </div> */}
            </div>
            
        </div>
        )
    }
