import React, {useRef} from 'react';
import './about.css';
import Cards from '../reusable/cards/Cards';
import { useInView } from "framer-motion";

export default function AboutEmpowering({props}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const data = props;
  return (
    <section className="about-empowering">
        <div className="container">
            <div className="about-empowering-wrapper">
                <div className="about-empowering-top">
                <div ref={ref} style={{
                 transform: isInView ? "translateY(0)" : "translateY(-100px)",
                 opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="insage-top-categories-label flex flex-jsc">
              <span className="label col-white">{data?.empowerTag}</span>
              </div>
              <div className="insage-top-categories-heading">
                <h2  ref={ref} style={{
                 transform: isInView ? "translateY(0)" : "translateY(100%)",
                 opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{data?.empowerHead}</h2>
                </div>
              <div className="insage-top-categories-body">
                <p  ref={ref} style={{
                 transform: isInView ? "translateY(0)" : "translateY(100%)",
                 opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{data?.empowerBody}</p>
                </div>
                </div>
                <div className="about-empowering-bottom">
                 <Cards posts={props}/>
                </div>
            </div>
        </div>
    </section>
  )
}
