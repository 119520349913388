import React, { useContext, useState } from 'react';
import axios from 'axios';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import BannerExplore from '../../components/explore-our-mentors/explore-our-mentors-banner';
import BamSecond from '../../components/become-a-mentor/bam-second';
import BamGetAhead from '../../components/become-a-mentor/bam-get-ahead';
import TopCatog from '../../components/reusable/top-categories/TopCatog';
import Testimonials from '../../components/testimonials/Testimonials';
import AboutEmpowering from '../../components/about/about-empowering';
import ExploreMentors from '../../components/reusable/explore-mentors/ExploreMentors';
import FAQ from '../../components/reusable/faq/FAQ';
import PopularMentors from '../../components/explore-our-mentors/PopularMentors';
import Cookies from 'js-cookie';
import AllMentors from '../../components/explore-our-mentors/AllMentors';
import { AuthContext } from '../../context/AuthContext';
import NewMentors from '../../components/explore-our-mentors/NewMentors';
import { useLocation } from 'react-router-dom';
import actionTypes from '../../context/actionTypes';
import ScrollToTop from '../../components/go-to-top/Go-to-top';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function ExploreAllMentors() {
  const [bamData, setBamData] = React.useState([]);
  
  const {dispatch } = useContext(AuthContext);

  const location = useLocation();
  const { filterCat } = location.state || {};



  React.useEffect(() => {
    axios
    .get("./explore.json")
    .then((res) => setBamData(res.data))
    .catch(err=>console.log(err))
  },[]);
  // const { state } = useContext(AuthContext);
    const [popularMentors, setPopularMentors] = React.useState([]);
    const [newMentorData, setNewMentorData] = React.useState([]);
    const [allMentorData, setAllMentorData] = React.useState([]);
    const [loadMoreDisplay, setLoadMoreDisplay] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    let url = process.env.REACT_APP_API_URL+'/explore-mentors';

    React.useEffect(() => {
      // Function to fetch data from the server
      
      const fetchData = async () => {
        try {
          setLoadingState(true);
          const response = await axios.get(url);
          const { result } = response.data;
    
          // Update state with fetched data
          setPopularMentors(result.popularMentor);
          setNewMentorData(result.newMentor);
          setLoadMoreDisplay(result.hasMorePages);
          setLoadingState(false);

          
    
          // If filterCat is provided, apply filtering
          // if (filterCat) {
          //   handleFilter(filterCat);
          // } else {
          //   // If no filterCat is provided, apply default filter
          //   handleFilter();
          // }
        } catch (error) {
          // Handle errors during data fetching
          console.error('Error fetching data:', error);
        }
      };
    
      // Call the fetchData function when the component mounts
      fetchData();
    }, [url]); // Depend on url and filterCat for re-executing the effect when they change
    
  // Fetch the user role from the cookie
const userRole = Cookies.get('userRole');

const handleFilter = async (industry,country, levelOfExp, sortValue=-1, page=1, search='' ) => {
  setLoadingState(true);
  const filters = {
    industry: industry || undefined,
    country: country || undefined,
    levelOfExp: levelOfExp || undefined,
  };

  const filterObject = Object.keys(filters).reduce((acc, key) => {
    if (filters[key] !== undefined) {
      acc[key] = filters[key];
    }
    return acc;
  }, {});

  // Create your final filter object including sortValue
  const finalFilter = {
    filters: filterObject,
    sortValue,
    ...(search && { search }),
    sortBy: 'createdAt'
  };

  // console.log(finalFilter);


  let url = process.env.REACT_APP_API_URL+`/search-mentors/?page=${page}`;
  try {
    // setLoadingState(true);
    const response = await axios.post(url, finalFilter);
    if(page==1){
      setAllMentorData(response.data);
      setLoadMoreDisplay(response.data.hasMorePages)
      setLoadingState(false);
    }else{
      setLoadMoreDisplay(response.data.hasMorePages);

      let mergedData = [...allMentorData.result.allMentor, ...response.data?.result?.allMentor]
        console.log('mergedData ', mergedData);
        setAllMentorData({
          ...allMentorData,
          result: {
            allMentor: mergedData
          }
        });
        setLoadingState(false);

    }

    dispatch({ type: actionTypes.REMOVE_ERROR });
    
    setLoadingState(false);
  } catch (error) {
    setLoadingState(false)
    console.error("Error fetching data:", error);
  }
};


const scrollToSecondComponent = () => {
  // console.log('i am scroll');
  const targetElement = document.getElementById("targetElementId");
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};


  return (
    <>
        
        <Header/>
        <BannerExplore props={bamData.bannerData} propsrole={userRole} scrollToSecondComponent={scrollToSecondComponent}/>
        <PopularMentors props={popularMentors} scrollToSecondComponent={scrollToSecondComponent} loadingState={loadingState}/>
        <NewMentors props={newMentorData} scrollToSecondComponent={scrollToSecondComponent} loadingState={loadingState}/>
        {/* <AllMentors props={allMentorData} setAllMentorData={setAllMentorData} scrollToSecondComponent={scrollToSecondComponent} loadMoreDisplay={loadMoreDisplay} setLoadMoreDisplay={setLoadMoreDisplay} handleFilter={handleFilter} filterCat={filterCat} loadingState={loadingState}/> */}
        <Footer/>
    </>
    
  )
}
