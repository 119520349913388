import React,{useState, useContext, useEffect} from 'react';
import '../login/login.css';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../ErrorMessage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Googlesign from '../login-mentor/Googlesign';
import LinkedInPage from '../login/LinkedInPage';
import Loader from '../loading/Loader';

export default function SignUp({ onClose, onLoginClick }) {

    const { signUpMentor, state } = useContext(AuthContext);
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [signUpFormData, setSignUpFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({
        email: '',
        password: '',
      });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setErrors({
            email: '',
            password: '',
          })
        setSignUpFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
      
    const handleClose = () => {
        onClose(); // Call the onClose function passed from the parent component
    };

    useEffect(() => {
      setErrors({
        email: '',
        password: '',
      })
    }, []);


      
      const [isValidForm, setIsValidForm] = useState(false);
      const validateForm = async (e) => {
        e.preventDefault();
        const newErrors = {
          email: '',
          password: '',
        };
      
        // Check email
        if (!signUpFormData.email) {
          newErrors.email = 'Email is required';
          setErrors(newErrors);
        } else if (!/\S+@\S+\.\S+/.test(signUpFormData.email)) {
          newErrors.email = 'Invalid email address';
          setErrors(newErrors);
        }
      
        // Check password
        if (!signUpFormData.password) {
          newErrors.password = 'Password is required';
          setErrors(newErrors);
        } else if (signUpFormData.password.length < 8) {
          newErrors.password = 'Password must be at least 8 characters long';
          setErrors(newErrors);
        } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(signUpFormData.password)) {
          newErrors.password =
            'Password must contain at least one uppercase letter, one lowercase letter, and one number';
            setErrors(newErrors);
        }
        try {
          let res = await signUpMentor(signUpFormData);
          if(res){
            onClose();
            navigate('/mentor/onboarding');
        }
        } catch (error) {
          console.log(error);
        }

       
      };

      // const handleSignUp = async (e) => {
       
      
       
                 
      // };
        
    return (
    <div className="insage-login">
        <div className="insage-login-wrapper">
            <div className="login-head"><span>mentor sign up</span></div>
            {state.loading && <Loader/>}
            {state.error && <ErrorMessage errorData={state.error} />}
            <div className="login-form-wrapper">
                <form>
                    <div className="login-form-input flex flex-c">
                        <label htmlFor="email">Email ID</label>
                        <input type="email" placeholder="Type here" name='email' value={signUpFormData.email} onChange={handleInputChange} />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className="login-form-input flex flex-c">
                        <label htmlFor="password">Create Password</label>
                        <div className="login-password-wrapper">
                        <input type={showPassword ? 'text' : 'password'} placeholder="Password" name='password' value={signUpFormData.password} onChange={handleInputChange} />
                        {errors.password && <span className="error-message">{errors.password}</span>}
                            <svg className={`password-eye ${showPassword ? 'show' : ''}`} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <svg className={`password-eye-slash ${showPassword ? 'show' : ''}`} onClick={handleTogglePassword} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                            <path d="M1 7.5C1 7.5 4.27273 1.5 10 1.5C15.7273 1.5 19 7.5 19 7.5C19 7.5 15.7273 13.5 10 13.5C4.27273 13.5 1 7.5 1 7.5Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.0014 9.75C11.357 9.75 12.456 8.74264 12.456 7.5C12.456 6.25736 11.357 5.25 10.0014 5.25C8.64581 5.25 7.54688 6.25736 7.54688 7.5C7.54688 8.74264 8.64581 9.75 10.0014 9.75Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.75 4.75L15.25 12" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </div>
                        <div className="forgot-password-btn">
                            <div className='flex align-s'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="#777777" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7 9.4V7" stroke="#777777" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7 4.60156H7.006" stroke="#777777" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                                <span>Your password must have atleast 8 characters, an upper case character, a lower case character and a number</span>
                            </div>
                        </div>
                    </div>
                    <button className='primary-btn black login-button' type="button" onClick={validateForm}>Sign Up</button>
                </form>
                <div className="login-with-text">
                    <span>or login with</span>
                </div>
                <div className="login-socials-wrapper flex flex-jsc align">
                    {/* <GoogleOAuthProvider clientId="317676183347-io5gtaipe1mf5a47alo58kuh2aumkhgf.apps.googleusercontent.com">
                        <Googlesign />
                    </GoogleOAuthProvider> */}
                    <Googlesign popClose={onClose} />
                </div>
                <div className="login-already-have flex align-e flex-jsc">
                    <div className="login-already-have-text"><span>Already have an account?</span></div>
                    <div className="login-already-have-btn"><span onClick={onLoginClick}>Login</span></div>
                </div>
            </div>
        </div>
        <div className="login-close-button" onClick={handleClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
    </div>
    )
}
