import React,{useContext, useState, useEffect} from 'react'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';
import axios from 'axios';
import Loader from '../reusable/loading/Loader';

const ObStep5 = ({nextStep,prevStep, handleOnboardingInputChange, values}) => {

  const { onboardingForm, state } = useContext(AuthContext);
  const [errorBio, setErrorBio] = useState("");
  const [errorIndus, setErrorIndus] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(values.bio.length === 0){
      setErrorBio("Please enter your bio.");
      return;
    }
    if(values.industry.length === 0){
      setErrorIndus("Please select an industry.");
      return;
    }
    if(values.country.length === 0){
      setErrorCountry("Please select a country.");
      return;
    }

    try {
        let res = await onboardingForm(values);
        res && navigate('/mentee/dashboard'); 
    } catch (error) {
        console.log(error);
    }
  };
  const handleBack = () =>{
    prevStep();
  }
  const [profilePhoto, setProfilePhoto] = useState(null);

  const handleNameChange = () => {
    const name = values.firstName;
    const initialLetter = name.charAt(0).toUpperCase();
    setProfilePhoto(initialLetter);
    // ... other form data handling
  };
  const handlePhotoUpload = e => {
    const file = e.target.files[0];
    
    // Check if the file is an image
    if (file.type.startsWith('image/')) {
        console.log('file ', file);
        const reader = new FileReader();
      
        reader.onload = () => {
            setProfilePhoto(reader.result);
        };
      
        if (file) {
            reader.readAsDataURL(file);
            handleOnboardingInputChange({
                target: {
                    name: "avatar",
                    value: file,
                },
            });
        }
    } else {
        // Notify the user that only image files are allowed
        alert('Please upload an image file (e.g., JPG, PNG)');
        // Clear the file input field
        e.target.value = '';
    }
};

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      return response.data.map(country => ({
        value: country.name.common,
        label: country.name.common,
      }));
    } catch (error) {
      console.error('Error fetching countries:', error);
      return [];
    }
  };

  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      const countries = await fetchCountries();
      const sortedCountries = countries.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });
      setCountryOptions(sortedCountries);
    };
  
    getCountries();
  }, []);
  // const [inputValue, setInputValue] = useState('');
  // const maxCharacterLimit = 100;
  // const handleInputChange = e => {
  //   const value = e.target.value;
  //   setInputValue(value);
  // };
  // const charactersRemaining = maxCharacterLimit - inputValue.length;
  return (
    <>
    <div className='stepLine'>
        <div className='slFilled mWidth100'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 5 of 5
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Let's get to know you!</h5>
        </div>
        </div>
        {state.loading && <Loader/>}
        {state.error && <ErrorMessage errorData={state.error} />}
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label className='flex bio-label align' htmlFor="bio">Enter a bio
                    <span className='flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                            <path d="M5.5 11C8.26142 11 10.5 8.76142 10.5 6C10.5 3.23858 8.26142 1 5.5 1C2.73858 1 0.5 3.23858 0.5 6C0.5 8.76142 2.73858 11 5.5 11Z" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.5 8V6" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.5 4H5.505" stroke="#555555" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                    </span>
                    </label>
                    {errorBio && <p className="error-name-onb">{errorBio}</p>} 
                        <textarea
                        name='bio'
                        value={values.bio}
                        onChange={handleOnboardingInputChange}
                        // maxLength={maxCharacterLimit}
                        >
                            {/* {charactersRemaining} characters remaining */}
                        </textarea>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="industry/expertise">In what industry are you seeking mentorship?</label>
                    <div className="select-wrapper">
                    {errorIndus && <p className="error-name-onb">{errorIndus}</p>} 
                    <select
                            id="industry-expertise"
                            name="industry"
                            value={values.industry}
                            onChange={handleOnboardingInputChange}
                            >
                            <option value="" disabled>Eg. Design, Development, etc.</option>
                            <option value="Art & Design">Art & Design</option>
                            <option value="Coding & Dev">Coding & Dev</option>
                            <option value="Marketing">Marketing</option>
                            <option value="IT & Software">IT & Software</option>
                            <option value="Personal Development">Personal Development</option>
                            <option value="Business & Sales">Business & Sales</option>
                            <option value="Photography">Photography</option>
                            <option value="Management">Management</option>
                            <option value="Other">Other</option>
                            {/* Add more options as needed */}
                            </select>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="country">Which country do you live in? </label>
                    <div className="select-wrapper">
                    {errorCountry && <p className="error-name-onb">{errorCountry}</p>} 
                    <select
                            id="country"
                            name="country"
                            value={values.country}
                            onChange={handleOnboardingInputChange}
                            >
                            <option value="" disabled>Eg. India, Dubai, etc</option>
                            {countryOptions.map((country) => (
                                <option key={country.value} value={country.value}>
                                  {country.label}
                                </option>
                              ))}
                            </select>
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="timezone">Change profile picture</label>
                    <div className="login-form-profile-wrapper flex align">
                        <div className="login-profile-image">
                            {profilePhoto ? (
                            <img src={profilePhoto} alt="Profile" />
                            ) : (
                            <div className="initial-letter">{profilePhoto}</div>
                            )}
                        </div>
                        <div className="login-profile-btn">
                            <span>Make sure it doesn’t exceed 2mb.</span>
                            
                            {profilePhoto ? (
                          <label>
                            <input type="file" accept="image/*" onChange={handlePhotoUpload} /> 
                            Update file
                          </label>
                            ) : (
                                  <label>
                                  <input type="file" accept="image/*" onChange={handlePhotoUpload}/> 
                                    Upload a file
                                  </label>
                            )}
                        </div>
                    </div>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</>
  )
}

export default ObStep5
