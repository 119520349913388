import React, { useRef } from "react";
import "./TopCatog.css";
import { useInView } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

export default function TopCatog() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleClick = (data) => {
    // Navigate to the second page with the button value
    navigate("/explore-all-mentors", { state: { filterCat: data } });
  };
  return (
    <section className="insage-top-categories pad-vot">
      <div className="container">
        <div className="insage-top-categories-top-wrapper">
          <div
            style={{
              transform: isInView ? "translateY(0px)" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: ".9s cubic-bezier(.51,.01,0,1.06)",
            }}
            className="insage-top-categories-label flex flex-jsc"
          >
            <span className="label col-white">popular topics</span>
          </div>
          <div className="insage-top-categories-heading">
            <h2
              style={{
                transform: isInView ? "translateY(0%)" : "translateY(100%)",
                opacity: isInView ? 1 : 0,
                transition: ".9s cubic-bezier(.51,.01,0,1.06)",
              }}
            >
              Explore Top Categories{" "}
            </h2>
          </div>
          <div className="insage-top-categories-body">
            <p
              ref={ref}
              style={{
                transform: isInView ? "translateY(0%)" : "translateY(100%)",
                opacity: isInView ? 1 : 0,
                transition: ".9s cubic-bezier(.51,.01,0,1.06)",
              }}
            >
              Explore a diverse range of expertise spanning various industries,
              and discover your perfect mentor match.
            </p>
          </div>
        </div>
        <div className="insage-top-categories-middle-wrapper">
          <div className="mentored-in-cards-wrapper flex">
            <div className="mentored-in-card">
              <div className="mentored-in-card-wrapper">
                <div className="mentoredin-top">
                  <div className="mentored-in-label">
                    <span className="label">get mentored in</span>
                  </div>
                  <div className="mentored-in-title">Coding & Dev</div>
                </div>
                <div className="mentoredin-content">
                  <div className="mentored-in-body">
                    <p>
                      Meet well-versed developers from leading companies and
                      learn the fine points of coding and development.{" "}
                    </p>
                  </div>
                  <div className="mentored-in-btn">
                    <div
                      onClick={() => handleClick("Coding & Dev")}
                      className="primary-btn flex align"
                    >
                      Explore Now
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.42265H0.42265V5.57735H1V4.42265ZM11.4082 5.40825C11.6337 5.18278 11.6337 4.81722 11.4082 4.59175L7.73401 0.917517C7.50854 0.692048 7.14299 0.692048 6.91752 0.917517C6.69205 1.14299 6.69205 1.50854 6.91752 1.73401L10.1835 5L6.91752 8.26599C6.69205 8.49146 6.69205 8.85701 6.91752 9.08248C7.14299 9.30795 7.50854 9.30795 7.73401 9.08248L11.4082 5.40825ZM1 5.57735H11V4.42265H1V5.57735Z"
                          fill="#222222"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mentored-in-card">
              <div className="mentored-in-card-wrapper">
                <div className="mentoredin-top">
                  <div className="mentored-in-label">
                    <span className="label">get mentored in</span>
                  </div>
                  <div className="mentored-in-title">Art & Design</div>
                </div>

                <div className="mentoredin-content">
                  <div className="mentored-in-body">
                    <p>
                      Connect with industry experts in art and design. Get
                      mentored and gain invaluable insights.{" "}
                    </p>
                  </div>
                  <div className="mentored-in-btn">
                    <div
                      onClick={() => handleClick("Art & Design")}
                      className="primary-btn flex align"
                    >
                      Explore Now
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.42265H0.42265V5.57735H1V4.42265ZM11.4082 5.40825C11.6337 5.18278 11.6337 4.81722 11.4082 4.59175L7.73401 0.917517C7.50854 0.692048 7.14299 0.692048 6.91752 0.917517C6.69205 1.14299 6.69205 1.50854 6.91752 1.73401L10.1835 5L6.91752 8.26599C6.69205 8.49146 6.69205 8.85701 6.91752 9.08248C7.14299 9.30795 7.50854 9.30795 7.73401 9.08248L11.4082 5.40825ZM1 5.57735H11V4.42265H1V5.57735Z"
                          fill="#222222"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mentored-in-card">
              <div className="mentored-in-card-wrapper">
                <div className="mentoredin-top">
                  <div className="mentored-in-label">
                    <span className="label">get mentored in</span>
                  </div>
                  <div className="mentored-in-title">Business & Sales</div>
                </div>

                <div className="mentoredin-content">
                  <div className="mentored-in-body">
                    <p>
                      Receive mentorship from experienced individuals in the
                      fields of education and outreach.{" "}
                    </p>
                  </div>
                  <div className="mentored-in-btn">
                    <div
                      onClick={() => handleClick("Business & Sales")}
                      className="primary-btn flex align"
                    >
                      Explore Now
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.42265H0.42265V5.57735H1V4.42265ZM11.4082 5.40825C11.6337 5.18278 11.6337 4.81722 11.4082 4.59175L7.73401 0.917517C7.50854 0.692048 7.14299 0.692048 6.91752 0.917517C6.69205 1.14299 6.69205 1.50854 6.91752 1.73401L10.1835 5L6.91752 8.26599C6.69205 8.49146 6.69205 8.85701 6.91752 9.08248C7.14299 9.30795 7.50854 9.30795 7.73401 9.08248L11.4082 5.40825ZM1 5.57735H11V4.42265H1V5.57735Z"
                          fill="#222222"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="insage-top-categories-bottom-wrapper">
          <div className="insage-top-categories-bottom-content">
            <p
              style={{
                transform: isInView ? "translateY(0%)" : "translateY(100%)",
                opacity: isInView ? 1 : 0,
                transition: ".9s cubic-bezier(.51,.01,0,1.06)",
              }}
            >
              Elevate your professional path with tailored guidance from
              <br /> industry leaders, curated exclusively for your needs.
            </p>
          </div>
          <div className="insage-top-categories-bottom-btn">
            <div
              style={{
                opacity: isInView ? 1 : 0,
                transition: ".9s cubic-bezier(.51,.01,0,1.06)",
              }}
              className="primary-btn primary-btn-2 black"
            >
              <Link to="/explore-our-mentors">Find Your Mentor</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
