import React, {useRef} from 'react';
import '../home/testemonials-with-arrow.css';
import { useInView } from "framer-motion";

export default function AboutInspires({props}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
    const data = props;
  return (
    <section className="about-inspires pad-vot">
        <div className="custom-container-fluid">
        <div className="insage-knowledge-at-work-wrapper flex flex-jsb align">
            <div className="insage-knowledge-at-work-left flex align">
                 <div ref={ref} style={{
          transform: isInView ? "translateX(0)" : "translateX(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-label flex flex-jsc">
                   <div className="label col-white">{data?.inspireTag}</div>
                 </div>
                 <div className="insage-knowledge-at-work-content">
                    <div className="insage-knowledge-at-work-content-wrapper">
                        <div className="insage-knowledge-at-work-content-heading">
                            <h3 ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} dangerouslySetInnerHTML={{ __html: data?.inspireHeading }}></h3>
                            </div>
                        <div className="insage-knowledge-at-work-content-body">
                            <div className="insage-knowledge-at-work-content-body-label">
                                <span ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="label">{data?.inspireLabel}</span>
                            </div>
                            <div className="insage-knowledge-at-work-content-body-text">
                                <p ref={ref} style={{
          transform: isInView ? "translateY(0)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className='col-p'>{data?.inspireBody}</p>
                            </div>
                        </div>
                    </div>
                 </div>

            </div>
            <div className="insage-knowledge-at-work-right">
                <div ref={ref} style={{
           clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }} className="insage-get-ahead-image">
                    <img className='img' src={data?.inspireImg} alt="" />
                </div>
            </div>
        </div> 
        </div>
    </section>
  )
}
