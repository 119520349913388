import React, { useContext, useState, useRef, useEffect } from 'react'
import Girl from '../../assets/images/messageIb.png';
import NoSesh from '../../assets/images/Frame 4533/Group 3349.svg';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import useFetchRecipient from '../../hooks/useFetchRecipient';
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';
import Loader from '../reusable/loading/Loader';

function ChatBox() {
    const [textMessage, setTextMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    // const [messagesToShow, setMessagesToShow] = useState(5);
    // const [displayedMessages, setDisplayedMessages] = useState([]);
    const emojiPickerRef = useRef(null);
    

    const {state} = useContext(AuthContext);
    const {currentChat, messages, isMessagesLoading, messagesErr, sendTextMessage, onlineUsers, scrollContainerRef, scrollToBottom } = useContext(ChatContext);

    const {recipientUser} = useFetchRecipient(currentChat, state.profile);

    const handleInputChange = (e) => {
        setTextMessage(e.target.value);
    };

    const handleChatSendMessage = () => {
        if(textMessage.trim().length > 0){
            sendTextMessage(textMessage, state.profile, currentChat?._id, setTextMessage  )
        }
        
    }

    const handleEmojiClick = (emoji) => {
        setTextMessage(textMessage + emoji.emoji);
        setShowEmojiPicker(false);
    };

    const handleDocumentClick = (e) => {
        if (emojiPickerRef.current && !emojiPickerRef.current.contains(e.target)) {
            setShowEmojiPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    // const handleScroll = () => {
    //     const container = scrollContainerRef.current;
    //     if (container.scrollTop === 0) {
    //       // When the user scrolls to the top, load more messages
    //       setMessagesToShow(messagesToShow + 5); // Load 5 more messages
    //     }
    // };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // useEffect(() => {
        setTimeout(() => {
            scrollToBottom();
        }, 0);
    //   }, []);

    // useEffect(() => {
    //     // Update displayedMessages when messages or messagesToShow change
    //     setDisplayedMessages(messages.slice(0, messagesToShow));
    // }, [messages, messagesToShow]);



    // useEffect(() => {
    //     scroll.current?.scrollIntoView({ behavior: "smooth" });
    // }, [messages]);

    const renderMessages = () => {
        let messageGroups = [];
        let currentGroup = [];
    
        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            const isSelf = message.senderId === state.profile?.userId;
            const prevMessage = i > 0 ? messages[i - 1] : null;
            const isNewGroup = !prevMessage || message.senderId !== prevMessage.senderId;
    
            if (isNewGroup) {
                if (currentGroup.length > 0) {
                    messageGroups.push(currentGroup);
                }
                currentGroup = [message];
            } else {
                currentGroup.push(message);
            }
    
            if (i === messages.length - 1) {
                messageGroups.push(currentGroup);
            }
        }
    
        return messageGroups.map((group, index) => {
            const isSelf = group[0].senderId === state.profile?.userId;
            const messageDivs = group.map((msg, idx) => (
                <div key={idx} className={`dashboard-messages-${isSelf ? 'sender' : 'reciever'}`}>
                    <div className="dashboard-messages-text-sent">
                        {msg.text}
                    </div>
                    <div className="dashboard-message-time">
                        {/* {moment(msg.createdAt).format('h:mm A')} */}
                        {moment(msg.createdAt).format('DD MMM h:mm A')}
                        <div className="message-tick-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                                <path d="M7 1.4375L2.875 5.5625L1 3.6875" stroke="#97BEB4" strokeLinecap="square" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
            ));
    
            return (
                <div key={index} className={`dashboard-messages-${isSelf ? 'sender' : 'reciever'}-wrap`}>
                    {messageDivs}
                </div>
            );
        });
    };
    
    

    if(!recipientUser){
        if (isMessagesLoading) {
            return <Loader/>
        }
        return <div className="dashboard-messages-right-inbox-tab active"> 
        <div className="dashboard-messages-inbox-right-empty">
            <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">You have no messages</div>
                <div className="dash-emp-no-sess-body">
                    <p>Our experienced mentors are always available to connect with you and help you grow. You can message them anytime!</p>
                </div>
            </div>
            </div>     
        </div>
        {/* <div className="dashboard-messages-inbox-right-messages-empty">
            <div className="dash-emp-no-sess">
            <div className="dash-emp-no-sess-wrap">
                <div className="dash-emp-no-sess-img">
                    <img src={NoSesh} alt="" />
                </div>
                <div className="dash-emp-no-sess-head">Your messages</div>
                <div className="dash-emp-no-sess-body">
                    <p>Share thoughts & messages with mentors and get on the journey of growing your career.</p>
                </div>
            </div>
            </div>
            <div className="dashboard-messages-keyboard">
                <div>
                    <div className="emoji-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#666666" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 13C7 13 8.5 15 11 15C13.5 15 15 13 15 13" stroke="#666666" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8 8H8.01" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14 8H14.01" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <textarea name="" id="" cols="30" rows="1" placeholder='Start typing...'></textarea>
                    <div className="send-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M19.0016 1L9.10156 10.9" stroke="#666666" strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19 1L12.7 19L9.1 10.9L1 7.3L19 1Z" stroke="#666666" strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>     
        </div> */}
        </div>
    }

    if (isMessagesLoading) {
    return (
        // <p style={{ textAlign: "center", width: "100%" }}>Loading chat...</p>
        <Loader/>
    );
    }



    return (
    <div className="dashboard-messages-right-inbox-tab active"> 
        <div className="dashboard-messages-inbox-right-messages-active">
        <div className="dahboard-messages-ui">
            <div className="dashboard-messages-inbox-peep">
                <div className="dashboard-messages-inbox-peep-wrap">
                    <div className="dashboard-messages-inbox-peep-left">
                        <div className="dashboard-messages-inbox-peep-img">
                            <img src={recipientUser?.avatar} alt="avatar" />
                        <div className={onlineUsers?.some((u) => u.userId === recipientUser?._id ) ? 'online-offline-dot online' : 'online-offline-dot'}></div>
                        </div>
                        <div className="dashboard-messages-inbox-peep-details">
                            <div className="dashboard-messages-inbox-peep-name">{recipientUser?.fullName}</div>
                            <div className="dashboard-messages-inbox-peep-desig">{recipientUser?.designation}</div>
                        </div>
                    </div> 
                    {/* <div className="dashboard-messages-ui-report-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="3" height="13" viewBox="0 0 3 13" fill="none">
                        <circle cx="1.5" cy="1.5" r="1.5" fill="#BABABA"/>
                        <circle cx="1.5" cy="6.5" r="1.5" fill="#BABABA"/>
                        <circle cx="1.5" cy="11.5" r="1.5" fill="#BABABA"/>
                        </svg>
                    </div> */}
                </div>
            </div>
            <div className="dashboard-messages-interface" ref={scrollContainerRef} style={{ height: 'calc(100vh - 420px)', overflowY: 'auto' }}>
                <div className="dashboard-messages-chats-wrap"  >
                    <div className="dashboard-messages-date">{ messages?.length >= 1 ? moment(messages[0].createdAt).calendar() : moment().calendar()}</div>
                            {renderMessages()}
                            {/* <div
                                className="scrollable-container"
                                style={{ height: '100%', overflowY: 'auto' }}
                                ref={scrollContainerRef}
                                // onScroll={handleScroll}
                            >
                                    {messages &&
                                            messages.map((message, index) => (
                                                <div
                                                key={index}
                                                className={`${
                                                    message?.senderId === state.profile?.userId
                                                    ? "message self align-self-end flex-grow-0"
                                                    : "message align-self-start flex-grow-0"
                                                }`}
                                                >
                                                <span>{message.text}</span>
                                                <span className="message-footer">
                                                    {moment(message.createdAt).calendar()}
                                                </span>
                                                </div>
                                            ))}
                            </div> */}
                    
                </div>
            </div>
        </div>
        <div className="dashboard-messages-keyboard" ref={emojiPickerRef}>
            <div>
                <div className="emoji-icon" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#666666" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 13C7 13 8.5 15 11 15C13.5 15 15 13 15 13" stroke="#666666" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 8H8.01" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14 8H14.01" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                {showEmojiPicker && (
                    <EmojiPicker
                        onEmojiClick={handleEmojiClick}
                        disableSearchBar
                        disableSkinTonePicker
                        />
                    )}
                <textarea name="" id="" cols="30" rows="1" placeholder='Start typing...' value={textMessage} onChange={handleInputChange} onKeyDown={(e) => {
    if (e.key === 'Enter') {
        e.preventDefault(); // Prevent the default newline behavior
        if(textMessage.trim().length > 0){
            sendTextMessage(textMessage, state.profile, currentChat?._id, setTextMessage  )
        }
        
    }
    }}></textarea>
                <div className="send-icon" onClick={handleChatSendMessage}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M19.0016 1L9.10156 10.9" stroke="#666666" strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M19 1L12.7 19L9.1 10.9L1 7.3L19 1Z" stroke="#666666" strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>     
    </div>
    </div>
    )
    }

export default ChatBox