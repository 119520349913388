import React, { useContext } from 'react';
import SuccImg from '../../assets/images/payment-succes.png'
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { useNavigate } from 'react-router-dom';


const PaymentConfPopUp = () => {

    const { state, showPayemntSuccessPopUp, setShowPayemntSuccessPopUp, mentorIds } = useContext(AuthContext);
    const {createChat, UpdatePotentialUserList, potentialChats, handleTabIndex} = useContext(ChatContext)
    const navigate = useNavigate();


    const handleMessageToMentor = async () => {
        const res = await createChat(state?.profile?.userId, mentorIds);
        UpdatePotentialUserList(potentialChats, mentorIds);
        setShowPayemntSuccessPopUp(false);
        handleTabIndex(1);
        navigate('/mentee/dashboard');
    }

    const closePopUpHandle = () => {
        setShowPayemntSuccessPopUp(false)
        handleTabIndex(0);
        navigate('/mentee/dashboard');
    }

  return (
    <div class="payment-successful">
    <div class="payment-successful-cont">
        <div className="payment-successful-top">
            <div className="payment-success-head">Congratulations! You have successfully booked the session.</div>
            <div className="payment-sucess-body">In the mean time you can prepare for it for better results.</div>
        </div>
        <div className="payment-successful-bottom">
            <div className="payment-successful-bottom-cont flex flex-jsb align">
                <div className="payment-successful-bottom-left flex align">
                    <div className="payment-successful-bottom-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M10 15.1622C10 16.2787 6 16.2787 6 15.1622V10.1077C6 8.99119 6.8955 8.08594 8 8.08594C9.1045 8.08594 10 8.99119 10 10.1077V15.1622Z" fill="#C1694F"/>
                        <path d="M12 0H4V10.1089H16V4.04357C16 1.81051 14.209 0 12 0Z" fill="#99AAB5"/>
                        <path d="M4 0C1.791 0 0 1.81051 0 4.04357V10.1089H8V4.04357C8 1.81051 6.209 0 4 0Z" fill="#292F33"/>
                        <path d="M16 5.05469H10C9.448 5.05469 9 5.50757 9 6.06558C9 6.62359 9.448 7.07648 10 7.07648H14V8.08737C14 8.64538 14.448 9.09826 15 9.09826H16C16.552 9.09826 17 8.64538 17 8.08737V6.06558C17 5.50757 16.552 5.05469 16 5.05469Z" fill="#DD2E44"/>
                        </svg>
                    </div>
                    <div className="payment-successful-bottom-head">Why not ask your mentor some questions?!</div>
                </div>
                <div className="payment-successful-bottom-right" >
                    <button className="primary-btn black" onClick={handleMessageToMentor}>Message</button>
                </div>
            </div>
        </div>
        <div className="payment-success-img">
            <img src={SuccImg} alt="" />
        </div>
        <div class="payment-success-cross" onClick={closePopUpHandle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none"><path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"></path><path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </div>
    </div>
</div>
  );
};

export default PaymentConfPopUp;