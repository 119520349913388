import React, {useContext, useState } from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import AllMentors from '../../components/explore-our-mentors/AllMentors';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import actionTypes from '../../context/actionTypes';
import ScrollToTop from '../../components/go-to-top/Go-to-top';


const MentorFilter = () => {

    const {dispatch } = useContext(AuthContext);

    const location = useLocation();
    const { filterCat } = location.state || {};

    const [allMentorData, setAllMentorData] = useState([]);
    const [loadMoreDisplay, setLoadMoreDisplay] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    const scrollToSecondComponent = () => {
        // console.log('i am scroll');
        const targetElement = document.getElementById("targetElementId");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const handleFilter = async (industry,country, levelOfExp, sortValue='highestRated', page=1, search='' ) => {
        setLoadingState(true)
        const filters = {
          industry: industry || undefined,
          country: country || undefined,
          levelOfExp: levelOfExp || undefined,
        };
      
        const filterObject = Object.keys(filters).reduce((acc, key) => {
          if (filters[key] !== undefined) {
            acc[key] = filters[key];
          }
          return acc;
        }, {});
      
        // Create your final filter object including sortValue
        const finalFilter = {
          filters: filterObject,
          sortValue,
          ...(search && { search }),
          // sortBy: 'createdAt'
        };
      
        // console.log(finalFilter);
      
      
        let url = process.env.REACT_APP_API_URL+`/search-mentors/?page=${page}`;
        try {
          // setLoadingState(true);
          const response = await axios.post(url, finalFilter);
          if(page==1){
            setAllMentorData(response.data);
            setLoadMoreDisplay(response.data.hasMorePages)
            setLoadingState(false);
          }else{
            setLoadMoreDisplay(response.data.hasMorePages);
      
            let mergedData = [...allMentorData.result.allMentor, ...response.data?.result?.allMentor]
              console.log('mergedData ', mergedData);
              setAllMentorData({
                ...allMentorData,
                result: {
                  allMentor: mergedData
                }
              });
              setLoadingState(false);
      
          }
      
          dispatch({ type: actionTypes.REMOVE_ERROR });
          
          setLoadingState(false);
        } catch (error) {
          setLoadingState(false)
          console.error("Error fetching data:", error);
        }
      };


      React.useEffect(() => {
        const fetchData = async () => {
          try {
            // dispatch({ type: actionTypes.START_LOADING });
            if (filterCat) {
              handleFilter(filterCat);
            } else {
              handleFilter();
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        // Call the fetchData function when the component mounts
        fetchData();
      }, [filterCat]);

    return <>
    
    <Header/>
    <AllMentors props={allMentorData} setAllMentorData={setAllMentorData} scrollToSecondComponent={scrollToSecondComponent} loadMoreDisplay={loadMoreDisplay} setLoadMoreDisplay={setLoadMoreDisplay} handleFilter={handleFilter} filterCat={filterCat} loadingState={loadingState}/>
    <Footer/>
    </>
}

export default MentorFilter;

