import React,{useState} from 'react';
import 'react-tabs/style/react-tabs.css';
import './transitions.css';
// import '../Dashboard/profile.css';
import WithdrawPop from './withdrawPop';

export default function TransactionHistory ({props,index}) {

    const [isPop, setIsPop] = useState(false);
    const [activeCard, setActiveCard] = useState(-1);

    const handleClick = () =>{
        setIsPop(true);
        document.body.classList.add('popup-open');
    }
    const handleClose = () =>{
        setIsPop(false);
        document.body.classList.remove('popup-open');
    }

    const toggleFAQ = (index) => {
        setActiveCard(index === activeCard ? -1 : index);
    };

    console.log('Earnings' , props);

    const cardClass = index === activeCard ? 'transaction-card active' : 'transaction-card';

  return (
    <div className={cardClass}>
        
        <div className='transaction-card-in'>
            <div className='transaction-card-header'>
                <div className='tch-in'>
                    <div className='tch-column'>
                        <div className='tchc-data'>
                            {props?.bookingEarning ? props?.bookingEarning : "-" }
                        </div>
                    </div>
                    <div className='tch-column'>
                        <div className='tchc-data'>
                            <div className='mentee-image'>
                                {props?.menteeAvatar ? <img src={props?.menteeAvatar} /> : ""}
                            </div>
                            <div className='mentee-name'>{props?.menteeFullName ? props?.menteeFullName : "-"}</div>
                        </div>
                    </div>
                    <div className='tch-column'>
                        <div className='tchc-data'>
                            {props?.transactionDate ? props?.transactionDate : "-"}
                        </div>
                    </div>
                    <div className='tch-column'>
                        <div className='tchc-data'>
                             {props?.transactionStatus === 'credit' ? <div class="tstatus green">{props?.transactionStatus}</div> : props?.transactionStatus === 'in-progress' ? <div class="tstatus blue">{props?.transactionStatus}</div> : props?.transactionStatus === 'requested' ? <div class="tstatus yellow">{props?.transactionStatus}</div> : props?.transactionStatus === 'debited' ? <div class="tstatus red">{props?.transactionStatus}</div> : '' } 
                            {/* {props?.transactionStatus ? props?.transactionStatus : '-'} */}
                        </div>
                    </div>
                    <div className='tch-column'>
                        <div className='tchc-data'>$ {props?.bookingEarning ? props?.bookingEarning : "-"}</div>
                    </div>
                    <div className='tch-column'>
                        <div className='tchc-data'  onClick={() => toggleFAQ(index)}>Details 
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                                <path d="M1 1.5L3 3.5L5 1.5" stroke="#444444" stroke-width="0.8" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='transaction-card-expand'>
                <div className='tce-in'>
                    <div className='tcein-wrap'>
                        <div className='tciwrap-colm left-colm'>
                            <div className='tcicolm-dates'>
                                <div class="sgcmt-lower">
                                    <div class="sg-date">
                                        <div class="sgd-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 1.5V3.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4 1.5V3.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.5 5.5H10.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"></path></svg>
                                        </div>
                                        <div class="sgd-text">Session Date: <span>{props?.sessionDate ? props?.sessionDate : "-"}</span></div>
                                    </div>
                                    <div class="sg-time">
                                        <div class="sgd-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none"><g clip-path="url(#clip0_5122_71366)"><path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_5122_71366"><rect width="12" height="12" fill="white" transform="translate(0 0.5)"></rect></clipPath></defs></svg>
                                        </div>
                                        <div class="sgd-text">Session Duration: <span>{props?.sessionDuration ? props?.sessionDuration : "-"} mins</span></div>
                                    </div>
                                </div>       
                            </div>
                            <div className='tci-disc-topic'>
                                <div className='tci-disc-topic-in'>
                                    <h6>Discussion Topic</h6>
                                    <p>{props?.bookingAgenda ? props?.bookingAgenda : "-"}</p>
                                </div>
                            </div>
                            <div className='tci-disc-topic'>
                                <div className='tci-disc-topic-in'>
                                    <h6>Detailed Session Agenda</h6>
                                    <p>{props?.bookingMessage ? props?.bookingMessage : "-"}</p>
                                </div>
                            </div>
                        </div>
                        <div className='tciwrap-colm right-colm'>
                            <div className='sess-cuts'>
                                <div className='sess-note'>
                                    <div className='sessnote-in'>
                                        <div className='sn-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <path d="M4.11267 7.78644L7.78906 4.11029L4.63748 1.48438L1.48624 4.63541L4.11267 7.78644Z" fill="#BE1931"/>
                                                <path d="M0.433686 4.63648C1.01357 5.217 1.95407 5.217 2.53396 4.63648L4.6349 2.53602C5.21545 1.95618 5.21545 1.01506 4.6349 0.434883C4.05535 -0.144961 3.11451 -0.144961 2.53396 0.434883L0.433686 2.53602C-0.146533 3.11586 -0.146533 4.05664 0.433686 4.63648Z" fill="#DD2E44"/>
                                                <path d="M7.35212 5.94922C7.35212 5.94922 12.2521 11.7262 11.9898 11.9894C11.7273 12.2523 5.94959 7.35166 5.94959 7.35166L7.35212 5.94922Z" fill="#99AAB5"/>
                                                <path d="M3.58463 9.36468C4.16417 9.94519 5.10501 9.94519 5.6859 9.36468L9.36196 5.6882C9.94185 5.10802 9.94185 4.16791 9.36196 3.58773C8.78174 3.00721 7.8409 3.00721 7.26069 3.58773L3.58463 7.26388C3.00441 7.84439 3.00441 8.78416 3.58463 9.36468Z" fill="#DD2E44"/>
                                            </svg>
                                        </div>
                                        <p><b>Note:</b> Insage takes 20% cut of every sale. </p>
                                    </div>
                                </div>
                                <div className='sesscut-details'>
                                    <div className='sesscut-details-list'>
                                        <div className='scd-label'>Session cost</div>
                                        <div className='scd-data'>${props?.bookingCost ? props?.bookingCost : "-"}</div>
                                    </div>
                                    <div className='sesscut-details-list'>
                                        <div className='scd-label'>Insage’s cut</div>
                                        <div className='scd-data'>${props?.bookingInsageCut ? props?.bookingInsageCut : "-"}</div>
                                    </div>
                                    <div className='sesscut-details-list final'>
                                        <div className='scd-label'>Final earnings</div>
                                        <div className='scd-data'>${props?.bookingEarning ? props?.bookingEarning : "-"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    </div>
  )
}
