import React, { useState, useRef } from 'react';
import './faq.css';
import { useInView } from "framer-motion";

export default function FAQ({props}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const data = props;
    const [activeFAQ, setActiveFAQ] = useState(0);

  const toggleFAQ = (index) => {
    setActiveFAQ(index === activeFAQ ? -1 : index);
  };
  return (
      <section className="insage-faqs">
        <div className="container">
            <div className="insage-faqs-wrapper">
                <div className="faqs-heading">
                    <h2 ref={ref} style={{
          transform: isInView ? "translateY(0%)" : "translateY(100%)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}>{data?.faqhead}</h2>
                </div>
                <div className="faqs-questions">
                    <div className="faqs-questions-wrapper">
                    <div className="faqs-body-content">
                        <ul className="accordion-list flex flex-c">
                        {data?.faqs?.map((faq,index) => {
                            return(
                            <li key={index} onClick={() => toggleFAQ(index)} className={activeFAQ === index ? 'active' : ''}>
                            <div className="answer-body flex flex-jsb align">
                                <div className="answer-heading" >{faq.faqQues}</div>
                                <div className="answer-arrow ">
                                <svg className='faq-plus' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 1V11" stroke="#222222" strokeWidth="1.71429" strokeLinecap="square" strokeLinejoin="round"/>
                                    <path d="M1 6H11" stroke="#222222" strokeWidth="1.71429" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>
                                <svg className='faq-minus' width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1H11" stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>
                                </div>
                                </div>
                            <div className="answer">
                                <div>
                                <p>{faq.faqAns}</p>
                                </div>
                            </div>
                            <div className="faq-bg">
                            <svg width="140" height="74" viewBox="0 0 140 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M140 -10.4259H92.2858L126.022 -44.1622L112.972 -57.2188L69.9968 -14.2501L27.0282 -57.2188L13.9716 -44.1622L47.7142 -10.4259H0V8.03885H47.7142L13.9716 41.7815L27.0282 54.8317L69.9968 11.8631L112.972 54.8317L126.022 41.7815L92.2858 8.03885H140V-10.4259Z" fill="#3F7B6D"/>
                                <path d="M82.6491 60.8379C83.7831 53.8507 79.0381 47.2672 72.0509 46.1332C65.0637 44.9992 58.4802 49.7442 57.3462 56.7314C56.2122 63.7186 60.9572 70.3021 67.9444 71.4361C74.9316 72.57 81.5151 67.8251 82.6491 60.8379Z" fill="#3F7B6D"/>
                                </svg>
                            </div>
                            </li>
                            );
                              })}
                        </ul>
                </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
  )
}
