import React, {useState, useRef, useEffect} from 'react';
import './dashSession.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import starFilled from '../../../assets/images/star-filled.svg'
import starEmpty from '../../../assets/images/star-empty.svg'
import StarRating from '../../Reviews/StarRatings';

export default function PurchaseHistory ({post,index}) {

    const [activeCard, setActiveCard] = useState(-1); // Initialize to -1 to ensure no card is active by default
    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleFAQ = (index) => {
        setActiveCard(index === activeCard ? -1 : index);
    };

    const cardClass = index === activeCard ? 'session-list-card-in active' : 'session-list-card-in';

    const handleMenuClick = () => {
        setMenuOpen(!isMenuOpen);
    };



  return ( 
    <div className='session-list-card'>
        <div className={cardClass}>
        <div className='slci-main'>
            <div className='slci-head'>
                <div className='slct-image'>
                    <img src={post?.mentorAvatar}/>
                </div>
                <div className='slct-data'>
                    <div className='slcih-top'>
                        <div className='sl-top-name'>Mentorship session with <span>{post?.mentorName}</span></div>
                    </div>
                    <div className='slcih-bottom'>
                        <div className='slb-date'>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"/>
                                </svg>
                            </span> 
                            {post?.sessionDate}
                        </div>
                        <div className='slb-time'>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <g clipPath="url(#clip0_5004_40300)">
                                    <path d="M6 12C9.03757 12 11.5 9.53757 11.5 6.5C11.5 3.46243 9.03757 1 6 1C2.96243 1 0.5 3.46243 0.5 6.5C0.5 9.53757 2.96243 12 6 12Z" fill="#B4B4B4" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6 3.5V6.5L8 7.5" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5004_40300">
                                        <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span> 
                            <div className='timer'>
                            {post?.startTime} - {post?.endTime} <span className='duration'>{post?.duration}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='details-btn' onClick={() => toggleFAQ(index)}>View Details 
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="6" viewBox="0 0 4 6" fill="none">
                        <path d="M1 5L3 3L1 0.999999" stroke="#444444" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
            <div className='slci-content'>
                <div className='slci-content-in'>
                    
                    <div className='slci-in-content'>
                        <div className='slciic-topic'>
                            <h6 className='micro'>Discussion Topic</h6>
                            <p>{post?.agenda}</p>
                        </div>
                        <div className='slciic-detailed'>
                            <h6 className='micro'>Detailed Session Agenda</h6>
                            <p>{post?.message}</p>
                        </div>
                        {post?.allReviews === 'NA' ? '' : (                     
                        <div className='slciic-reviews'>
                            <h6 className='micro'>
                                My Reviews
                                <ul className='review-stars'>
                                {post?.allReviews[0]?.avarage ? 
                                <StarRating rating={post?.allReviews[0]?.avarage}/>
                                : <>
                                <li><img alt='' src={starEmpty}/></li>
                                <li><img alt='' src={starEmpty}/></li>
                                <li><img alt='' src={starEmpty}/></li>
                                <li><img alt='' src={starEmpty}/></li>
                                <li><img alt='' src={starEmpty}/></li>
                                </>

                                }
                                </ul>
                            </h6>
                            <div className='reviewBox'>
                                <div className='re-in'>
                                    <div className='rb-defined'>
                                        {post?.allReviews[0]?.approachability ? 
                                        <>
                                        <div className='rb-defined-in'>
                                            <div className='rbd-tag'>approachability</div>
                                            <div className='rbd-stars'>
                                                <StarRating rating={post?.allReviews[0]?.approachability}/>
                                            </div>
                                        </div>
                                        </>:
                                        <div className='rb-defined-in'>
                                            <div className='rbd-tag'>approachability</div>
                                            <div className='rbd-stars'>
                                                <ul className='review-stars'>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                        {post?.allReviews[0]?.contentDelivery ? 
                                        <>
                                        <div className='rb-defined-in'>
                                        <div className='rbd-tag'>content delivery</div>
                                            <div className='rbd-stars'>
                                                <StarRating rating={post?.allReviews[0]?.contentDelivery}/>
                                            </div>
                                        </div>
                                        </>:
                                        <div className='rb-defined-in'>
                                            <div className='rbd-tag'>content delivery</div>
                                            <div className='rbd-stars'>
                                                <ul className='review-stars'>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                        {post?.allReviews[0]?.valueForMoney ? 
                                        <>
                                        <div className='rb-defined-in'>
                                        <div className='rbd-tag'>value for money</div>
                                            <div className='rbd-stars'>
                                                <StarRating rating={post?.allReviews[0]?.valueForMoney}/>
                                            </div>
                                        </div>
                                        </>:
                                        <div className='rb-defined-in'>
                                            <div className='rbd-tag'>value for money</div>
                                            <div className='rbd-stars'>
                                                <ul className='review-stars'>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                        {/* {post?.allReview?.critera04 ? 
                                        <>
                                        <div className='rb-defined-in'>
                                        <div className='rbd-tag'>criteria 04</div>
                                            <div className='rbd-stars'>
                                                <StarRating rating={post?.allReview?.critera04}/>
                                            </div>
                                        </div>
                                        </>:
                                        <div className='rb-defined-in'>
                                             <div className='rbd-tag'>criteria 04</div>
                                            <div className='rbd-stars'>
                                                <ul className='review-stars'>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                    <li><img alt='' src={starEmpty}/></li>
                                                </ul>
                                            </div>
                                        </div>
                                        } */}
                                    </div>
                                    <div className='rb-content'>
                                        <p>{post?.allReviews[0]?.message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                        <div className='ses-cost'>
                            <div className='ph-ses-cost'>
                                Session Cost:
                                <span>${post?.sessionCost}</span>
                            </div>
                            <div className='ph-ses-id'>
                                Receipt:
                                <span><a href={post?.receipt_url} target='_blank'>View Receipt</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
    </div>
  )
}
