import React, {useRef} from 'react';
import './usecases.css';
import { useInView } from "framer-motion";

export default function LearningCommunity({props}) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const data = props;
  return (
    <section  className="usecases-learning-community">
        <div className="container">
            <div className="usecases-learning-community-wrapper flex align">
                <div className="usecases-learn-comm-left">
                    <div className="usecases-learn-comm-left-wrapper">
                      <div className="usecases-learn-comm-left-bg">
                        <svg width="368" height="504" viewBox="0 0 368 504" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M368 117.378V503.637H250.73L250.775 200.268L250.548 200.494L-53.1488 504L-134.866 419.479L167.425 117.378L167.606 117.197H-136L-135.955 0H250.548L368 117.378Z" fill="#FFF3D6"/>
                        </svg>

                      </div>
                      <div ref={ref} style={{
                  clipPath: isInView ? "inset(-10% -10% -10% -10%)" : "inset(0 100% 0 0)",
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="usecases-learn-comm-left-images">
                        <div className="usecases-learn-comm-left-image">
                            <img src={data?.commImg} alt="" className='img' />
                        </div>
                        <div className="usecases-learn-comm-left-image">
                        <img src={data?.commImg2} alt="" className='img' />
                        </div>
                        <div className="usecases-learn-comm-left-image">
                        <img src={data?.commImg3} alt="" className='img' />
                        </div>
                        <div className="usecases-learn-comm-left-image">
                        <img src={data?.commImg4} alt="" className='img' />
                        </div>
                        <div className="usecases-learn-comm-left-image">
                        <img src={data?.commImg5} alt="" className='img' />
                        </div>
                        <div className="usecases-learn-comm-left-image">
                        <img src={data?.commImg6} alt="" className='img' />
                        </div>
                        <div className="usecases-learn-comm-left-image">
                        <img src={data?.commImg7} alt="" className='img' />
                        </div>
                      </div>
                    </div>
                </div>
                <div className="usecases-learn-comm-right">
                <div className="usecases-learn-comm-right-wrapper">
                  <div className="usecases-learn-comm-heading"><h3 style={{
                    transform: isInView ? "translateY(0%)" : "translateY(100%)",
                    opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} dangerouslySetInnerHTML={{ __html: data?.commHead }}></h3></div>
                  <div className="usecases-learn-comm-body">
                    <p  style={{
                    transform: isInView ? "translateY(0%)" : "translateY(100%)",
                    opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{data?.commBody}</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
  )
}
