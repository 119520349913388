import React from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import ContactBanner from '../../components/contact/ContactBanner';
import ContactForm from '../../components/contact/ContactForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
   const notify = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Form Submitted Successfully!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );
  return (
    <main className='contact-main'>
        <Header/>
        <ContactBanner/>
        <ContactForm noti={notify}/>
        <Footer/>
    </main>
  )
}
