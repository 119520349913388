import React,{useContext, useState, useEffect} from 'react'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage';

const ObStep5 = ({nextStep,prevStep, handleOnboardingInputChange2, values}) => {

    const [educationData, setEducationData] = useState({
        degree: values?.education?.degree ? values?.education?.degree : "",
        college: values?.education?.collegeName ? values?.education?.collegeName : "",
        startDate: "",  
        endDate: "",    
        startMonth: values?.education?.from ? new Date(values?.education?.from).getMonth() + 1 : "",
        startYear: values?.education?.from ? new Date(values?.education?.from).getFullYear() : "",
        endMonth: values?.education?.to ? new Date(values?.education?.to).getMonth() + 1 : "",
        endYear: values?.education?.to ? new Date(values?.education?.to).getFullYear() : "",
        });

        const [years, setYears] = useState([]);
        const [endYears, setEndYears] = useState([]);
        useEffect(() => {
            // Generate the list of years when the component mounts
            const startYearY = 1980;
            const endYearY = 2040;
            const yearsArray = Array.from(
              { length: endYearY - startYearY + 1 },
              (_, index) => startYearY + index
            );
            setYears(yearsArray);
            setEndYears(yearsArray);
          }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEducationData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'startYear' || name === 'startMonth') {
            filterEndYears(value);
          }

    };
    const filterEndYears = (selectedStartYear) => {
        const startYear = parseInt(selectedStartYear, 10);
      
        if (!isNaN(startYear)) {
          const filteredEndYears = years.filter((year) => year > startYear);
          setEndYears(filteredEndYears);
        } else {
          setEndYears(years);
        }
      };

    // const startYearY = 1980;
    // const endYearY = 2040;
    // const years = Array.from({ length: endYearY - startYearY + 1 }, (_, index) => startYearY + index);

    const[posiError, setPosiError] = useState("");  
    const[compError, setCompError] = useState("");  
    const[startMError, setStartMError] = useState("");
    const[startYError, setStartYError] = useState("");
    const [dimmed, setDimmed] = useState(values?.educationCheckbox);

    const toggleElementOpacity = () => {
          setDimmed(!dimmed);
          handleOnboardingInputChange2('educationCheckbox', !dimmed)
          setEducationData((prevData) => ({
            ...prevData,
            "endMonth": "",
            "endYear": "",
        }));
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleOnboardingInputChange2('education', {
        degree: educationData.degree,
        collegeName: educationData.college,
        from: educationData.startYear+'-'+educationData.startMonth+'-01',  
        to: educationData.endYear ? educationData.endYear+'-'+educationData.endMonth+'-01': null,   
        })
        if (!educationData.college.trim()) {
            setPosiError("College name is Required");
            return;
        }else if (educationData?.companyName?.length < 3) {
            setPosiError('College should be at least 3 characters');
            return;
        } else if (educationData?.companyName?.length > 30) {
            setPosiError('College should not exceed 30 characters');
            return;
        } else {
            setPosiError('')
        }


        if (!educationData.degree.trim()) {
            setCompError("Degree is Required");
            return;
        }else if (educationData.companyName?.length < 3) {
            setCompError('Degree should be at least 3 characters');
            return;
        } else if (educationData.companyName?.length > 30) {
            setCompError('Degree should not exceed 30 characters');
            return;
        } else {
            setCompError('')
        }


        // if(educationData?.startMonth?.length === 0){
        //     setStartMError("Please select a month");
        //     return;
        // }else {
        //     setStartMError('')
        // }
        // if(educationData?.startYear?.length === 0){
        //     setStartYError("Please select a year");
        //     return;
        // }else {
        //     setStartYError('')
        // }
        // nextStep();

        if (!educationData.startMonth || !educationData.startYear) {
            setStartMError("Please select both month and year for the start date");
            return;
        } else {
            setStartMError("");
        }
    
        // if (educationData.present) {
        //     // If present is selected, no need for end date
        //     nextStep();
        //     return;
        // }

        if(!dimmed){
            if (!educationData.endMonth || !educationData.endYear) {
                setStartYError("Please select both month and year for the end date");
                return;
            } else {
                setStartYError("");
            }
        }
    
        
    
        nextStep();
    };
    const handleBack = () =>{
        prevStep();
    }

return (
    <>
    <div className='stepLine'>
        <div className='slFilled width60'></div>
    </div>
    <div className='obStep'>
        <div className='obStepDetails'>
            step 5 of 9
        </div>
        <div className="obHeadingWrapper flex flex-jsb align">
        <div className='obHeading'>
            <h5>Share your Educational Background.</h5>
        </div>
        <div className="obHeadingSvg">
           <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M18 8L11 0H0L14 17L25.521 12.25L18 8Z" fill="#55ACEE"/>
            <path d="M25 0L18 8L23.39 15.312L24.617 13.823L36 0H25Z" fill="#3B88C3"/>
            <path d="M23.2596 16.026C23.3396 15.809 23.3906 15.578 23.3906 15.333C23.3906 14.229 22.4946 13.333 21.3906 13.333H14.7236C13.6186 13.333 12.7236 14.229 12.7236 15.333C12.7236 15.578 12.7736 15.809 12.8546 16.026C9.59662 17.852 7.39062 21.333 7.39062 25.333C7.39062 31.224 12.1656 36 18.0576 36C23.9486 36 28.7246 31.224 28.7246 25.333C28.7236 21.333 26.5186 17.852 23.2596 16.026Z" fill="#FFAC33"/>
            <path d="M18.0586 33.333C22.4769 33.333 26.0586 29.7513 26.0586 25.333C26.0586 20.9147 22.4769 17.333 18.0586 17.333C13.6403 17.333 10.0586 20.9147 10.0586 25.333C10.0586 29.7513 13.6403 33.333 18.0586 33.333Z" fill="#FFD983"/>
            <path d="M21.2778 30.6338C21.1358 30.6338 20.9938 30.5898 20.8718 30.5028L17.9998 28.4438L15.1288 30.5028C14.8838 30.6778 14.5558 30.6778 14.3128 30.4998C14.0698 30.3238 13.9678 30.0108 14.0578 29.7258L15.1288 26.2698L12.2838 24.2648C12.0418 24.0868 11.9418 23.7728 12.0338 23.4868C12.1268 23.2018 12.3918 23.0078 12.6928 23.0058L16.2168 23.0008L17.3388 19.6308C17.4338 19.3458 17.6998 19.1538 18.0008 19.1538C18.3008 19.1538 18.5678 19.3458 18.6628 19.6308L19.7658 23.0008L23.3078 23.0058C23.6088 23.0078 23.8748 23.2018 23.9668 23.4868C24.0598 23.7728 23.9588 24.0858 23.7168 24.2648L20.8708 26.2698L21.9418 29.7258C22.0338 30.0118 21.9298 30.3238 21.6878 30.4998C21.5648 30.5898 21.4208 30.6338 21.2778 30.6338Z" fill="#FFAC33"/>
            </svg>
        </div>
        </div>
        <div className="obBodyWrapper">
            <p>Add your latest experience. You can add more later.</p>
        </div>
        <div className='obFormData'>
            <form onSubmit={handleSubmit}>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="college">College name</label>
                    <div className="select-wrapper">
                    {posiError && <p className="error-name-onb">{posiError}</p>}   
                    <input
                            id="colleges"
                            name="college"
                            type="text"
                            placeholder='Ex. Indian Institute of Technology , Bombay'
                            onChange={handleInputChange}
                            value={educationData.college}
                        />
                    </div>
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="company">Degree</label>
                    {compError && <p className="error-name-onb">{compError}</p>}
                    <input
                            id="degree"
                            name="degree"
                            type="text"
                            placeholder='Ex. Bachelors of Technology'
                            onChange={handleInputChange}
                            value={educationData.degree}
                        />
                </div>
                <div className='login-form-input flex flex-c'>
                    <label htmlFor="timezone">Start date</label>
                    {startMError && <p className="error-name-onb">{startMError}</p>}
                    {startYError && <p className="error-name-onb">{startYError}</p>}
                    <div className="select-wrapper flex">
                        <select
                        id="start-date-month"
                        name="startMonth"
                        value={educationData.startMonth}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Month</option>
                        <option value="1" >January</option>
                        <option value="2" >February</option>
                        <option value="3" >March</option>
                        <option value="4" >April</option>
                        <option value="5" >May</option>
                        <option value="6" >June</option>
                        <option value="7" >July</option>
                        <option value="8" >August</option>
                        <option value="9" >September</option>
                        <option value="10" >October</option>
                        <option value="11" >November</option>
                        <option value="12" >December</option>
                        </select>
                        <select
                        id="start-date-year"
                        name="startYear"
                        value={educationData.startYear}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                        <div className="is-present-checkbox">
                            <input type="checkbox" name="present" id="present" checked={dimmed} onChange={toggleElementOpacity} />
                            <label htmlFor="present">
                                Present
                            </label>
                        </div>
                    </div>
                </div>
                <div className={dimmed ? 'login-form-input flex flex-c dimmed' : 'login-form-input flex flex-c'}>
                    <label htmlFor="timezone">End date</label>
                    <div className="select-wrapper flex">
                        <select
                        id="end-date-month"
                        name="endMonth"
                        value={educationData.endMonth}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Month</option>
                        <option value="1" >January</option>
                        <option value="2" >February</option>
                        <option value="3" >March</option>
                        <option value="4" >April</option>
                        <option value="5" >May</option>
                        <option value="6" >June</option>
                        <option value="7" >July</option>
                        <option value="8" >August</option>
                        <option value="9" >September</option>
                        <option value="10" >October</option>
                        <option value="11" >November</option>
                        <option value="12" >December</option>
                        </select>
                        <select
                        id="end-date-year"
                        name="endYear"
                        value={educationData.endYear}
                        onChange={handleInputChange}
                        >
                        <option value="" disabled>Year</option>
                        {endYears.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className="obFromBtn flex align flex-jsb">
                <button onClick={handleBack} className='flex align obFromBackBtn' type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                            <path d="M4.5 1.5L0.5 5.5L4.5 9.5" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg> 
                        Back
                    </button>
                    <button className='primary-btn black' type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
</>
  )
}

export default ObStep5
