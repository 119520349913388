import React from 'react';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import ErrorTemplate from '../../components/404/errorTemplate';

export default function ErrorPage() {
  return (
    <main>
        <Header/>
          <ErrorTemplate/>
        <Footer/>
    </main>
  )
}
