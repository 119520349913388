import React, {useRef} from 'react';
import './stats-section.css';
import { useInView } from "framer-motion";
import DataHead from './data-head';

export default function StatsSection({props}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
    const statsLength = props?.stats?.length;
  return (
    <section className="insage-knowledge-at-work">
    <div className="custom-container-fluid">
        <div className="insage-knowledge-at-work-wrapper flex flex-jsb align">
            <div className="insage-knowledge-at-work-left flex align-e">
                 <div ref={ref} style={{
                 transform: isInView ? "translateX(0px)" : "translateX(-100px)",
                  opacity: isInView ? "1" : "0",
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="insage-knowledge-at-work-label flex flex-jsc">
                   <div  className="label col-white">{props?.quote}</div>
                 </div>
                 <div className="insage-knowledge-at-work-content">
                    <div className="insage-knowledge-at-work-content-wrapper">
                        <div className="insage-knowledge-at-work-content-heading"><h3 ref={ref} style={{
                 transform: isInView ? "translateY(0%)" : "translateY(100%)",
                 opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{props?.heading}</h3></div>
                        <div className="insage-knowledge-at-work-content-body">
                            <div className="insage-knowledge-at-work-content-body-label">
                                <span ref={ref} style={{
                 transform: isInView ? "translateY(0%)" : "translateY(100%)",
                 opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="label">{props?.paraHeading}</span>
                            </div>
                            <div className="insage-knowledge-at-work-content-body-text">
                                <p ref={ref} style={{
                 transform: isInView ? "translateY(0%)" : "translateY(100%)",
                 opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className='col-p'>{props?.para}</p>
                            </div>
                        </div>
                    </div>
                 </div>

            </div>
            <div className="insage-knowledge-at-work-right">
                <div className="insage-knowledge-at-work-right-wrapper flex flex-c">
                    <div className="insage-knowledge-top-data">

                      {props?.stats?.map((post,index) => {
                        const isLastItem = index === statsLength - 1;
                          return (
                            <DataHead key={index} targetNumber={post?.number} currentText={post?.text} isLastItem={isLastItem}/>
                          );
                      })}   
                    </div>
                </div>
            </div>
        </div> 
    </div>
   </section>
  )
}
