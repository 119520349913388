import React, { useContext, useEffect, useState } from 'react';
import './profile.css';
import Patt from '../../assets/images/dash-empty.svg';
import NoSesh from '../../assets/images/no-sesh.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation} from "swiper";
import TrmCard from '../reusable/trm-card/TrmCard';
import DashSessionGrid from '../reusable/session-cards/DashSessionGrid';
import DashSessionGridCompleted from '../reusable/session-cards/DashSessionGridCompleted';
import DashSessionGridOngoing from '../reusable/session-cards/DashSessionOngoing';
import { AuthContext } from '../../context/AuthContext';
import data from './data.json';
import { Link } from 'react-router-dom';
import ExpandedSessionPopup from './ExpandedSessionPopup';
import { ToastContainer, toast } from 'react-toastify';
import CancelSessionPopup from './CancelSessionPopup';
import ReportSessionPopup from './ReportSessionPopup';
import ConfirmCancelSessionPopup from './ConfirmCencelSessionPopup';
import ConfirmReportSessionPopup from './ConfirmReportSessionPopup';
import CompletedExpandedSessionPopup from './CompletedExpandedSessionPopup';
import WriteReviewPopup from './WriteReviewPopup';
import 'react-toastify/dist/ReactToastify.css';
import WriteReviewOnDashPopup from './WriteReviewOnDashPopup';
import PaymentConfPopUp from './PaymentConfPopUp';


export default function DashFilled({props,gotoSession}) {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const gridcardPrevRef = React.useRef(null)
    const gridcardNextRef = React.useRef(null)
    const { state, showPayemntSuccessPopUp, setShowPayemntSuccessPopUp } = useContext(AuthContext);
    const limitedCompleted = props?.completedBookingsFetch?.slice(0, 3);
    const [openExpandSessionPopup, setOpenExpandSessionPopup] = useState(false);
    const [openCompletedExpandSessionPopup, setOpenCompletedExpandSessionPopup] = useState(false);
    const [openCancelSessionPopup, setOpenCancelSessionPopup] = useState(false);
    const [openWriteReviewPopup, setOpenWriteReviewPopup] = useState(false);
    const [openWriteReviewPopupOnDash, setOpenWriteReviewPopupOnDash] = useState(false);
    const [openReportSessionPopup, setOpenReportSessionPopup] = useState(false);
    const [openReportConfirmPopup, setopenReportConfirmPopup] = useState(false);
    const [selectedItemData, setSelectedItemData] = useState(null);
    const [currentPopupOpen, setCurrentPopupOpen] = useState(false);
    const [expandPopupOpen, setexpandPopupOpen] = useState(false);
    const [expandCompletedPopupOpen, setexpandCompletedPopupOpen] = useState(false);
    const [newPopupOpen, setNewPopupOpen] = useState(false);
    const [cancellationData, setCancellationData] = useState(null);
    const [newPopupOpen2, setNewPopupOpen2] = useState(false);

    // console.log('DAshFilled props ', props);

    const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Details Saved Successfully</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );
      const notifyC = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Cancel Session Request Sent Successfully!</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

    const ongoingSession = props?.onGoingBookingsFetch;
    const completedSession = props?.completedBookingsFetch;
    const upcomingSession = props?.upcomingBookingsFetch;

    //Open Expanded Session Popup
    const openExpandSessionPop = (session) => {
        setSelectedItemData(session);
        setOpenExpandSessionPopup(true);
        setexpandPopupOpen(true);
        document.body.classList.add('popup-open');
    };
    //Close Expanded Session Popup
    const closeExpandSessionPopup = () => {
        setOpenExpandSessionPopup(false);
        setexpandPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    //Open Cancel Session Popup
    const openCancelSessionPop = (session) => {
        setSelectedItemData(session);
        setOpenCancelSessionPopup(true);
        setCurrentPopupOpen(true);
        setOpenExpandSessionPopup(false);
        document.body.classList.add('popup-open');
    };
    //Close Cancel Session Popup
    const closeCancelSessionPopup = () => {
        setOpenCancelSessionPopup(false);
        setOpenExpandSessionPopup(false);
        setCurrentPopupOpen(false);
        setexpandPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    //Open Confirm Cancel Session Popup
    const openNewPopup = () => {
        setCurrentPopupOpen(false);
        setNewPopupOpen(true);
        setOpenCancelSessionPopup(false);
        setOpenExpandSessionPopup(false);
        setNewPopupOpen2(true);
        // setCancellationData({
        //     cancelReason,
        //     additionalDetails,
        //   });

    };
    //Close Confirm Cancel Session Popup
    const closeNewPopup = () => {
        setNewPopupOpen(false);
        setNewPopupOpen2(false);
        setOpenCancelSessionPopup(false);
        document.body.classList.remove('popup-open');
    };
    //Close Confirm Cancel & Open Cancel Session Popup
    const closeNewAndOpenCancelPopup = () => {
        setNewPopupOpen(false);
        setCurrentPopupOpen(true);
        setOpenCancelSessionPopup(true);
    };
    //Open Report Session Popup
    const openReportSessionPop = (ongoingSession) => {
        setSelectedItemData(ongoingSession);
        setOpenReportSessionPopup(true);
        setCurrentPopupOpen(true);
        document.body.classList.add('popup-open');
    };
    //Close Report Session Popup
    const closeReportSessionPop = () => {
        setOpenReportSessionPopup(false);
        setCurrentPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    //Open Confirm Report Session Popup
    const openConfirmReportPop = () => {
        setCurrentPopupOpen(false);
        setOpenReportSessionPopup(false)
        setNewPopupOpen(true);
    };
    //Close Confirm Report Session Popup
    const closeConfirmReportPop = () => {
        setNewPopupOpen(false);
        setOpenReportSessionPopup(false);
        document.body.classList.remove('popup-open');
    };
    //Open Complete Expanded Session Popup
    const openCompletedExpandSessionPop = (session) => {
        setSelectedItemData(session);
        setOpenCompletedExpandSessionPopup(true);
        setexpandCompletedPopupOpen(true);
        document.body.classList.add('popup-open');
    };
    //Close Complete Expanded Session Popup
    const closeCompletedExpandSessionPop = () => {
        setOpenCompletedExpandSessionPopup(false);
        setexpandCompletedPopupOpen(false);
        document.body.classList.remove('popup-open');
    };
    //Open Report Session Popup
    const openWriteReviewPop = (session) => {
        setSelectedItemData(session);
        setOpenWriteReviewPopup(true);
        setOpenCompletedExpandSessionPopup(false);
        setCurrentPopupOpen(true);
        document.body.classList.add('popup-open');
    };

    const openWriteReviewPopOnDash = (session) => {
        setSelectedItemData(session);
        setOpenWriteReviewPopupOnDash(true);
        setOpenCompletedExpandSessionPopup(false);
        setCurrentPopupOpen(true);
        document.body.classList.add('popup-open');
    };

    const closeWriteReviewPopOnDash = () => {
        setOpenWriteReviewPopupOnDash(false);
        setCurrentPopupOpen(false);
        document.body.classList.remove('popup-open');
    };

    //Close Report Session Popup
    const closeWriteReviewPop = () => {
        setOpenWriteReviewPopup(false);
        setCurrentPopupOpen(false);
        document.body.classList.remove('popup-open');
    };

    // console.log('dash-filled-data', props)

    let lastIndex;

if (ongoingSession && ongoingSession.length > 0) {
  lastIndex = ongoingSession.length - 1;
} else {
  // Handle the case when the array is empty or undefined
  lastIndex = -1; // or any other default value
}
const notifyR = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Review Posted Successfully!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );

    return (
        <div className="dashboard-empty-state">
            <div className="dashboard-empty-state-banner">
                <div className="dashboard-empty-state-banner-wrapper">
                    <div className="dash-empty-banner-ptrn">
                        <img src={Patt} alt="" srcSet="" />
                    </div>
                    <div className="dash-empty-text-wrap">
                        <div className="dash-empty-name flex align">
                            <div className="dash-empty-name-text">Welcome, {props?.fullName}!</div>
                            <div className="dash-empty-name-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
                                <path d="M20.3795 6.22633C26.1791 12.0244 27.7462 19.8599 23.8801 23.7245C20.0139 27.5906 12.18 26.0235 6.38112 20.2255C6.18414 20.0285 6.00451 19.8237 5.81699 19.622L9.29708 16.1419C13.8069 20.6517 19.5522 22.2196 22.1286 19.6417C24.7058 17.0645 23.1387 11.3192 18.6296 6.80936L18.6469 6.82591L18.6304 6.80857C13.4768 1.65422 6.42524 0.348694 2.88211 3.89182C1.16846 5.60547 0.599608 8.14089 1.04004 10.8827C0.36403 7.66579 0.921854 4.68522 2.88211 2.72575C6.74748 -1.13962 14.5814 0.427482 20.3795 6.22633Z" fill="#FDD888"/>
                                <path d="M12.9236 27.3956C12.7251 27.3956 12.5273 27.335 12.3571 27.2136L8.35781 24.3449L4.3585 27.2136C4.01735 27.4579 3.56038 27.4579 3.22158 27.2097C2.88279 26.9639 2.74019 26.529 2.86704 26.1311L4.35929 21.3171L0.395437 18.524C0.0582213 18.2759 -0.0812346 17.8394 0.0471908 17.4407C0.177192 17.0436 0.546711 16.7734 0.965078 16.7702L5.80821 16.7608L7.43678 12.0673C7.56914 11.6702 7.94023 11.4023 8.3586 11.4023C8.77697 11.4023 9.14806 11.6702 9.28043 12.0673L10.7506 16.7608L15.7513 16.7702C16.1705 16.7734 16.5408 17.0436 16.6692 17.4407C16.7984 17.8394 16.6582 18.2759 16.321 18.524L12.3571 21.3171L13.8486 26.1311C13.9762 26.529 13.832 26.9647 13.4948 27.2097C13.3239 27.3342 13.1237 27.3956 12.9236 27.3956Z" fill="#FFAC33"/>
                                </svg>
                            </div>
                        </div>
                        <div className="dash-empty-body">
                            <p>Start your mentee journey today.</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* Upcoming Sessions Starts*/}
            {upcomingSession.length > 0 ? 
            
            <div className="dash-empty-upcoming-sessions less-space">
                <div className="dash-empty-sessions-content">
                    <div className="dash-empty-sess-head">upcoming sessions</div>
                    <div className="dash-empty-sess-body flex align flex-jsb">
                        <p>Check your sessions for today, tomorrow and whenever here.</p>
                        <div className="dash-empty-trm-slider-btns flex align">
                            <div className="det-slider-prev" ref={gridcardPrevRef}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                            </div>
                            <div className="det-slider-next" ref={gridcardNextRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                            </div>
                        </div>
                        </div>
                </div>
                <div className='dash-sessions-data'>
                    <Swiper modules={[Navigation]}  breakpoints={{
                        0:{
                            slidesPerView: 1,
                        },
                        600:{
                            slidesPerView: 2,
                        },                
                        1200: {
                            slidesPerView: 3,
                        }
                    }}  slidesPerView={3} spaceBetween={13}  navigation={{ prevEl: gridcardPrevRef.current, nextEl: gridcardNextRef.current,}}  onSwiper={(swiper) => {
                        // Delay execution for the refs to be defined
                        setTimeout(() => {
                        // Override prevEl & nextEl now that refs are defined
                        swiper.params.navigation.prevEl = gridcardPrevRef.current
                        swiper.params.navigation.nextEl = gridcardNextRef.current

                        // Re-init navigation
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }} className="mySwiper dash-top-rated-mentors-slider">
                            {upcomingSession?.map((session,index) => { 
                                return (
                                    <SwiperSlide key={index} height='auto'>
                                        {index === 0 && <div className="next-up">Next Up</div>}
                                        <DashSessionGrid
                                            props={session}
                                            openExpandSessionPopup={() => openExpandSessionPop(session)}
                                            openCancelSessionPopup={() => openCancelSessionPop(session)}
                                        />
                                    </SwiperSlide>
                                );
                            })} 
                    </Swiper>
                </div>
            </div> : 
            <div className="dash-empty-upcoming-sessions">
                <div className="dash-empty-sessions-content">
                    <div className="dash-empty-sess-head">upcoming sessions</div>
                    <div className="dash-empty-sess-body"><p>Check your sessions for today, tomorrow and whenever here.</p></div>
                </div>
                <div className="dash-emp-no-sess">
                    <div className="dash-emp-no-sess-wrap">
                        <div className="dash-emp-no-sess-img">
                            <img src={NoSesh} alt="" />
                        </div>
                        <div className="dash-emp-no-sess-head">You don’t have upcoming sessions</div>
                        <div className="dash-emp-no-sess-body">
                            <p> Don't worry, our experienced mentors are
                                available to connect with you and help you grow.</p>
                        </div>
                        <div className="dash-emp-no-sess-btn">
                            <Link to="/explore-our-mentors" className="primary-btn black">Explore Mentors</Link>
                        </div>
                    </div>
                </div>
                </div>
            
            }
            {/* Upcoming Sessions Ends*/}
            
            {/* Completed Sessions Starts */}
            {limitedCompleted?.length > 0 && 
            <>
                <div className="dash-empty-upcoming-sessions less-space">
                    <div className="dash-empty-sessions-content">
                        <div className="dash-empty-sess-head">Completed sessions</div>
                        <div className="dash-empty-sess-body flex align flex-jsb">
                            <p>Review the sessions you took here.</p>
                            <div className="dash-empty-trm-slider-btns flex align">
                                <div href='' className='primary-btn border small grey' onClick={() => gotoSession()}>View All</div>
                            </div>
                        </div>
                    </div>
                    <div className='dash-sessions-data'>
                        <div className='dash-session-grid'>
                            {limitedCompleted?.map((session,index) => {
                                return (
                                    <DashSessionGridCompleted 
                                        props={session} 
                                        key={index}
                                        openCompletedExpandSessionPopup={() => openCompletedExpandSessionPop(session)}
                                        openWriteReviewPopupOnDash={() => openWriteReviewPopOnDash(session)}
                                    />
                                );
                            })} 
                        </div>
                    </div>
                </div>
            </>
            }
           
            {/* Completed Sessions Ends */}

            {/* Ongoing Sessions Starts*/}
            {ongoingSession?.length > 0 && 
                <>
                    <div className="dash-empty-upcoming-sessions less-space ongoing-empty">
                <div className='dash-ongoing-wrap'>
                    <div className="dash-empty-sessions-content">
                        <div className="dash-empty-sess-head">Ongoing Session</div>
                        <div className="dash-empty-sess-body flex align flex-jsb">
                            <p>Check the session you are taking at the moment.</p>
                        </div>
                    </div>
                    <div className='dash-sessions-data'>
                        <DashSessionGridOngoing 
                            props={ongoingSession?.[lastIndex]} 
                            openReportSessionPopup={() => openReportSessionPop(ongoingSession)}
                        />
                    </div>
                    <div className='ongoing-report'>
                        <div className='or-report'>
                            <div className='or-text'>Not happy with your session with Mira?</div>
                            <div className='or-button'>
                                <button className='popup-btn cancel-btn' onClick={() => openReportSessionPop(ongoingSession)} >
                                    Report
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </> 
            }
            {/* Ongoing Sessions Ends*/}

            {/* New Mentors Starts*/}
            {/* <div className="dash-empty-top-rated-mentors">
                <div className="dash-empty-sessions-content">
                    <div className="dash-empty-sess-head">New Mentors</div>
                        <div className="dash-empty-sess-body flex align flex-jsb">
                            <p>Checkout our new mentors in your industry!</p>
                            <div className="dash-empty-trm-slider-btns flex align">
                                <div className="det-slider-prev" ref={navigationPrevRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                                <div className="det-slider-next" ref={navigationNextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                        <path d="M1 9L5 5L1 1" stroke="#222222" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dash-emp-trm-slider flex">
                        <div className="dash-emp-trm-slider-left">
                        <div className="dash-emp-trm-slider-left-wrap flex flex-c">
                            <div className="dash-emp-trm-slider-left-head">Our<br/> Best<br/> Batch</div>
                            <div className="dash-emp-trm-slider-left-dash"></div>
                            <div className="dash-emp-trm-slider-left-cont">
                                <div className="dash-emp-trm-slider-left-cont-body">
                                    <p>Discover all mentors
                                    below across industries!</p>
                                </div>
                                <div className="dash-emp-trm-slider-left-cont-btn">
                                    <Link to="/explore-our-mentors" className="primary-btn border">Dicover All Mentors</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="dash-emp-trm-slider-right">
                            <Swiper  modules={[Navigation]}  breakpoints={{
                                0:{
                                    slidesPerView: 1,
                                },                
                                1200: {
                                slidesPerView: 2,
                                }
                            }}  slidesPerView={2} spaceBetween={13}  navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current,}}  onSwiper={(swiper) => {
                                // Delay execution for the refs to be defined
                                setTimeout(() => {
                                // Override prevEl & nextEl now that refs are defined
                                swiper.params.navigation.prevEl = navigationPrevRef.current
                                swiper.params.navigation.nextEl = navigationNextRef.current

                                // Re-init navigation
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                                })
                            }} className="mySwiper dash-top-rated-mentors-slider">
                                    {props?.topRated?.map((posts,index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <TrmCard post={posts}/>
                                            </SwiperSlide>
                                            
                                        );
                                    })} 
                                
                            </Swiper>
                        </div>
                    </div>   
            </div> */}
            {/* New Mentors Ends*/}

            <div className="mentee-profile-pop-ups">
                
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openExpandSessionPopup ? 'active' : ''}`}>
                    {expandPopupOpen && (
                        <ExpandedSessionPopup 
                            data={selectedItemData} 
                            noti={notify} 
                            onclose={closeExpandSessionPopup}
                            onCancel={openCancelSessionPop} 
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openCancelSessionPopup ? 'active' : ''}`}>
                    {currentPopupOpen && (
                        <CancelSessionPopup
                            data={selectedItemData}
                            noti={notifyC}
                            onclose={closeCancelSessionPopup}
                            onOpenNew={openNewPopup}
                            // Pass the function to open the new popup
                        />
                    )}
                </div>
                {/* <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ newPopupOpen ? 'active' : ''}`}>
                    {newPopupOpen && <ConfirmCancelSessionPopup data={selectedItemData} reason={cancellationData.cancelReason} details={cancellationData.additionalDetails} onclose={closeNewPopup}  noti={notifyC} dontCancel={closeNewAndOpenCancelPopup} />}
                </div> */}
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openReportSessionPopup ? 'active' : ''}`}>
                    {currentPopupOpen && (
                        <ReportSessionPopup
                            data={selectedItemData}
                            noti={notify}
                            onclose={closeReportSessionPop}
                            onOpenNew={openConfirmReportPop} // Pass the function to open the new popup
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ newPopupOpen ? 'active' : ''}`}>
                    {newPopupOpen && <ConfirmReportSessionPopup  data={selectedItemData} onclose={closeConfirmReportPop} />}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openCompletedExpandSessionPopup ? 'active' : ''}`}>
                    {expandCompletedPopupOpen && (
                        <CompletedExpandedSessionPopup 
                            data={selectedItemData} 
                            noti={notify} 
                            onclose={closeCompletedExpandSessionPop}
                            onReview={openWriteReviewPopOnDash} 
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openWriteReviewPopup ? 'active' : ''}`}>
                    {openWriteReviewPopup && (
                        <WriteReviewPopup 
                            data={selectedItemData} 
                            noti={notifyR} 
                            onclose={closeWriteReviewPop}
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openWriteReviewPopupOnDash ? 'active' : ''}`}>
                    {openWriteReviewPopupOnDash && (
                        <WriteReviewOnDashPopup
                            data={selectedItemData} 
                            noti={notifyR} 
                            onclose={closeWriteReviewPopOnDash}
                        />
                    )}
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ newPopupOpen2 ? 'active' : ''}`}>
                    {newPopupOpen2 && <ConfirmCancelSessionPopup data={selectedItemData} onclose={closeNewPopup} dontCancel={closeNewAndOpenCancelPopup} noti={notifyC} />}
                </div>
                
            </div>

        </div>
        )
    }
