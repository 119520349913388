import React, {useState, useContext, useEffect} from 'react';
import './sessions.css';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../ErrorMessage';
import Loader from '../reusable/loading/Loader';
import DashSessionCard from '../reusable/session-cards/DashSessionCard';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import BadhboardSessionsEmpty from './BadhboardSessionsEmpty';
import WriteReviewPopup from '../Dashboard/WriteReviewPopup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReportSessionPopup from '../Dashboard/ReportSessionPopup';
import CancelSessionPopup from '../Dashboard/CancelSessionPopup';
import ConfirmCancelSessionPopup from '../Dashboard/ConfirmCencelSessionPopup';
import NoSesh from '../../assets/images/no-sesh.svg';
import { Link } from 'react-router-dom';
import actionTypes from '../../context/actionTypes';

const DashboardSessions = () => {

const { getMenteeSessions,getMenteeUpcomingSessions,getMenteeCompletedSessions,getMenteeCancelledSessions,getMenteeCancelRequestedSessions,getMenteeReportedSessions, state, dispatch } = useContext(AuthContext);
const [openWriteReviewPopup, setOpenWriteReviewPopup] = useState(false);
const [openReportPopup, setOpenReportPopup] = useState(false);
const [selectedItemData, setSelectedItemData] = useState(null);
const [openCancelSessionPopup, setOpenCancelSessionPopup] = useState(false);
const [newPopupOpen2, setNewPopupOpen2] = useState(false);
const [page, setPage] = useState(1);
const [activeTab, setActiveTab] = useState(0);

const openReviewPop = (data)=>{
    setOpenWriteReviewPopup(true);
    setSelectedItemData(data);
    document.body.classList.add('popup-open');
}
const openReportPopupF = (data)=>{
  setOpenReportPopup(true);
  setSelectedItemData(data);
  document.body.classList.add('popup-open');
}
const closeReportPopupF = (data)=>{
    setOpenReportPopup(false);
    document.body.classList.remove('popup-open');
  }
const closeReviewPop = ()=>{
    setOpenWriteReviewPopup(false);
    document.body.classList.remove('popup-open');
}
const openCancelSessionPop = (session) => {
    setOpenCancelSessionPopup(true);
    setSelectedItemData(session);
    document.body.classList.add('popup-open');
};
//Close Cancel Session Popup
const closeCancelSessionPopup = () => {
    setOpenCancelSessionPopup(false);
    document.body.classList.remove('popup-open');
};
const closeNewAndOpenCancelPopup = () => {
    setNewPopupOpen2(false);
    setOpenCancelSessionPopup(true);
};
 //Open Confirm Cancel Session Popup
 const openNewPopup = () => {
    setNewPopupOpen2(true);
    setOpenCancelSessionPopup(false);
};
//Close Confirm Cancel Session Popup
const closeNewPopup = () => {
    setNewPopupOpen2(false);
    setOpenCancelSessionPopup(false);
    document.body.classList.remove('popup-open');
};

const handleCancelSession = (cancelData) => {
    // Handle cancel session action here
    console.log("Cancel data received:", cancelData);
    closeCancelSessionPopup();
};

const handleConfirmCancel = (confirmCancelData) => {
    // Handle confirm cancel action here
    console.log("Confirm cancel data received:", confirmCancelData);
    closeNewPopup();
};


const notifyC = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Session delete request has been sent to admin!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );
useEffect(() => {
    const fetchData = async () => {
    try {
        const res = await getMenteeSessions();
    } catch (error) {
        console.log(error);
    }
};
fetchData();
},[]);

// console.log('state?.sessionData ', state?.sessionData);


const notifyR = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Review Posted Successfully!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );
  const notifyRe = () => toast.success(
    <div className="custom-toast">
        <div className="custom-toast-top flex align">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
            <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
            </svg>
      <span>Success</span>
        </div>
        <div className="custom-toast-bot">
          <span>Session Reported Successfully!</span>
        </div>
    </div>,
    {
      className: 'custom-toast-container',
    }
  );

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleLoadMore = async (pageNo) => {
    let res =await getMenteeSessions(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.sessionData,
            ...res,
            result: [...state.sessionData.result, ...res.result]
          };
        dispatch({ type: actionTypes.SESSION_DATA, payload: mergedData});
    }
}

const handleLoadMoreUpcoming = async (pageNo) => {
    let res =await getMenteeUpcomingSessions(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.upcomingSessionData,
            ...res,
            result: [...state.upcomingSessionData.result, ...res.result]
          };
        dispatch({ type: actionTypes.UPCOMING_SESSION_DATA, payload: mergedData});
    }
}

const handleLoadMoreCompleted = async (pageNo) => {
    let res =await getMenteeCompletedSessions(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.completedSessionData,
            ...res,
            result: [...state.completedSessionData.result, ...res.result]
          };
        dispatch({ type: actionTypes.COMPLETED_SESSION_DATA, payload: mergedData});
    }
}

const handleLoadMoreCancelled = async (pageNo) => {
    let res =await getMenteeCancelledSessions(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.cancelledSessionData,
            ...res,
            result: [...state.cancelledSessionData.result, ...res.result]
          };
        dispatch({ type: actionTypes.CANCELLED_SESSION_DATA, payload: mergedData});
    }
}

const handleLoadMoreCancelRequested = async (pageNo) => {
    let res =await getMenteeCancelRequestedSessions(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.cancelRequestedSessionData,
            ...res,
            result: [...state.cancelRequestedSessionData.result, ...res.result]
          };
        dispatch({ type: actionTypes.CANCEL_REQUESTED_SESSION_DATA, payload: mergedData});
    }
}

const handleLoadMoreReported = async (pageNo) => {
    let res =await getMenteeReportedSessions(Number(pageNo)+1);

    if(typeof res === 'object'){
        let mergedData = {
            ...state.reportedSessionData,
            ...res,
            result: [...state.reportedSessionData.result, ...res.result]
          };
        dispatch({ type: actionTypes.REPORTED_SESSION_DATA, payload: mergedData});
    }
}


const data = state?.sessionData?.result?.length || state?.upcomingSessionData?.result?.length || state?.completedSessionData?.result?.length > 0 ? (
    <div className="fav-mentors-full dashboard-empty-state">
    {state.loading && <Loader/>}
    {/* {state.error && <ErrorMessage errorData={state.error} />} */}
        <div className="dash-empty-sessions-content">
            <div className="dash-empty-sess-head">sessions</div>
            <div className="dash-empty-sess-body">
                <p>Check all your sessions for today, tomorrow and whenever here.</p>
            </div>              
        </div>
        <div className="dash-session-list">
            <div className='dsl-tabs'>
            <Tabs>
                <div className='dsltabs-tabs'>
                    <TabList>
                        {/* <Tab>All</Tab>
                        <Tab>Upcoming</Tab>
                        <Tab>Completed</Tab>
                        <Tab>Canceled</Tab>
                        <Tab>Cancel Requested</Tab>
                        <Tab>Reported</Tab> */}
                        <Tab onClick={() => getMenteeSessions(1)}>All</Tab>
                        <Tab onClick={() => getMenteeUpcomingSessions(1)}>Upcoming</Tab>
                        <Tab onClick={() => getMenteeCompletedSessions(1)}>Completed</Tab>
                        <Tab onClick={() => getMenteeCancelledSessions(1)}>Canceled</Tab>
                        <Tab onClick={() => getMenteeCancelRequestedSessions(1)}>Cancel Requested</Tab>
                        <Tab onClick={() => getMenteeReportedSessions(1)}>Reported</Tab>
                    </TabList>
                </div>
                <div className='dsltabs-data'>
                    {/* All Sessions */}
                    <TabPanel>
                        {
                            state?.sessionData?.result?.length > 0 ? (
                                state?.sessionData?.result?.map((data, index) => (
                                <DashSessionCard
                                post={data}
                                openReview={() => openReviewPop(data)}
                                openReport={()=> openReportPopupF(data)}
                                openCancelPop={() => openCancelSessionPop(data)}
                                key={index}
                                />
                            ))
                            ) : (
                            <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">You haven’t taken any sessions yet</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>As a mentee, you haven't started your journey yet! Start taking sessions and sharing increase your skills with mentors today</p>
                                    </div>
                                </div>
                            </div> 
                            )
                        }
                           { state?.sessionData?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMore(state.sessionData.currentPage)}>
                                Load More
                            </div>
                            </div> }
                    </TabPanel>
                    {/* All Sessions */}
                    
                    {/* Upcoming Sessions */}
                    <TabPanel>
                            {state?.upcomingSessionData?.result?.length > 0 ? (
                                state?.upcomingSessionData?.result?.map((data, index) => (
                                 <DashSessionCard
                                post={data}
                                openReport={()=> openReportPopupF(data)}
                                openCancelPop={() => openCancelSessionPop(data)}
                                key={index}
                                />
                            ))
                            ) : (
                            <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">You don't have any upcoming session</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>You don't have any upcoming session yet</p>
                                    </div>
                                </div>
                            </div> 
                            )
                            }
                         { state?.upcomingSessionData?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMoreUpcoming(state.upcomingSessionData.currentPage)}>
                                Load More
                            </div>
                            </div> }
                    </TabPanel>
                    {/* Upcoming Sessions */}

                    {/* Completed Sessions */}
                    <TabPanel>
                        {
                            state?.completedSessionData?.result?.length > 0 ? (
                                state?.completedSessionData?.result?.map((data, index) => (
                                <DashSessionCard
                                post={data}
                                openReview={() => openReviewPop(data)}
                                openReport={()=> openReportPopupF(data)}
                                openCancelPop={() => openCancelSessionPop(data)}
                                key={index}
                                />
                            ))
                            ) : (
                            <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">You don't have any completed session</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>You don't have any completed session yet</p>
                                    </div>
                                </div>
                            </div> 
                            )
                        }
                        { state?.completedSessionData?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMoreCompleted(state.completedSessionData.currentPage)}>
                                Load More
                            </div>
                            </div> }
                        
                    </TabPanel>
                    {/* Completed Sessions */}

                    {/* Cancelled Sessions */}
                    <TabPanel>
                        {
                            state?.cancelledSessionData?.result?.length > 0 ? (
                                state?.cancelledSessionData?.result?.map((data, index) => (
                                <DashSessionCard
                                post={data}
                                openReport={()=> openReportPopupF(data)}
                                openCancelPop={() => openCancelSessionPop(data)}
                                key={index}
                                />
                            ))
                            ) : (
                            <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">You don't have any cancelled session</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>You don't have any cancelled session yet</p>
                                    </div>
                                </div>
                            </div> 
                            )
                        }
                        { state?.cancelledSessionData?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMoreCancelled(state.cancelledSessionData.currentPage)}>
                                Load More
                            </div>
                            </div> }
                      
                    </TabPanel>
                    {/* Cancelled Sessions */}

                    {/* Cancel Requested Sessions */}
                    <TabPanel>
                        {
                            state?.cancelRequestedSessionData?.result?.length > 0 ? (
                                state?.cancelRequestedSessionData?.result?.map((data, index) => (
                                <DashSessionCard
                                post={data}
                                openReport={()=> openReportPopupF(data)}
                                openCancelPop={() => openCancelSessionPop(data)}
                                key={index}
                                />
                            ))
                            ) : (
                            <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">You don't have any cancel requested session</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>You don't have any cancel requested session yet</p>
                                    </div>
                                </div>
                            </div> 
                            )
                        }
                        { state?.cancelRequestedSessionData?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMoreCancelRequested(state.cancelRequestedSessionData.currentPage)}>
                                Load More
                            </div>
                            </div> }
                     
                    </TabPanel>
                    {/* Cancel Requested Sessions */}

                    {/* Reported Sessions */}
                    <TabPanel>
                        {
                            state?.reportedSessionData?.result?.length > 0 ? (
                                state?.reportedSessionData?.result?.map((data, index) => (
                                <DashSessionCard
                                post={data}
                                openReport={()=> openReportPopupF(data)}
                                openCancelPop={() => openCancelSessionPop(data)}
                                key={index}
                                />
                            ))
                            ) : (
                            <div className="dash-emp-no-sess">
                                <div className="dash-emp-no-sess-wrap">
                                    <div className="dash-emp-no-sess-img">
                                        <img src={NoSesh} alt="" />
                                    </div>
                                    <div className="dash-emp-no-sess-head">You don't have any reported session</div>
                                    <div className="dash-emp-no-sess-body">
                                        <p>You don't have any reported session yet</p>
                                    </div>
                                </div>
                            </div> 
                            )
                        }
                        { state?.reportedSessionData?.hasMorePages && <div className="favourite-mentors-list-button">
                            <div className="isg-guide-load-more" onClick={() => handleLoadMoreReported(state.reportedSessionData.currentPage)}>
                                Load More
                            </div>
                            </div> }
                     
                    </TabPanel>
                    {/* Reported Sessions */}
                </div>
            </Tabs>
            </div>
          
        </div>     

        <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openWriteReviewPopup ? 'active' : ''}`}>
                    {openWriteReviewPopup && (
                        <WriteReviewPopup 
                            data={selectedItemData} 
                            noti={notifyR} 
                            onclose={closeReviewPop}
                        />
                    )}
            </div>
            <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openReportPopup ? 'active' : ''}`}>
                    {openReportPopup && (
                        <ReportSessionPopup
                            data={selectedItemData} 
                            noti={notifyRe} 
                            onclose={closeReportPopupF}
                        />
                    )}
            </div>
            <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openCancelSessionPopup ? 'active' : ''}`}>
                   
                        <CancelSessionPopup
                            data={selectedItemData}
                            onclose={closeCancelSessionPopup}
                            onOpenNew={openNewPopup} 
                            onSubmit={handleCancelSession} // Pass the function to open the new popup
                        />
                </div>
                <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ newPopupOpen2 ? 'active' : ''}`}>
                    {newPopupOpen2 && <ConfirmCancelSessionPopup data={selectedItemData} onclose={closeNewPopup} onSubmit={handleConfirmCancel}  dontCancel={closeNewAndOpenCancelPopup} noti={notifyC} />}
                </div>

  </div>) : <BadhboardSessionsEmpty/>
  


  return data
  
}

export default DashboardSessions
