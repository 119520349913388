import React, {useContext, useState, useEffect} from 'react';
import './mentee-profile.css';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ExperiencePop({onclose, noti}) {

    const { state, updateExperienceMentee, addExperienceMentee, deleteExperienceMentee } = useContext(AuthContext);

    const [addAnotherState, setAnotherState] = useState(false);
    const [openPopupIndex, setOpenPopupIndex] = useState(null);
    const [loading, setLoading] = useState(false);

    const [experienceData, setExperienceData] = useState({
        positionName: "",
        companyName: "",
        endDate: "",    
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        experianceId:""
    });

    // const startYearY = 1980;
    // const endYearY = 2040;
    // const years = Array.from({ length: endYearY - startYearY + 1 }, (_, index) => startYearY + index);
    const [years, setYears] = useState([]);
    const [availableEndYears, setAvailableEndYears] = useState([]);

    useEffect(() => {
        // Generate the list of years when the component mounts
        const startYearY = 1980;
        const endYearY = 2040;
        const yearsArray = Array.from(
        { length: endYearY - startYearY + 1 },
        (_, index) => startYearY + index
        );
        setYears(yearsArray);
    }, []);

    const calculateAvailableEndYears = (startYear) => {
        const currentYear = 2040;
        const endYears = [];
      
        if (startYear !== "") {
          for (let year = parseInt(startYear) + 1; year <= currentYear; year++) {
            endYears.push(year.toString());
          }
        }
      
        setAvailableEndYears(endYears);
      };

      const[posiError, setPosiError] = useState("");  
      const[compError, setCompError] = useState("");  
      const[startMError, setStartMError] = useState("");
      const[startYError, setStartYError] = useState("");
      const[posiError2, setPosiError2] = useState("");  
      const[compError2, setCompError2] = useState("");  
      const[startMError2, setStartMError2] = useState("");
      const[startYError2, setStartYError2] = useState("");
      const[endMError, setEndMError] = useState(""); 
      const [dimmed, setDimmed] = useState(experienceData?.endYear === null || experienceData?.endYear === undefined ? false : true);
      const [isRequired, setIsRequired] = useState(false);

      const toggleElementOpacity = () => {
            setDimmed(!dimmed);
        };

        useEffect(() => {
            setIsRequired(!dimmed);
        }, [dimmed]);

    const editAnotherExp = (id) =>{
        console.log('id ', id);
        const filtered = state.profile?.experience?.filter(exp => exp._id === id) || [];
        if(filtered.length){
        const experienceDataToUpdate = {
                experianceId: id,
                positionName: filtered?.[0]?.positionName || '',
                companyName: filtered?.[0]?.companyName || '',
                startMonth: new Date(filtered?.[0]?.from).getMonth() + 1 ,
                startYear: new Date(filtered?.[0]?.from).getFullYear(),
                endMonth: filtered?.[0]?.to ? new Date(filtered?.[0]?.to).getMonth() + 1 : null,
                endYear: filtered?.[0]?.to ? new Date(filtered?.[0]?.to).getFullYear(): null,
            }
            calculateAvailableEndYears(experienceDataToUpdate.startYear);
            setExperienceData(experienceDataToUpdate);
        }


       
        if (openPopupIndex === id) {
            setOpenPopupIndex(null); // Close the open pop-up
          } else {
            setOpenPopupIndex(id); // Open the clicked pop-up
          }
        setAnotherState(false);
    }

    const handleExperienceChange = (e) => {
        const { name, value } = e.target;
        setExperienceData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'startYear') {
            const startYear = parseInt(value);
            calculateAvailableEndYears(startYear);
          }

    };

    const deleteAnotherExp = async (id) => {
        const res = await deleteExperienceMentee({experianceId:id});
    }

    const onUpdateExperience = async (e) => {
        e.preventDefault()
        let data = {
            experience: {
                positionName: experienceData.positionName,
                companyName: experienceData.companyName,
                from: experienceData.startYear+'-'+experienceData.startMonth+'-01',  
                to: experienceData.endYear ? experienceData.endYear+'-'+experienceData.endMonth+'-01': null, 
            },
            experianceId: experienceData.experianceId
        }
        if (!experienceData.positionName.trim()) {
            setPosiError("Position is Required");
            return;
        }else if (experienceData.positionName.length < 3) {
            setPosiError("Position Name should be at least 3 characters");
            return;
        }else if (experienceData.positionName.length > 50){
            setPosiError("Position Name should not exceed 50 characters");
            return;
        }else {
            setPosiError('')
        }

        
        if (!experienceData.companyName.trim()) {
            setCompError("Company is Required");
            return;
        }else if (experienceData.companyName.length < 3) {
            setCompError("Company Name should be at least 3 characters");
            return;
        }else if (experienceData.companyName.length > 50){
            setCompError("Company Name should not exceed 50 characters");
            return;
        }else {
            setCompError('')
        }

        
        if(experienceData.startMonth.length === 0){
            setStartMError("Please select a month");
            return;
        }else {
            setStartMError('')
        }
        if(experienceData.startYear.length === 0){
            setStartYError("Please select a year");
            return;
        }else {
            setStartYError('')
        }

        // if(isRequired){
        //     setEndMError("Please select a value");
        //     return;
        // }else {
        //     setEndMError('')
        //     // setEndYError('')
        // }

        setLoading(true);
        const res = await updateExperienceMentee(data);
        if(res){
            commonState();
            setLoading(false);
            setDimmed(!dimmed);
        }
    }

    const onSaveExperience = async (e) => {
        e.preventDefault()
        let data = {
            experience: {
                positionName: experienceData.positionName,
                companyName: experienceData.companyName,
                from: experienceData.startYear+'-'+experienceData.startMonth+'-01',  
                to: experienceData.endYear ? experienceData.endYear+'-'+experienceData.endMonth+'-01': null, 
            },
        }
        if (!experienceData.positionName.trim()) {
            setPosiError2("Position is Required");
            return;
        }else if (experienceData.positionName.length < 3) {
            setPosiError2("Position Name should be at least 3 characters");
            return;
        }else if (experienceData.positionName.length > 50){
            setPosiError2("Position Name should not exceed 50 characters");
            return;
        }else {
            setPosiError2('')
        }


        if (!experienceData.companyName.trim()) {
            setCompError2("Company is Required");
            return;
        }else if (experienceData.companyName.length < 3) {
            setCompError2("Company Name should be at least 3 characters");
            return;
        }else if (experienceData.companyName.length > 50){
            setCompError2("Company Name should not exceed 50 characters");
            return;
        }else {
            setCompError2('')
        }


        if(experienceData.startMonth.length === 0){
            setStartMError2("Please select a month");
            return;
        }else {
            setStartMError2();
        }
        if(experienceData.startYear.length === 0){
            setStartYError2("Please select a year");
            return;
        }else {
            setStartYError2();
        }
        setLoading(true);
        const res = await addExperienceMentee(data);
        if(res){
            commonState();
            setLoading(false);
            setDimmed(!dimmed);
        }
    }


    const commonState = () => {
        setAnotherState(false);
        setOpenPopupIndex(null);
        setExperienceData({
            positionName: "",
            companyName: "",
            endDate: "",    
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            experianceId:""
        })
    }

    const handleCloseEdit = (e) => {
        e.preventDefault();
        commonState();
    }

    const handleCloseAdd = (e) => {
        e.preventDefault();
        commonState();
    }

    const addAnotherExp = () => {
        setAnotherState(true);
        setOpenPopupIndex(null);
        setExperienceData({
            positionName: "",
            companyName: "",
            endDate: "",    
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            experianceId:""
        })
    }
    const handleClick = () =>{
        setLoading(true);
        
        setTimeout(() => {
        setLoading(false);
        onclose();
        noti();
        }, 2000);
    }
    return (
        <div className="insage-login">
        <div className="insage-login-wrapper">
            <div className="login-head"><span>edit experience</span></div>
            <div className="overview-profile-pop-form">
            {state?.profile?.experience && state.profile?.experience?.map((exp) => {
                return <div className="experience-edit-box" key={exp?._id}>
                    <div className={ openPopupIndex === exp?._id ? 'experience-edit-box-wrap active flex align flex-jsb' : 'experience-edit-box-wrap flex align flex-jsb'}>
                        <div className="experience-edit-icon-text flex align">
                            <div className="experience-edit-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
                                <path d="M20.4997 9V15C20.4997 16.7001 19.1997 18 17.4997 18H3.49999C1.79994 18 0.5 16.7001 0.5 15V9C1.4 9.69994 2.39994 9.99994 3.49999 9.99994H17.5001C18.6 9.99994 19.5999 9.69994 20.4999 9H20.4997ZM0.5 5.49994C0.799999 4.10006 2 3 3.49999 3H6.49998C6.49998 1.29994 7.79992 0 9.49997 0H11.5C13.2001 0 14.5 1.29994 14.5 3H17.5C19 3 20.2999 3.99994 20.5 5.49994C20.2999 6.99994 18.9998 8.00006 17.4998 8.00006H3.50016C2.00017 8.00006 0.800171 6.9 0.500172 5.49994H0.5ZM8.49969 3H12.4996C12.4996 2.4 12.0997 2.00006 11.4997 2.00006H9.49963C8.8998 2.00006 8.49969 2.4 8.49969 3Z" fill="#FFA800"/>
                                </svg>
                            </div>
                            <div className="experience-edit-text flex flex-c">
                                <div className="experience-edit-text-head">{exp?.positionName}</div>
                                <div className="experience-edit-text-comp-year flex align">
                                    <span>{exp?.companyName}</span><span className='experience-edit-text-dot'></span>
                                    <span>
                                        {exp?.from && new Date(exp?.from).getFullYear()}{exp?.to && '-'+ new Date(exp?.to).getFullYear()}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="experience-edit-delete-btns flex">
                            <div className="experience-edit-delete-btn" onClick={() =>deleteAnotherExp(exp?._id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M1.75 3.5H2.91667H12.25" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.0807 3.4974V11.6641C11.0807 11.9735 10.9578 12.2702 10.739 12.489C10.5202 12.7078 10.2235 12.8307 9.91406 12.8307H4.08073C3.77131 12.8307 3.47456 12.7078 3.25577 12.489C3.03698 12.2702 2.91406 11.9735 2.91406 11.6641V3.4974M4.66406 3.4974V2.33073C4.66406 2.02131 4.78698 1.72456 5.00577 1.50577C5.22456 1.28698 5.52131 1.16406 5.83073 1.16406H8.16406C8.47348 1.16406 8.77023 1.28698 8.98902 1.50577C9.20781 1.72456 9.33073 2.02131 9.33073 2.33073V3.4974" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.83594 6.41406V9.91406" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.16406 6.41406V9.91406" stroke="#222222" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div onClick={() => editAnotherExp(exp?._id)} className="experience-edit-delete-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path d="M8.69469 1.42497C8.82942 1.29024 8.98937 1.18336 9.1654 1.11044C9.34144 1.03753 9.53011 1 9.72065 1C9.91119 1 10.0999 1.03753 10.2759 1.11044C10.4519 1.18336 10.6119 1.29024 10.7466 1.42497C10.8813 1.5597 10.9882 1.71965 11.0611 1.89568C11.134 2.07171 11.1716 2.26039 11.1716 2.45092C11.1716 2.64146 11.134 2.83014 11.0611 3.00617C10.9882 3.1822 10.8813 3.34215 10.7466 3.47688L3.82139 10.4021L1 11.1716L1.76947 8.35019L8.69469 1.42497Z" stroke="black" strokeWidth="1.02596" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className={ openPopupIndex === exp?._id ? 'experience-add-another-form active' : 'experience-add-another-form'}>
                            <div>
                                <div className="experience-add-another-form-wrap">
                                    <form action="post">
                                        <div className='login-form-input flex flex-c'>
                                            <label htmlFor="position">Position</label>
                                            {posiError && <p className="error-name-onb">{posiError}</p>}   
                                            <input placeholder='Ex. Civil Engineer' type="text" name="positionName" id="position" onChange={handleExperienceChange} value={experienceData.positionName}/>  
                                        </div>
                                        <div className='login-form-input flex flex-c'>
                                            <label htmlFor="company">Company name</label>
                                            {compError && <p className="error-name-onb">{compError}</p>}
                                            <input placeholder='Ex. L&T' type="text"  name="companyName" id="company" value={experienceData.companyName} onChange={handleExperienceChange}/>  
                                        </div>
                                        <div className='login-form-input flex flex-c'>
                                            <label htmlFor="start-date-month">Start Date</label>
                                            {startMError && <p className="error-name-onb">{startMError}</p>}
                                            {startYError && <p className="error-name-onb">{startYError}</p>}
                                            <div className="select-wrapper flex">
                                                <select
                                                id="start-date-month"
                                                name="startMonth"
                                                onChange={handleExperienceChange}
                                                value={experienceData.startMonth}
                                                required
                                                >
                                                <option value="" disabled>Month</option>
                                                <option value="1" >January</option>
                                                <option value="2" >February</option>
                                                <option value="3" >March</option>
                                                <option value="4" >April</option>
                                                <option value="5" >May</option>
                                                <option value="6" >June</option>
                                                <option value="7" >July</option>
                                                <option value="8" >August</option>
                                                <option value="9" >September</option>
                                                <option value="10" >October</option>
                                                <option value="11" >November</option>
                                                <option value="12" >December</option>
                                                </select>
                                                <select
                                                    id="start-date-year"
                                                    name="startYear"
                                                    onChange={handleExperienceChange}
                                                    value={experienceData.startYear}
                                                    required
                                                    >
                                                    <option value="" disabled>Year</option>
                                                    {years.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="is-present-checkbox">
                                                    <input type="checkbox" name="present" id="present" checked={dimmed} onChange={toggleElementOpacity} />
                                                    <label htmlFor="present">
                                                        Present
                                                    </label>
                                                </div>
                                            </div>  
                                        </div>
                                        <div className={dimmed ? 'login-form-input flex flex-c dimmed' : 'login-form-input flex flex-c'}>
                                            <label htmlFor="end-date-month">End Date</label>
                                            <div className="select-wrapper flex">
                                            <select
                                                id="end-date-month"
                                                name="endMonth"
                                                value={experienceData.endMonth}
                                                onChange={handleExperienceChange}
                                                >
                                                <option value="" disabled>Month</option>
                                                <option value="1" >January</option>
                                                <option value="2" >February</option>
                                                <option value="3" >March</option>
                                                <option value="4" >April</option>
                                                <option value="5" >May</option>
                                                <option value="6" >June</option>
                                                <option value="7" >July</option>
                                                <option value="8" >August</option>
                                                <option value="9" >September</option>
                                                <option value="10" >October</option>
                                                <option value="11" >November</option>
                                                <option value="12" >December</option>
                                                </select>
                                                <select
                                                id="end-date-year"
                                                name="endYear"
                                                value={experienceData.endYear}
                                                onChange={handleExperienceChange}
                                                >
                                                <option value="" disabled>Year</option>
                                                {availableEndYears.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                                </select>
                                            </div>  
                                        </div>
                                        <div className="experience-add-another-form-buttons flex align">
                                            <div className="experience-add-cancel-btn">
                                                <button className='primary-btn' onClick={handleCloseEdit}>Cancel</button>
                                            </div>
                                            <div className="experience-add-save-btn">
                                                <button className='primary-btn black' onClick={onUpdateExperience}>
                                                    {loading ? (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                        ) : (
                                                        <><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M3.75 1V7" stroke="white" strokeLinecap="square" strokeLinejoin="round"/><path d="M0.75 4H6.75" stroke="white" strokeLinecap="square" strokeLinejoin="round"/></svg>Save experience</>
                                                        )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                </div>
            })}
                <div className="experience-edit-box">
                    <div className="experience-edit-box-wrap flex align flex-jsb">
                        <div onClick={addAnotherExp} className={ addAnotherState ? "experience-add-another-btn active flex align": "experience-add-another-btn flex align"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <path d="M4 1V7" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                                <path d="M1 4H7" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>
                            add another
                        </div>
                        <div className={ addAnotherState ? 'experience-add-another-form active' : 'experience-add-another-form'}>
                            <div>
                                <div className="">
                                    <form action="post">
                                        <div className='login-form-input flex flex-c'>
                                            <label htmlFor="position">Position</label>
                                            {posiError2 && <p className="error-name-onb">{posiError2}</p>} 
                                            <input placeholder='Ex. Civil Engineer' type="text" name="positionName" id="position" onChange={handleExperienceChange} value={experienceData.positionName}/>  
                                        </div>
                                        <div className='login-form-input flex flex-c'>
                                            <label htmlFor="company">Company name</label>
                                            {compError2 && <p className="error-name-onb">{compError2}</p>}
                                            <input placeholder='Ex. L&T' type="text"  name="companyName" id="company" value={experienceData.companyName} onChange={handleExperienceChange}/>  
                                        </div>
                                        <div className='login-form-input flex flex-c'>
                                            <label htmlFor="start-date-month">Start Date</label>
                                            {startMError2 && <p className="error-name-onb">{startMError2}</p>}
                                            {startYError2 && <p className="error-name-onb">{startYError2}</p>}
                                            <div className="select-wrapper flex">
                                                <select
                                                id="start-date-month"
                                                name="startMonth"
                                                onChange={handleExperienceChange}
                                                value={experienceData.startMonth}
                                                required
                                                >
                                                <option value="" disabled>Month</option>
                                                <option value="1" >January</option>
                                                <option value="2" >February</option>
                                                <option value="3" >March</option>
                                                <option value="4" >April</option>
                                                <option value="5" >May</option>
                                                <option value="6" >June</option>
                                                <option value="7" >July</option>
                                                <option value="8" >August</option>
                                                <option value="9" >September</option>
                                                <option value="10" >October</option>
                                                <option value="11" >November</option>
                                                <option value="12" >December</option>
                                                </select>
                                                <select
                                                    id="start-date-year"
                                                    name="startYear"
                                                    onChange={handleExperienceChange}
                                                    value={experienceData.startYear}
                                                    required
                                                    >
                                                    <option value="" disabled>Year</option>
                                                    {years.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="is-present-checkbox">
                                                    <input type="checkbox" name="present" id="present" checked={dimmed} onChange={toggleElementOpacity} />
                                                    <label htmlFor="present">
                                                        Present
                                                    </label>
                                                </div>
                                            </div>  
                                        </div>
                                        <div className={dimmed ? 'login-form-input flex flex-c dimmed' : 'login-form-input flex flex-c'}>
                                            <label htmlFor="end-date-month">End Date</label>
                                            {endMError && <p className="error-name-onb">{endMError}</p>}
                                            <div className="select-wrapper flex">
                                            <select
                                                id="end-date-month"
                                                name="endMonth"
                                                value={experienceData.endMonth}
                                                onChange={handleExperienceChange}
                                                >
                                                <option value="" disabled>Month</option>
                                                <option value="1" >January</option>
                                                <option value="2" >February</option>
                                                <option value="3" >March</option>
                                                <option value="4" >April</option>
                                                <option value="5" >May</option>
                                                <option value="6" >June</option>
                                                <option value="7" >July</option>
                                                <option value="8" >August</option>
                                                <option value="9" >September</option>
                                                <option value="10" >October</option>
                                                <option value="11" >November</option>
                                                <option value="12" >December</option>
                                                </select>
                                                <select
                                                id="end-date-year"
                                                name="endYear"
                                                value={experienceData.endYear}
                                                onChange={handleExperienceChange}
                                                >
                                                <option value="" disabled>Year</option>
                                                {availableEndYears.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                                </select>
                                            </div>  
                                        </div>
                                        <div className="experience-add-another-form-buttons flex align">
                                            <div className="experience-add-cancel-btn" onClick={handleCloseAdd}>
                                                <button className='primary-btn'>Cancel</button>
                                            </div>
                                            <div className="experience-add-save-btn">
                                                <button className='primary-btn black' onClick={onSaveExperience}>
                                                {loading ? (
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                        ) : (
                                                        <><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M3.75 1V7" stroke="white" strokeLinecap="square" strokeLinejoin="round"/><path d="M0.75 4H6.75" stroke="white" strokeLinecap="square" strokeLinejoin="round"/></svg>Save experience</>
                                                        )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overview-profile-pop-btn">
                        <button onClick={handleClick} className='primary-btn'>
                        {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    'Submit'
                                )}
                         </button>
                    </div>
            </div>
        </div>
        <div className="login-close-button" onClick={onclose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
            <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
        </div>
    )
}
