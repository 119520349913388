import React, { useState, useContext } from 'react';
import './BlogInner.css';
import '../blogs/blogs.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import noImageAvailable from '../../assets/images/no-image-available.png';

function BlogInnercomment({props, openR, openC, noti, setBlogDetails, blogDetails}) {
  const [loading, setLoading] = useState(false);
  const [loadingCom, setLoadingCom] = useState(false);
  const [commentError, setCommentError] = useState('');
  const { addComment} = useContext(AuthContext);
  const [comment, setCommentData] = useState({
    blogId: props.blogId, 
    comment: ''
  });
  
  const handleCommentChange = (e) => {
    const { name, value } = e.target;
    setCommentData((prevData) => ({
        ...prevData,
        [name]: value,
    }));   
  }

  // console.log('props ', props);

  const fetchBlogDetails = (page=2) => {
    setLoadingCom(true);

    const token = Cookies.get('token');
    let userRole = Cookies.get('userRole');

    if(userRole){
        axios
            .get(`${process.env.REACT_APP_API_URL}/bloging/${props?.slug}?page=${page}`, {
                headers: {
                Authorization: `Bearer ${token}`, // Include the token in the headers
                },
            })
            .then((res) => {
              let mergedData = {...props, ...res.data.result , blogComments: [...props.blogComments, ...res.data.result.blogComments]}
              setBlogDetails({ ...blogDetails , result: mergedData})
              setLoadingCom(false);
            })


    }else {
        axios
        .get(`${process.env.REACT_APP_API_URL}/blog/${props?.slug}?page=${page}`)
        .then((res) => {
          let mergedData = {...props, ...res.data.result , blogComments: [...props.blogComments, ...res.data.result.blogComments]}
          setBlogDetails({ ...blogDetails , result: mergedData})
          setLoadingCom(false);
        })
    }
}

  const handleLoadMore = (page) => {
    fetchBlogDetails(Number(page)+1)
  }


  const postComment = async () => {
    try {
     
      setLoading(true);
  
      if (!comment.comment.trim()) {
         setLoading(false);
         setCommentError('Comment cannot be empty');
         return;
      }

      setCommentData({
        blogId: props.blogId, 
        comment: ''
      });
  
      const response = await addComment(comment);
  
      if(response){
        setLoading(false);
        noti();
        console.log('Comment posted:', response.data);
       
      }
     
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  };
  
  let token = Cookies.get('token');
  const isTokenAvailable = !!token;

  // Parse user details only if the token is available
  let userDetails = null;

  if (isTokenAvailable) {
    try {
      const profileCookie = Cookies?.get('profile');
      userDetails = profileCookie ? JSON.parse(profileCookie) : null;
    } catch (error) {
      console.error('Error parsing user details from cookie:', error);
    }
  }

  return (
    <div className="blogInnercomment">
      <div className='comment-wrap'>
        <div className='comment-top'>
          <h4>Comments</h4>
          <p>Please have a look at our <span onClick={openC}>community guidelines</span> before commenting.</p>
        </div>
        <div className='comment-bottom'>
        {commentError && <p className="error-name-onb">{commentError}</p>}
        {isTokenAvailable && (
          <div className='comment-addition'>
              <div className='commentBox'>
                <div className='commentBox-outer'>
                  <div className='commentBox-in'>
                    <div className='cbi-top'>
                      <div className='cbit-image'>
                        <img src={userDetails?.avatar ? userDetails?.avatar : noImageAvailable} />
                      </div>
                      <div className='cbit-name'>{userDetails?.fullName}</div>
                      
                    </div>
                    <div className='cbi-bottom'>
                      <div className='cbib-add'>
                          <textarea value={comment.comment} onChange={handleCommentChange} name="comment" placeholder='Write a comment...' rows={5}></textarea>
                      </div>
                      <div className='cbib-post'>
                        <div className='post-buttons'>
                          <button onClick={() => setCommentData({
        blogId: props?.blogId, 
        comment: ''
      })} className='clear'>Clear</button>
                          <button className='primary-btn green' onClick={postComment}>
                          {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    'Post'
                                )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
          <div className='comment-listing'>
              {props?.blogComments?.map((posts,index) => {
                  return (
                      <div className="item" key={index}>
                          <div className='comment-item'>
                            <div className='ci-top'>
                              <div className='ci-user-image'>
                                <img src={posts?.avatar} />
                              </div>
                              <div className='ci-user-details'>
                                <div className='ci-user-details-name'>{posts?.fullName} <span>{posts?.userType}</span></div>
                                <div className='ci-user-details-time'>{posts?.createdAt}</div>
                              </div>
                              <div onClick={() => openR(posts)} className="cbit-report">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4" fill="none">
                                  <path d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <div>
                                <span class="tooltiptext">report
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="6" viewBox="0 0 15 6" fill="none">
                                    <path d="M7.53906 6.11959e-07L13.6012 4.5L1.47688 4.5L7.53906 6.11959e-07Z" fill="#4E4E4E"/>
                                  </svg>
                                </span>
                                </div>
                            </div>
                            </div>
                            <div className='ci-bottom'>
                              <p>{posts?.comment}</p>
                            </div>
                          </div>
                          <div className='comment-report'>
                            <div className='cr-dots'></div>
                          </div>
                      </div>
                  );
              })}

{props?.hasMorePages && <div className='loadmore-btn'><div className='primary-btn black' onClick={() => handleLoadMore(props?.currentPage)}>{loadingCom ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    'Load More Comments'
                                )}</div></div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogInnercomment;