import React, {useState, useRef, useEffect} from 'react';
import '../reusable/session-cards/dashSession.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import starFilled from '../../assets/images/star-filled.svg'
import starEmpty from '../../assets/images/star-empty.svg'

// export default function MentorDashReviewsCard ({post,index}) {
    export default function MentorDashReviewsCard ({post,index}) {
    const [activeCard, setActiveCard] = useState(-1); // Initialize to -1 to ensure no card is active by default

    const toggleFAQ = (index) => {
        setActiveCard(index === activeCard ? -1 : index);
    };

    const cardClass = index === activeCard ? 'session-list-card-in active' : 'session-list-card-in';

    const starsFilled = post?.review;

    const stars = [];
    const approachability = [];
    const contentDelivery = [];
    const valueForMoney = [];
    const critera04 = [];

    for (let i = 0; i < 5; i++) {
        if (i < starsFilled) {
            stars.push(<li key={i}><img src={starFilled} alt="filled star"/></li>);
        } else {
            stars.push(<li key={i}><img src={starEmpty} alt="empty star"/></li>);
        }
    }

    const approachabilityFilled = post?.allReview?.approachability;
    const contentDeliveryFilled = post?.allReview?.contentDelivery
    const valueForMoneyFilled = post?.allReview?.valueForMoney
    const critera04Filled = post?.allReview?.critera04

    for (let i = 0; i < 5; i++) {
        if (i < approachabilityFilled) {
            approachability.push(<li key={i}><img src={starFilled} alt="filled star"/></li>);
        } else {
            approachability.push(<li key={i}><img src={starEmpty} alt="empty star"/></li>);
        }
    }

    for (let i = 0; i < 5; i++) {
        if (i < contentDeliveryFilled) {
            contentDelivery.push(<li key={i}><img src={starFilled} alt="filled star"/></li>);
        } else {
            contentDelivery.push(<li key={i}><img src={starEmpty} alt="empty star"/></li>);
        }
    }

    for (let i = 0; i < 5; i++) {
        if (i < valueForMoneyFilled) {
            valueForMoney.push(<li key={i}><img src={starFilled} alt="filled star"/></li>);
        } else {
            valueForMoney.push(<li key={i}><img src={starEmpty} alt="empty star"/></li>);
        }
    }

    for (let i = 0; i < 5; i++) {
        if (i < critera04Filled) {
            critera04.push(<li key={i}><img src={starFilled} alt="filled star"/></li>);
        } else {
            critera04.push(<li key={i}><img src={starEmpty} alt="empty star"/></li>);
        }
    }

  return ( 
    <div className='session-list-card'>
        <div className={cardClass}>
            <div className='slci-main'>
                <div className='slci-head sh-withimage'>
                    <div className='slcihead-image'>
                        <img src={post?.menteeAvatar}/>
                    </div>
                    <div className='slcihead-content'>
                        <div className='slcih-top'>
                            <div className='sl-top-name'>Mentorship session with <span>{post?.menteeName}</span></div>
                            <div className='details-btn' onClick={() => toggleFAQ(index)}>Details 
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="6" viewBox="0 0 4 6" fill="none">
                                        <path d="M1 5L3 3L1 0.999999" stroke="#444444" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className='slcih-bottom'>
                            <div className='slb-date'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                        <path d="M9.5 2.5H2.5C1.94772 2.5 1.5 2.94772 1.5 3.5V10.5C1.5 11.0523 1.94772 11.5 2.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V3.5C10.5 2.94772 10.0523 2.5 9.5 2.5Z" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4 1.5V3.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1.5 5.5H10.5" stroke="#B4B4B4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1.5 3.75V4.5C1.5 5.05228 1.94772 5.5 2.5 5.5H9.25C9.80229 5.5 10.25 5.05228 10.25 4.5V3.75C10.25 3.19772 9.80229 2.75 9.25 2.75H2.5C1.94772 2.75 1.5 3.19772 1.5 3.75Z" fill="#B4B4B4"/>
                                    </svg>
                                </span> 
                                {post?.sessionDate}
                            </div>
                            <div className='slb-time'>
                            {post?.menteeName} rated you
                                <span className='star-box'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                                        <path d="M4.5 0L5.8905 2.96215L9 3.44006L6.75 5.74448L7.281 9L4.5 7.46215L1.719 9L2.25 5.74448L0 3.44006L3.1095 2.96215L4.5 0Z" fill="#C39D00"/>
                                    </svg>
                                    {post?.review}
                                </span> 
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='slci-content no-margin'>
                    <div className='slci-content-in'>
                        
                        <div className='slci-in-content'>
                            <div className='slciic-topic'>
                                <h6 className='micro'>Discussion Topic</h6>
                                <p>{post?.agenda}</p>
                            </div>
                            <div className='slciic-detailed'>
                                <h6 className='micro'>Detailed Session Agenda</h6>
                                <p>{post?.message}</p>
                            </div>
                            <div className='slciic-reviews'>
                                <h6 className='micro'>
                                    My Reviews
                                    <ul className='review-stars'>
                                    {stars}
                                    </ul>
                                </h6>
                                <div className='reviewBox'>
                                    <div className='re-in'>
                                        <div className='rb-defined'>
                                            <div className='rb-defined-in'>
                                                <div className='rbd-tag'>approachability</div>
                                                <div className='rbd-stars'>
                                                    <ul className='review-stars'>
                                                      {approachability}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='rb-defined-in'>
                                                <div className='rbd-tag'>content delivery</div>
                                                <div className='rbd-stars'>
                                                    <ul className='review-stars'>
                                                       {contentDelivery}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='rb-defined-in'>
                                                <div className='rbd-tag'>value for money</div>
                                                <div className='rbd-stars'>
                                                    <ul className='review-stars'>
                                                        {valueForMoney}
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className='rb-defined-in'>
                                                <div className='rbd-tag'>criteria 04</div>
                                                <div className='rbd-stars'>
                                                    <ul className='review-stars'>
                                                       {critera04}
                                                    </ul>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className='rb-content'>
                                            <p>{post?.allReview?.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='slci-foot'>
                    {post?.bookingStatus === "Upcoming" ? <div className='foot-upcoming'>
                        <div className='foot-ques'>Not able to make it to your session?</div>
                        <div className='foot-cancel'>
                            <button className='cancel-text'>Cancel Session</button>
                        </div>
                    </div> : <div className='foot-completed'>
                        <div className='foot-ques'>Good feedback helps you improve your session experience.</div>
                    </div>}
                </div>
            </div>

            {/* <div className={post?.bookingStatus === "Upcoming" ? 'session-tag upcoming' : post?.bookingStatus === "Completed" ?  'session-tag completed' : post?.bookingStatus === "Cancelled" ?  'session-tag cancelled': "session-tag"}>
                <span>{post?.bookingStatus}</span>
            </div> */}
        </div>
    </div>
  )
}
