import React, { useContext, useState } from 'react';
import './dashboardPopup.css';
import MultiSelectTags from '../reusable/multi-select/multiSelect';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import axios from 'axios';

export default function ConfirmCancelSessionPopup ({onclose, data, noti }) {
  
  const {cancelReason, setCancelReason} = useContext(AuthContext);
  const {additionalDetails, setAdditionalDetails} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const handleCancel = (event) => {
        event.preventDefault();
        setLoading(true);
        const userToken = Cookies.get('token');
        let bookingId = data.bookingId; 
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/mentee/cancel-session`,
            {
              bookingId,
              reason:cancelReason,
              details:additionalDetails
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {
        
            if(res){
              // const updatedData = data.filter(session => session.bookingId !== bookingId);
              setLoading(false);
              noti();
              onclose();
            }
          })
          .catch((err) => {
           
          })
          .finally(() => {
            setLoading(false);
          });
      };

      console.log('cancelData',additionalDetails);
    return (
        <div className="insage-login dashboard-popups">
            <div className="insage-login-wrapper">
                <div className="login-head"><span>sure you want to cancel?</span></div>
                <div className='popup-description'>
                    <p className='pd-para'>If you’re cancelling the session within 48hrs of the session time you won’t get a refund for the session cancellation.</p>
                </div>

                <div className="overview-profile-pop-form no-border">
                    <form  onSubmit={handleCancel} action="post">
                        
                        <div className="overview-profile-pop-btn flexbuttons">
                            <button className='popup-btn line-btn' onClick={() => onclose()}>
                                Don’t cancel
                            </button>
                            <button className='popup-btn cancel-btn'>
                            {loading ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                                  ) : (
                                  'cancel anyway'
                                  )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="login-close-button" onClick={() => onclose()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"/>
                    <path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}
