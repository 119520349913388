import React from 'react';
import './error.css';
import ErrorSvg from '../../assets/images/error-svg.svg';

export default function ErrorTemplate() {
  return (
   <section className="error-template">
      <div className="error-template-wrapper">
        <div className="error-template-image">
            <img src={ErrorSvg} alt="" />
        </div>
        <div className="error-template-heading">
            <h1>Looks like you are lost</h1>
        </div>
        <div className="error-template-body">
            <p>We can’t seem to fing the page you are looking for. We will help you back to the homepage now.</p>
        </div>
        <div className="error-template-button">
            <a href="/" className="primary-btn black">back to homepage</a>
        </div>
      </div>
   </section>
  )
}
