import React, { useContext, useEffect } from 'react';
import './profile.css';
import TrmCard from '../reusable/trm-card/TrmCard';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../ErrorMessage';
import FavouriteEmpty from './FavouriteEmpty';
import Loader from '../reusable/loading/Loader';


export default function FavouriteMentors() {

  const { getFavoriteMentor, state } = useContext(AuthContext);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //         const res = await getFavoriteMentor();
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };
  // fetchData();
  // },[]);

  console.log(' state?.favoriteMentor ',  state?.favoriteMentor);

  const data = state?.favoriteMentor?.length > 0 ? (
  <div className="fav-mentors-full dashboard-empty-state">
  {state.loading && <Loader/>}
  {state.error && <ErrorMessage errorData={state.error} />}
      <div className="dash-empty-sessions-content">
                <div className="dash-empty-sess-head">Favorite Mentors</div>
                <div className="dash-empty-sess-body"><p>Find your Favourite mentors here</p></div>              </div>
      <div className="favourite-mentors-list-grid">
        {state?.favoriteMentor && state?.favoriteMentor?.map((data) => {
          return <TrmCard post={data} key={data.mentorId}/>;
        })}
        </div>     
        {/* <div className="favourite-mentors-list-button">
        <a href="#!" className="isg-guide-load-more">load more</a>
        </div>  */}
</div>) : <FavouriteEmpty />


    return data;
}
