import React, {useRef} from 'react';
import './usecases.css';
import { useInView } from "framer-motion";

export default function ProfessionalNetworking({props}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });  
    const data = props;
  return (
    <section className="usecases-professional-networking">
        <div className="container">
            <div className="usecases-pro-network-wrapper flex align">
                <div className="usecases-pro-network-left">
                    <div className="usecases-pro-network-left-wrapper">
                        <div className="pro-network-heading"><h3 ref={ref} style={{
                  transform: isInView ? "translateY(0%)" : "translateY(100%)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{data?.networkHead}</h3></div>
                        <div className="pro-network-body"><p ref={ref} style={{
                  transform: isInView ? "translateY(0%)" : "translateY(100%)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }}>{data?.networkBody}</p></div>
                    </div>
                </div>
                <div className="usecases-pro-network-right">
                <div className="usecases-pro-network-right-wrapper flex flex-jse">
                    <div style={{
                   clipPath: isInView ? "inset(0 0 0 0)" : "inset(0 100% 0 0)",
                  opacity: isInView ? 1 : 0,
                  transition: ".9s cubic-bezier(.51,.01,0,1.06)",
                }} className="pro-network-main-image">
                        <img src={data?.networkImg} alt="" className="img" />
                        <div className="pro-network-data">
                            <div className="pro-network-data-wrapper">
                                <div className="pro-network-numbers">
                                    <div className='pnn-number'>30+</div>
                                    <div className='pnn-number-word'>mentors</div>
                                </div>
                                <svg width="120" height="100" viewBox="0 0 120 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.5">
                                        <path d="M192.503 78.1544H126.897L173.289 31.7623L155.34 13.8125L96.2543 72.8979L37.1689 13.8125L19.2191 31.7623L65.6112 78.1544H0V103.541H65.6112L19.2191 149.933L37.1689 167.883L96.2543 108.797L155.34 167.883L173.289 149.933L126.897 103.541H192.503V78.1544Z" fill="#FFCABE"/>
                                        <path d="M96.2523 25.9891C105.987 25.9891 113.88 18.0967 113.88 8.36172C113.88 -1.37324 105.987 -9.26562 96.2523 -9.26562C86.5174 -9.26562 78.625 -1.37324 78.625 8.36172C78.625 18.0967 86.5174 25.9891 96.2523 25.9891Z" fill="#FFCABE"/>
                                        </g>
                                </svg>
                            </div>
                        </div>
                        <div className="pro-network-views">
                            <div className="pro-network-views-wrapper flex align">
                                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.6364 13.9972V12.5529C12.6364 11.7867 12.3299 11.0519 11.7843 10.5102C11.2388 9.96842 10.4988 9.66406 9.72727 9.66406H3.90909C3.13755 9.66406 2.39761 9.96842 1.85205 10.5102C1.30649 11.0519 1 11.7867 1 12.5529V13.9972" stroke="#676767" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.81925 6.77758C8.42589 6.77758 9.72834 5.48423 9.72834 3.88879C9.72834 2.29336 8.42589 1 6.81925 1C5.2126 1 3.91016 2.29336 3.91016 3.88879C3.91016 5.48423 5.2126 6.77758 6.81925 6.77758Z" stroke="#676767" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M17.0021 13.9971V12.5527C17.0016 11.9127 16.7871 11.2909 16.3922 10.785C15.9973 10.2791 15.4444 9.91783 14.8203 9.75781" stroke="#676767" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.9102 1.09375C12.5359 1.25285 13.0905 1.61424 13.4866 2.12094C13.8827 2.62764 14.0977 3.25083 14.0977 3.89227C14.0977 4.5337 13.8827 5.15689 13.4866 5.66359C13.0905 6.17029 12.5359 6.53168 11.9102 6.69078" stroke="#676767" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                               <div className="pro-network-views-text">
                                 70+ Users online
                               </div>
                            </div>
                        </div>
                    </div>
                    <div className="pro-network-thumb-images flex flex-c">
                        <div className="pro-network-thumb-images-image">
                            <img src={data?.networkImg2} alt="" className="img" />
                        </div>
                        <div className="pro-network-thumb-images-image">
                            <img src={data?.networkImg3} alt="" className="img" />
                        </div>
                        <div className="pro-network-thumb-images-image">
                            <img src={data?.networkImg4} alt="" className="img" />
                        </div>
                        <div className="pro-network-thumb-images-image">
                            <img src={data?.networkImg5} alt="" className="img" />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
  )
}
