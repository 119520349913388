
import React, { useEffect, useRef, useState } from 'react';
import PopularCards from './PopularCards';
import "./all-mentors.css";
import axios from 'axios';
import Loader from '../reusable/loading/Loader';
import NoSesh from '../../assets/images/no-sesh.svg';


const AllMentors = ({props,setAllMentorData, scrollToSecondComponent, loadMoreDisplay, setLoadMoreDisplay, handleFilter, filterCat, loadingState}) => {

  const [selectedOption, setSelectedOption] = useState(filterCat? filterCat:'');
  const [selectedOption1, setSelectedOption1] = useState('');
  const [selectedOption2, setSelectedOption2] = useState('');
  const [selectedOption3, setSelectedOption3] = useState('');
  const [query, setQuery] = useState('');
  

  const [showDropdown, setShowDropdown] = useState(false);
  const [industry, setIndustry] = useState();

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [country, setCountry] = useState();

  const [showDropdown2, setShowDropdown2] = useState(false);
  const [levelOfExp, setLevelOfExp] = useState();

  const [showDropdown3, setShowDropdown3] = useState(false);
  const [sortValue, setSortValue] = useState('highestRated');

  const [countryOptions, setCountryOptions] = useState([]);

  const [page, setPage] = useState(1)


  const dropdownRef = useRef();

  const dropdownOptions = [
    'Art & Design',
    'Coding & Dev',
    'Marketing',
    'IT & Software',
    'Personal Development',
    'Business & Sales',
    'Photography',
    'Management',
    'Other'
  ];
  
  const dropdownOptionsCon = [
    'India',
    'United Arab Emirates'
  ]

  const dropdownOptions2 = [
    '0-3 Years',
    '3-6 Years',
    '6-10 Years',
    '10-15 Years',
    '15+ Years',
  ];
  const dropdownOptions3 = [
    {value:"highestRated",lable:  'Highest Rated'},
    {value:"priceLowToHigh",lable:  'Price Low to High'},
    {value:"priceHighToLow",lable:  'Price High to Low'}

  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


      

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    setShowDropdown1(false);
    setShowDropdown2(false);
    setShowDropdown3(false);
  };
  const toggleDropdown1 = () => {
    setShowDropdown1(!showDropdown1);
    setShowDropdown(false);
    setShowDropdown2(false);
    setShowDropdown3(false);
  };
  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2);
    setShowDropdown(false);
    setShowDropdown1(false);
    setShowDropdown3(false);
  };
  const toggleDropdown3 = () => {
    setShowDropdown3(!showDropdown3);
    setShowDropdown(false);
    setShowDropdown1(false);
    setShowDropdown2(false);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowDropdown(false);
    setShowDropdown1(false);
    setShowDropdown2(false);
    setShowDropdown3(false);
    setIndustry(option)
    setPage(1)
    handleFilter(option, country, levelOfExp, sortValue, 1, query);
  };
  const handleOptionSelect1 = (option) => {
    setSelectedOption1(option);
    setShowDropdown(false);
    setShowDropdown1(false);
    setShowDropdown2(false);
    setShowDropdown3(false);
    setCountry(option);
    setPage(1)
    handleFilter(industry, option, levelOfExp, sortValue, 1, query);
  };
  const handleOptionSelect2 = (option) => {
    setSelectedOption2(option);
    setShowDropdown(false);
    setShowDropdown1(false);
    setShowDropdown2(false);
    setShowDropdown3(false);
    setLevelOfExp(option);
    setPage(1)
    handleFilter(industry, country, option, sortValue, 1, query);
  };
  const handleOptionSelect3 = (option) => {
    setSelectedOption3(option);
    setShowDropdown(false);
    setShowDropdown1(false);
    setShowDropdown2(false);
    setShowDropdown3(false);
    setPage(1)
    setSortValue(option);
    handleFilter(industry, country, levelOfExp, option, 1, query);
 
    // if(option == 'newest'){
    //   setSortValue(-1);
    //   handleFilter(industry, country, levelOfExp, -1, 1, query);
    // }else {
    //   setSortValue(1);
    //   handleFilter(industry, country, levelOfExp, 1, 1, query);
    // }
  };


  const handleLoadMore = async () => {
    let incressPage = page+1;
    let cat = selectedOption ? selectedOption : industry;
    setPage(incressPage)
    handleFilter(cat, country, levelOfExp, sortValue, incressPage, query);
  }



  const handleSearchFunction  = async (e) => {
    e.preventDefault();
    setQuery(e.target.value)
    if(e.target.value.length >= 1){
      setTimeout(() => {
        handleFilter(industry, country, levelOfExp, sortValue, 1, e.target.value);
      }, 1000);
      
    }else{
      setTimeout(() => {
        handleFilter();
      }, 1000);
    }

  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setShowDropdown1(false);
        setShowDropdown2(false);
        setShowDropdown3(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const targetElementRef = useRef(null);

  const scrollToElement = () => {
    if (targetElementRef.current) {
      targetElementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const allMentorList = props;

  return (
    <section className='allMentors' id="targetElementId" ref={targetElementRef}>
      <div className="all-mentors-filters" ref={dropdownRef} >
                <div className="sort-by-search">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.92658 10.8547C3.19801 10.8547 1 8.65636 1 5.92736C1 3.19836 3.19801 1 5.92658 1C8.65515 1 10.8532 3.19836 10.8532 5.92736C10.8532 8.65636 8.65515 10.8547 5.92658 10.8547ZM5.92658 1.75806C3.61488 1.75806 1.75794 3.61529 1.75794 5.92736C1.75794 8.23943 3.61488 10.0967 5.92658 10.0967C8.23828 10.0967 10.0952 8.23943 10.0952 5.92736C10.0952 3.61529 8.23828 1.75806 5.92658 1.75806Z" fill="#24584B" stroke="#24584B" stroke-width="0.5"/>
                  <path d="M9.5969 9.05859L13 12.4623L12.4642 12.9982L9.06104 9.59454L9.5969 9.05859Z" fill="#24584B" stroke="#24584B" stroke-width="0.5"/>
                  </svg>
                  <input type="text" placeholder='Search by company, role or language' value={query}
                  onChange={(e) => handleSearchFunction(e)} />
                </div>
                <div className="sort-by-dropdown modified">
                  <label htmlFor="levelofexp" className="dropdown-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none" className="sort-icon">
                      <path d="M4 5.71875V11.3438" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M7 8.71875L4 11.7188L1 8.71875" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M15 8.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15 5.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 11.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* country:{' '} */}
                  </label>
                  <select
                    id="levelofexp"
                    value={selectedOption}
                    onChange={(e) => handleOptionSelect(e.target.value)}
                  >
                    <option value="">&#160;&#160;Select Industry</option>
                    {dropdownOptions.map((option) => (
                      <option key={option} value={option}>
                        &#160;&#160;{option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="sort-by-dropdown modified">
                  <label htmlFor="countrySelect" className="dropdown-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none" className="sort-icon">
                      <path d="M4 5.71875V11.3438" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M7 8.71875L4 11.7188L1 8.71875" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M15 8.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15 5.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 11.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* country:{' '} */}
                  </label>
                  <select
                    id="countrySelect"
                    value={selectedOption1}
                    onChange={(e) => handleOptionSelect1(e.target.value)}
                  >
                    <option value="">&#160;&#160;Select Country</option>
                    {dropdownOptionsCon.map((option) => (
                      <option key={option} value={option}>
                        &#160;&#160;{option}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="sort-by-dropdown modified">
                  <label htmlFor="levelofexp" className="dropdown-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none" className="sort-icon">
                      <path d="M4 5.71875V11.3438" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M7 8.71875L4 11.7188L1 8.71875" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M15 8.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15 5.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 11.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* country:{' '} */}
                  </label>
                  <select
                    id="levelofexp"
                    value={selectedOption2}
                    onChange={(e) => handleOptionSelect2(e.target.value)}
                  >
                    <option value="">&#160;&#160;Select level of exp</option>
                    {dropdownOptions2.map((option) => (
                      <option key={option} value={option}>
                        &#160;&#160;{option}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="sort-by-dropdown modified">
                  <label htmlFor="levelofexp" className="dropdown-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none" className="sort-icon">
                      <path d="M4 5.71875V11.3438" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M7 8.71875L4 11.7188L1 8.71875" stroke="#555555" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round" />
                      <path d="M15 8.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M15 5.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 11.5H10" stroke="#555555" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* country:{' '} */}
                  </label>
                  <select
                    id="levelofexp"
                    value={selectedOption3}
                    onChange={(e) => handleOptionSelect3(e.target.value)}
                  >
                    <option value="">&#160;&#160;Sort By</option>
                    {dropdownOptions3.map((option) => (
                      <option key={option.value} value={option.value}>
                        &#160;&#160;{option.lable}
                      </option>
                    ))}
                  </select>
                </div>
      </div>
      <div className='custom-container-fluid'>
      {loadingState && <Loader/>}
              {allMentorList?.result?.allMentor?.length > 0 ? (
                 <div className='mentorGrid'>
                {allMentorList.result.allMentor.map((post, index) => (
                    <PopularCards key={index} post={post} loading={loadingState} />
                ))}
                </div>
          ) : (
        <div className='noresultsfound mentorFilterNotFound'>
            <div className="dash-emp-no-sess">
              <div className="dash-emp-no-sess-wrap">
                  <div className="dash-emp-no-sess-img">
                      <img src={NoSesh} alt="" />
                  </div>
                  <div className="dash-emp-no-sess-head">No results found</div>
                  <div className="dash-emp-no-sess-body">
                      <p> Oops! It seems like there are no mentor that match your selected filters. Try adjusting your filters or explore our other mentors.</p>
                  </div>
                  
              </div>
            </div>
        </div>
          )}

        
        {loadMoreDisplay && <div className='loadmore-btn'><div className='primary-btn black' onClick={handleLoadMore}>Load More Mentors</div></div>}
      </div>
    </section>
  )
}

export default AllMentors