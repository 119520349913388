import React, { useCallback, useState, useContext, useEffect } from 'react';
import './profile.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DashEmpty from './DashEmpty';
import Messages from './Messages';
import FavouriteEmpty from './FavouriteEmpty';
import FavouriteMentors from './FavouriteMentors';
import Settings from './Settings';
import { AuthContext } from '../../context/AuthContext';
import ErrorMessage from '../ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChatContext } from '../../context/ChatContext';
import Loader from '../reusable/loading/Loader';
import DashboardSessions from '../sessions/DashboardSessions';
import DashFilled from './DashFilled';
import Avatar from '../../assets/images/avatar.png';
import SavedResources from '../resources/SavedResources';
import MentorCommunity from '../myCommunity/MentorCommunity';
import axios from 'axios'
import Cookies from 'js-cookie';
import actionTypes from '../../context/actionTypes';
import { getRequest } from '../../context/api';
import ConfirmLogoutPopup from '../reusable/popups/ConfirmLogoutPopup';

export default function Dashboard() {

   

    const { getUserDashboardInfo,getUserProfileInfo,showPayemntSuccessPopUp,getMenteePurchaseHistory, getMenteeSessions ,state , logout, verifyTokenFunction, dispatch, getFavoriteMentor, getSavedResources, getCommunityCards} = useContext(AuthContext);
    const {getUserChats, dashTabIndex} = useContext(ChatContext);
    const [selectedIndex, setSelectedIndex] = useState(dashTabIndex);
    const [openLogoutPopup, setopenLogoutPopup] = useState(false);
    

    const [isActive, setIsActive] = useState(false);

    const header = document.querySelector('header');

    const addClass = () => {
        setIsActive(!isActive);
        document.body.classList.add('popup-open');
        header.classList.add('active');
    };

    const removeClass = () => {
        setIsActive('');
        document.body.classList.remove('popup-open');
        header.classList.remove('active');
    };

    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to top on tab change
        window.scrollTo(0, 0);
    }, [selectedIndex]);

    console.log('dashTabIndex ', dashTabIndex);

    useEffect(() => {
        if(dashTabIndex == 1){
            getUserChats();
        }
    }, [dashTabIndex, showPayemntSuccessPopUp])

    useEffect(() => {
        if(selectedIndex == 5){
            handleCommunityPost();
        }
    }, [selectedIndex])

    useEffect(() => {
        if(selectedIndex == 1){
            handleGetUserChats();
        }
    }, [selectedIndex])

    useEffect(() => {
        const fetchData = async () => {
            try {

                const verfyToken = await verifyTokenFunction();
                const res = await getUserDashboardInfo();
                const resProfile = await getUserProfileInfo();  // using this messge tab too
                // console.log('Details of Dashboard' + res);

                if(res == false){
                    logout();
                    navigate('/'); 
                }

                // const resProfile = await getUserProfileInfo();
                //const resSession = await getMenteeSessions();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const post = state?.dashboard;
    // console.log(post,"This is data dashboard");

    function getFirstLetter(str) {
        if (str?.length === 0) return str;
            return str.charAt(0);
        }

    const handleProfileClick = () => {
        navigate('/mentee/profile'); 
    }
    const openPopup = () =>{
        document.getElementById('customPopup').style.display = 'block';
    }
    const closePopup = () =>{
        document.getElementById('customPopup').style.display = 'none';
    }

    const handleSelect = (index) => {
        setSelectedIndex(index);
      };
    
      const handleSessionClick = () => {
        setSelectedIndex(2);
      }
    const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" fill="#299F60"/>
                <path d="M12.2218 5.09766L6.41623 10.9032L3.77734 8.26432" stroke="white" strokeWidth="1.05556" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="0.527778" y="0.527778" width="14.9444" height="14.9444" rx="7.47222" stroke="#299F60" strokeWidth="1.05556"/>
                </svg>
          <span>Success</span>
            </div>
            <div className="custom-toast-bot">
              <span>Password Changed Successfully</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container',
        }
      );

    const avatar = post?.avatar === undefined || post?.avatar == null ? Avatar : post?.avatar;

    const handleHome = async () => {
        verifyTokenFunction();
        await getUserDashboardInfo();
        dispatch({ type: actionTypes.REMOVE_ERROR });
    }
    const handleGetUserChats = () => {
        verifyTokenFunction();
        dispatch({ type: actionTypes.REMOVE_ERROR });
        getUserChats()
    }
    const handleSession = () => {
        verifyTokenFunction();
        dispatch({ type: actionTypes.REMOVE_ERROR });
    }
    const handleFavoriteMentors = () => {
        verifyTokenFunction();
        getFavoriteMentor();
        dispatch({ type: actionTypes.REMOVE_ERROR });
    }
    const handleSavedResource = async () => {
        verifyTokenFunction();
        await getSavedResources();
        dispatch({ type: actionTypes.REMOVE_ERROR });
    }
    const handleCommunityPost = () => {
        verifyTokenFunction();
        getCommunityCards()
        dispatch({ type: actionTypes.REMOVE_ERROR });
    }
    const handleSetting = async () => {
        verifyTokenFunction();
        dispatch({ type: actionTypes.REMOVE_ERROR });
    }
    //Open Logout Popup
    const handleopenLogoutPopup = () => {
        setopenLogoutPopup(true);
        document.body.classList.add('popup-open');
    };
    //Close Logout Popup
    const handlecloseLogoutPopup = () => {
        setopenLogoutPopup(false);
        document.body.classList.remove('popup-open');
    };

    return (
    <> 
    <section className='profile-dashboard'>
        <Tabs selectedIndex={selectedIndex} onSelect={handleSelect}>
            <div className="profile-dashboard-wrapper flex">
                <div className="profile-dashboard-left">
                    <div className="profile-dashboard-left-wrapper">
                    {state.loading && <Loader/>}
                        {/* {state.error && <ErrorMessage errorData={state.error} />} */}
                        <div className="profile-name-display-wrapper flex align flex-jsb">
                        
                            <div className="prof-name-disp-left flex align" onClick={handleProfileClick}>
                                <div className="profile-name-photo">
                                <img src={avatar} alt='avatar' />
                                </div>
                                
                                <div className="profile-name-edit-profile-wrap flex flex-c">
                                    <div className="profile-name-text">{post?.fullName}</div>
                                    <div className="profile-name-btn">
                                        <span>Update profile</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="prof-name-disp-right">
                                <div className="prof-name-disp-fill"></div>
                                <div className="prof-name-disp-percentage">70%</div>
                            </div> */}
                        </div>
                        <div className={`profile-dashboard-tabs ${isActive ? 'toggledClass' : ''}`}>
                        <div className='rt-mob-details'>
                                <div className='rtmd-left'>Dashboard Navigation</div>
                                <div className='rtmd-right'>
                                <div class="login-close-button" onClick={removeClass}><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none"><path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#F2F2F2"></path><path d="M17.2272 10.7656L10.7656 17.2272" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.7656 10.7656L17.2272 17.2272" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"></path></svg></div>
                                </div>
                            </div>
                            <TabList>
                                <Tab onClick={removeClass}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M3 7.97817C3 7.36099 3.28495 6.77838 3.77212 6.39946L10.7721 0.955018C11.4943 0.39329 12.5057 0.393291 13.2279 0.955019L20.2279 6.39946C20.7151 6.77838 21 7.36099 21 7.97817V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V7.97817Z"/>
                                        <path d="M10 20.6016V16.6016H14V20.6016" fill="#FBFBFB"/>
                                        <path d="M10 20.6016V16.6016H14V20.6016" stroke="#FBFBFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div onClick={handleHome}>Home</div>
                                </Tab>
                                <Tab onClick={removeClass}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M22.1411 0.199219L14.193 22.908L9.65125 12.6891L0 8.71503L22.1411 0.199219Z" fill="#C0C0C0"/>
                                        <path d="M9.35938 12.5195L21.2271 0.761209L22.1523 0.185939L21.576 1.10911L9.85044 13.0139L9.60491 12.7667L9.35938 12.5195Z" fill="#FDFDFD"/>
                                    </svg>
                                    <div onClick={handleGetUserChats}>messages</div>
                                </Tab>
                                <Tab onClick={removeClass}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#C0C0C0" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 6V12L16 14" stroke="#FFFDFF" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div onClick={handleSession}>sessions</div>
                                </Tab>
                                <Tab onClick={removeClass}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M20.4578 4.67974C19.9691 4.14721 19.3889 3.72477 18.7503 3.43656C18.1117 3.14834 17.4272 3 16.7359 3C16.0446 3 15.3601 3.14834 14.7215 3.43656C14.0829 3.72477 13.5026 4.14721 13.0139 4.67974L11.9997 5.7844L10.9855 4.67974C9.99842 3.60458 8.6596 3.00056 7.26361 3.00056C5.86761 3.00056 4.52879 3.60458 3.54168 4.67974C2.55456 5.7549 2 7.21313 2 8.73364C2 10.2541 2.55456 11.7124 3.54168 12.7875L4.55588 13.8922L11.9997 22L19.4436 13.8922L20.4578 12.7875C20.9467 12.2553 21.3346 11.6233 21.5992 10.9277C21.8638 10.2321 22 9.48657 22 8.73364C22 7.98071 21.8638 7.23516 21.5992 6.53958C21.3346 5.84399 20.9467 5.21201 20.4578 4.67974Z" fill="#C0C0C0"/>
                                    </svg>
                                    <div onClick={handleFavoriteMentors}>favorite mentors</div>
                                </Tab>
                                <Tab onClick={removeClass}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" fill="#C0C0C0" stroke="#C0C0C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M14 2V8H20" fill="white"/>
                                        <path d="M14 2V8H20" stroke="#C0C0C0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16 13H8" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16 17H8" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10 9H9H8" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div onClick={handleSavedResource}>Saved resources</div>
                                    </Tab>
                                <Tab onClick={removeClass}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" fill="#C0C0C0"/>
                                        <path d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z" fill="#C0C0C0" stroke="#FAFAFA" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div onClick={handleCommunityPost}>My community posts</div>
                                </Tab>
                                <Tab onClick={removeClass}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.24922 22L8.84922 18.8C8.63255 18.7167 8.42855 18.6167 8.23722 18.5C8.04522 18.3833 7.85755 18.2583 7.67422 18.125L4.69922 19.375L1.94922 14.625L4.52422 12.675C4.50755 12.5583 4.49922 12.4457 4.49922 12.337V11.662C4.49922 11.554 4.50755 11.4417 4.52422 11.325L1.94922 9.375L4.69922 4.625L7.67422 5.875C7.85755 5.74167 8.04922 5.61667 8.24922 5.5C8.44922 5.38333 8.64922 5.28333 8.84922 5.2L9.24922 2H14.7492L15.1492 5.2C15.3659 5.28333 15.5702 5.38333 15.7622 5.5C15.9536 5.61667 16.1409 5.74167 16.3242 5.875L19.2992 4.625L22.0492 9.375L19.4742 11.325C19.4909 11.4417 19.4992 11.554 19.4992 11.662V12.337C19.4992 12.4457 19.4826 12.5583 19.4492 12.675L22.0242 14.625L19.2742 19.375L16.3242 18.125C16.1409 18.2583 15.9492 18.3833 15.7492 18.5C15.5492 18.6167 15.3492 18.7167 15.1492 18.8L14.7492 22H9.24922ZM12.0492 15.5C13.0159 15.5 13.8409 15.1583 14.5242 14.475C15.2076 13.7917 15.5492 12.9667 15.5492 12C15.5492 11.0333 15.2076 10.2083 14.5242 9.525C13.8409 8.84167 13.0159 8.5 12.0492 8.5C11.0659 8.5 10.2366 8.84167 9.56122 9.525C8.88655 10.2083 8.54922 11.0333 8.54922 12C8.54922 12.9667 8.88655 13.7917 9.56122 14.475C10.2366 15.1583 11.0659 15.5 12.0492 15.5Z" fill="#C0C0C0"/>
                                </svg>
                                    <div onClick={handleSetting}>settings</div>
                                </Tab>
                            </TabList>
                            <div className='logOut'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none" width="24" height="24">
                                <path className="st0" d="M12.6,6.3V2.5c0,0-0.1-0.9,0.4-1.1c0.6-0.2,1,0.1,1.1,0.2S21.5,9,21.5,9S22,9.5,22,9.7c0,0.2,0,0.5-0.4,0.9
                                    C21.2,11,14.2,18,14.2,18s-0.7,0.8-1.3,0.2c-0.4-0.3-0.3-0.9-0.4-1.2c0-0.3,0-3.5,0-3.5h-6c0,0-1.1,0-1.1-1s0-5.2,0-5.2
                                    s-0.1-1.1,1-1.1S12.6,6.3,12.6,6.3z" fill="#C0C0C0"/>
                                <path className="st0" d="M4.6,2.7h3.9c0,0,0.5,0,0.5-0.4s0-1,0-1S8.8,0.9,8.5,0.9c-0.3,0-4.4,0-4.4,0S0,1,0,5.1s0,9,0,9
                                    s-0.1,4.6,3.7,4.6c3.8,0,4.9,0.1,4.9,0.1S9,18.7,9,18.4c0-0.3,0-0.8,0-0.8s0.1-0.5-0.6-0.5c-0.7,0-4.3,0-4.3,0s-2.4,0.2-2.4-3
                                    s0.1-9,0.1-9S1.5,2.8,4.6,2.7z" fill="#C0C0C0"/>
                                </svg>
                                <div onClick={handleopenLogoutPopup}>Log out</div>
                            </div>
                        </div>
                        <div className={`dashtabsbutton ${isActive ? 'active' : ''}`} onClick={addClass}>Dashboard Menu <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m31.71 15.29-10-10-1.42 1.42 8.3 8.29H0v2h28.59l-8.29 8.29 1.41 1.41 10-10a1 1 0 0 0 0-1.41z" data-name="3-Arrow Right"/></svg></div>
                    </div>
                </div>
                <div className="profile-dashboard-right">
                    <div className="profile-dashboard-right-wrapper">
                        <TabPanel>
                            {post?.upcomingBookingsFetch?.length > 0 || post?.completedBookingsFetch?.length > 0 || post?.onGoingBookingsFetch?.length > 0 ? <DashFilled props={post} gotoSession={handleSessionClick}/> : <DashEmpty props={post} />}
                        </TabPanel>
                        <TabPanel>
                            <Messages/>
                        </TabPanel>
                        <TabPanel>
                            <DashboardSessions/>
                        </TabPanel>
                        <TabPanel>
                            <FavouriteMentors/>
                        </TabPanel>
                        <TabPanel>
                            <SavedResources />
                        </TabPanel>
                        <TabPanel>
                            <MentorCommunity/>
                        </TabPanel>
                        <TabPanel>
                            <Settings noti={notify}/>
                        </TabPanel>
                    </div>
                </div>
            </div>
        </Tabs>
    </section>
    <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ openLogoutPopup ? 'active' : ''}`}>
        {openLogoutPopup && <ConfirmLogoutPopup onclose={handlecloseLogoutPopup} />}
    </div>
    </>
    )
}
