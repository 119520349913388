import React from 'react';
import NoSesh from '../../assets/images/no-sesh.svg';
import './profile.css';
import { Link } from 'react-router-dom';

export default function FavouriteEmpty() {
  return (
   <div className="fav-mentors-empty dashboard-empty-state">
         <div className="dash-empty-sessions-content">
                    <div className="dash-empty-sess-head">Favorite Mentors</div>
                    <div className="dash-empty-sess-body"><p><b><i>Find your Favourite mentors here</i></b></p></div>
                </div>

                <div className="dash-emp-no-sess">
                     <div className="dash-emp-no-sess-wrap">
                        <div className="dash-emp-no-sess-img">
                            <img src={NoSesh} alt="" />
                        </div>
                        <div className="dash-emp-no-sess-head">You haven’t favorited any mentors</div>
                        <div className="dash-emp-no-sess-body">
                            <p> Don't worry, our experienced mentors are
                                available to connect with you and help you grow.</p>
                        </div>
                        <div className="dash-emp-no-sess-btn">
                            <Link to="/explore-our-mentors" className="primary-btn black">explore mentors</Link>
                        </div>
                     </div>
                </div>       
   </div>
  )
}
