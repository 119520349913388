import React, { useContext, useState } from 'react';
import './mentorbooking.css';
import Calendar from '../Calendar-mentee/Calendar';
import ConfirmPop from './ConfirmPop';
import PaymentPage from '../Payment/PaymentPage';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import PaymentConfPopUp from '../Dashboard/PaymentConfPopUp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MentorBookingGrid({availableDates, availabilities, setAvailabilities, setLoading, selectedDate, setSelectedDate}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [secondStep, setSecondStep] = useState(true);
    const [confirmPop, setConfirmPop] = useState(false);
    const [paymentPop, setPaymentPop] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState({});
    const [agenda, setAgenda] = useState('');
    const [mentorMessage, setMentorMessage] = useState('');
    const [agendaError, setAgendaError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [duration, setDuration] = useState('');
    const [availabilityId, setAvailabilityId] = useState('');
    const [sessionCost, setSessionCost] = useState('');
    const {state, showPayemntSuccessPopUp, setShowPayemntSuccessPopUp, mentorIds , setMentorIds} = useContext(AuthContext);
    const {handleTabIndex} = useContext(ChatContext);
    const {createChat, UpdatePotentialUserList, potentialChats} = useContext(ChatContext)


    console.log('availableDates ', availableDates);
    console.log('availabilities ', availabilities);
    console.log('selectedSlot ', selectedSlot);

    const handleAgendaChange = (e) => {
        setAgenda(e.target.value);
    };

    const handleMentorMessageChange = (e) => {
        setMentorMessage(e.target.value);
    };


    const handleConfirmPop = () => {
        if (!agenda.trim()) {
            setAgendaError("Agenda is Required");
            return;
        }
        else{
            setAgendaError("");
        }
        if (!mentorMessage.trim()) {
            setMessageError("Message is Required");
            return;
        }
        else{
            setMessageError("");
        }
        setConfirmPop(true);
        document.body.classList.add('popup-open');
    }
    const handleClosePop = () => {
        setConfirmPop(false);
        document.body.classList.remove('popup-open');
    }
    const handlePaymentClose = () => {
        setPaymentPop(false);
        document.body.classList.remove('popup-open');
    };
    const bio = availabilities?.mentorDetails?.bio

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    }

    const handleTrueState = () => {
        if(Object.keys(selectedSlot).length){
            setSecondStep(false);
        }else{
            setSecondStep(true);
        }
        
    }

    const handleBookingStatus = () => {
        notify();
    }

    const notify = () => toast.success(
        <div className="custom-toast">
            <div className="custom-toast-top flex align">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#F45E5C"/>
                    <path d="M11.25 4.75L4.75 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.75 4.75L11.25 11.25" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
          <span>Warning</span>
            </div>
            <div className="custom-toast-bot">
              <span>The selected slot is already booked. Please select another slot or another date.</span>
            </div>
        </div>,
        {
          className: 'custom-toast-container-error',
        }
      );

    const handleSelectedSlot = (slot) => {
        setSelectedSlot(slot)
        setDuration(`${slot.duration} mins`);
        setAvailabilityId(slot.availabilityId);
        let hourlyRate = Number(availabilities?.mentorDetails?.hourlyRate.replace('$', '')/60);
        let sessionCost = hourlyRate * Number(slot.duration);
        let formattedSessionCost = `$${sessionCost.toFixed(2)}`;
        setSessionCost(formattedSessionCost);
    }

    const disabledDates = ['2023-10-12', '2023-10-15', '2023-10-22'];
  return (
    <>
   <div className="mentor-bookinggrid">
    <div className="mentor-bookinggrid-wrap">
        <div className="mentor-bookinggrid-left">
            <div className="mentor-bookinggrid-left-wrap">
                <div className="mentor-booking-mentorDeets">
                    <div className="mentor-booking-mentorDeets-pic">
                        <img src={availabilities?.mentorDetails?.avatar} alt="avatar" />
                    </div>
                    <div className="mentor-booking-mentorDeets-name-desig">
                        <div className="mentor-booking-mentorDeets-name">
                            {availabilities?.mentorDetails?.fullName}
                        </div>
                        <div className="mentor-booking-mentorDeets-desig">
                            <div className="mentor-booking-mentorDeets-desig-price"> {availabilities?.mentorDetails?.hourlyRate}</div>
                            <div className="mentor-booking-mentorDeets-desig-sep"></div>
                            <div className="mentor-booking-mentorDeets-desig-time">60 min</div>
                        </div>
                        
                    </div>
                    <div className="mentor-booking-mentorDeets-experience">
                        <div className="mentor-booking-mentorDeets-experience-top">
                            <div className="mentor-booking-mentorDeets-experience-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <g clipPath="url(#clip0_4438_79483)">
                                <path d="M10 4H2C1.44772 4 1 4.38376 1 4.85714V9.14286C1 9.61624 1.44772 10 2 10H10C10.5523 10 11 9.61624 11 9.14286V4.85714C11 4.38376 10.5523 4 10 4Z" fill="#C0C0C0" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 3V1.22222C8 1.16329 7.89464 1.10676 7.70711 1.06509C7.51957 1.02341 7.26522 1 7 1H5C4.73478 1 4.48043 1.02341 4.29289 1.06509C4.10536 1.10676 4 1.16329 4 1.22222V3" stroke="#BABABA" strokeLinecap="square" strokeLinejoin="round"/>
                                <path d="M8 11V4M4 4V11" stroke="#FEFBF4" strokeLinecap="square" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_4438_79483">
                                <rect width="12" height="12" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                            </div>
                            <div className="mentor-booking-mentorDeets-experience-text">
                            {availabilities?.mentorDetails?.designation}
                            </div>
                        </div>
                        <div className="mentor-booking-mentorDeets-experience-bottom">
                        <div className="mentor-booking-mentorDeets-experience-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path d="M9 4H9.5C10.0304 4 10.5391 4.21071 10.9142 4.58579C11.2893 4.96086 11.5 5.46957 11.5 6C11.5 6.53043 11.2893 7.03914 10.9142 7.41421C10.5391 7.78929 10.0304 8 9.5 8H9" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1 3.5H9V8C9 8.53043 8.78929 9.03914 8.41421 9.41421C8.03914 9.78929 7.53043 10 7 10H3C2.46957 10 1.96086 9.78929 1.58579 9.41421C1.21071 9.03914 1 8.53043 1 8V3.5Z" fill="#C0C0C0"/>
                                <path d="M3 0.5V2" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5 0.5V2" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7 0.5V2" stroke="#C0C0C0" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="mentor-booking-mentorDeets-experience-text">
                            {availabilities?.mentorDetails?.levelOfExp}
                            </div>
                        </div>
                    </div>
                </div>
                {secondStep ? 
                    <>
                    <div className="mentee-profile-about">
                        <div className="mentee-profile-about-head">About me</div>
                        <div className={`mentee-profile-about-body ${isExpanded ? 'expanded' : ''}`}>
                            <p>{bio}</p>
                            
                        </div>
                        {bio?.split(' ')?.length > 25 && (
                                <div
                                className="mentee-profile-about-show-btn"
                                onClick={toggleExpansion}
                                >
                                {isExpanded ? 'Show less' : 'Show more'}
                                </div>
                            )}
                    </div>
                    </> 
                    : 
                    <>
                    <div className="mentor-booking-mentorDeets-note">
                        <div className="slot-booking-note">
                            <div className="slot-booking-note-wrap">
                                <div className="slot-booking-note-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M4.11267 7.78644L7.78906 4.11029L4.63748 1.48438L1.48624 4.63541L4.11267 7.78644Z" fill="#BE1931"></path>
                                        <path d="M0.433686 4.63648C1.01357 5.217 1.95407 5.217 2.53396 4.63648L4.6349 2.53602C5.21545 1.95618 5.21545 1.01506 4.6349 0.434883C4.05535 -0.144961 3.11451 -0.144961 2.53396 0.434883L0.433686 2.53602C-0.146533 3.11586 -0.146533 4.05664 0.433686 4.63648Z" fill="#DD2E44"></path>
                                        <path d="M7.35212 5.94922C7.35212 5.94922 12.2521 11.7262 11.9898 11.9894C11.7273 12.2523 5.94959 7.35166 5.94959 7.35166L7.35212 5.94922Z" fill="#99AAB5"></path>
                                        <path d="M3.58463 9.36273C4.16417 9.94324 5.10501 9.94324 5.6859 9.36273L9.36196 5.68624C9.94185 5.10606 9.94185 4.16596 9.36196 3.58578C8.78174 3.00526 7.8409 3.00526 7.26069 3.58578L3.58463 7.26193C3.00441 7.84244 3.00441 8.78221 3.58463 9.36273Z" fill="#DD2E44"></path>
                                    </svg>
                                </div>
                                <div className="slot-booking-note-text"><strong>Note: </strong>Session cost & duration might change with changing dates and time.</div>
                            </div>
                        </div>
                         <div className="mentor-booking-mentorDeets-session-deets">
                            <div className="mentor-booking-mentorDeets-session-head">Session Details</div>
                            <div className="mentor-booking-mentorDeets-session-details">
                                 <div className="mentor-booking-mentorDeets-session-details-text">{availabilities?.sessionDetails?.sessionDate}</div>
                                 <div className="mentor-booking-mentorDeets-session-details-sep"></div>
                                 <div className="mentor-booking-mentorDeets-session-details-text">{Object.keys(selectedSlot).length && selectedSlot.startTime}</div>
                                 <div className="mentor-booking-mentorDeets-session-details-sep"></div>
                                 <div className="mentor-booking-mentorDeets-session-details-text">{availabilities?.sessionDetails?.timezone}</div>
                            </div>
                         </div>
                        <div className="mentor-bookinggrid-select-calendar-right-bottom-sep">

                        </div>
                         <div className="mentor-bookinggrid-select-calendar-right-bottom-boxes">
                                <div className="mentor-bookinggrid-select-calendar-right-bottom-box">
                                    <div className="mentor-bookinggrid-select-calendar-right-bottom-box-wrap">
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-top">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                                    <g clipPath="url(#clip0_4438_79636)">
                                                    <path d="M9.5 2.27344H2.5C1.94772 2.27344 1.5 2.72115 1.5 3.27344V10.2734C1.5 10.8257 1.94772 11.2734 2.5 11.2734H9.5C10.0523 11.2734 10.5 10.8257 10.5 10.2734V3.27344C10.5 2.72115 10.0523 2.27344 9.5 2.27344Z" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M8 1.27344V3.27344" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M4 1.27344V3.27344" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M1.5 5.27344H10.5" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M1.5 3.52344V4.27344C1.5 4.82572 1.94772 5.27344 2.5 5.27344H9.25C9.80229 5.27344 10.25 4.82572 10.25 4.27344V3.52344C10.25 2.97115 9.80229 2.52344 9.25 2.52344H2.5C1.94772 2.52344 1.5 2.97115 1.5 3.52344Z" fill="#DAD5CB"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_4438_79636">
                                                    <rect width="12" height="12" fill="white" transform="translate(0 0.273438)"/>
                                                    </clipPath>
                                                    </defs>
                                                    </svg>
                                            </div>
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-label">
                                                    Session Cost
                                            </div>
                                        </div>
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-bottom">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-head">
                                            {sessionCost}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mentor-bookinggrid-select-calendar-right-bottom-box">
                                    <div className="mentor-bookinggrid-select-calendar-right-bottom-box-wrap">
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-top">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                                <g clipPath="url(#clip0_4438_79647)">
                                                <path d="M6 11.7734C9.03757 11.7734 11.5 9.311 11.5 6.27344C11.5 3.23587 9.03757 0.773438 6 0.773438C2.96243 0.773438 0.5 3.23587 0.5 6.27344C0.5 9.311 2.96243 11.7734 6 11.7734Z" fill="#DAD5CB" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6 3.27344V6.27344L8 7.27344" stroke="#FEFBF4" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_4438_79647">
                                                <rect width="12" height="12" fill="white" transform="translate(0 0.273438)"/>
                                                </clipPath>
                                                </defs>
                                                </svg>
                                            </div>
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-label">
                                            Session Duration
                                            </div>
                                        </div>
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-bottom">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-head">
                                            {duration}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                         </div>
                    </div>
                    </>
                }
            
            </div>
        </div>
        <div className="mentor-bookinggrid-right">
            <div className="mentor-bookinggrid-right-wrap">
                <div className="mentor-bookinggrid-right-top">
                    <div className="schedule-steps-label">{secondStep ? 'Step 1/2': 'Step 2/2'}</div>
                    <div className="login-head"><span>Schedule Session</span></div>
                </div>
                <div className="mentor-bookinggrid-right-sep"></div>
               {secondStep?  
                <>
                <div className="mentor-bookinggrid-right-middle">
                    <div className="mentor-bookinggrid-select-date">
                        <div className="dash-empty-sess-head">1. Select Date</div>
                        <div className="dash-empty-sess-body"><p>Choose a date that suits you best, you can book sessions upto one month in advance.</p></div>
                    </div>
                    <div className="mentor-bookinggrid-select-calendar">
                        <div className="mentor-bookinggrid-select-calendar-left">
                           <Calendar disabledDates={disabledDates} availableDates={availableDates} setAvailabilities={setAvailabilities} setLoading={setLoading} setSelectedSlot={setSelectedSlot} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                        </div>
                        <div className="mentor-bookinggrid-select-calendar-right">
                           <div className="mentor-bookinggrid-select-calendar-right-top">
                                <div className="slot-booking-note">
                                    <div className="slot-booking-note-wrap">
                                            <div className="slot-booking-note-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                    <path d="M4.11267 7.78644L7.78906 4.11029L4.63748 1.48438L1.48624 4.63541L4.11267 7.78644Z" fill="#BE1931"></path>
                                                    <path d="M0.433686 4.63648C1.01357 5.217 1.95407 5.217 2.53396 4.63648L4.6349 2.53602C5.21545 1.95618 5.21545 1.01506 4.6349 0.434883C4.05535 -0.144961 3.11451 -0.144961 2.53396 0.434883L0.433686 2.53602C-0.146533 3.11586 -0.146533 4.05664 0.433686 4.63648Z" fill="#DD2E44"></path>
                                                    <path d="M7.35212 5.94922C7.35212 5.94922 12.2521 11.7262 11.9898 11.9894C11.7273 12.2523 5.94959 7.35166 5.94959 7.35166L7.35212 5.94922Z" fill="#99AAB5"></path>
                                                    <path d="M3.58463 9.36273C4.16417 9.94324 5.10501 9.94324 5.6859 9.36273L9.36196 5.68624C9.94185 5.10606 9.94185 4.16596 9.36196 3.58578C8.78174 3.00526 7.8409 3.00526 7.26069 3.58578L3.58463 7.26193C3.00441 7.84244 3.00441 8.78221 3.58463 9.36273Z" fill="#DD2E44"></path>
                                                </svg>
                                            </div>
                                            <div className="slot-booking-note-text"><strong>Note: </strong>Session cost & duration might change with changing dates and time.</div>
                                    </div>
                                </div>
                           </div>
                           <div className="mentor-bookinggrid-select-calendar-right-bottom">
                              <div className="mentor-bookinggrid-select-calendar-right-bottom-date">
                                  <div className="mentor-bookinggrid-select-calendar-right-bottom-date-label">Session Date</div>
                                  <div className="mentor-bookinggrid-select-calendar-right-bottom-date-text">{availabilities?.sessionDetails?.sessionDate}</div>
                              </div>
                              <div className="mentor-bookinggrid-select-calendar-right-bottom-sep">

                              </div>
                              <div className="mentor-bookinggrid-select-calendar-right-bottom-boxes">
                                <div className="mentor-bookinggrid-select-calendar-right-bottom-box">
                                    <div className="mentor-bookinggrid-select-calendar-right-bottom-box-wrap">
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-top">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                                    <g clipPath="url(#clip0_4438_79636)">
                                                    <path d="M9.5 2.27344H2.5C1.94772 2.27344 1.5 2.72115 1.5 3.27344V10.2734C1.5 10.8257 1.94772 11.2734 2.5 11.2734H9.5C10.0523 11.2734 10.5 10.8257 10.5 10.2734V3.27344C10.5 2.72115 10.0523 2.27344 9.5 2.27344Z" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M8 1.27344V3.27344" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M4 1.27344V3.27344" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M1.5 5.27344H10.5" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M1.5 3.52344V4.27344C1.5 4.82572 1.94772 5.27344 2.5 5.27344H9.25C9.80229 5.27344 10.25 4.82572 10.25 4.27344V3.52344C10.25 2.97115 9.80229 2.52344 9.25 2.52344H2.5C1.94772 2.52344 1.5 2.97115 1.5 3.52344Z" fill="#DAD5CB"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_4438_79636">
                                                    <rect width="12" height="12" fill="white" transform="translate(0 0.273438)"/>
                                                    </clipPath>
                                                    </defs>
                                                    </svg>
                                            </div>
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-label">
                                                    Session Cost
                                            </div>
                                        </div>
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-bottom">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-head">
                                            {sessionCost}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mentor-bookinggrid-select-calendar-right-bottom-box">
                                    <div className="mentor-bookinggrid-select-calendar-right-bottom-box-wrap">
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-top">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                                <g clipPath="url(#clip0_4438_79647)">
                                                <path d="M6 11.7734C9.03757 11.7734 11.5 9.311 11.5 6.27344C11.5 3.23587 9.03757 0.773438 6 0.773438C2.96243 0.773438 0.5 3.23587 0.5 6.27344C0.5 9.311 2.96243 11.7734 6 11.7734Z" fill="#DAD5CB" stroke="#DAD5CB" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6 3.27344V6.27344L8 7.27344" stroke="#FEFBF4" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_4438_79647">
                                                <rect width="12" height="12" fill="white" transform="translate(0 0.273438)"/>
                                                </clipPath>
                                                </defs>
                                                </svg>
                                            </div>
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-label">
                                            Session Duration
                                            </div>
                                        </div>
                                        <div className="mentor-bookinggrid-select-calendar-right-bottom-box-bottom">
                                            <div className="mentor-bookinggrid-select-calendar-right-bottom-box-head">
                                            {duration}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                           </div>
                        </div>
                    </div>
                </div>
                <div className="mentor-bookinggrid-right-sep"></div>
                <div className="mentor-bookinggrid-right-bottom">
                    <div className="mentor-bookinggrid-select-date">
                        <div className="dash-empty-sess-head">2. Select Time</div>
                        <div className="dash-empty-sess-body">
                            <p>Each session on {availabilities?.sessionDetails?.sessionDate} would be {duration} long.
                                    Ex: A session that starts at 6:00 am would end at 6:30 am.</p>
                        </div>
                    </div>
                    <div className="mentor-bookinggrid-right-bottom-slots">
                        <ul>
                
                        {availabilities?.sessionDetails?.slots?.map((slot, index) => {

                                const isSelected = selectedSlot === slot;

                            return (
                                // <li
                                //     key={index}
                                //     onClick={() => handleSelectedSlot(slot)}
                                //     className={`${
                                //         isSelected ? 'selected-slot' : ''
                                //         }${!slot.status? 'fully-disabled' : ''}`}
                                // >
                                <li
                                    key={index}
                                    onClick={!slot.status ? handleBookingStatus : () => handleSelectedSlot(slot)}
                                    className={`${
                                        isSelected ? 'selected-slot' : ''
                                    }${!slot.status ? 'fully-disabled' : ''}`}
                                >
                                {slot.startTime} 
                                <div className="slot-selected-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                        <path d="M10.668 3L5.16797 8.5L2.66797 6" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                </li>
                            );
                        })}

                            
                            
                        </ul>
                    </div>
                </div>
                <div className="mentor-bookinggrid-button" onClick={handleTrueState}>
                    <button className="primary-btn gren">
                        Confirm & continue
                    </button>
                </div>
                </>
                :
                <>
                <div className="mentor-bookinggrid-session-details">
                    <div className="mentor-bookinggrid-session-details-head">Session Details</div>
                    <div className="mentor-bookinggrid-session-details-inputs">
                        <div className="mentor-bookinggrid-session-details-input">
                            <label className='mentor-bookinggrid-session-details-input-label' htmlFor="agenda">What is the agenda of the session?</label>
                            {agendaError && <p className="error-name-onb">{agendaError}</p>}
                            <input type="text" name="agenda" id="agenda" placeholder='Write one line agenda...' value={agenda}
          onChange={handleAgendaChange} />
                        </div>
                        <div className="mentor-bookinggrid-session-details-input">
                            <label className='mentor-bookinggrid-session-details-input-label' htmlFor="mentor-message">Add your message for the mentor</label>
                            {messageError && <p className="error-name-onb">{messageError}</p>}
                            <textarea type="text" name="mentor-message" id="mentor-message" placeholder='Write a concise message for the mentor...' value={mentorMessage}
          onChange={handleMentorMessageChange}/>
                        </div>
                    </div>
                    <div className="mentor-bookinggrid-session-details-btn">
                        <button onClick={handleConfirmPop} className="primary-btn">
                        Confirm & continue
                        </button>
                    </div>
                    <div className="mentor-bookinggrid-session-details-btn-2" onClick={() => setSecondStep(true)}>
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                            <path d="M4.5 1L0.5 5L4.5 9" stroke="#222222" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>
                            Change date and time
                        </button>
                    </div>
                </div>
                </>
               }
            </div>
        </div>
    </div>
    <div className={`mentor-bookinggrid-confirmPop ${confirmPop ? 'active': ''}`}>
        <ConfirmPop onClose={handleClosePop} sessionDate={availabilities?.sessionDetails?.sessionDate} startTime={Object.keys(selectedSlot).length && selectedSlot.startTime} timezone={availabilities?.sessionDetails?.timezone} sessionCost={sessionCost} sessionDuration={duration} fullName={availabilities?.mentorDetails?.fullName} setPaymentPop={setPaymentPop}/>
    </div>
     <div className={`mentor-bookinggrid-paymentPop ${paymentPop ? 'active': ''}`}> 
        <PaymentPage onClose={handlePaymentClose} agenda={agenda} sessionCost={sessionCost} description={mentorMessage} mentorId={availabilities?.mentorDetails?.userId} availabilityId={availabilityId} slotId={selectedSlot?._id}/>
     </div>
   </div>
   <div className={`login-sign-up-pop-up flex flex-c overview-profile-pop ${ showPayemntSuccessPopUp ? 'active' : ''}`}>
                {showPayemntSuccessPopUp && <PaymentConfPopUp />}
                </div>  
   </>
  )
}
