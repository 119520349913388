import React from 'react';
import axios from 'axios';
import Header from '../../layouts/header/Header';
import Footer from '../../layouts/footer/Footer';
import BannerBecome from '../../components/become-a-mentor/become-a-mentor-banner';
import BamSecond from '../../components/become-a-mentor/bam-second';
import BamGetAhead from '../../components/become-a-mentor/bam-get-ahead';
import TopCatog from '../../components/reusable/top-categories/TopCatog';
import Testimonials from '../../components/testimonials/Testimonials';
import AboutEmpowering from '../../components/about/about-empowering';
import ExploreMentors from '../../components/reusable/explore-mentors/ExploreMentors';
import FAQ from '../../components/reusable/faq/FAQ';
import Cookies from 'js-cookie';

export default function BecomeAMentor() {
  const [bamData, setBamData] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("./bam.json")
    .then((res) => setBamData(res.data))
    .catch(err=>console.log(err))
  },[]);

  // Fetch the user role from the cookie
const userRole = Cookies.get('userRole');

// Check if the userRole cookie exists and is not empty
// if (userRole) {
//   // Use the user role in your application logic
//   console.log('User role:', userRole);
// } else {
//   // Handle the case where the user role is not found
//   console.log('User role:', userRole);
// }

const [topMentorData, settopMentorData] = React.useState([]);
React.useEffect(() => {
  const url = process.env.REACT_APP_API_URL+'/all-mentors'
  axios
  .get(url)
  .then((res) => settopMentorData(res.data))
  .catch(err=>console.log(err))
},[]);

  return (

    <main className="insage-become-a-mentor">
        <Header/>
        <BannerBecome props={bamData.bannerData} propsrole={userRole}/>
        <BamSecond props={bamData.ecosystem} topMentorData={topMentorData}/>
        <BamGetAhead props={bamData.getAhead} propsrole={userRole}/>
        <TopCatog/>
        <Testimonials />
        <AboutEmpowering props={bamData.empowers} />
        <ExploreMentors topMentorData={topMentorData}/>
        <FAQ props={bamData.faq}/>
        <Footer/>
    </main>
    
  )
}
