import React, { useState, useEffect } from 'react';
import './contact.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import twitter from '../../assets/images/twitter-icon.jpeg';

const ContactForm = ({noti}) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        topic: '',
        message : '',
        email : '',
      });

      const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        topic: '',
        message: '',
        email: '',
      });
      const { firstName, lastName, topic, message, email } = formData;
      const [loading, setLoading] = useState(false);

      const validateForm = () => {
        let valid = true;
        const newErrors = { ...errors };
    
        // Validation for firstName
        if (!firstName.trim()) {
          newErrors.firstName = 'First Name is required';
          valid = false;
        } else {
          newErrors.firstName = '';
        }
    
        // Validation for lastName
        if (!lastName.trim()) {
          newErrors.lastName = 'Last Name is required';
          valid = false;
        } else {
          newErrors.lastName = '';
        }
    
        // Validation for topic
        if (!topic) {
          newErrors.topic = 'Please select a topic';
          valid = false;
        } else {
          newErrors.topic = '';
        }
    
        // Validation for email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim() || !emailRegex.test(email)) {
          newErrors.email = 'Please enter a valid email address';
          valid = false;
        } else {
          newErrors.email = '';
        }
    
        // Validation for message
        if (!message.trim()) {
          newErrors.message = 'Message is required';
          valid = false;
        } else {
          newErrors.message = '';
        }
    
        setErrors(newErrors);
        return valid;
      };

const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
        ...errors,
        [e.target.name]: '',
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
        submitForm(formData);
    }
    
  };

  const submitForm = async (data) => {
    try {
        console.log(data);
      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint where you want to send the data.
      setLoading(true);
      const url = process.env.REACT_APP_API_URL+'/contact-us';
      const response = await axios.post(url, data);
     if(response){
        noti();
        setLoading(false);
        setFormData({
            firstName: '',
            lastName: '',
            topic: '',
            message : '',
            email : '',
          })

     }

      // Optionally, you can show a success message to the user or perform other actions after successful submission.
    } catch (error) {
      console.error('Error submitting the form:', error.response);

      // Optionally, you can show an error message to the user or perform other error handling.
    }
  };
  

  return (
   <section className="insage-contact-form">
    <div className="insage-contact-form-wrapper flex">
        <div className="isg-con-from-left">
            <div className="isg-con-left-wrapper">
                <div className="isg-con-form-heading"><h3>Say Hi and Let's Talk!</h3></div>
                <div className="isg-con-form-wrapper">
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="topic">Topic:</label>
                        <div>{errors.topic && <p className='error-name-onb'>{errors.topic}</p>}</div>
                        <select
                        id="topic"
                        name="topic"
                        value={topic}
                        onChange={handleChange}
                        >
                        <option value="" disabled>Select a topic</option>
                        <option value="General Inquiry">General</option>
                        <option value="Feedback">Feedback</option>
                        <option value="Query">Query</option>
                        <option value="Report">Report</option>
                        <option value="Mentee Related">Mentee Related</option>
                        <option value="Mentor Related">Mentor Related</option>
                        <option value="Earnings">Earnings</option>
                        <option value="Regarding Sessions">Regarding Sessions</option>
                        <option value="Purchase & Refunds">Purchase & Refunds</option>
                        {/* Add more options as needed */}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="firstName">First Name:</label>
                        <div>{errors.firstName && <p className='error-name-onb'>{errors.firstName}</p>}</div>
                        <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder='Type here'
                        value={firstName}
                        onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName">Last Name:</label>
                        <div>{errors.lastName && <p className='error-name-onb'>{errors.lastName}</p>}</div>
                        <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder='Type here'
                        value={lastName}
                        onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <div>{errors.email && <p className='error-name-onb'>{errors.email}</p>}</div>
                        <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder='Enter Email'
                        value={email}
                        onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="message">Briefly, tell us the reason for contacting us</label>
                        <div>{errors.message && <p className='error-name-onb'>{errors.message}</p>}</div>
                        <textarea
                        id="message"
                        name="message"
                        placeholder='I’d like to know more about...'
                        value={message}
                        onChange={handleChange}
                        />
                    </div>
                    <button className='primary-btn black' type="submit">
                            {loading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    'Submit'
                                )}
                    </button>
                    {/* <div className="message">{message ? <p>{message}</p> : null}</div> */}
                 </form>
                </div>
            </div>
        </div>
        <div className="isg-con-from-right">
            <div className="isg-con-right-wrapper">
                <div className="isg-con-right-top">
                    <div className="isg-con-right-top-wrapper">
                        <div className="isg-con-right-top-head">
                            <h6>What is Insage?</h6>
                        </div>
                        <div className="isg-con-right-top-body">
                            <p>Insage is a cutting-edge online platform for mentors and mentees to connect and grow together. 
                                </p>
                            </div>
                    </div>
                </div>
                <div className="isg-con-right-middle">
                    <div className="isg-con-right-middle-wrapper">
                        <div className="isg-con-right-top-head">
                                <h6>Contact Details</h6>
                         </div>
                         <div className="isg-con-right-middle-icons flex">
                            {/* <div className='isg-con-item'>
                                <div className="isg-con-right-middle-icon">
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.0083 18.2628L20.612 15.8665C19.2891 14.5436 17.1436 14.5436 15.8213 15.8665C15.3285 16.3587 15.0214 16.9653 14.8953 17.5993C10.9437 16.8604 7.10032 13.0329 6.8611 9.61188C7.51022 9.49124 8.13193 9.18211 8.63368 8.67968C9.9566 7.35678 9.9566 5.21136 8.63368 3.88847L6.23802 1.49217C4.91509 0.169276 2.76963 0.169276 1.4467 1.49217C-5.74096 8.67968 15.8207 30.2408 23.0083 23.0533C24.3306 21.7311 24.3306 19.5857 23.0083 18.2628Z" fill="#478554"/>
                                    </svg>
                                </div>
                                <div className="isg-con-right-middle-details">
                                    <div className="isg-con-right-middle-details-head">
                                        Call us At
                                        </div>
                                    <div className="isg-con-right-middle-details-body">
                                        <a href="tel:1-888-708-1115">1-888-708-1115</a>
                                    </div>
                                </div>
                            </div> */}
                            <div className='isg-con-item'>
                                <div className="isg-con-right-middle-icon">
                                    <svg width="36" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.02782 3.5C7.02782 4.052 6.57804 4.5 6.02384 4.5H1.00397C0.44978 4.5 0 4.052 0 3.5C0 2.948 0.44978 2.5 1.00397 2.5H6.02384C6.57804 2.5 7.02782 2.948 7.02782 3.5ZM5.01987 8.5C5.01987 9.052 4.57009 9.5 4.01589 9.5H1.00397C0.44978 9.5 0 9.052 0 8.5C0 7.948 0.44978 7.5 1.00397 7.5H4.01589C4.57009 7.5 5.01987 7.948 5.01987 8.5ZM3.01192 13.5C3.01192 14.053 2.56214 14.5 2.00795 14.5H1.00397C0.44978 14.5 0 14.053 0 13.5C0 12.947 0.44978 12.5 1.00397 12.5H2.00795C2.56214 12.5 3.01192 12.947 3.01192 13.5Z" fill="#55ACEE"/>
                                        <path d="M32.4287 16.5C31.8002 18.709 29.4921 20.5 27.2743 20.5H7.19481C4.97704 20.5 3.68894 18.709 4.31742 16.5L7.72893 4.5C8.35741 2.291 10.6645 0.5 12.8823 0.5H32.9618C35.1796 0.5 36.4687 2.291 35.8402 4.5L32.4287 16.5Z" fill="#CCD6DD"/>
                                        <path d="M17.4052 9.137L4.40174 18.45C4.36761 18.478 4.34753 18.513 4.31641 18.541C4.49612 19.111 4.83646 19.584 5.31235 19.925C5.34749 19.902 5.38564 19.892 5.41977 19.865L18.4232 10.551C18.9262 10.16 19.1069 9.528 18.8258 9.137C18.5437 8.746 17.9092 8.746 17.4052 9.137ZM31.2701 18.541C31.255 18.513 31.254 18.478 31.236 18.451L23.5315 9.137C23.2493 8.746 22.6148 8.746 22.1098 9.137C21.6068 9.528 21.4271 10.16 21.7082 10.551L29.4147 19.865C29.4328 19.891 29.4659 19.902 29.487 19.925C30.1557 19.585 30.7661 19.111 31.2701 18.541Z" fill="#99AAB5"/>
                                        <path d="M35.8413 4.5C36.4698 2.291 35.1807 0.5 32.9629 0.5H12.8835C10.6657 0.5 8.35856 2.291 7.73007 4.5L7.70898 4.574L16.55 13.026C18.1875 14.61 20.353 14.501 22.2977 13.397L35.5773 5.433L35.8413 4.5Z" fill="#99AAB5"/>
                                        <path d="M32.9636 0.5H12.8841C11.2999 0.5 9.68248 1.421 8.65039 2.748L17.904 11.542C18.656 12.261 20.3477 12.271 21.5745 11.542L35.9253 2.774C35.6583 1.433 34.5579 0.5 32.9636 0.5Z" fill="#E1E8ED"/>
                                    </svg>
                                </div>
                                <div className="isg-con-right-middle-details">
                                    <div className="isg-con-right-middle-details-head">
                                    Email Us At
                                        </div>
                                    <div className="isg-con-right-middle-details-body">
                                        <a href="mailto:info@insage.co">info@insage.co</a>
                                    </div>
                                </div>
                            </div>
                            
                         </div>
                    </div>
                </div>
                <div className="isg-con-right-bottom">
                    <div className="isg-con-right-bottom-wrapper">
                        <div className="isg-con-right-top-head">
                                    <h6>Find us on</h6>
                            </div>
                            <div className="isg-con-right-bottom-icons flex">
                                 <a href="https://www.instagram.com/insage.co/" target='_blank'>
                                    <div className="isg-con-right-bottom-icon">
                                        <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.5" width="52" height="52" rx="8" fill="url(#paint0_linear_2785_188572)"/>
                                            <path d="M32.666 13.168H19.3327C15.6508 13.168 12.666 16.1527 12.666 19.8346V33.168C12.666 36.8499 15.6508 39.8346 19.3327 39.8346H32.666C36.3479 39.8346 39.3327 36.8499 39.3327 33.168V19.8346C39.3327 16.1527 36.3479 13.168 32.666 13.168Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M31.3336 25.6604C31.4981 26.7701 31.3086 27.9034 30.7919 28.8991C30.2753 29.8948 29.4578 30.7023 28.4558 31.2066C27.4537 31.711 26.3182 31.8865 25.2106 31.7083C24.1031 31.5301 23.0799 31.0072 22.2867 30.214C21.4935 29.4207 20.9706 28.3976 20.7924 27.29C20.6141 26.1825 20.7897 25.0469 21.294 24.0449C21.7984 23.0429 22.6058 22.2254 23.6016 21.7087C24.5973 21.1921 25.7306 21.0025 26.8403 21.1671C27.9722 21.3349 29.0201 21.8624 29.8292 22.6715C30.6383 23.4806 31.1657 24.5285 31.3336 25.6604Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M33.334 19.168H33.3473" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <defs>
                                            <linearGradient id="paint0_linear_2785_188572" x1="48.425" y1="3.75" x2="-2.275" y2="55.425" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#B465B6"/>
                                            <stop offset="0.187077" stop-color="#B567B3"/>
                                            <stop offset="0.487715" stop-color="#D76A8F"/>
                                            <stop offset="0.683787" stop-color="#E87A7F"/>
                                            <stop offset="0.777893" stop-color="#EA8779"/>
                                            <stop offset="1" stop-color="#EE9A72"/>
                                            </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                 </a>
                                 <a href="https://twitter.com/Insage_official" target="_blank">
                                    <div className="isg-con-right-bottom-icon">
                                        <img src={twitter} alt="" />
                                    </div>
                                 </a>
                                 <a href=" https://www.facebook.com/insage.co " target="_blank">
                                    <div className="isg-con-right-bottom-icon">
                                      <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="52" height="52" rx="8" fill="#00AEFE"/>
                                        <path d="M34.0007 13.168H30.0007C28.2325 13.168 26.5368 13.8703 25.2866 15.1206C24.0364 16.3708 23.334 18.0665 23.334 19.8346V23.8346H19.334V29.168H23.334V39.8346H28.6673V29.168H32.6673L34.0007 23.8346H28.6673V19.8346C28.6673 19.481 28.8078 19.1419 29.0578 18.8918C29.3079 18.6418 29.647 18.5013 30.0007 18.5013H34.0007V13.168Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    </div>
                                 </a>
                                 <a href="https://www.youtube.com/channel/UCvPlRV7gOGXklACDLz7QUiQ" target="_blank">
                                    <div className="isg-con-right-bottom-icon">
                                      <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="52" height="52" rx="8" fill="#F83436"/>
                                        <path d="M40.0528 19.0587C39.8944 18.4259 39.5718 17.8461 39.1177 17.3779C38.6635 16.9097 38.0938 16.5696 37.4661 16.392C35.1728 15.832 25.9995 15.832 25.9995 15.832C25.9995 15.832 16.8261 15.832 14.5328 16.4454C13.9051 16.6229 13.3354 16.963 12.8813 17.4312C12.4271 17.8995 12.1045 18.4792 11.9461 19.112C11.5264 21.4394 11.3211 23.8004 11.3328 26.1654C11.3178 28.5481 11.5232 30.9271 11.9461 33.272C12.1207 33.8852 12.4505 34.4429 12.9037 34.8913C13.3568 35.3398 13.9179 35.6638 14.5328 35.832C16.8261 36.4454 25.9995 36.4454 25.9995 36.4454C25.9995 36.4454 35.1728 36.4454 37.4661 35.832C38.0938 35.6545 38.6635 35.3144 39.1177 34.8462C39.5718 34.3779 39.8944 33.7982 40.0528 33.1654C40.4693 30.8555 40.6746 28.5125 40.6661 26.1654C40.6811 23.7826 40.4758 21.4036 40.0528 19.0587Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M23 30.5286L30.6667 26.1686L23 21.8086V30.5286Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                       </svg>
                                    </div>
                                 </a>
                                 {/* <a href="https://www.reddit.com/user/Insage_Official" target="_blank">
                                    <div className="isg-con-right-bottom-icon">
                                      <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="52" height="52" rx="8" fill="#F83436"/>
                                        <path d="M40.0528 19.0587C39.8944 18.4259 39.5718 17.8461 39.1177 17.3779C38.6635 16.9097 38.0938 16.5696 37.4661 16.392C35.1728 15.832 25.9995 15.832 25.9995 15.832C25.9995 15.832 16.8261 15.832 14.5328 16.4454C13.9051 16.6229 13.3354 16.963 12.8813 17.4312C12.4271 17.8995 12.1045 18.4792 11.9461 19.112C11.5264 21.4394 11.3211 23.8004 11.3328 26.1654C11.3178 28.5481 11.5232 30.9271 11.9461 33.272C12.1207 33.8852 12.4505 34.4429 12.9037 34.8913C13.3568 35.3398 13.9179 35.6638 14.5328 35.832C16.8261 36.4454 25.9995 36.4454 25.9995 36.4454C25.9995 36.4454 35.1728 36.4454 37.4661 35.832C38.0938 35.6545 38.6635 35.3144 39.1177 34.8462C39.5718 34.3779 39.8944 33.7982 40.0528 33.1654C40.4693 30.8555 40.6746 28.5125 40.6661 26.1654C40.6811 23.7826 40.4758 21.4036 40.0528 19.0587Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M23 30.5286L30.6667 26.1686L23 21.8086V30.5286Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                       </svg>
                                    </div>
                                 </a>
                                 <a href="https://www.quora.com/profile/Insage" target="_blank">
                                    <div className="isg-con-right-bottom-icon">
                                      <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="52" height="52" rx="8" fill="#F83436"/>
                                        <path d="M40.0528 19.0587C39.8944 18.4259 39.5718 17.8461 39.1177 17.3779C38.6635 16.9097 38.0938 16.5696 37.4661 16.392C35.1728 15.832 25.9995 15.832 25.9995 15.832C25.9995 15.832 16.8261 15.832 14.5328 16.4454C13.9051 16.6229 13.3354 16.963 12.8813 17.4312C12.4271 17.8995 12.1045 18.4792 11.9461 19.112C11.5264 21.4394 11.3211 23.8004 11.3328 26.1654C11.3178 28.5481 11.5232 30.9271 11.9461 33.272C12.1207 33.8852 12.4505 34.4429 12.9037 34.8913C13.3568 35.3398 13.9179 35.6638 14.5328 35.832C16.8261 36.4454 25.9995 36.4454 25.9995 36.4454C25.9995 36.4454 35.1728 36.4454 37.4661 35.832C38.0938 35.6545 38.6635 35.3144 39.1177 34.8462C39.5718 34.3779 39.8944 33.7982 40.0528 33.1654C40.4693 30.8555 40.6746 28.5125 40.6661 26.1654C40.6811 23.7826 40.4758 21.4036 40.0528 19.0587Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M23 30.5286L30.6667 26.1686L23 21.8086V30.5286Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                       </svg>
                                    </div>
                                 </a> */}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </section>
  )
}

export default ContactForm;